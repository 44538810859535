import React, { Component } from "react";
import PropTypes from "prop-types";
import TodosList from "../list/todos/todos";
import { be, bm } from "../../utils/bliss";

import "./references.css";

const MODULE_NAME = "References";

const regexFindUrl = /(.*)\s?((?:http|ftp|https):\/\/(?:[\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-]))\s?(.*)/i;

const References = ({ edit, references, onDelete, onChange }) => (
  <div className={bm(MODULE_NAME)}>
    {!edit ? (
      <ul className={be(MODULE_NAME, "list")}>
        {references.map(ref => {
          const link = regexFindUrl.exec(ref.data);
          if (link) {
            return (
              <li key={ref.id} className={be(MODULE_NAME, "item")}>
                {link[1]}
                <a href={link[2]} target="_blank">
                  {link[2]}
                </a>
                {link[3]}
              </li>
            );
          }
          if (ref.data) {
            return (
              <li key={ref.id} className={be(MODULE_NAME, "item")}>
                {ref.data}
              </li>
            );
          }
          return (
            <li key="1" className={be(MODULE_NAME, "item")}>
              {ref}
            </li>
          );
        })}
      </ul>
    ) : (
      <TodosList
        inputs={references.map(ref => ({
          value: ref.data,
          name: ref.id,
          data: ref
        }))}
        newBtn="+ pridat dalsi"
        onChange={onChange}
        onDelete={onDelete}
      />
    )}
  </div>
);

References.defaultProps = {
  edit: false,
  references: [],
  onDelete: () => {}
};

References.propTypes = {
  edit: PropTypes.bool,
  onDelete: PropTypes.func,
  references: PropTypes.array
};

export default References;
