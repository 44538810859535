/* eslint-disable no-nested-ternary */
import React from "react";
import { isMobile } from "../../../../utils/utils";
import { be, bm } from "../../../../utils/bliss";
import "../../shopCart.css";
import { PREVIEWS_API } from "../../../../services/variables";
import Text from "../../../../components/text/text";

const MODULE_NAME = "ShopCart";

const BookItem = ({ project, reward, recap, decrement, increment, remove, number }) => {
  const image = project.moonImage || (project.coverPhoto ? `${PREVIEWS_API}${project.coverPhoto}` : null);

  return recap ? (
    !isMobile() ? (
      <div>
        <div className="row">
          <div className="col-lg-4 col-md-10" style={{ display: "flex", alignItems: "flexStart" }}>
            <img className="align-self-center mr-3 cover" src={image} alt="cover" style={{ height: "75px" }} />
            <div style={{ flex: "1", paddingTop: "10px" }}>
              <p style={{ margin: "0" }} className="title">
                <Text small bold>
                  {project.name}
                </Text>
              </p>
              <p style={{ margin: "0" }} className="autor">
                <Text medium>{project.authorName}</Text>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3" style={{ paddingTop: "25px" }}>
            <Text medium disabled>
              {reward.title}
            </Text>
          </div>
          <div className="col-lg-3 col-md-3" style={{ paddingTop: "25px" }}>
            <div>{number} ks</div>
          </div>
          <div className="col-md-2 price text-right" style={{ paddingTop: "25px" }}>
            <b>{Math.round(number * reward.price)} Kč</b>
          </div>
        </div>
        <hr />
      </div>
    ) : (
      <div>
        <div className="row">
          <div className="col-lg-7 col-md-6" style={{ display: "flex", alignItems: "flexStart" }}>
            <img className="align-self-center mr-3 cover" src={image} alt="cover" style={{ height: "150px" }} />
            <div style={{ flex: "1" }}>
              <p className="title">
                <Text semi primary bold>
                  {project.name}
                </Text>
              </p>
              <p className="autor">
                <Text small>{project.authorName}</Text>
              </p>
              <p className="type">
                <Text medium disabled>
                  {reward.title}
                </Text>
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-10 col-10 qty text-right" style={{ paddingRight: "85px" }}>
            <div className="col-lg-3 col-md-3">
              <div>{number} ks</div>
            </div>
          </div>
          <div
            className="col-lg-2 col-md-2 col-sm-2 col-2 text-right order-md-12"
            style={{ marginLeft: "-30px", whiteSpace: "noWrap" }}
          >
            <b>{Math.round(number * reward.price)} Kč</b>
          </div>
        </div>
        <hr />
      </div>
    )
  ) : !isMobile() ? (
    <div>
      <div className="row">
        <div className="col-lg-7 col-md-6" style={{ display: "flex", alignItems: "flexStart" }}>
          <img className="align-self-center mr-3 cover" src={image} alt="cover" style={{ height: "150px" }} />
          <div style={{ flex: "1" }}>
            <p className="title">
              <Text cartItem primary bold>
                {project.name}
              </Text>
            </p>
            <p className="autor">
              <Text small>{project.authorName}</Text>
            </p>
            <p className="type">
              <Text medium disabled>
                {reward.title}
              </Text>
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-10 col-10 qty text-right">
          <div>
            <a
              onClick={decrement}
              style={{ position: "absolute", left: "45px" }}
              className={be(MODULE_NAME, "symbolWrapper")}
            >
              <span className={be(MODULE_NAME, "plus")}>-</span>
            </a>{" "}
            <b>{number}</b> ks{" "}
            <a onClick={increment} className={be(MODULE_NAME, "symbolWrapper")}>
              <span className={be(MODULE_NAME, "plus")}>+</span>
            </a>
          </div>
        </div>
        <div className="col-md-2 price text-right" style={{ paddingTop: "8px" }}>
          <Text bold>{Math.round(number * reward.price)} Kč</Text>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-1 col-1 trash text-right order-md-12" style={{ paddingTop: "8px" }}>
          <a onClick={remove}>
            <i className="far fa-trash-alt" style={{ color: "#E8452C" }} />
          </a>
        </div>
      </div>
      <hr />
    </div>
  ) : (
    <div>
      <div className="row">
        <div className="col-lg-7 col-md-6" style={{ display: "flex", alignItems: "flexStart" }}>
          <img className="align-self-center mr-3 cover" src={image} alt="cover" style={{ height: "150px" }} />
          <div style={{ flex: "1" }}>
            <p className="title">
              <Text cartItem primary bold>
                {project.name}
              </Text>
            </p>
            <p className="autor">
              <Text small>{project.authorName}</Text>
            </p>
            <p className="type">
              <Text medium disabled>
                {reward.title}
              </Text>
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-10 col-10 qty text-right" style={{ paddingRight: "50px" }}>
          <div>
            <a
              onClick={decrement}
              style={{ position: "absolute", left: "107px" }}
              className={be(MODULE_NAME, "symbolWrapper")}
            >
              <span className={be(MODULE_NAME, "plus")}>-</span>
            </a>{" "}
            <b>{number}</b> ks{" "}
            <a onClick={increment} className={be(MODULE_NAME, "symbolWrapper")}>
              <span className={be(MODULE_NAME, "plus")}>+</span>
            </a>
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-1 col-1 trash text-right order-md-12" style={{ paddingTop: "8px" }}>
          <a onClick={remove}>
            <i className="far fa-trash-alt" style={{ color: "#E8452C" }} />
          </a>
        </div>
        <div className="col-md-2 price text-right" style={{ paddingTop: "8px" }}>
          <Text bold>{Math.round(number * reward.price)} Kč</Text>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default BookItem;
