import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { Checkbox } from "react-bootstrap";
import { get as g, head, isEmpty } from "lodash";

import "./contributorsPage.css";

import UserPanel from "../../components/userPanel/userPanel";
import UserDuck from "../../redux/ducks/user";
import SelectComponent from "../../components/input/select/select";
import EnumsDuck from "../../redux/ducks/enums";
import { dynamicTitle, getQueryObjFromString, isMobile } from "../../utils/utils";
import Button from "../../components/button/button";
import Loader from "../../components/loader/loader";
import Headline from "../../components/headline/headline";
import { bm, be } from "../../utils/bliss";
import Text from "../../components/text/text";
import Pagination from "../../components/pagination/pagination";
import Screen from "../details/project/projectDetail";
import SearchInput from "../../components/input/searchInput/searchInput";

const MODULE_NAME = "ContributorsScreen";

const GLOBAL_SEARCH_LOADER = false;

class ContributorsPage extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search = "" },
      roles
    } = props;
    const query = getQueryObjFromString(search);
    const subroles = g(roles, "contributor.subroles", []);
    const subrole = subroles.filter(sr => g(query, "subroles", []).includes(sr.id)).map(sr => sr.id);
    this.state = {
      filter: subrole,
      roles: props.roles,
      filters: {
        order: "desc",
        subroles: subrole,
        limit: 9,
        search: null
      },
      loading: true,
      searchLoading: false
    };
    props.dispatch(UserDuck.fetchContributors(this.state.filters)).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.location.search && !isEmpty(nextProps.roles) && isEmpty(state.filter)) {
      const query = getQueryObjFromString(g(nextProps, "location.search", ""));
      const subroles = g(nextProps, "roles.contributor.subroles", []);
      const subrole = subroles.filter(sr => g(query, "subroles", []).includes(sr.id)).map(sr => sr.id);
      const newFilters = {
        ...state.filters,
        subroles: subrole
      };
      nextProps.dispatch(UserDuck.fetchContributors(newFilters));
      return {
        ...state,
        filter: subrole,
        filters: newFilters
      };
    }
    return {};
  }

  showUser = userId => {
    const {
      match: { params },
      history,
      location: { search }
    } = this.props;
    let route = `/profile/${userId}`;
    if (params.projectId) {
      route += `/${params.projectId}`;
    }
    const { subroles = [] } = getQueryObjFromString(search);
    if (!isEmpty(subroles)) {
      route += `/${head(subroles)}`;
    }
    history.push(route);
  };

  handleCheckbox = subroleId => {
    window.scrollTo(0, 0);
    const {
      filters: { subroles: selectedSubroles = [] }
    } = this.state;
    let subrole = [];

    if (selectedSubroles.includes(subroleId)) {
      subrole = selectedSubroles.filter(id => id !== subroleId);
    } else {
      subrole = [subroleId];
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          subroles: subrole,
          page: 1
        }
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.filters));
      }
    );
  };

  handleMobileSubroles = ({ target: { value: subroles = [] } }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          subroles
        }
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.filters));
      }
    );
  };

  handleSort = ({ target }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          order: target.value
        }
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.filters));
      }
    );
  };

  handleSearch = (text) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          search: text,
          page: undefined
        },
        loading: true && GLOBAL_SEARCH_LOADER,
        searchLoading: true && !GLOBAL_SEARCH_LOADER
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.filters)).then(() => {
          this.setState({
            loading: false,
            searchLoading: false
          });
        });
      }
    );
  };

  onPaginationChange = page => {
    window.scrollTo(0, 0);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page
        }
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.filters));
      }
    );
  };

  loadMore = () => {
    window.scrollTo(0, 0);
    this.props.dispatch(UserDuck.fetchMoreContributors(this.state.filters));
  };

  loadLess = () => {
    window.scrollTo(0, 0);
    this.props.dispatch(UserDuck.fetchLessContributors(this.state.filters));
  };

  resetSubroles = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          subroles: []
        }
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.filters));
      }
    );
  };

  getContent() {
    const { contributors } = this.props;
    const { filters } = this.state;
    const selectedSubroles = g(filters, "subroles", []);
    const users = Object.values(contributors);

    if (!isEmpty(users)) {
      return users.map(user => (
        <div key={user.id} className="col-sm-6 col-lg-4">
          <UserPanel onClick={() => this.showUser(user.id)} selectedSubroles={selectedSubroles} user={user} />
        </div>
      ));
    }
    if (this.state.filters.search) {
      return (
        <div className="col-xs-12" style={{textAlign: 'center'}}>
          <Text bold center lg>
            Žádné výsledky pro jméno {this.state.filters.search}
          </Text>
        </div>
      );
    }

    return (
      <div className="col-xs-12">
        <Text bold center lg>
          Pro specifikované parametry, nikdo nenalezen!
        </Text>
      </div>
    );
  }

  render() {
    const { t, roles, canLoadMore, canLoadLess, maxPage } = this.props;
    const { filters, loading } = this.state;
    const selectedSubroles = g(filters, "subroles", []);

    if (loading) {
      return <Loader />;
    }
    const searchInput = (
      <SearchInput
        onSearch={this.handleSearch}
        currentSearch={this.state.filters.search}
        loading={this.state.searchLoading}
      />
    );
    return (
      <div className={bm(MODULE_NAME, null, "contributors-page")}>
        {dynamicTitle(t("dashboard.contributors"), false, this.props.location.pathname)}
        <div className="row">
          <div className="col-md-auto">
            <Headline bold page>
              {t("dashboard.contributors")}
            </Headline>
          </div>
          <div className="col-md d-flex align-items-center justify-content-input-search ">
            <div className={be(MODULE_NAME, "selector")}>{searchInput}</div>
          </div>
          {isMobile() && (
            <div className="col-md d-flex align-items-center justify-content-end ">
              <div className={be(MODULE_NAME, "selector")}>
                <SelectComponent
                  classNamePrefix="select-box"
                  clearable
                  isMulti
                  name="order"
                  onChange={this.handleMobileSubroles}
                  options={g(roles, "contributor.subroles", []).map(subrole => ({
                    label: subrole.name,
                    value: subrole.id
                  }))}
                  placeholder={t("dashboard.rolesPlaceholder")}
                  value={selectedSubroles}
                />
              </div>
            </div>
          )}
          <div className="col-md d-flex align-items-center justify-content-end ">
            <div className={be(MODULE_NAME, "selector")}>
              <SelectComponent
                classNamePrefix="select-box"
                clearable
                defaultValue={{
                  label: t("dashboard.sortDesc"),
                  value: "desc"
                }}
                name="order"
                onChange={this.handleSort}
                options={[
                  {
                    label: t("dashboard.sortDesc"),
                    value: "desc"
                  },
                  {
                    label: t("dashboard.sortAsc"),
                    value: "asc"
                  }
                ]}
                placeholder={t("dashboard.sortPlaceholder")}
              />
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className="row">
          {!isMobile() && (
            <div className="col-sm-2">
              <Checkbox
                key="000"
                checked={isEmpty(selectedSubroles)}
                className={be(MODULE_NAME, "checkbox", null, `${isEmpty(selectedSubroles) ? "checked" : ""}`)}
                onChange={this.resetSubroles}
              >
                Všechny profese
              </Checkbox>
              {g(roles, "contributor.subroles", []).map(subrole => {
                const checked = selectedSubroles.includes(subrole.id);
                return (
                  <Checkbox
                    key={subrole.id}
                    checked={selectedSubroles.includes(subrole.id)}
                    className={be(MODULE_NAME, "checkbox", null, `${checked ? "checked" : ""}`)}
                    onChange={() => this.handleCheckbox(subrole.id)}
                  >
                    {subrole.name}
                  </Checkbox>
                );
              })}
            </div>
          )}
          <div className="col">
            <div className="row">{this.state.searchLoading ? <Loader className="#faede5" /> : this.getContent()}</div>
            <div className="row">
              <div className="col d-flex mt-5 justify-content-center align-items-center">
                {console.log("Pagination data", filters.page, maxPage, canLoadLess, canLoadMore)}
                {(canLoadMore || canLoadLess) && (
                  // bez key zlobí stránkování po vyhledávání
                  // když byl key `${filters.page}-${maxPage}${filters.search ? `-${filters.search}` : ''}`, začalo to po zavedení debounce opět zlobit
                  // takže key byl rozšířen na `${filters.page}-${maxPage}${filters.search ? `-${filters.search}` : ''}-${new Date().getTime()}`
                  <Pagination key={`${filters.page}-${maxPage}${filters.search ? `-${filters.search}` : ''}-${new Date().getTime()}`} activePage={filters.page} maxPage={maxPage} onChange={this.onPaginationChange} />
                )}
                {/* {canLoadLess && <Button spacing medium onClick={this.loadLess}>{t('loadPrevious')}</Button>} */}
                {/* {canLoadMore && <Button spacing medium onClick={this.loadMore}>{t('loadNext')}</Button>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContributorsPage.propTypes = {
  canLoadLess: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  maxPage: PropTypes.number,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  contributors: UserDuck.getContributors(state),
  roles: EnumsDuck.getRoles(state),
  canLoadMore: UserDuck.canLoadMoreContributors(state),
  canLoadLess: UserDuck.canLoadLessContributors(state),
  maxPage: UserDuck.getContributorMaxPage(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ContributorsPage);
