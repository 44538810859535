import { isEmpty } from "lodash";

import { createActionTypes } from "../createActionTypes";

const FormDuck = {
  name: "Forms"
};

FormDuck.formTypes = {
  register1: "register_form_1".toUpperCase(),
  register2: "register_form_2".toUpperCase(),
  register3: "register_form_3".toUpperCase(),
  login: "login_form".toUpperCase(),
  forgottenPassword: "forgotten_password_form".toUpperCase(),
  samples: "samples_form".toUpperCase(),
  handshake: "handshake_form".toUpperCase(),
  editProfile: "edit_profile_form".toUpperCase(),
  resetPassword: "reset_password_form".toUpperCase(),
  subscribe: "subscribe".toUpperCase(),
  news: "NEWS",
  editNews: "EDIT_NEWS",
  requestHandshake: "REQUEST_HANDSHAKE"
};

const initialState = {};

const actionTypes = createActionTypes(
  {
    signForm: "sign_form",
    setData: "set_data",
    setInput: "set_input",
    resetRegisters: "reset_registers"
  },
  FormDuck.name
);

FormDuck.reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.signForm:
      return {
        ...state,
        [action.payload.name]: action.payload.data || {}
      };

    case actionTypes.setData:
      return {
        ...state,
        [action.payload.name]: {
          ...(state[action.payload.name] || {}),
          ...action.payload.data
        }
      };

    case actionTypes.setInput:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          ...action.payload
        }
      };

    default:
      return state;
  }
};

// FormDuck.applyDataByFormName = (formName, additionalData = {}) => (dispatch, getState) => {
//     const params = {
//         ...FormDuck.getData(getState(), formName),
//         ...additionalData
//     };

//     switch (formName) {

//         default:
//             console.log('applyDataByFormName', formName, params);
//             break;
//     }
// };

FormDuck.resetRegistrationForms = () => dispatch => {
  dispatch(FormDuck.resetForm(FormDuck.formTypes.register1));
  dispatch(FormDuck.resetForm(FormDuck.formTypes.register2));
  dispatch(FormDuck.resetForm(FormDuck.formTypes.register3));
};

FormDuck.signForm = (name, data, shouldInitializeFormParam) => (dispatch, getState) => {
  const shouldInitializeForm = shouldInitializeFormParam || isEmpty(getForm(getState(), name));
  if (shouldInitializeForm) {
    dispatch({
      type: actionTypes.signForm,
      payload: {
        name,
        data
      }
    });
  }
};

FormDuck.setData = (name, data) => ({
  type: actionTypes.setData,
  payload: {
    name,
    data
  }
});

FormDuck.resetForm = name => (dispatch, getState) => {
  const formData = getForm(getState(), name);
  const resetData = Object.keys(formData).reduce(
    (res, key) => ({
      ...res,
      [key]: ""
    }),
    {}
  );
  dispatch(FormDuck.setData(name, resetData));
};

FormDuck.setInput = (formName, inputName, value) => ({
  type: actionTypes.setInput,
  payload: {
    [inputName]: value
  },
  formName
});

const getOwnState = state => state[FormDuck.name] || initialState;
const getForm = (state, name) => getOwnState(state)[name] || {};

FormDuck.getData = (state, name) => getForm(state, name);

export default FormDuck;
