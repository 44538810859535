import React, { Component } from "react";
import "./messagePage.css";
import { be } from "../../utils/bliss";
import { isMobile } from "../../utils/utils";
import Header from "../../components/header/header";
import Loader from "../../components/loader/loader";
import Message from "./components/message/message";
import Screen from "../../components/screen/screen";
import SentMessage from "./components/sentMessage/sentMessage";
import UserBox from "./components/userBox";
import UserBoxContributor from "./components/userBoxContributor";

const MODULE_NAME = "MessagePage";

class MessagePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loader />;
    }
    return (
      <Screen header={<Header />}>
        <div className="row d-flex justify-content-around">
          <div className={`${!isMobile() ? "col-3" : "col"}`}>
            <div style={{ paddingBottom: "40px" }}>
              <UserBox />
            </div>
            <div style={{ paddingBottom: "40px" }}>
              <UserBox multiple />
            </div>
            <UserBoxContributor />
          </div>
          <div className={`${!isMobile() ? "col-7" : "col"}`}>
            <Message />
            <SentMessage />
            <SentMessage answered />
          </div>
        </div>
        <div className={be(MODULE_NAME, "spacingMd")} />
      </Screen>
    );
  }
}

export default MessagePage;
