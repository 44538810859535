import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isEmpty } from "lodash";

import StorageService from "../../services/storageService";
import { DEFAULT_ROUTE, NOT_ALLOWED_ROUTE } from "../../utils/routeUtils.js";
import { isRouteValid } from "../../utils/routeUtils";
import { getQueryObjFromString } from "../../utils/utils";

export const AuthenticatedRoute = ({
  component: Component,
  isPrevented = false,
  uncompletedUser = false,
  enabledUser = true,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const { pathname, search } = props.location;
      const { notification } = getQueryObjFromString(search);

      if (!enabledUser) {
        return (
          <Redirect
            to={{
              pathname: `/confirm-email/${user.email}`,
              search,
              state: { from: props.location }
            }}
          />
        );
      }
      if (uncompletedUser) {
        return (
          <Redirect
            to={{
              pathname: "/onboarding",
              search,
              state: { from: props.location }
            }}
          />
        );
      }
      if (isPrevented || (!isRouteValid(pathname) && pathname !== "/")) {
        return (
          <Redirect
            to={{
              pathname: NOT_ALLOWED_ROUTE,
              search,
              state: { from: props.location }
            }}
          />
        );
      }
      if (StorageService.hasToken() && pathname !== "/" && isRouteValid(pathname)) {
        return <Component {...props} />;
      }
      let route = pathname === "/" ? "/landing" : "/login";

      if (notification) {
        route = "/login";
      }
      return (
        <Redirect
          to={{
            pathname: route,
            search,
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);
