// polyfills
import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/number/is-nan";
import "core-js/fn/object/values";

import * as Sentry from "@sentry/browser";

import React from "react";
import ReactDOM from "react-dom";
import "alertifyjs/build/css/alertify.css";
import alertify from "alertifyjs";

import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL
  });
}

window.alertify = alertify;

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
unregister();
