import React from "react";
import { PropTypes as T } from "prop-types";
import { compose, pure } from "recompose";
import { bm, be } from "../../utils/bliss";
import Link from "../link/link";

import "./button.css";

const MODULE_NAME = "Button";

const withButton = compose(pure);

const renderButton = ({
  type = "button",
  children,
  isBusy,
  isDisabled,
  busyMessage = "Loading ...",
  primary,
  secondary,
  inverted,
  invertedPrimary,
  group,
  flex,
  navigation,
  lightGrey,
  pBox,
  lightGreyMob,
  lightGreySm,
  small,
  big,
  share,
  semi,
  fb,
  succes,
  border,
  project,
  medium,
  onClick,
  wide,
  semiWide,
  scroll,
  smallModal,
  large,
  sm,
  nav,
  radiusBig,
  lowerCase,
  spacing,
  xs,
  round,
  smallRadius,
  middleRadius,
  xxs,
  mini,
  paddingSmall,
  green,
  invertedGreen,
  black,
  grey,
  disableFunding,
  greyPrimary,
  invertedGrey,
  invertedBlack,
  invertedBlue,
  spacingLeft,
  to,
  white,
  widePrimary,
  lightPrimary,
  half,
  cookies,
  pagination,
  xl,
  smallFunding,
  href,
  ...restProps
}) => {
  const className = bm(
    MODULE_NAME,
    {
      primary,
      secondary,
      inverted,
      invertedPrimary,
      navigation,
      small,
      big,
      spacing,
      fb,
      round,
      smallRadius,
      middleRadius,
      half,
      succes,
      semi,
      lightGreySm,
      border,
      lightGrey,
      pBox,
      lightGreyMob,
      flex,
      smallFunding,
      project,
      smallModal,
      paddingSmall,
      group,
      green,
      invertedGreen,
      black,
      greyPrimary,
      grey,
      invertedGrey,
      invertedBlack,
      invertedBlue,
      share,
      sm,
      scroll,
      wide,
      semiWide,
      xs,
      xl,
      xxs,
      mini,
      spacingLeft,
      large,
      nav,
      radiusBig,
      lowerCase,
      pagination,
      lightPrimary,
      disableFunding,
      medium,
      cookies,
      white,
      widePrimary
    },
    {
      isBusy,
      isDisabled
    }
  );

  if (to) {
    return (
      <Link className={className} to={to} {...restProps}>
        {isBusy ? busyMessage : children}
      </Link>
    );
  }
  if (href) {
    return (
      <a className={className} href={href} {...restProps}>
        {isBusy ? busyMessage : children}
      </a>
    );
  }

  return (
    <button className={className} onClick={onClick} type={type} {...restProps}>
      {isBusy ? busyMessage : children}
    </button>
  );
};

const Button = withButton(renderButton);

Button.propTypes = {
  border: T.bool,
  group: T.bool,
  href: T.string,
  inverted: T.bool,
  invertedPrimary: T.bool,
  isBusy: T.bool,
  isDisabled: T.bool,
  medium: T.bool,
  navigation: T.bool,
  onClick: T.func,
  project: T.bool,
  secondary: T.bool,
  small: T.bool,
  to: T.string
};

export default Button;
