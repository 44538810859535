import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import "./documentWizardPage.css";

import WizardDuck from "../../redux/ducks/wizard";
import GenresList from "../../components/genres/genresList";
import TagsList from "../../components/tags/tagsList";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import EnumsDuck from "../../redux/ducks/enums";
import TipsCarousel from "../../components/carousel/tips/tipsCarousel";
import UploadFile from "../../components/input/uploadFile/uploadFile";
import Tip from "../../components/carousel/tips/tip";
import { bm, be } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import InputField from "../../components/forms/inputField";
import { isMobile } from "../../utils/utils";
import Loader from "../../components/loader/loader";

const MODULE_NAME = "DocumentWizardPage";

class DocumentWizardPage extends Component {
  state = {
    thereIsNoTitle: false,
    loading: true
  };

  componentWillReceiveProps(nextProps) {
    if (isMobile() && nextProps.step !== this.props.step) {
      window.scrollTo(0, 0);
    }
  }

  onSelectGenre = genreId => {
    const { genres, dispatch } = this.props;
    let newGenres = [];

    if (genres.includes(genreId)) {
      newGenres = genres.filter(gId => gId !== genreId);
    } else {
      newGenres = [...genres, genreId];
    }
    dispatch(WizardDuck.setGenres(newGenres));
  };

  onSelectTag = newTags => {
    this.props.dispatch(WizardDuck.setTags(newTags));
  };

  goBack = () => {
    const { dispatch, step } = this.props;
    dispatch(WizardDuck.setStep(step - 1));
  };

  handleSubmit = () => {
    const { step, maxStep, dispatch, title, genres } = this.props;

    if (step === maxStep) {
      dispatch(WizardDuck.submit());
    } else if (step === 1 && isEmpty(genres)) {
      window.alertify.error("Vyberte aspoň jeden žánr!");
    } else if (step === 3 && !title) {
      this.setState(
        {
          thereIsNoTitle: true
        },
        () => {
          window.alertify.error("Název knihy je povinný!");
        }
      );
    } else {
      dispatch(WizardDuck.setStep(step + 1));
    }
  };

  handleTitle = ({ target }) => {
    if (target.value && this.state.thereIsNoTitle) {
      this.setState(
        {
          thereIsNoTitle: false
        },
        () => {
          this.props.dispatch(WizardDuck.setTitle(target.value));
        }
      );
    } else {
      this.props.dispatch(WizardDuck.setTitle(target.value));
    }
  };

  handleAnnotation = ({ target }) => {
    const value = target.value.slice(0, 600);

    if (target.value.length > 600) {
      // PP-449
      window.alertify.error("Maximální počet znaků anotace je 600!");
    }
    this.props.dispatch(WizardDuck.setAnnotation(value));
  };

  handleFiles = (files = []) => {
    this.props.dispatch(WizardDuck.setFiles(files));
  };

  getContent(step) {
    const { genres, tags, title, t, annotation, files } = this.props;
    const { thereIsNoTitle } = this.state;

    switch (step) {
      case 1:
        return <GenresList medium onSelectGenre={this.onSelectGenre} selectedGenres={genres} />;

      case 2:
        return (
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <TagsList onSelectTag={this.onSelectTag} secondary selectedTags={tags} />
            </div>
          </div>
        );

      case 3:
        return (
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <InputField
                name="title"
                onChange={this.handleTitle}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    this.handleSubmit();
                  }
                }}
                placeholder={t(`wizardPage.inputPlaceholder${step}`)}
                validationMessage={title === "" && thereIsNoTitle ? "Povinné pole" : ""}
                validationType={title === "" && thereIsNoTitle ? "error" : null}
                value={title}
              />
              {/* <Input */}
              {/* wide */}
              {/* value={title} */}
              {/* onChange={this.handleTitle} */}
              {/* onKeyDown={(e) => { */}
              {/* if (e.keyCode === 13) { */}
              {/* this.handleSubmit(); */}
              {/* } */}
              {/* }} */}
              {/* placeholder={t(`wizardPage.inputPlaceholder${step}`)} */}
              {/* /> */}
            </div>
          </div>
        );

      case 4:
        return (
          <Input
            className="input-annotation"
            cols="50"
            max="600"
            onChange={this.handleAnnotation}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.handleSubmit();
              }
            }}
            placeholder={t(`wizardPage.inputPlaceholder${step}`)}
            rows="7"
            textArea
            value={annotation}
          />
        );

      case 5:
        return (
          <div className="row">
            <div className="col-sm">
              <UploadFile
                buttonPlaceholder={t("wizardPage.chooseFile")}
                center
                disableManual
                onFilesInsert={this.handleFiles}
                value={files}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  getTooltips(step) {
    const { tooltips } = this.props;

    switch (step) {
      case 3:
        return (
          <Fragment>
            <TipsCarousel tips={g(tooltips, "wizard3", [])} />
            <div className="spacing" />
          </Fragment>
        );

      case 4:
        return (
          <Fragment>
            <TipsCarousel tips={g(tooltips, "wizard4", [])} />
            <div className="spacing" />
          </Fragment>
        );

      case 5:
        return (
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <Tip text={(g(tooltips, "wizard5", [])[0] || {}).text || ""} />
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  render() {
    const { loading, t, maxStep, step, editing, canGoBack } = this.props;
    const title = t(`wizardPage.title${step}`);
    const tooltip = this.getTooltips(step);

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "navigation")}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-center text-md-left">{title}</div>
              </div>
              <div className="col-md-6">
                <div className="text-center text-md-right">
                  {t("wizardPage.step", {
                    step: canGoBack ? step : step - 2,
                    maxStep: canGoBack ? maxStep : maxStep - 2
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "navigationSpacing")} />

        <div className={be(MODULE_NAME, "title", null, "text-center")}>
          <Headline bold>{t(`wizardPage.label${step}`)}</Headline>
        </div>
        <div className="row">
          <div className="col-sm-8 offset-sm-2">
            <div className={be(MODULE_NAME, "description", null, "text-center")}>
              <Text>{t(`wizardPage.description${step}`)}</Text>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <div className="text-center">{this.getContent(step)}</div>
          </div>
        </div>
        {/*
                <div className="row">
                    <div className="col-sm-10 offset-sm-1">
                        <div className={be(MODULE_NAME, 'tip', tooltip ? 'active' : '')}>{tooltip}</div>
                    </div>
                </div>
                */}
        <div className={be(MODULE_NAME, "submit", null, "text-center")}>
          <Button large onClick={this.handleSubmit}>
            {t(`wizardPage.${editing ? "edit" : "submit"}${step}`)}
          </Button>
          {(canGoBack || step > 3) && (
            <div className={be(MODULE_NAME, "back")}>
              {step > 1 && (
                <span onClick={this.goBack}>
                  &#8592; Zpět na <b>{t(`wizardPage.back${step}`)}</b>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

DocumentWizardPage.propTypes = {
  annotation: PropTypes.string.isRequired,
  canGoBack: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  editor: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  genres: PropTypes.array.isRequired,
  maxStep: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tooltips: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  step: WizardDuck.getStep(state),
  maxStep: WizardDuck.getMaxStep(state),
  genres: WizardDuck.getGenres(state),
  tags: WizardDuck.getTags(state),
  title: WizardDuck.getTitle(state),
  annotation: WizardDuck.getAnnotation(state),
  editor: WizardDuck.getEditor(state),
  files: WizardDuck.getFiles(state),
  editing: WizardDuck.isEditing(state),
  tooltips: EnumsDuck.getTips(state),
  canGoBack: WizardDuck.canGoBack(state),
  loading: WizardDuck.isLoadingUserProjects(state)
});

export default compose(connect(mapStateToProps), translate("translations"))(DocumentWizardPage);
