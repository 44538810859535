import React from "react";
import T from "prop-types";
import { compose, pure } from "recompose";
import { Helmet } from "react-helmet";
import { bm, be } from "../../utils/bliss";

import Footer from "../footer/footer";

import "./screen.css";
import Modal from "../modal/modal";
import { FB_APP_ID } from "../../services/variables";
import Cookies from "../cookies/cookies";

const MODULE_NAME = "Screen";

const withScreen = compose(pure);

const renderScreen = props => {
  const { className, header, footer = <Footer />, children, ...restProps } = props;

  return (
    <div className={bm(MODULE_NAME, null, className)} {...restProps}>
      <Helmet defaultTitle="Pointa.cz" titleTemplate="Pointa.cz | %s">
        <meta charSet="utf-8" />
        <title>Vydej svoji knihu!</title>
        <meta content="Vydej svoji knihu!" name="description" />
        <meta content="knihy, books, book authors, create book, pointa, pointa.cz" name="keywords" />
        <meta content="https://pointa.cz/images/logo1200x630.png" name="image" />
        {/* FB meta */}
        <meta property="fb:app_id" content={FB_APP_ID} />
        <meta property="og:title" content="Pointa.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pointa.cz/" />
        <meta property="og:image" content="https://pointa.cz/images/logo1200x630.png" />
        <meta property="og:site_name" content="Pointa.cz" />
        <meta property="og:description" content="Vydej svoji knihu!" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="Pointa.cz" />
        <meta name="twitter:title" content="Pointa.cz" />
        <meta name="twitter:description" content="Vydej svoji knihu!" />
        <meta name="twitter:image" content="https://pointa.cz/images/logo1200x630.png" />
        <meta name="twitter:url" content="https://pointa.cz/" />
      </Helmet>
      <div className={be(MODULE_NAME, "header")}>{header}</div>

      <div className={be(MODULE_NAME, "content")}>
        <div className="container">{children}</div>
      </div>

      <div className={be(MODULE_NAME, "footer")}>{footer}</div>
      <Modal />
      <Cookies />
    </div>
  );
};

const Screen = withScreen(renderScreen);

Screen.propTypes = {
  footer: T.node,
  header: T.node
};

export default Screen;
