import React from "react";

import "./howToContributor.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";
import Button from "../../../../components/button/button";
import ContributionCard from "../contributionCard/contributionCard";

const MODULE_NAME = "HowToContributor";

const HowToContributor = () =>
  !isMobile() ? (
    <div style={{ padding: "50px 0", textAlign: "center" }}>
      <div className="text-center mb-4">
        <Headline greenReward>Jak to funguje</Headline>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="d-flex align-items-start" style={{ marginBottom: "40px" }}>
            <div className={be(MODULE_NAME, "number")}>1</div>
            <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
              <Text bold greenReward midSemi>
                Vyber svůj obor
              </Text>
              <span style={{ paddingTop: "10px" }}>
                <Text greenReward small>
                  Rozmysli si, které služby chceš na Pointě nabízet a zda pro ně máš potřebné zkušenosti. Bez doložené
                  praxe ti profil bohužel nemůžeme schválit. Kolegou (kolegyní) můžeš být ve více oborech.
                </Text>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#ffffff", margin: "0 -106%", padding: "0 100%" }} className="NewLandingPage">
        <div className="row">
          <ContributionCard />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>2</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Založ si a vyplň profil knižního kolegy
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Zaregistruj se na Pointě, založ si profil jako kolega a dej si záležet na prezentaci sebe i svojí
                    práce. Vyplň medailonek, pochlub se zajímavými projekty nebo knihami, na kterých jsi pracoval(a) a
                    nahraj i ukázky práce.
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start" style={{ padding: "170px 0 210px 0" }}>
              <div className={be(MODULE_NAME, "number")}>4</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Najdi si zajímavý projekt
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Po schválení profilu tě autoři mohou začít oslovovat a ty můžeš nabízet svoje služby. Až si s
                    autorem (autorkou) knihy, na které se chceš podílet, domluvíš rozsah a finanční ohodnocení práce,
                    jedním kliknutím spolu uzavřete smlouvu.
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>6</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Podílej se na tvorbě knihy
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    V případě, že bude předprodej knihy úspěšný (vybere se dostatek financí), můžete se spolu s týmem
                    vrhnout na práci. Po jejím odvedení od nás dostaneš zaplaceno.
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center" style={{ width: "20%" }}>
          <p className={be(MODULE_NAME, "middleLine")} />
          <p className={be(MODULE_NAME, "middleSquareOne")} />
          <p className={be(MODULE_NAME, "middleSquareTwo")} />
          <p className={be(MODULE_NAME, "middleSquareThree")} />
          <p className={be(MODULE_NAME, "middleSquareFour")} />
          <p className={be(MODULE_NAME, "arrowSymbol")}>&#8595;</p>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <div style={{ padding: "180px 0 200px 0" }}>
            <div className="d-flex align-items-start" style={{ paddingBottom: "240px" }}>
              <div className={be(MODULE_NAME, "number")}>3</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Pošli profil na schválení
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Až budeš mít celý profil vyplněný, překontroluj ho a jedním kliknutím nám ho pošli na schválení.
                    Když bude kompletní, můžeš začít poptávat práci. V případě, že bude něco chybět, poprosíme tě o
                    doplnění.
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>5</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Vyberte peníze na vydání
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Abys mohl(a) začít pracovat, musí kniha úspěšně projít předprodejem. V tom můžeš autorovi (autorce)
                    s ostatními knižními kolegy pomoct třeba sdílením projektu. Na knize budeš pracovat jen v případě,
                    že se vybere dostatek peněz.
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center" style={{ marginTop: "80px" }}>
        <Button small smallRadius xl href="/register/contributor">
          Chci být knižní kolega(yně)
        </Button>
      </div>
    </div>
  ) : (
    <div style={{ padding: "50px 0", textAlign: "center" }}>
      <div className="text-center mb-4">
        <Headline greenReward>Jak to funguje</Headline>
      </div>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>1</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Vyber svůj obor
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Rozmysli si, které služby chceš na Pointě nabízet a zda pro ně máš potřebné zkušenosti. Bez doložené
                    praxe ti profil bohužel nemůžeme schválit. Kolegou (kolegyní) můžeš být ve více oborech.
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
        <ContributionCard />
      </div>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start" style={{ padding: "40px 0" }}>
              <div className={be(MODULE_NAME, "number")}>2</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Založ si a vyplň profil knižního kolegy
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Zaregistruj se na Pointě, založ si profil jako kolega a dej si záležet na prezentaci sebe i svojí
                    práce. Vyplň medailonek, pochlub se zajímavými projekty nebo knihami, na kterých jsi pracoval(a) a
                    nahraj i ukázky práce.
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>3</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Pošli profil na schválení
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Až budeš mít celý profil vyplněný, překontroluj ho a jedním kliknutím nám ho pošli na schválení.
                    Když bude kompletní, můžeš začít poptávat práci. V případě, že bude něco chybět, poprosíme tě o
                    doplnění.
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start" style={{ padding: "40px 0" }}>
              <div className={be(MODULE_NAME, "number")}>4</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Najdi si zajímavý projekt
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Po schválení profilu tě autoři mohou začít oslovovat a ty můžeš nabízet svoje služby. Až si s
                    autorem (autorkou) knihy, na které se chceš podílet, domluvíš rozsah a finanční ohodnocení práce,
                    jedním kliknutím spolu uzavřete smlouvu.
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>5</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Vyberte peníze na vydání
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    Abys mohl(a) začít pracovat, musí kniha úspěšně projít předprodejem. V tom můžeš autorovi (autorce)
                    s ostatními knižními kolegy pomoct třeba sdílením projektu. Na knize budeš pracovat jen v případě,
                    že se vybere dostatek peněz.
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start" style={{ padding: "40px 0" }}>
              <div className={be(MODULE_NAME, "number")}>6</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  Podílej se na tvorbě knihy
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    V případě, že bude předprodej knihy úspěšný (vybere se dostatek financí), můžete se spolu s týmem
                    vrhnout na práci. Po jejím odvedení od nás dostaneš zaplaceno.
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center" style={{ marginTop: "25px" }}>
        <Button small smallRadius xl href="/register/contributor">
          Chci být knižní kolega(yně)
        </Button>
      </div>
    </div>
  );

export default HowToContributor;
