import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { bm, be } from "../../../utils/bliss";

import "./genre.css";

const MODULE_NAME = "Genre";

const tpComponent = (id = "tooltip", text) => (
  <Tooltip className="in" id={id} placement="bottom">
    {text}
  </Tooltip>
);

const Genre = ({ id, name, onClick = noop, selected, small, medium, className, disabled }) => (
  <div
    className={bm(MODULE_NAME, { clickable: typeof onClick === "function", small, medium }, [
      { isActive: selected },
      { isDisabled: disabled },
      className
    ])}
    id={id}
    onClick={e => onClick(id, e)}
  >
    <OverlayTrigger overlay={tpComponent(id, `test tooltip ${name}`)} placement="bottom">
      <span>{name}</span>
    </OverlayTrigger>
  </div>
);

Genre.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

export default Genre;
