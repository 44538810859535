import React from "react";

import { be } from "../../../../utils/bliss";

import Text from "../../../../components/text/text";

const MODULE_NAME = "ProjectDetailScreen";

const SuccessTopBar = () => (
  <div className={be("ProfileScreen", "info", "topbar")}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md">
          <div className="text-center text-md-left">
            <Text component="div" inverted>
              Nabídl/a jsi autorovi/autorce spolupráci na tomto projektu
            </Text>
            <div className="mb-3" />
            <Text component="div" inverted lineHeightSmall normal small>
              Pokud autor/ka bude mít zájem, ozve se ti zpátky
            </Text>
            <div className={be("ProfileScreen", "infoSpacingMd")} />
          </div>
        </div>
        <div className="col-auto pl-md-0 d-flex align-items-right">
          <img alt="pending" className={be(MODULE_NAME, "pending", "clock")} src="/images/svg/clock--white.svg" />
        </div>
      </div>
    </div>
  </div>
);

export default SuccessTopBar;
