import React, { Component } from "react";

import "./contributionCard.css";

import { bm } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";
import Text from "../../../../components/text/text";
import MobileSlider from "../../../../components/mobileSlider/mobileSlider";

const MODULE_NAME = "ContributionCard";

const ContributionCard = () => (
  <div className="col-12">
    <MobileSlider
      responsive={[
        {
          breakpoint: 1900,
          infinite: false,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            arrows: true
          }
        },
        {
          breakpoint: 920,
          infinite: false,
          settings: {
            slidesToShow: 2.2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          infinite: false,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1
          }
        }
      ]}
    >
      <div className="col-12">
        <div className={bm(MODULE_NAME, "one")}>
          <Text small bold greenReward mAuto>
            Redaktor(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "two")}>
          <Text small bold greenReward mAuto>
            Korektor(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "three")}>
          <Text small bold greenReward mAuto>
            Grafik(grafička)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "four")}>
          <Text small bold greenReward mAuto>
            Sazeč(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "five")}>
          <Text small bold greenReward mAuto>
            Ilustrátor(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "six")}>
          <Text small bold greenReward mAuto>
            Výkonnostní marketér(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "seven")}>
          <Text small bold greenReward mAuto>
            Influencer(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "eight")}>
          <Text small bold greenReward mAuto>
            Novinář(ka)/bloger(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "nine")}>
          <Text small bold greenReward mAuto>
            Fotograf(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "ten")}>
          <Text small bold greenReward mAuto>
            Betačtenář(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "one")}>
          <Text small bold greenReward mAuto>
            Ghostwriter(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "two")}>
          <Text small bold greenReward mAuto>
            Stratég(stratéžka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "three")}>
          <Text small bold greenReward mAuto>
            Ambasador(ka)
          </Text>
        </div>
      </div>
      <div className="col-12">
        <div className={bm(MODULE_NAME, "four")}>
          <Text small bold greenReward mAuto>
            Event manager(ka)
          </Text>
        </div>
      </div>
    </MobileSlider>
  </div>
);

export default ContributionCard;
