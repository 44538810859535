import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty, get as g } from "lodash";
import { connect } from "react-redux";

import "./fundingNews.css";
import { be, bm } from "../../utils/bliss";
import Text from "../text/text";
import FundingNewsTab from "../fundingNewsTab/fundingNewsTab";
import Line from "../line/line";
import Pagination from "../pagination/pagination";
import WizardDuck from "../../redux/ducks/wizard";
import UserDuck from "../../redux/ducks/user";

const MODULE_NAME = "FundingNews";

class FundingNews extends Component {
  state = {
    page: 1,
    limit: 3,
    order: "desc"
  };

  onPaginationChange = page => {
    this.setState(
      {
        page
      },
      () => {
        const { project, dispatch } = this.props;
        dispatch(WizardDuck.setDetailNews(project.id, this.state));
      }
    );
  };

  getEmptyContent() {
    const { project, user } = this.props;
    const isPublicProject = !g(project, "author", []).find(author => author.id === user.id);

    if (isPublicProject) {
      return (
        <div className={be(MODULE_NAME, "empty")}>
          <Text greenReward bold component="div">
            Projekt nemá ještě zvěřejněnou žádnou novinku
          </Text>
        </div>
      );
    }
    return (
      <div className={be(MODULE_NAME, "empty")}>
        <Text bold component="div">
          Nemáte zvěřejněnou ještě žádnou novinku
        </Text>
        <Text component="div">
          Sdělte svým fanouškům, jak se projekt knihy vyvíjí. Plánujete autogramiádu? Dopsal/a jste kapitolu?
        </Text>
      </div>
    );
  }

  render() {
    const { news, project, totalCount, canLoadMore, canLoadLess, isEditable } = this.props;
    const { page } = this.state;

    return (
      <div className={bm(MODULE_NAME)}>
        {/*<Text light uppercase xs>
                    Zveřejněné novinky
                </Text>*/}
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        {!isEmpty(news)
          ? news.map((item, i) => (
              <Fragment key={i}>
                <FundingNewsTab key={i} isEditable={isEditable} item={item} projectId={project.id} />
                {news.length > 1 && (
                  <div
                    style={{ height: "50px", marginLeft: "20px", borderLeft: "1px solid #38675B" }}
                    className="lineVertical"
                  ></div>
                )}
              </Fragment>
            ))
          : this.getEmptyContent()}
        {(canLoadMore || canLoadLess) && (
          <Pagination activePage={page} maxPage={totalCount} onChange={this.onPaginationChange} />
        )}
      </div>
    );
  }
}

FundingNews.defaultProps = {
  isEditable: false
};

FundingNews.propTypes = {
  canLoadLess: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  news: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: WizardDuck.getDetail(state),
  news: WizardDuck.getNews(state),
  totalCount: WizardDuck.getNewsTotalCount(state),
  canLoadMore: WizardDuck.canLoadMoreNews(state),
  canLoadLess: WizardDuck.canLoadLessNews(state),
  user: UserDuck.getUser(state)
});

export default connect(mapStateToProps)(FundingNews);
