import React from "react";

import { be } from "../../../../utils/bliss";

import Text from "../../../text/text";

const MODULE_NAME = "ProjectDetailScreen";

const AcceptMessage = () => (
  <div className={be(MODULE_NAME, "acceptContainer")} style={{ textAlign: "center" }}>
    <div style={{ height: "15px" }} />
    <div style={{ textAlign: "center", marginBottom: "15px" }}>
      <img src="/images/blackLogo.png" style={{ width: "100px" }} alt="logo" />
    </div>
    <div>
      <div>
        <Text bold primary>
          Platba byla zrušena
        </Text>
      </div>
    </div>
  </div>
);

export default AcceptMessage;
