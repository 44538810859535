import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g } from "lodash";

import { bm, be } from "../../utils/bliss";
import FormDuck from "../../redux/ducks/forms";
import Button from "../../components/button/button";
import Forms from "../../components/forms/form";
import { getQueryObjFromString } from "../../utils/utils";
import UserService from "../../services/userService";
import Headline from "../../components/headline/headline";
import Screen from "../../components/screen/screen";
import Header from "../../components/header/header";

const MODULE_NAME = "ResetPasswordPage";

class ResetPasswordPage extends Component {
  resetPassword = data => {
    const { location, history, t } = this.props;
    const query = getQueryObjFromString(g(location, "search", ""));
    UserService.resetPassword({
      password: data.password,
      token: query.token
    }).then(() => {
      window.alertify.success(t("resetPassword.confirmMessage"));
      history.push("/login");
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Screen header={<Header {...this.props} />}>
        <div className={bm(MODULE_NAME)}>
          <div className="row">
            <div className="col">
              <div className="text-center">
                <Headline page primary>
                  {t("resetPassword.title")}
                </Headline>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "headlineSpacing")} />
          <div className={be(MODULE_NAME, "body")}>
            <div className="row">
              <div className="col-sm-6 offset-sm-3">
                <Forms
                  inputs={[
                    {
                      placeholder: t("resetPassword.password"),
                      label: t("resetPassword.password"),
                      name: "password",
                      type: "password",
                      entityType: "password",
                      required: true,
                      className: "col-xs-12"
                    },
                    {
                      placeholder: t("resetPassword.passwordAgain"),
                      label: t("resetPassword.passwordAgain"),
                      name: "confirm_password",
                      type: "password",
                      entityType: "password",
                      required: true,
                      className: "col-xs-12"
                    }
                  ]}
                  name={FormDuck.formTypes.resetPassword}
                  onSubmit={this.resetPassword}
                >
                  <div className="col">
                    <div className="text-center">
                      <div className={be(MODULE_NAME, "formSpacing")} />
                      <Button medium type="submit">
                        {t("resetPassword.confirm")}
                      </Button>
                    </div>
                  </div>
                </Forms>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

ResetPasswordPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(translate("translations"), connect())(ResetPasswordPage);
