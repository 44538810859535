import React from "react";
import T from "prop-types";
import { map } from "lodash";
import { bm, be } from "../../../utils/bliss";

import "./genreWrapper.css";

const MODULE_NAME = "GenreWrapper";

const GenreWrapper = ({ center, left, genres, medium }) => (
  <div className={bm(MODULE_NAME, { center, left })}>
    {map(genres, genre => (
      <div key={`genreWrapper-${Math.random()}`} className={be(MODULE_NAME, "genre", { medium })}>
        {genre}
      </div>
    ))}
  </div>
);

GenreWrapper.propTypes = {
  center: T.bool,
  genres: T.arrayOf(T.node),
  left: T.bool
};

export default GenreWrapper;
