import React from "react";
import { Get } from "restful-react";

import _filter from "lodash/filter";
import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import { isMobile } from "../../utils/utils";

import Screen from "../../components/screen/screen";
import Header from "../../components/header/header";
import Link from "../../components/link/link";
import Headline from "../../components/headline/headline";
import Loader from "../../components/loader/loader";

const PostList = ({ history, location: { search } }) => {
  const isUser = !!search;
  return (
    <Get
      base="https://blog.pointa.cz/wp-json/wp/v2/"
      path="posts?&categories=13&per_page=100"
      resolve={response => {
        const filtered = _filter(response, "knowledgebase_section");
        const grouped = _groupBy(filtered, "knowledgebase_section.value");
        return grouped;
      }}
    >
      {(data, { loading }) => {
        if (loading) return <Loader />;
        return (
          <Screen
            className="/src/pages/newLandingPage/newLandingPage"
            header={<Header history={history} isUser={isUser} landing />}
          >
            <div style={{ paddingBottom: "30px" }}>
              {!isMobile() ? (
                <Headline greenReward bold fundingPage>
                  Průvodce vydáním knihy
                </Headline>
              ) : (
                <Headline greenReward bold semiMd>
                  Průvodce vydáním knihy
                </Headline>
              )}
            </div>
            <div
              style={{
                background: "#ffffff",
                margin: "0 -1500px",
                padding: "30px 1500px 30px 1500px",
                height: "auto",
                minHeight: "550px"
              }}
            >
              <div className="row justify-content-between" style={{ padding: "0 15px" }}>
                {_sortBy(Object.keys(data)).map(section => (
                  <div key={section} className="col-md-6" style={{ padding: "0 30px 30px 0" }}>
                    <Headline greenReward spacingSm bold pageSub>
                      {data[section][0].knowledgebase_section.label}
                    </Headline>
                    {data[section].map(post => (
                      <Link key={post.id} to={`/knowledgebase/${post.slug}`}>
                        <Headline spacingSemi primary pageSubSmall>
                          {post.title.rendered}
                        </Headline>
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Screen>
        );
      }}
    </Get>
  );
};

export default PostList;
