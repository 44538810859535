import React from "react";
import T from "prop-types";
import { compose, pure } from "recompose";
import { bm, be } from "../../utils/bliss";

import Link from "../link/link";

import "./footer.css";

import facebookIcon from "./img/facebook.svg";
import blogIcon from "./img/blog.svg";
import instagramIcon from "./img/instagram.svg";
import Headline from "../headline/headline";
import { API } from "../../services/config";
import GaSend from "../ga/gaSend";
import pgBannerImg from "./img/pg-banner.png";

const MODULE_NAME = "Footer";

const withFooter = compose(pure);

const renderFooter = ({ transparent }) => (
  <div className={bm(MODULE_NAME, { transparent })}>
    <div className={be(MODULE_NAME, "email")}>
      <Link to="mailto:dikyvam@pointa.cz">dikyvam@pointa.cz</Link>
    </div>

    <div className={be(MODULE_NAME, "links")}>
      <div className={be(MODULE_NAME, "link")}>
        <Link light medium target="_blank" to={`${API}/docs/gdpr`}>
          Zásady zpracování osobních údajů
        </Link>
      </div>
      {/*<div className={be(MODULE_NAME, 'link')}>
                <GaSend action="click" category="button" label="Často kladené otázky">
                    <Link light medium to="/landing/faq">
                        Často kladené otázky
                    </Link>
                </GaSend>
            </div>*/}
      <div className={be(MODULE_NAME, "link")}>
        <Link light medium target="_blank" to={`${API}/docs/vop`}>
          Obchodní podmínky
        </Link>
      </div>
      <div className={be(MODULE_NAME, "contact")}>
        <Link light medium to="/contact">
          Kontakt
        </Link>
      </div>
      {/* <div className={be(MODULE_NAME, 'link')}> */}
      {/* <Link medium light to="/landing">O nás</Link> */}
      {/* </div> */}
      {/* <div className={be(MODULE_NAME, 'link')}> */}
      {/* <Link medium light to="/landing">Kontakt</Link> */}
      {/* </div> */}
    </div>

    <div className={be(MODULE_NAME, "feeds")}>
      <a className={be(MODULE_NAME, "feed", "blog")} href="https://blog.pointa.cz/" target="_blank">
        <img alt="blog" className={be(MODULE_NAME, "feedIcon")} src={blogIcon} />
        Blog
      </a>
      <a className={be(MODULE_NAME, "feed", "facebook")} href="https://www.facebook.com/Pointa.cz/" target="_blank">
        <img alt="facebook" className={be(MODULE_NAME, "feedIcon")} src={facebookIcon} />
        Facebook
      </a>
      <a className={be(MODULE_NAME, "feed", "instagram")} href="https://www.instagram.com/pointa.cz" target="_blank">
        <img alt="instagram" className={be(MODULE_NAME, "feedIcon")} src={instagramIcon} />
        Instagram
      </a>
    </div>

    <div className={be(MODULE_NAME, "pg-banner")}>
      <img alt="Comgate, Visa, MasterCard, GooglePay, Apple Pay" src={pgBannerImg} />
    </div>
  </div>
);

const Footer = withFooter(renderFooter);

Footer.propTypes = {};

export default Footer;
