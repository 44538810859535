import React, { Fragment } from "react";
import { get as g, isEmpty } from "lodash";

import { be } from "../../../../utils/bliss";
import { getProjectDetailSubroles } from "../../../../utils/utils";

import Button from "../../../../components/button/button";
import Thread from "../../../../components/thread/thread";
import Tip from "../../../../components/carousel/tips/tip";
import GaSend from "../../../../components/ga/gaSend";

import UIDuck from "../../../../redux/ducks/ui";
import WizardDuck from "../../../../redux/ducks/wizard";

import GetNewsContent from "./getNewsContent";
import GetInProgressContent from "./getInProgressContent";
import GetBasicParameters from "./getBasicParameters";
import GetMinimumContent from "./getMinimumContent";
import GetRecommendedContent from "./getRecommendedContent";
import GetOtherContent from "./getOtherContent";

const MODULE_NAME = "ProjectDetailScreen";

// projectStates, project, user, genres, tips, handshakeStates
export default ({ projectStates, project, genres, tips, handshakeStates, dispatch, refetch }) => {
  const transitionToFunding = () => {
    const nextState = g(projectStates, project.funding === "own" ? "inProgress.name" : "funding.name", "");

    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: (
          <p>
            Tato operace <b>nejde</b> vzít zpátky. Jste si jistí?
          </p>
        ),
        callback: () => {
          dispatch(
            WizardDuck.patchProject(
              project.id,
              {
                state: nextState,
                name: project.name
              },
              true
            )
          ).then(() => {
            window.alertify.success(`Projekt je nyní ve stavu ${nextState}.`);
          });
        }
      })
    );
  };

  let tabs = [
    {
      header: "Novinky",
      content: <GetNewsContent isEditable={!project.isPublicProject} project={project} />
    },
    {
      header: "Komentáře",
      content: <Thread project={project} />
    }
  ];

  if (project.state === g(projectStates, "inProgress.name", "") && !project.isPublicProject) {
    tabs = [
      {
        header: "Kontrolní seznam",
        content: <GetInProgressContent project={project} refetch={refetch} />
      },
      ...tabs
    ];
  } else if (project.state === g(projectStates, "proposal.name", "") && !project.isPublicProject) {
    const data = getProjectDetailSubroles(project, genres);
    const requiredIds = g(data, "required", []).map(s => s.id);
    const acceptedHandshakes = g(project, "handshakes", []).filter(
      ({ state }) => g(handshakeStates, "accepted.name", "") === state
    );
    const isProjectPublishable =
      !!g(project, "funding", "") &&
      acceptedHandshakes.filter(({ subrole }) => requiredIds.includes(subrole.id)).length === requiredIds.length &&
      !isEmpty(g(project, "basePrice", {}));

    tabs = [
      {
        header: "Parametry a kolegové",
        content: (
          <Fragment>
            <div className="col-sm">
              {project.isEditable && <GetBasicParameters project={project} />}
              {project.isEditable && <GetMinimumContent data={data.required} project={project} />}
              {project.isEditable && <GetRecommendedContent data={data.recommended} project={project} />}
              {project.isEditable && <GetOtherContent data={data.other} project={project} />}
              <div className={be(MODULE_NAME, "spacing", "lg")} />
            </div>
            {project.isEditable && (
              <div className="row">
                <div className="col-sm-8 offset-sm-2">
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                  <Tip text={g(g(tips, "projectDetail", [])[0], "text", "")} />
                </div>
              </div>
            )}
            {project.isEditable && (
              <div className="row">
                <div className="col">
                  {project.isEditable && (
                    <div className="text-center">
                      <div className={be(MODULE_NAME, "spacing", "lg")} />
                      <GaSend action="click" category="button" label="MÁM HOTOVO A CHCI DO">
                        <Button isDisabled={!isProjectPublishable} onClick={transitionToFunding} secondary sm>
                          MÁM HOTOVO A CHCI DO {project.funding === "own" ? "VÝROBY" : "FINANCOVÁNÍ"}
                        </Button>
                      </GaSend>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )
      },
      ...tabs
    ];
  }
  return tabs;
};
