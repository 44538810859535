import { get as g } from "lodash";
import axios from "axios";

import CoreAPIService from "./coreAPIService";
import { API } from "./config";

class EnumsService {
  async getGenres() {
    return new Promise((res, rej) => {
      CoreAPIService.get("genres")
        .then(response => {
          res(g(response, "data", []));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getCatalog() {
    return new Promise((res, rej) => {
      axios
        .get(`${API}/catalog`)
        .then(response => {
          res(g(response, "data", {}));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getTags() {
    return new Promise((res, rej) => {
      CoreAPIService.get("tags")
        .then(response => {
          res(g(response, "data", []));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getTips() {
    return new Promise((res, rej) => {
      CoreAPIService.get("tooltips")
        .then(response => {
          res(g(response, "data", []));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async createTag(name) {
    return new Promise((res, rej) => {
      CoreAPIService.post("tags", { name })
        .then(response => {
          res(g(response, "data", {}));
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data.message", ""));
          rej(err);
        });
    });
  }

  async getRoles() {
    return new Promise((res, rej) => {
      axios
        .get(`${API}/roles`)
        .then(response => {
          res(g(response, "data", {}));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getBindings() {
    return new Promise((res, rej) => {
      CoreAPIService.get("bindings")
        .then(response => {
          res(g(response, "data", {}));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }
}

export default new EnumsService();
