/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { get as g, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { connect } from "react-redux";
import { compose } from "redux";

import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import moment from "moment";

import { isMobile, formatPrice } from "../../../../utils/utils";

import { be } from "../../../../utils/bliss";
import { PREVIEWS_API } from "../../../../services/variables";

import AuthorInfoReleased from "./authorInfoReleased";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";
import Button from "../../../../components/button/button";

import CartDucks from "../../../../redux/ducks/cart";
import CommonPriceInfo from "./commonPriceInfo";

const MODULE_NAME = "ProjectDetailScreen";

class ProgressState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      value: _isEmpty(props.project.rewards) ? null : props.project.rewards[0].id
    };
  }

  showModal = () => {
    this.setState({
      modal: true
    });
  };

  hideModal = () => {
    this.setState({
      modal: false
    });
  };

  orderBook(selectedReward) {
    const { project, dispatch } = this.props;

    if (project.state === "Výroba") {
      this.context.router.history.push({
        pathname: "/delivery",
        state: {
          items: [
            {
              project,
              reward: selectedReward,
              number: 1
            }
          ]
        }
      });
    } else {
      dispatch(CartDucks.addItem(project, selectedReward));
      this.context.router.history.push("/cart");
    }
  }

  render() {
    const { project, scrollToMyRef } = this.props;
    const { modal, value } = this.state;
    const image = project.moonImage || (project.coverPhoto ? `${PREVIEWS_API}${project.coverPhoto}` : null);
    const selectedReward = _find(project.rewards, { id: value });

    return (
      <div>
        <div>
          {" "}
          <Modal animation={false} onHide={this.handleClose} show={modal}>
            <Modal.Header>
              <img className="close-btn" onClick={this.hideModal} src="/images/svg/cross--black.svg" alt="x" />
            </Modal.Header>
            <AuthorInfoReleased project={project} extended />
          </Modal>
        </div>
        <div className="row justify-content-center">
          {!isMobile() && (
            <div className="col-md-5 text-center">
              <img alt="pic" src={image} style={{ maxHeight: "400px", maxWidth: "100%" }} />
            </div>
          )}
          <div className="col-md-6">
            <AuthorInfoReleased project={project} onClick={this.showModal} />
            <Headline fundingPage greenReward>
              {project.name}
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className={be(MODULE_NAME, "genres")}>
              {g(project, "genres", [])
                .filter(gen => !isEmpty(gen))
                .map((genre, i) => (
                  <div key={genre.id} className={be(MODULE_NAME, "genre")}>
                    <Text bold greenReward medium>
                      {genre.name}
                      {i === project.genres.length - 1 ? "" : ","}{" "}
                    </Text>
                  </div>
                ))}
            </div>
            {isMobile() && (
              <div className="d-flex justify-content-center">
                <img alt="pic" src={image} style={{ maxHeight: "350px", maxWidth: "100%" }} />
              </div>
            )}
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <Text greenReward>{project.bibliographyDescription}</Text>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            <div className={be(MODULE_NAME, "box")} style={{ width: !isMobile() ? "75%" : "100%" }}>
              {!_isEmpty(project.rewards) && !isMobile() && (
                <FormControl component="fieldset">
                  <RadioGroup
                    name="bookType"
                    onChange={(e, val) => {
                      this.setState({ value: Number(val) });
                    }}
                    style={{ display: "flex", flexDirection: "row" }}
                    value={Number(value)}
                  >
                    {project.rewards.map(reward => (
                      <FormControlLabel
                        key={reward.id}
                        control={<Radio style={{ color: "#E8452C" }} />}
                        label={
                          reward.title === "Kniha" ? (
                            <Typography
                              style={{
                                fontFamily: '"Matter","open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                                fontSize: "14px",
                                color: "#E8452C",
                                fontWeight: "bold",
                                paddingRight: "45px"
                              }}
                            >
                              {reward.title} {`${Math.round(reward.price)} Kč`}
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                fontFamily: '"Matter","open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                                fontSize: "14px",
                                color: "#E8452C",
                                fontWeight: "bold",
                                paddingRight: "0"
                              }}
                            >
                              {reward.title} {`${Math.round(reward.price)} Kč`}
                            </Typography>
                          )
                        }
                        labelPlacement="end"
                        value={Number(reward.id)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              {!isMobile() && !_isEmpty(project.rewards) ? (
                <div style={{ paddingLeft: "34px", marginTop: "-20px", marginBottom: "20px" }}>
                  {project.rewards.map(reward =>
                    reward.orig_price ? (
                      <Text greenReward medium>
                        Běžná cena: {formatPrice(reward.orig_price)} Kč <CommonPriceInfo />
                      </Text>
                    ) : (
                      ""
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {!_isEmpty(project.rewards) && isMobile() && (
                <div style={{ paddingBottom: "20px" }}>
                  <div>
                    <div className="row">
                      <div className="col-8">
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="bookType"
                            onChange={(e, val) => {
                              this.setState({ value: Number(val) });
                            }}
                            value={Number(value)}
                          >
                            {project.rewards.map(reward => (
                              <FormControlLabel
                                key={reward.id}
                                control={<Radio style={{ color: "#E8452C" }} />}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily:
                                        '"Matter","open-sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                                      fontSize: "14px",
                                      color: "#E8452C",
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {reward.title}
                                  </Typography>
                                }
                                labelPlacement="end"
                                value={Number(reward.id)}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {!_isEmpty(project.rewards) ? (
                        <div style={{ position: "absolute", paddingLeft: "49px", marginTop: "30px" }}>
                          {project.rewards.map(reward =>
                            reward.orig_price ? (
                              <Text greenReward medium>
                                Běžná cena: {formatPrice(reward.orig_price)} Kč <CommonPriceInfo />
                              </Text>
                            ) : (
                              ""
                            )
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-4 text-right">
                        {project.rewards.length > 1 ? (
                          <div>
                            {project.rewards.map(reward => (
                              <div>
                                {reward.title === "Kniha" && (
                                  <div style={{ padding: "13px 0 30px 0" }}>
                                    <Text bold primary small>
                                      {`${Math.round(reward.price)} Kč`}
                                    </Text>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            {project.rewards.map(reward => (
                              <div>
                                {reward.title === "Kniha" && (
                                  <div style={{ paddingTop: "13px" }}>
                                    <Text bold primary small>
                                      {`${Math.round(reward.price)} Kč`}
                                    </Text>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                        {project.rewards.length > 1 ? (
                          <div>
                            {project.rewards.map(reward => (
                              <Text bold primary small>
                                {reward.title !== "Kniha" && `${Math.round(reward.price)} Kč`}
                              </Text>
                            ))}
                          </div>
                        ) : (
                          <div style={{ paddingTop: "13px" }}>
                            {project.rewards.map(reward => (
                              <Text bold primary small>
                                {reward.title !== "Kniha" && `${Math.round(reward.price)} Kč`}
                              </Text>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {value ? (
                <div className="d-flex justify-content-center text-center mt-5">
                  <Button onClick={() => this.orderBook(selectedReward)} smallRadius xl wide>
                    Koupit
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className={be(MODULE_NAME, "spacing", "sm")} />
              <div className="text-center">
                {project.releaseDate && (
                  <Text bold component="div" greenReward small>
                    Kniha vyjde {moment(project.releaseDate).format("DD. MM. YYYY")}
                  </Text>
                )}
              </div>
            </div>
          </div>
          {!isMobile() && <div className={be(MODULE_NAME, "spacing", "lg")} />}
        </div>
      </div>
    );
  }
}

ProgressState.contextTypes = {
  router: PropTypes.object.isRequired
};

export default compose(connect(null))(ProgressState);
