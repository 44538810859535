import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g } from "lodash";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import WizardDuck from "../../redux/ducks/wizard";
import { be } from "../../utils/bliss";
import Headline from "../headline/headline";
import SelectComponent from "../input/select/select";
import Button from "../button/button";
import ProjectInfo from "../projectInfo/projectInfo";

import "./followedProjects.css";
import UserDuck from "../../redux/ducks/user";
import { showProject } from "../../utils/goTo";

const MODULE_NAME = "FollowedProjects";

class FollowedProjects extends Component {
  constructor(props) {
    super(props);

    this.state = props.opt;
    // this.props.dispatch(WizardDuck.fetchFollowedProjects(props.user.id, props.opt));
  }

  handleFilters = ({ target }) => {
    const { user, dispatch } = this.props;
    const opt = {
      ...this.state,
      order: target.value
    };
    dispatch(WizardDuck.fetchFollowedProjects(user.id, opt));
  };

  render() {
    const { followedProjects, canLoadMoreFollowedProjects, canLoadLessFollowedProjects } = this.props;

    return (
      <div className={be(MODULE_NAME, "newContributors")}>
        <div className="row">
          <div className="col-md">
            <Headline bold component="h2" xs>
              Sledované projekty
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "lg", "d-md-none")} />
          </div>
          {
            <div className="col-md-auto">
              <div className={be(MODULE_NAME, "select")}>
                <SelectComponent
                  classNamePrefix="select-box"
                  defaultValue={{
                    label: "Od nejnovějších",
                    value: "desc"
                  }}
                  name="order"
                  onChange={this.handleFilters}
                  options={[
                    {
                      label: "Nejaktuálnější novinky",
                      value: "desc"
                    },
                    {
                      label: "Nejstarší novinky",
                      value: "asc"
                    }
                  ]}
                  placeholder="Vyberte řazení"
                />
              </div>
            </div>
          }
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className="row">
          {Object.values(followedProjects)
            .slice(0, g(this.state, "limit", 3))
            .map(project => (
              <div key={project.id} className="col-sm-6 col-lg-4">
                <ProjectInfo onClick={() => showProject(project.id)} project={project} />
              </div>
            ))}
        </div>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className="text-center">
          <Button medium to="/projects-followed">
            Zobrazit vše
          </Button>
        </div>
      </div>
    );
  }
}

FollowedProjects.defaultProps = {
  opt: {
    limit: 6,
    page: 1,
    order: "desc"
  }
};

FollowedProjects.propTypes = {
  dispatch: PropTypes.func.isRequired,
  followedProjects: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  opt: PropTypes.object,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  followedProjects: WizardDuck.getFollowedProjects(state),
  user: UserDuck.getUser(state)
});

export default compose(connect(mapStateToProps), withRouter)(FollowedProjects);
