import { push } from "react-router-redux";
import { get as g, isEmpty } from "lodash";
import * as Sentry from "@sentry/browser";

import { createActionTypes } from "../createActionTypes";
import UserService from "../../services/userService";
import StorageService from "../../services/storageService";
import { TOKEN_STORAGE_NAME } from "../../services/config";
import FormDuck from "./forms";
import WizardDuck from "./wizard";

const UserDuck = {
  name: "User"
};

let notificationsHook = null;

const initialState = {
  user: {},
  sso: {
    remote_auth_s3: "",
    timestamp: 0
  },
  onboarding: {
    step: 1,
    role: "",
    subroles: [],
    promo: "",
    web: "",
    genres: [],
    references: [
      { name: 1, value: "" },
      { name: 2, value: "" },
      { name: 3, value: "" }
    ],
    samples: [],
    units: {}
  },
  allUsers: {},
  contributors: {
    page: 1,
    limit: 9
  },
  authors: {
    page: 1,
    limit: 9
  },
  loading: {
    contributors: true,
    authors: true,
    allUsers: true,
    user: true,
    notifications: false
  },
  notifications: {}
};

const actionTypes = createActionTypes(
  {
    setUser: "set_user",
    setUserSso: "set_user_sso",
    removeUserSso: "remove_user_sso",
    setRole: "set_role",
    setSubroles: "set_subroles",
    setStep: "set_step",
    setPromo: "set_promo",
    addToOnboarding: "add_to_onboarding",
    setAllUsers: "set_all_users",
    setContributors: "set_all_contributors",
    deletePortfolio: "delete_portfolio",
    setPortfolios: "set_portfolios",
    setReferences: "set_references",
    replaceHandshake: "replace_handshake",
    replaceNotification: "replace_notification",
    replaceNotifications: "replace_notifications",
    setNotifications: "set_notifications",
    setUnit: "set_unit",
    setAuthors: "set_authors",
    removeAuthors: "remove_authors",
    setLoading: "set_loading",
    updateUserHandshake: "update_user_handshake",
    setLink: "set_link",
    setGenres: "set_genres"
  },
  UserDuck.name
);

UserDuck.reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setUser:
      if (process.env.REACT_APP_SENTRY_URL) {
        Sentry.configureScope(scope => {
          scope.setUser({ id: action.payload.id, email: action.payload.email });
        });
      }
      return {
        ...state,
        user: action.payload
      };

    case actionTypes.setUserSso:
      return {
        ...state,
        sso: action.payload
      };

    case actionTypes.removeUserSso:
      return {
        ...state,
        sso: initialState.sso
      };

    case actionTypes.setLink:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          web: action.payload
        }
      };

    case actionTypes.setGenres:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          genres: action.payload
        }
      };

    case actionTypes.setRole:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          step: 2,
          role: action.payload
        }
      };

    case actionTypes.updateUserHandshake:
      return {
        ...state,
        user: {
          ...state.user,
          handshakes: g(state, "user.handshakes", []).map(hs => {
            if (hs.id === g(action, "payload.id", "")) {
              return action.payload;
            }
            return hs;
          })
        }
      };

    case actionTypes.setSubroles:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          subroles: action.payload
        }
      };

    case actionTypes.setStep:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          step: action.payload
        }
      };

    case actionTypes.setLoading:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.entity]: action.payload
        }
      };

    case actionTypes.setPromo:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          promo: action.payload
        }
      };

    case actionTypes.deletePortfolio:
      return {
        ...state,
        user: {
          ...state.user,
          portfolio: g(state, "user.portfolio", []).filter(file => file.id !== action.payload)
        }
      };

    case actionTypes.setPortfolios:
      return {
        ...state,
        user: {
          ...state.user,
          portfolio: action.payload
        }
      };

    case actionTypes.setReferences:
      return {
        ...state,
        user: {
          ...state.user,
          reference: action.payload
        }
      };

    case actionTypes.addToOnboarding:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...action.payload
        }
      };

    case actionTypes.setAllUsers:
      return {
        ...state,
        allUsers: {
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, user) => ({
              ...res,
              [user.id]: user
            }),
            {}
          )
        }
      };

    case actionTypes.removeAuthors:
      return {
        ...state,
        authors: { page: 1 }
      };

    case actionTypes.setContributors:
      return {
        ...state,
        contributors: {
          ...state.contributors,
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, user) => ({
              ...res,
              [user.id]: user
            }),
            {}
          )
        }
      };

    case actionTypes.setAuthors:
      return {
        ...state,
        authors: {
          ...state.authors,
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, user) => ({
              ...res,
              [user.id]: user
            }),
            {}
          )
        }
      };

    case actionTypes.replaceHandshake:
      return {
        ...state,
        user: {
          ...state.user,
          contributorHandshakes: g(state, "user.contributorHandshakes", []).map(hs => {
            if (hs.id === action.payload.id) {
              return action.payload;
            }
            return hs;
          }),
          authorHandshakes: g(state, "user.authorHandshakes", []).map(hs => {
            if (hs.id === action.payload.id) {
              return action.payload;
            }
            return hs;
          })
        }
      };

    case actionTypes.replaceNotification:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: g(state, "user.notifications", []).map(notification => {
            if (notification.id === action.payload.id) {
              return action.payload;
            }
            return notification;
          })
        }
      };

    case actionTypes.setNotifications:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: g(action, "payload.data", [])
        },
        notifications: action.payload
      };

    case actionTypes.replaceNotifications:
      return {
        ...state,
        notifications: action.payload
      };

    case actionTypes.setUnit:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          units: {
            ...state.units,
            ...action.payload
          }
        }
      };

    default:
      return state;
  }
};

UserDuck.setLoading = (entity, isLoading) => ({
  type: actionTypes.setLoading,
  payload: isLoading,
  entity
});

UserDuck.registerUser = (user = {}) => dispatch => {
  StorageService.clearStorage();
  // eslint-disable-next-line no-unused-vars
  return UserService.createUser(user).then(data => {
    window.alertify.notify("Registrace se zdařila!", "success", 5);
    // StorageService.set(TOKEN_STORAGE_NAME, data.token);
    // dispatch({
    //     type: actionTypes.setUser,
    //     payload: data.user
    // });
    dispatch(FormDuck.resetRegistrationForms());
    dispatch(push(`/confirm-email/${user.email}`));
  });
};

UserDuck.loginUser = (user = {}, search = "", url = null) => (dispatch, getState) => {
  const state = getState();
  StorageService.clearStorage();
  UserService.loginUser(user)
    .then(data => {
      window.alertify.notify("Uživatel přihlášen!", "success", 5);
      StorageService.set(TOKEN_STORAGE_NAME, data.token);
      dispatch({
        type: actionTypes.setUser,
        payload: data.user
      });
      dispatch(UserDuck.setUserSso());
      dispatch(WizardDuck.fetchUserProjects(data.user.id));
      let route = "/";
      if (g(data, "user.roles", []).includes("ROLE_USER")) {
        route = "/onboarding";
      } else {
        route = "/dashboard";
      }
      if (search) {
        route += search;
      }
      const returnUrl = g(state, "Router.state.from.pathname", false);
      if (returnUrl) {
        route = returnUrl;
      }
      if (url) {
        route = url;
      }
      dispatch(UserDuck.fetchUserNotifications(g(data, "user.id", "")));
      dispatch(push(route));
    })
    .catch(err => {
      if (g(err, "response.data.code", "") === "user-not-enabled") {
        dispatch(push(`/confirm-email/${user.email}`));
      }
    });
};

UserDuck.logout = () => dispatch => {
  StorageService.clearStorage();
  dispatch(UserDuck.fetchUserNotifications());
  window.alertify.notify("Uživatel úpěšně odhlášen!", "success", 5);
  dispatch({
    type: actionTypes.removeAuthors
  });
  dispatch({
    type: actionTypes.removeUserSso
  });
  // eslint-disable-next-line no-unused-vars
  return new Promise((res, rej) => {
    dispatch({
      type: actionTypes.setUser,
      payload: {}
    });
    res();
  });
};

UserDuck.setUserByToken = (errorRoute = "/landing", catchError = true, showNotif = true) => (dispatch, getState) => {
  const currentUser = UserDuck.getUser(getState());

  return new Promise((res, rej) => {
    if (!isEmpty(currentUser)) {
      res(currentUser);
    } else if (StorageService.hasToken()) {
      dispatch(UserDuck.loadUserFromToken((errorRoute = "/landing"), (catchError = true), (showNotif = false)))
        .then(user => res(user))
        .catch(err => rej(err));
    }
  });
};

UserDuck.setUserSso = (errorRoute = "/landing", catchError = true, showNotif = true) => dispatch =>
  new Promise((res, rej) => {
    UserService.getUserSsoToken(catchError)
      .then(ssoToken => {
        dispatch({
          type: actionTypes.setUserSso,
          payload: ssoToken
        });
        res(ssoToken);
      })
      .catch(err => {
        if (catchError) {
          rej(err);
        }
      });
  });

UserDuck.loadUserFromToken = (
  errorRoute = "/landing",
  catchError = true,
  showNotif = true,
  shouldFetchEverything = true
) => dispatch =>
  new Promise((res, rej) => {
    UserService.getUserByToken(catchError)
      .then(user => {
        dispatch({
          type: actionTypes.setUser,
          payload: user
        });
        if (shouldFetchEverything) {
          dispatch(UserDuck.setUserSso());
          dispatch(UserDuck.fetchUserNotifications(g(user, "id", "")));
          dispatch(WizardDuck.fetchUserProjects(user.id));
        }

        if (g(user, "roles", []).includes("ROLE_USER")) {
          dispatch(push("/onboarding"));
        }
        res(user);
      })
      .catch(err => {
        console.log({ err });
        if (err) {
          if (g(err, "response.data.code", "") === 401) {
            StorageService.clearStorage();
          }
          if (showNotif) {
            window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          }
          if (g(err, "response.data.code", "") === "user-not-enabled") {
            errorRoute = "/confirm-email";
          }
          rej(err);
          dispatch(push(errorRoute));
        }
      });
  });

UserDuck.setRole = role => ({
  type: actionTypes.setRole,
  payload: role
});

UserDuck.setSubroles = (subroles = []) => ({
  type: actionTypes.setSubroles,
  payload: subroles
});

UserDuck.setUnit = payload => ({
  type: actionTypes.setUnit,
  payload
});

UserDuck.setOnboardingStep = step => ({
  type: actionTypes.setStep,
  payload: step
});

UserDuck.setPromo = text => ({
  type: actionTypes.setPromo,
  payload: text
});

UserDuck.addToOnboarding = (data = {}) => ({
  type: actionTypes.addToOnboarding,
  payload: data
});

UserDuck.completeOnboarding = (opt = {}) => (dispatch, getState) => {
  const state = getState();
  const user = UserDuck.getUser(state);
  const data = UserDuck.getOnboarding(state);
  let res = {
    roles: [data.role],
    subroles: Object.values(data.units),
    promoText: data.promo,
    portfolio: (g(data, "samples", []) || []).map(file => ({
      data: file.data,
      filename: file.name
    })),
    reference: (g(data, "references", []) || []).filter(ref => !!ref.value).map(ref => ref.value)
  };
  if (opt.isReader) {
    delete res.portfolio;
    delete res.subroles;
    delete res.reference;
    res = {
      ...res,
      followedGenres: data.genres,
      web: data.web
    };
  }
  UserService.patchUser(user.id, res).then(userData => {
    dispatch({
      type: actionTypes.setUser,
      payload: userData
    });
    window.alertify.notify("Uživatel byl zdárně dokončen!", "success", 5);
    dispatch(push("/dashboard"));
  });
};

UserDuck.patchUser = (newData = {}) => (dispatch, getState) => {
  const user = UserDuck.getUser(getState());
  return UserService.patchUser(user.id, newData).then(userData => {
    dispatch({
      type: actionTypes.setUser,
      payload: userData
    });
    window.alertify.notify("Uživatel byl úspěšně upraven!", "success", 5);
  });
};

UserDuck.loadContributors = (opt = {}) => dispatch => {
  dispatch(UserDuck.fetchContributors(opt));
  dispatch(UserDuck.setLoading("allUsers", true));
  UserService.getAllUsers().then(users => {
    dispatch({
      type: actionTypes.setAllUsers,
      payload: users
    });
    dispatch(UserDuck.setLoading("allUsers", false));
  });
};

UserDuck.loadAuthors = (opt = {}) => dispatch => {
  dispatch(UserDuck.setLoading("authors", true));
  UserService.getAllAuthors(opt).then(authors => {
    dispatch({
      type: actionTypes.setAuthors,
      payload: authors
    });
    dispatch(UserDuck.setLoading("authors", false));
  });
};

UserDuck.fetchContributors = (opt = {}) => dispatch => {
  dispatch(UserDuck.setLoading("contributors", true));
  return UserService.getAllContributors(opt).then(contributors => {
    dispatch({
      type: actionTypes.setContributors,
      payload: {
        ...contributors,
        ...opt
      }
    });
    dispatch(UserDuck.setLoading("contributors", false));
  });
};

UserDuck.deletePortfolio = portfolioId => (dispatch, getState) => {
  const user = UserDuck.getUser(getState());
  UserService.deletePortfolio(user.id, portfolioId).then(portfolios => {
    window.alertify.notify("Portfolio smazano uspesne.", "success", 5);
    dispatch({
      type: actionTypes.setPortfolios,
      payload: portfolios
    });
    // UserService.getUserByToken().then(user => {
    //     dispatch({
    //         type: actionTypes.setUser,
    //         payload: user
    //     });
    // });
  });
};

UserDuck.deleteReference = referenceId => (dispatch, getState) => {
  const user = UserDuck.getUser(getState());
  UserService.deleteReference(user.id, referenceId).then(references => {
    window.alertify.notify("Reference smazana uspesne.", "success", 5);
    dispatch({
      type: actionTypes.setReferences,
      payload: references
    });
  });
};

UserDuck.setCooperation = (opt = {}) => dispatch => {
  console.log({ opt });
  return UserService.setCooperation(opt).then(() => {
    dispatch(push(`/project/${opt.projectId}`));
  });
};

UserDuck.removeHandshake = (projectId, handshakeId, redirect = true) => dispatch =>
  UserService.removeCooperation(projectId, handshakeId).then(() => {
    if (redirect) {
      dispatch(push(`/project/${projectId}`));
    }
  });

UserDuck.rejectHandshakeRequest = (projectId, handshakeId, redirect = true) => dispatch =>
  UserService.rejectHandshakeRequest(projectId, handshakeId).then(() => {
    if (redirect) {
      dispatch(push(`/project/${projectId}`));
    }
  });

UserDuck.fetchMoreContributors = (opt = {}) => (dispatch, getState) => {
  const state = getOwnState(getState()).contributors;
  dispatch(
    UserDuck.fetchContributors({
      ...opt,
      page: parseInt(/page=(\d)/.exec(g(state, "links.next", ""))[1], 10)
    })
  );
};

UserDuck.fetchLessContributors = (opt = {}) => (dispatch, getState) => {
  const state = getOwnState(getState()).contributors;
  dispatch(
    UserDuck.fetchContributors({
      ...opt,
      page: parseInt(/page=(\d)/.exec(g(state, "links.prev", ""))[1], 10)
    })
  );
};

UserDuck.fetchMoreAuthors = (opt = {}) => (dispatch, getState) => {
  const state = getOwnState(getState()).authors;
  dispatch(
    UserDuck.loadAuthors({
      ...opt,
      page: parseInt(/page=(\d)/.exec(g(state, "links.next", ""))[1], 10)
    })
  );
};

UserDuck.fetchLessAuthors = (opt = {}) => (dispatch, getState) => {
  const state = getOwnState(getState()).authors;
  dispatch(
    UserDuck.loadAuthors({
      ...opt,
      page: parseInt(/page=(\d)/.exec(g(state, "links.prev", ""))[1], 10)
    })
  );
};

UserDuck.confirmHandshake = (projectId = "", handshakeId = "") => dispatch =>
  new Promise(res => {
    UserService.acceptHandshake(projectId, handshakeId).then(newHandshake => {
      dispatch({
        type: actionTypes.replaceHandshake,
        payload: newHandshake
      });
      res(newHandshake);
    });
  });

UserDuck.requestHandshake = (projectId = "", handshakeId = "") => dispatch =>
  new Promise(res => {
    UserService.requestHandshake(projectId, handshakeId).then(newHandshake => {
      dispatch({
        type: actionTypes.replaceHandshake,
        payload: newHandshake
      });
      res(newHandshake);
    });
  });

UserDuck.deliverHandshake = (projectId = "", handshakeId = "") => dispatch =>
  UserService.deliverHandshake(projectId, handshakeId).then(newHandshake => {
    dispatch({
      type: actionTypes.replaceHandshake,
      payload: newHandshake
    });
    UserService.getUserByToken().then(user => {
      dispatch({
        type: actionTypes.setUser,
        payload: user
      });
    });
  });

UserDuck.readNotification = (notification = {}) => dispatch =>
  UserService.readNotifications(notification.id).then(updatedNotification => {
    dispatch({
      type: actionTypes.replaceNotification,
      payload: updatedNotification
    });
  });

UserDuck.fetchUserNotifications = (userId = "") => dispatch => {
  if (userId) {
    UserService.getNotificationsByUserId(userId).then(notifs => {
      dispatch({
        type: actionTypes.setNotifications,
        payload: notifs
      });
    });
    notificationsHook = setInterval(() => {
      UserService.getNotificationsByUserId(userId).then(notifs => {
        dispatch({
          type: actionTypes.setNotifications,
          payload: notifs
        });
      });
    }, 2 * 60 * 1000);
  } else {
    clearInterval(notificationsHook);
  }
};

UserDuck.loadSpecificUserNotifications = (userId = "", opt = { limit: 10 }) => dispatch => {
  dispatch(UserDuck.setLoading("notifications", true));
  UserService.getNotificationsByUserId(userId, opt).then(notifs => {
    dispatch({
      type: actionTypes.replaceNotifications,
      payload: notifs
    });
    dispatch(UserDuck.setLoading("notifications", false));
  });
};

UserDuck.updateUserHandshake = (handshake = {}) => ({
  type: actionTypes.updateUserHandshake,
  payload: handshake
});

UserDuck.toggleUserVisibility = (user = {}) => dispatch =>
  new Promise(res => {
    if (user.isVisible) {
      UserService.hideUser().then(updatedUser => {
        dispatch({
          type: actionTypes.setUser,
          payload: updatedUser
        });
        window.alertify.success(`Uživatel je nyní skrytý!`);
        res(updatedUser);
      });
    } else {
      UserService.unhideUser().then(updatedUser => {
        dispatch({
          type: actionTypes.setUser,
          payload: updatedUser
        });
        window.alertify.success(`Uživatel je nyní veřejný!`);
        res(updatedUser);
      });
    }
  });

UserDuck.setLink = (link = "") => ({
  type: actionTypes.setLink,
  payload: link
});

UserDuck.setGenres = (genres = []) => ({
  type: actionTypes.setGenres,
  payload: genres
});

const getOwnState = state => state[UserDuck.name] || initialState;
UserDuck.getUser = state => getOwnState(state).user;
UserDuck.getUserSso = state => getOwnState(state).sso;
UserDuck.isSsoValid = state => {
  const { sso } = getOwnState(state);
  const currentTimestamp = +new Date();
  // A valid token must not be older than two hours.
  const invalidSSOState =
    sso.timestamp === 0 || sso.timestamp + 2 * 3600 > currentTimestamp || sso.remote_auth_s3 === "";
  return !invalidSSOState;
};
UserDuck.getOnboarding = state => getOwnState(state).onboarding;
UserDuck.getAllUsers = state => g(getOwnState(state), "allUsers.data", {});
UserDuck.getContributors = state => g(getOwnState(state), "contributors.data", {});
UserDuck.getAuthors = state => g(getOwnState(state), "authors.data", {});
UserDuck.canLoadMoreContributors = state => !!g(getOwnState(state), "contributors.links.next", false);
UserDuck.canLoadLessContributors = state => !!g(getOwnState(state), "contributors.links.prev", false);
UserDuck.getContributorMaxPage = state =>
  Math.ceil(g(getOwnState(state), "contributors.total", 0) / g(getOwnState(state), "contributors.limit", 1));
UserDuck.isDashboardLoading = state => {
  const loadingState = g(getOwnState(state), "loading", {});
  return loadingState.contributors || loadingState.authors || loadingState.allUsers;
};
UserDuck.getNotifications = state => g(getOwnState(state), "notifications.data", []);
UserDuck.canLoadMoreNotifications = state => !!g(getOwnState(state), "notifications.links.next", false);
UserDuck.canLoadLessNotifications = state => !!g(getOwnState(state), "notifications.links.prev", false);
UserDuck.isNotificationsLoading = state => g(getOwnState(state), "loading.notifications", false);
UserDuck.isAuthorsLoading = state => g(getOwnState(state), "loading.authors", false);
UserDuck.canLoadMoreAuthors = state => !!g(getOwnState(state), "authors.links.next", false);
UserDuck.canLoadLessAuthors = state => !!g(getOwnState(state), "authors.links.prev", false);
UserDuck.getAuthorsMaxPage = state =>
  Math.ceil(g(getOwnState(state), "authors.total", 0) / g(getOwnState(state), "authors.limit", 1));

export default UserDuck;
