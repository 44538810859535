import React, { Component } from "react";
import "./shopRecap.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import _map from "lodash/map";
import _sumBy from "lodash/sumBy";
import _every from "lodash/every";

import UserService from "../../services/userService";

import CartDuck from "../../redux/ducks/cart";

import { isMobile } from "../../utils/utils";
import { be, bm } from "../../utils/bliss";
import Link from "../../components/link/link";
import Screen from "../../components/screen/screen";
import Header from "../../components/header/header";
import Loader from "../../components/loader/loader";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import BookItem from "../shopCart/components/bookItem/bookItem";

import { API } from "../../services/config";
import {
  ADDRESS_COMPLETE,
} from "../../services/variables";
import { DELIVERY } from "../shopDelivery/shopDelivery";
import { HelpBlock } from "react-bootstrap";
import { isCartItemDeliveryAfterDays } from "../../utils/deliveryAfterMoreDays";

const MODULE_NAME = "ShopRecap";

const CONDITION_ACCEPTANCE_MESSAGE_EXTENSION = "a beru na vědomí, že mi zboží nebude doručeno do 30 dnů od vytvoření objednávky";

class ShopRecap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  async confirmOrder() {
    if (!this.state.agreeWithConditions) {
      this.setState({ hasToAgreeWithConditions: true });
      return;
    }

    this.setState({ loading: true });
    const data = this.props.location.state;
    const fromCart = Boolean(this.props.location.state.fromCart);

    const isEverythingVirtual = this.isEverythingVirtual();

    const finalData = {
      email: data.email,
      firstname: data.name,
      surname: data.surname,
      phone: data.phone,
      method: data.payment,
      country: "cz",
      packetaPlaceId: data.deliveryAddress.packetaPlaceId ? data.deliveryAddress.packetaPlaceId : null,
      invoiceAddress: {
        firstname: data.invoiceAddress.name,
        surname: data.invoiceAddress.surname,
        com_name: data.invoiceAddress.invoiceCompanyName,
        street: data.invoiceAddress.street,
        zip: data.invoiceAddress.postcode,
        city: data.invoiceAddress.district,
        IC: data.invoiceAddress.ico,
        DIC: data.invoiceAddress.dic
      },
      deliveryAddress: {
        firstname: data.deliveryAddress.name,
        surname: data.deliveryAddress.surname,
        // com_name: data.deliveryAddress.deliveryCompanyName,
        street: data.deliveryAddress.street,
        zip: data.deliveryAddress.postcode,
        city: data.deliveryAddress.district
      },
      items: [
        ...data.items.map(i =>
          this.itemHasPrice(i)
            ? {
              id_book: i.project.moonlineId,
              id_reward: i.reward.id,
              num: i.number,
              price: i.rewardPrice
            }
            : {
              id_book: i.project.moonlineId,
              id_reward: i.reward.id,
              num: i.number
            }
        )
      ]
    };

    if (!isEverythingVirtual) {
      finalData.items.push({
        id_book: -1,
        id_reward: data.delivery,
        num: 1
      });
    }

    if (this.state.subscribeToNewsletter) {
      try {
        const newsletter = await UserService.subscribe(data.email, true, false);
        console.log(`Email ${data.email} subscribed to newsletter`, newsletter);
      } catch (e) {
        console.error("Error subscribing to newsletter", e);
      }
    }

    const result = await UserService.makeOrder(finalData);
    if (fromCart) {
      this.props.dispatch(CartDuck.resetCart());
    }
    window.location.href = result.paymentLink;
  }

  isEverythingVirtual() {
    const cartItems = this.props.location.state.items;
    return _every(cartItems, i => !i.reward.physical);
  }

  renderDelivery() {
    const { delivery } = this.props.location.state;
    var result;

    switch (delivery) {
      case DELIVERY.sendToCZ:
        result = "Česká pošta – Balík do ruky (Česko)";
        break;

      case DELIVERY.sendToSK:
        result = "Česká pošta (Slovensko)";
        break;

      case DELIVERY.pickUp:
        result = "Osobní odběr (" + ADDRESS_COMPLETE + ")";
        break;

      case DELIVERY.packeta:
        result = "Zásilkovna";
        break;

      case DELIVERY.pickUpAtAuthor:
        result = "Osobní předání autorem (autorkou)";

      default:
        // TODO: REPORT PROBLEM ! ! !
        break;
    }

    return result;
  }

  deliveryPrice() {
    const { delivery, deliveryPrices } = this.props.location.state;
    var price;

    switch (delivery) {
      case "1":
        price = deliveryPrices[DELIVERY.sendToCZ];
        break;

      case "3":
        price = deliveryPrices[DELIVERY.sendToSK];
        break;

      case "2":
        price = 0;
        break;

      case "4":
        price = deliveryPrices[DELIVERY.packeta];
        break;

      case DELIVERY.pickUpAtAuthor:
        price = 0;
        break;

      default:
        // TODO: REPORT PROBLEM ! ! !
        break;
    }

    return price;
  }

  render() {
    const { loading } = this.props;

    const cartItems = this.props.location.state.items;
    const fromCart = Boolean(this.props.location.state.fromCart);

    const {
      delivery,
      payment,
      areAddressesSame,
      email,
      phone,
      deliveryAddress,
      invoiceAddress
    } = this.props.location.state;

    const isEverythingVirtual = this.isEverythingVirtual();
    const priceSum =
      _sumBy(cartItems, i => i.number * this.itemRewardPrice(i)) + (!isEverythingVirtual ? this.deliveryPrice() : 0);

    const isPacketaDelivery = delivery === DELIVERY.packeta;

    if (loading || this.state.loading) {
      return <Loader />;
    }
    const deliveryAfter30Days = cartItems.some(i => isCartItemDeliveryAfterDays(i));
    return (
      <Screen header={<Header />}>
        {!isMobile() ? (
          // Desktop version
          <div>
            <div className="row">
              <div className="col-lg-6">
                <Headline page>Rekapitulace</Headline>
              </div>
              <div className="col-lg-6 col-md-12 d-flex align-items-end justify-content-end">
                <div>
                  {fromCart && (
                    <Text small>
                      <Link to="/cart">Košík</Link>{" "}
                    </Text>
                  )}
                  <Text small>
                    {fromCart && (
                      <React.Fragment>
                        <img
                          alt="arrowImg"
                          src="/images/svg/leftCart.svg"
                          style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                        />{" "}
                      </React.Fragment>
                    )}
                    <Link to={{ pathname: "/delivery", state: this.props.location.state }}>Doprava a platba</Link>
                  </Text>
                  <Text small>
                    <img
                      alt="arrowImg"
                      src="/images/svg/leftCart.svg"
                      style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                    />{" "}
                    <b>Rekapitulace</b>
                  </Text>
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "item")}>
              <div>
                {" "}
                <Text bold primary>
                  KUPUJETE
                </Text>
              </div>
              <hr />
              {_map(cartItems, item => (
                <div>
                  <BookItem
                    recap
                    project={item.project}
                    reward={{ ...item.reward, price: this.itemRewardPrice(item) }}
                    number={item.number}
                  />
                </div>
              ))}
              <div style={{ paddingTop: "15px" }}>
                <Text bold primary>
                  DOPRAVA A PLATBA
                </Text>
              </div>
              <hr />
              <div class="row mt-4 pt-3">
                {delivery !== DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor && (
                  <div class="col-lg-4 col-md-10">
                    <span class="title">
                      {!isEverythingVirtual ? (
                        <Text bold>Doručovací {areAddressesSame && "a fakturační"} adresa</Text>
                      ) : (
                        <Text bold>Fakturační adresa</Text>
                      )}
                    </span>
                    <p>
                      {isPacketaDelivery && (
                        <>
                          <Text primary>{deliveryAddress.packetaPlace}</Text>
                          <br />
                        </>
                      )}
                      {!isPacketaDelivery && (
                        <>
                          {deliveryAddress.name} {deliveryAddress.surname}
                          <br />
                        </>
                      )}
                      {deliveryAddress.deliveryCompanyName && (
                        <>
                          {deliveryAddress.deliveryCompanyName}
                          <br />
                        </>
                      )}
                      {deliveryAddress.street}
                      <br />
                      {deliveryAddress.postcode} {deliveryAddress.district}
                    </p>
                    <span class="title">
                      <Text bold>Kontakt</Text>
                    </span>
                    <p>
                      {email}
                      <br />
                      {phone}
                    </p>
                  </div>
                )}
                {(delivery === DELIVERY.pickUp || delivery === DELIVERY.pickUpAtAuthor || (!areAddressesSame && !isEverythingVirtual)) && (
                  <div class="col-lg-4 col-md-10">
                    <span class="title">
                      <Text bold>Fakturační adresa</Text>
                    </span>
                    <p>
                      {invoiceAddress.name} {invoiceAddress.surname}
                      <br />
                      {invoiceAddress.invoiceCompanyName && (
                        <>
                          {invoiceAddress.invoiceCompanyName}
                          <br />
                        </>
                      )}
                      {invoiceAddress.street}
                      <br />
                      {invoiceAddress.postcode} {invoiceAddress.district}
                      <br />
                      {invoiceAddress.ico && (
                        <>
                          IČ: {invoiceAddress.ico}
                          <br />
                        </>
                      )}
                      {invoiceAddress.dic && (
                        <>
                          DIČ: {invoiceAddress.dic}
                          <br />
                        </>
                      )}
                    </p>
                  </div>
                )}
                <div class="col-lg-8 col-md-3">
                  <span class="title">
                    <Text bold>Způsob doručení</Text>
                  </span>
                  <p>
                    {isEverythingVirtual && "Online"}
                    {!isEverythingVirtual && this.renderDelivery()}
                  </p>
                  <span class="title">
                    <Text bold>Způsob platby</Text>
                  </span>
                  <p>
                    {payment === "csob"
                      ? "Kartou On-line"
                      : "Platební brána (platba kartou online / bankovním převodem)"}
                  </p>
                </div>
              </div>
              <hr />

              <div className="d-flex justify-content-end  mt-4">
                <div classname="d-flex flex-column">
                  <div className="d-flex align-items-center" style={{ color: "#7E7E7B", gap: 4 }}>
                    <div>
                      <input
                        checked={(this.state && this.state.agreeWithConditions) || false}
                        id="agreeWithConditions"
                        onChange={() =>
                          // eslint-disable-next-line react/no-access-state-in-setstate
                          this.setState({
                            agreeWithConditions: !(this.state && this.state.agreeWithConditions),
                            hasToAgreeWithConditions: false
                          })
                        }
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <label style={{ fontWeight: "500" }} for="agreeWithConditions">
                        Souhlasím s{" "}
                        <Link target="_blank" to={`${API}/docs/vop_eshop`}>
                          <span>obchodními podmínkami</span>
                        </Link>{" "}
                        e&#8209;shopu Pointy{deliveryAfter30Days ? ` ${CONDITION_ACCEPTANCE_MESSAGE_EXTENSION}` : ""}
                      </label>
                    </div>
                  </div>
                  {this.state.hasToAgreeWithConditions && <div className="text-danger">Pole je povinné.</div>}
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-2" style={{ color: "#7E7E7B", gap: 4 }}>
                <div>
                  <input
                    checked={(this.state && this.state.subscribeToNewsletter) || false}
                    id="newsletter-sign-up"
                    onChange={() =>
                      // eslint-disable-next-line react/no-access-state-in-setstate
                      this.setState({ subscribeToNewsletter: !(this.state && this.state.subscribeToNewsletter) })
                    }
                    type="checkbox"
                  />
                </div>
                <div>
                  <label style={{ fontWeight: "500" }} for="newsletter-sign-up">
                    Chci odebírat novinky emailem (souhlasím s{" "}
                    <Link target="_blank" to={`${API}/docs/gdpr`}>
                      <span>podmínkami ochrany osobních dat</span>
                    </Link>
                    )
                  </label>
                </div>
              </div>

              <div className="row justify-content-end" style={{ padding: "15px 15px 0 0" }}>
                <div className="d-flex flex-column" style={{ padding: "0 40px" }}>
                  {!isEverythingVirtual &&
                    (delivery === DELIVERY.sendToCZ || delivery === DELIVERY.sendToSK || delivery == DELIVERY.packeta) ? (
                    <Text>
                      {delivery === DELIVERY.packeta ? "Doprava" : "Poštovné"}: <span>{this.deliveryPrice()} Kč</span>
                    </Text>
                  ) : (
                    ""
                  )}
                  <Text cartPrice>
                    Celkem: <span style={{ color: "#E8452C", fontWeight: "bold" }}>{priceSum} Kč</span>
                  </Text>
                </div>
                <div>
                  <Button onClick={() => this.confirmOrder()} primary semiWide>
                    DOKONČIT A ZAPLATIT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Mobile version
          <div>
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <Headline page>Rekapitulace</Headline>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-5">
                  <div>
                    {fromCart && (
                      <Text small>
                        <Link to="/cart">Košík</Link>{" "}
                      </Text>
                    )}
                    <Text small>
                      {fromCart && (
                        <React.Fragment>
                          <img
                            alt="arrowImg"
                            src="/images/svg/leftCart.svg"
                            style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                          />{" "}
                        </React.Fragment>
                      )}
                      <Link to={{ pathname: "/delivery", state: this.props.location.state }}>Doprava a platba</Link>
                    </Text>
                    <Text bold small>
                      <img
                        alt="arrowImg"
                        src="/images/svg/leftCart.svg"
                        style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                      />{" "}
                      Rekapitulace
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "item")}>
              <div>
                {" "}
                <Text bold primary>
                  KUPUJETE
                </Text>
              </div>
              <hr />
              {_map(cartItems, item => (
                <div>
                  <BookItem
                    recap
                    project={item.project}
                    reward={{ ...item.reward, price: this.itemRewardPrice(item) }}
                    number={item.number}
                  />
                </div>
              ))}
              <div style={{ paddingTop: "15px" }}>
                <Text bold primary>
                  DOPRAVA A PLATBA
                </Text>
              </div>
              <hr />
              <div className="row mt-4 pt-3">
                <div className="col-lg-4 col-md-10">
                  {delivery != DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor && (
                    <React.Fragment>
                      <span className="title">
                        {!isEverythingVirtual ? (
                          <Text bold>Doručovací {areAddressesSame && "a fakturační"} adresa</Text>
                        ) : (
                          <Text bold>Fakturační adresa</Text>
                        )}
                      </span>
                      <p>
                        {isPacketaDelivery && (
                          <>
                            <Text primary>{deliveryAddress.packetaPlace}</Text>
                            <br />
                          </>
                        )}
                        {!isPacketaDelivery && (
                          <>
                            {deliveryAddress.name} {deliveryAddress.surname}
                            <br />
                          </>
                        )}
                        {deliveryAddress.deliveryCompanyName && (
                          <>
                            {deliveryAddress.deliveryCompanyName}
                            <br />
                          </>
                        )}
                        {deliveryAddress.street}
                        <br />
                        {deliveryAddress.postcode} {deliveryAddress.district}
                      </p>
                    </React.Fragment>
                  )}
                  {(delivery === DELIVERY.pickUp || delivery === DELIVERY.pickUpAtAuthor || (!areAddressesSame && !isEverythingVirtual)) && (
                    <p>
                      <span className="title">
                        <Text bold>Fakturační adresa</Text>
                      </span>
                      <p>
                        {invoiceAddress.name} {invoiceAddress.surname}
                        <br />
                        {invoiceAddress.invoiceCompanyName && (
                          <>
                            {invoiceAddress.invoiceCompanyName}
                            <br />
                          </>
                        )}
                        {invoiceAddress.street}
                        <br />
                        {invoiceAddress.postcode} {invoiceAddress.district}
                        <br />
                        {invoiceAddress.ico && (
                          <>
                            IČ: {invoiceAddress.ico}
                            <br />
                          </>
                        )}
                        {invoiceAddress.dic && (
                          <>
                            DIČ: {invoiceAddress.dic}
                            <br />
                          </>
                        )}
                      </p>
                    </p>
                  )}
                  <span className="title">
                    <Text bold>Kontakt</Text>
                  </span>
                  <p>
                    {email}
                    <br />
                    {phone}
                  </p>
                </div>

                <div className="col-lg-8 col-md-3">
                  <span className="title">
                    <Text bold>Způsob doručení</Text>
                  </span>
                  <p>
                    {isEverythingVirtual && "Online"}
                    {!isEverythingVirtual && this.renderDelivery()}
                  </p>
                  <span className="title">
                    <Text bold>Způsob platby</Text>
                  </span>
                  <p>
                    {payment === "csob"
                      ? "Kartou On-line"
                      : "Platební brána (platba kartou online / bankovním převodem)"}
                  </p>
                </div>
              </div>
              <hr />

              <div style={{ paddingTop: "15px" }}>
                <div className="d-flex align-items-top mt-4" style={{ color: "#7E7E7B" }}>
                  <div>
                    <input
                      id="agreeWithConditionsMobile"
                      checked={(this.state && this.state.agreeWithConditions) || false}
                      onChange={() =>
                        // eslint-disable-next-line react/no-access-state-in-setstate
                        this.setState({
                          agreeWithConditions: !(this.state && this.state.agreeWithConditions),
                          hasToAgreeWithConditions: false
                        })
                      }
                      type="checkbox"
                    />
                  </div>
                  <div>
                    <label for="agreeWithConditionsMobile" className="ml-2 font-weight-normal">
                      Souhlasím s{" "}
                      <Link target="_blank" to={`${API}/docs/vop_eshop`}>
                        <span>obchodními podmínkami</span>
                      </Link>{" "}
                      e&#8209;shopu Pointy{deliveryAfter30Days ? ` ${CONDITION_ACCEPTANCE_MESSAGE_EXTENSION}` : ""}
                    </label>
                  </div>
                </div>
                {this.state.hasToAgreeWithConditions && <div className="text-danger">Pole je povinné.</div>}

                <div className="d-flex align-items-top mt-4" style={{ color: "#7E7E7B" }}>
                  <div>
                    <input
                      id="subscribeToNewsletterMobile"
                      checked={(this.state && this.state.subscribeToNewsletter) || false}
                      onChange={() =>
                        // eslint-disable-next-line react/no-access-state-in-setstate
                        this.setState({ subscribeToNewsletter: !(this.state && this.state.subscribeToNewsletter) })
                      }
                      type="checkbox"
                    />
                  </div>
                  <div>
                    <label for="subscribeToNewsletterMobile" className="font-weight-normal ml-2">
                      Chci odebírat novinky emailem (souhlasím s{" "}
                      <Link target="_blank" to={`${API}/docs/gdpr`}>
                        <span>podmínkami ochrany osobních dat</span>
                      </Link>
                      )
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column-reverse align-items-end">
                  <Text cartPrice>
                    Celkem: <span style={{ color: "#E8452C", fontWeight: "bold" }}>{priceSum} Kč</span>
                  </Text>
                  {!isEverythingVirtual &&
                    (delivery === DELIVERY.sendToCZ || delivery === DELIVERY.sendToSK || delivery == DELIVERY.packeta) ? (
                    <Text>
                      {delivery === DELIVERY.packeta ? "Doprava" : "Poštovné"}: <span>{this.deliveryPrice()} Kč</span>
                    </Text>
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-center pt-4">
                  <Button onClick={() => this.confirmOrder()} primary wide>
                    DOKONČIT A ZAPLATIT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Screen>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  itemHasPrice(item) {
    return typeof item.rewardPrice === "number";
  }

  itemRewardPrice(item) {
    if (this.itemHasPrice(item)) {
      return item.rewardPrice;
    }
    return item.reward.price;
  }
}

export default connect()(withRouter(ShopRecap));
