import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { isEmpty, get as g, uniqBy } from "lodash";
import _size from "lodash/size";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";

import "./header.css";

import MainNavigation from "./mainNavigation/mainNavigation";
import UserDuck from "../../redux/ducks/user";
import Avatar from "../avatar/avatar";
import UIDuck from "../../redux/ducks/ui";
import EnumsDuck from "../../redux/ducks/enums";
import WizardService from "../../services/wizardService";
import Link from "../link/link";
import { be, bm } from "../../utils/bliss";
import Button from "../button/button";
import Text from "../text/text";
import WizardDuck from "../../redux/ducks/wizard";
import CartDuck from "../../redux/ducks/cart";
import Notification from "../notification/notification";
import { getQueryObjFromString, isMobile } from "../../utils/utils";
import UserService from "../../services/userService";
import Bell from "../bell/bell";
import GaSend from "../ga/gaSend";

class Header extends Component {
  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const queryObj = getQueryObjFromString(search);

    if (queryObj.notification) {
      UserService.getNotificationById(queryObj.notification).then(notif => {
        this.showNotif(notif);
      });
    }
  }

  logout = () => {
    const { dispatch, history } = this.props;
    dispatch(UserDuck.logout()).then(() => {
      history.push("/login");
    });
  };

  showNotif = (notif = {}) => {
    console.log("notif", notif);
    const { dispatch, user, roles, handshakeStates, projectStates } = this.props;
    const isContributor = user.roles.includes(roles.contributor.name);

    dispatch(UserDuck.readNotification(notif)).then(() => {
      if (notif.handshake) {
        console.log("notif-hs.state", g(notif, "handshake.state", ""));
        const isRequested = g(notif, "handshake.state", "") === g(handshakeStates, "requested.name", "");
        const isSuggested = g(notif, "handshake.state", "") === g(handshakeStates, "suggested.name", "");
        const isAccepted = g(notif, "handshake.state", "") === g(handshakeStates, "accepted.name", "");
        const isDeclined = g(notif, "handshake.state", "") === g(handshakeStates, "declined.name", "");

        if (!isRequested && !isDeclined && !isAccepted) {
          WizardService.getProjectById(g(notif, "handshake.projectId", "")).then(project => {
            if (project.state === g(projectStates, "failed.name", "")) {
              window.alertify.error("Tento projekt bohužel neuspěl a tato operace není možná :-(");
              return;
            }
            let data = {
              handshake: {
                ...g(notif, "handshake", {})
              },
              project
            };
            if (isContributor) {
              data = {
                ...data,
                handshake: {
                  ...data.handshake,
                  contributor: user,
                  author: g(project, "author", [])
                }
              };
            } else {
              data = {
                ...data,
                handshake: {
                  ...data.handshake,
                  author: [user]
                }
              };
            }
            if (isSuggested) {
              dispatch(UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, data));
            } else if (isAccepted) {
              this.redirectFromNotifToLink(notif);
            }
          });
        } else if (isDeclined) {
          window.alertify.notify("Tato nabídka byla odmítnuta.", "error", 5);
          this.redirectFromNotifToLink(notif);
        } else if (isRequested) {
          window.alertify.notify(
            "Čeká se na vyplnění popisu a ceny. Vyřiďte spolu formality spolupráce přes email.",
            "success",
            5
          );
          this.redirectFromNotifToLink(notif);
        } else if (isAccepted) {
          window.alertify.notify("Tato spolupráce je již potvrzena.", "success", 5);
          this.redirectFromNotifToLink(notif);
        }
      }
      this.redirectFromNotifToLink(notif);
    });
  };

  redirectFromNotifToLink = notif => {
    const { history } = this.props;

    const domainRegex = /^.*\/\/[^\/]+/;
    if (domainRegex.test(notif.link)) {
      const domain = domainRegex.exec(notif.link)[0];
      const route = notif.link.slice(domain.length);
      if (route) {
        history.push(route);
      }
    }
  };

  rejectHandshakeFromNotif = (e, notif = {}) => {
    e.stopPropagation();
    const { t, dispatch } = this.props;

    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.removeHandshakeText"),
        callback: () => {
          dispatch(UserDuck.removeHandshake(g(notif, "handshake.projectId", ""), g(notif, "handshake.id", ""))).then(
            () => {
              window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
              dispatch(WizardDuck.setProjectById(g(notif, "handshake.projectId", "")));
            }
          );
        }
      })
    );
  };

  render() {
    const { user, t, history, moveToFaq, cartNumberOfItems } = this.props;
    const isUser = !isEmpty(user);
    let roles = `${g(user, "roles", [])
      .slice(0, 2)
      .map(role => t(`roles.${role}`))
      .join(", ")}${g(user, "roles.length", 0) > 2 ? "..." : ""}`;
    if (!isEmpty(user.subroles) && user.subroles) {
      roles = `${g(user, "subroles", [])
        .slice(0, 2)
        .map(({ subrole }) => subrole.name)
        .join(", ")}${g(user, "subroles.length", 0) > 2 ? "..." : ""}`;
    }
    if (!isUser) {
      return (
        <MainNavigation
          isUser={isUser}
          moveToFaq={moveToFaq}
          rightContent={
            <ul className="MainNavigation-links">
              <li className="MainNavigation-linkCart">
                {!isMobile() && (
                  <span style={{ padding: "0 5px", color: "#38675B" }}>
                    <i className="fa fa-shopping-cart" />
                  </span>
                )}
                {!isMobile() ? (
                  <Link align bold pointer greenReward to="/cart">
                    Košík
                  </Link>
                ) : (
                  <div className="MainNavigation-cart">
                    <Fragment>
                      <Link align pointer greenReward to="/cart">
                        <span style={{ padding: "0 5px", color: "#38675B" }}>
                          <i className="fa fa-shopping-cart" />
                        </span>
                        <span className="MainNavigation-number">{cartNumberOfItems}</span>
                      </Link>
                    </Fragment>
                  </div>
                )}
                {!isMobile() && <span className="MainNavigation-cartCount">{cartNumberOfItems}</span>}
              </li>
              <li className="MainNavigation-link">
                <GaSend action="click" category="link" label="Přihlásit se">
                  <span style={{ padding: "0 5px" }}>
                    <a href="/login">
                      <img src="/images/svg/shape.svg" />
                    </a>
                  </span>
                  {!isMobile() ? (
                    <Link align bold medium to="/login" uppercase>
                      Přihlásit se
                    </Link>
                  ) : (
                    ""
                  )}
                </GaSend>
              </li>
            </ul>
          }
        />
      );
    }

    const unreadNotifications = g(user, "notifications", [])
      .filter(notif => !notif.seen)
      .sort((notifA, notifB) => moment(notifB.createdAt) >= moment(notifA.createdAt));
    const allNotifications = uniqBy(
      [
        ...unreadNotifications,
        ...g(user, "notifications", []).sort((notifA, notifB) => moment(notifB.createdAt) >= moment(notifA.createdAt))
      ],
      "id"
    ).slice(0, unreadNotifications.length > 10 ? unreadNotifications.length : 10);

    return (
      <MainNavigation
        isUser={isUser}
        moveToFaq={moveToFaq}
        rightContent={
          <Fragment>
            <ul className="MainNavigation-links">
              <li className="MainNavigation-linkCart">
                {!isMobile() && (
                  <span style={{ padding: "0 5px", color: "#38675B" }}>
                    <i className="fa fa-shopping-cart" />
                  </span>
                )}
                {!isMobile() ? (
                  <Link align bold pointer greenReward to="/cart">
                    Košík
                  </Link>
                ) : (
                  <div className="MainNavigation-cart">
                    <Fragment>
                      <Link align pointer greenReward to="/cart">
                        <span style={{ padding: "0 5px", color: "#38675B" }}>
                          <i className="fa fa-shopping-cart" />
                        </span>
                        <span className="MainNavigation-number">{cartNumberOfItems}</span>
                      </Link>
                    </Fragment>
                  </div>
                )}
                {!isMobile() && <span className="MainNavigation-cartCount">{cartNumberOfItems}</span>}
              </li>
            </ul>
            <DropdownButton
              bsStyle="default"
              className="d-flex justify-content-center align-items-center mr-lg-4 mr-md-2 ml-3"
              id="user-drp-down"
              noCaret
              title={<Bell number={unreadNotifications.length} />}
            >
              <div className={be("Notification", "title")}>
                <Text bold small>
                  Upozornění
                </Text>
              </div>
              <div className={be("Notification", "content")}>
                {allNotifications.map(notif => (
                  <Notification
                    key={notif.id}
                    notif={notif}
                    onClick={() => this.showNotif(notif)}
                    onClickBtn={e => this.rejectHandshakeFromNotif(e, notif)}
                  />
                ))}
              </div>
              <div className={be("Notification", "button")}>
                <Button bold medium small to="/notifications">
                  Ukázat vše
                </Button>
              </div>
            </DropdownButton>
            <div className="Notification-wrapper Notification-wrapper-small">
              <DropdownButton
                bsStyle="default"
                className="small d-flex justify-content-center align-items-center mr-md-4"
                id="user-drp-down"
                noCaret
                pullRight
                title={
                  <Fragment>
                    <Avatar image={user.profilePic} navbar />
                  </Fragment>
                }
              >
                <MenuItem eventKey="1" onClick={() => history.push("/profile")}>
                  <Text medium>{t("header.myProfile")}</Text>
                </MenuItem>
                <MenuItem eventKey="1" onClick={() => history.push("/orderList")}>
                  <Text medium>Moje objednávky</Text>
                </MenuItem>
                <MenuItem eventKey="2" href="/projects-followed">
                  <Text medium>Sledované knihy</Text>
                </MenuItem>
                {/* <MenuItem eventKey="3" href="/orderlist">
                  <Text medium>Moje objednávky</Text>
                </MenuItem> */}
                <MenuItem eventKey="4">
                  <span className="menu-item" onClick={this.logout}>
                    <Text medium>{t("header.logout")}</Text>
                  </span>
                </MenuItem>
              </DropdownButton>
            </div>
          </Fragment>
        }
      />
    );
  }
}

Header.defaultProps = {
  location: {}
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handshakeStates: PropTypes.object.isRequired,
  history: PropTypes.object,
  projectStates: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  cartNumberOfItems: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  roles: EnumsDuck.getRoles(state),
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  projectStates: EnumsDuck.getProjectStates(state),
  cartNumberOfItems: CartDuck.getNumberOfItems(state)
});

export default compose(translate("translations"), connect(mapStateToProps), withRouter)(Header);
