/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import Avatar from "../../../../components/avatar/avatar";
import Button from "../../../../components/button/button";
import Forms from "../../../../components/forms/form";

class Message extends Component {
  render() {
    return (
      <div className="media Comment Comment-main">
        <div className="media-body Comment-mediabody">
          <div className="Comment-form">
            <Forms
              inputs={[
                {
                  label: null,
                  type: "textarea",
                  wide: true,
                  name: "body",
                  required: true
                }
              ]}
            >
              <Button small style={{ float: "right" }} type="submit">
                Odeslat
              </Button>
            </Forms>
          </div>
        </div>
        <div className="Comment-avatar">
          <Avatar md />
        </div>
      </div>
    );
  }
}

export default Message;
