import React from "react";
import "./detailBox.css";
import { be } from "../../../../utils/bliss";
import Button from "../../../button/button";
import Forms from "../../../forms/form";
import Text from "../../../text/text";

const MODULE_NAME = "DetailBox";

const DetailBox = () => (
  <div className={be(MODULE_NAME, "detailBoxContainer")} style={{ textAlign: "center" }}>
    <div className={be(MODULE_NAME, "detailBoxHeader")}>
      <div className={be(MODULE_NAME, "infoNotifWrapper")}>
        <img alt="infoNotif" className={be(MODULE_NAME, "infoNotif")} src="/images/svg/infoNotif.svg" />
      </div>
    </div>
    <div>
      <div className="pb-4">
        <Text bold>Poptáváte spolupráci s Jan Novák</Text>
      </div>
    </div>
    <div className="text-left pb-2">
      <div style={{ padding: "0 20px" }}>
        <Text small>
          Představte se a popište mu váš projekt a služby, které potřebujete. Kolegu by mohlo zajímat: technické
          specifikace (počet stran, druh vazby, jestli bude kniha barevná, nebo černobílá atd.), cílová skupina (věk,
          zájmy apod.), žánr a téma, příklady vydaných knih od jiných autorů, které se podobají tvé knize tónem, stylem
          nebo tématem.
        </Text>
      </div>
    </div>
    <div style={{ padding: "0 20px" }}>
      <Forms
        inputs={[
          {
            placeholder: "Představ se, popiš mu krátce tvůj projekt a služby, které potřebuješ.",
            label: null,
            type: "textarea",
            wide: true,
            name: "body",
            required: true
          }
        ]}
      />
    </div>
    <div className="d-flex justify-content-center">
      <div className={be(MODULE_NAME, "buttonSent")}>
        <Button small type="submit">
          Odeslat poptávku
        </Button>
      </div>
      <Button invertedBlack small style={{ float: "right" }} type="submit">
        Storno
      </Button>
    </div>
  </div>
);

export default DetailBox;
