import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g, isEmpty } from "lodash";

import "./mainNavigation.css";
import { slide as Menu } from "react-burger-menu";
import { bm, be } from "../../../utils/bliss";

import Link from "../../link/link";
import Logo from "../../logo/logo";
import UserDuck from "../../../redux/ducks/user";
import EnumsDuck from "../../../redux/ducks/enums";
import Text from "../../text/text";
import Button from "../../button/button";
import GaSend from "../../ga/gaSend";
import DataLayerPush from "../../gtm/dataLayerPush";
import Banner from "./banner";

const MODULE_NAME = "MainNavigation";

const MainNavigation = ({
  landing,
  user,
  rightContent = null,
  roles,
  isUser = !isEmpty(user),
  moveToFaq = () => {},
  dispatch
}) => {
  const isAuthor = g(user, "roles", []).includes(g(roles, "author.name", "")) || false;
  const isUncompletedUser = g(user, "roles", []).includes(g(roles, "user.name", "")) || false;

  return (
    <Fragment>
      <Banner />
      <div className={bm(MODULE_NAME, { landing }, "container-fluid")}>
        <div className="row w-100 flex-nowrap">
          <div className="col d-flex p-0 align-items-center justify-content-start">
            <div className={be(MODULE_NAME, "burger", null, "d-md-none")}>
              <Menu
                bubble
                customBurgerIcon={
                  <Fragment>
                    <div className={be(MODULE_NAME, "burgerItem")} />
                    <div className={be(MODULE_NAME, "burgerItem")} />
                    <div className={be(MODULE_NAME, "burgerItem")} />
                  </Fragment>
                }
                customCrossIcon={
                  <img alt="close" className={be(MODULE_NAME, "burgerClose")} src="/images/svg/cross.svg" />
                }
              >
                {!isUser && (
                  <Fragment>
                    <GaSend action="click" category="button" label="Knihy">
                      <Link big greenReward bold paddingMob to="/projects">
                        Knihy
                      </Link>
                    </GaSend>
                    {!landing ? (
                      <GaSend action="click" category="button" label="contact">
                        <Link greenReward big bold to="/contact">
                          Kontakt
                        </Link>
                      </GaSend>
                    ) : (
                      <span>
                        {" "}
                        <Text bold pointer greenReward to="/contact">
                          Kontakt
                        </Text>
                      </span>
                    )}
                    {/* <Link big bold paddingMob greenReward target="_blank" to="https://kupknihu.pointa.cz">
                      E-shop
                    </Link> */}
                    <Link big bold paddingMob greenReward target="_blank" to="https://blog.pointa.cz/">
                      Blog
                    </Link>
                    <DataLayerPush
                      event={{
                        event: "button_bytkolega_all",
                        funnel: "kolega",
                        path: "step1"
                      }}
                    >
                      <Link big bold pointer greenReward to="/contributorPage">
                        Chci být kolega
                      </Link>
                    </DataLayerPush>
                    <div className="mt-4">
                      <DataLayerPush
                        event={{
                          event: "button_vydatknihu_all",
                          funnel: "autor",
                          path: "step1"
                        }}
                      >
                        <GaSend action="click" category="button" label="Chci vydat knihu">
                          <Button small smallRadius semi to="/authorPage">
                            Chci vydat knihu
                          </Button>
                        </GaSend>
                      </DataLayerPush>
                    </div>
                  </Fragment>
                )}
                {isUser && (
                  <Fragment>
                    <Link big greenReward bold paddingMob to="/projects">
                      Knihy
                    </Link>
                    <Link big bold greenReward paddingMob to="/contributors">
                      Kolegové
                    </Link>
                    {/* <Link big bold paddingMob to="/authors">
                      Autoři
                    </Link>
                   <Link big bold greenReward paddingMob target="_blank" to="https://kupknihu.pointa.cz">
                      E-shop
                    </Link> */}
                    <Link big bold greenReward paddingMob target="_blank" to="https://blog.pointa.cz/">
                      Blog
                    </Link>
                    {!landing ? (
                      <Link big bold greenReward paddingMob to="/contact">
                        Kontakt
                      </Link>
                    ) : (
                      <span>
                        {" "}
                        <Text bold greenReward pointer>
                          Kontakt
                        </Text>
                      </span>
                    )}
                    {(isAuthor || isUncompletedUser) && (
                      <Link big bold greenReward paddingMob to={!isUser ? "/authorPage" : "/wizard"}>
                        Chci vydat knihu
                      </Link>
                    )}
                    {(isAuthor || isUncompletedUser) && (
                      <Link big bold greenReward paddingMob to="/knowledgebase">
                        Průvodce
                      </Link>
                    )}
                  </Fragment>
                )}
              </Menu>
            </div>
            <div className={be(MODULE_NAME, "logo")}>
              {isUncompletedUser && (
                <a onClick={() => dispatch(UserDuck.setOnboardingStep(1))}>
                  <Logo small={!landing} />
                </a>
              )}
              {!isUser && (
                <Link to="/landing">
                  <Logo small={!landing} />
                </Link>
              )}
              {isUser && !isUncompletedUser && (
                <Link to="/dashboard">
                  <Logo small={!landing} />
                </Link>
              )}
            </div>
          </div>
          <div className="col-auto p-0 d-flex align-items-center justify-content-center">
            {!isUser && (
              <div className={be(MODULE_NAME, "content", null, "d-none d-md-flex")}>
                <ul className={be(MODULE_NAME, "links")}>
                  <li className="MainNavigation-link d-none d-md-flex">
                    <DataLayerPush
                      event={{
                        event: "button_vydatknihu_all",
                        funnel: "autor",
                        path: "step1"
                      }}
                    >
                      <GaSend action="click" category="button" label="Chci vydat knihu">
                        <Button small smallRadius semi to="/authorPage">
                          Chci vydat knihu
                        </Button>
                      </GaSend>
                    </DataLayerPush>
                  </li>
                  <li className={be(MODULE_NAME, "link")}>
                    <DataLayerPush
                      event={{
                        event: "button_bytkolega_all",
                        funnel: "kolega",
                        path: "step1"
                      }}
                    >
                      <Link bold greenReward to="/contributorPage">
                        Chci být kolega
                      </Link>
                    </DataLayerPush>
                  </li>
                  <li className={be(MODULE_NAME, "link")}>
                    <Link greenReward bold to="/projects">
                      Knihy
                    </Link>
                  </li>
                  <li className={be(MODULE_NAME, "link")}>
                    <Link bold target="_blank" greenReward to="https://blog.pointa.cz/">
                      Blog
                    </Link>
                  </li>
                  {!landing ? (
                    <li className={be(MODULE_NAME, "link")}>
                      <Link greenReward bold to="/contact">
                        Kontakt
                      </Link>
                    </li>
                  ) : (
                    <li className={be(MODULE_NAME, "link")}>
                      <span>
                        {" "}
                        <Text greenReward bold to="/contact" pointer small>
                          Kontakt
                        </Text>
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {isUser && (
              <div className={be(MODULE_NAME, "content", null, "d-none d-md-flex")}>
                <ul className={be(MODULE_NAME, "links")}>
                  <li className={be(MODULE_NAME, "link")}>
                    <DataLayerPush
                      event={{
                        event: "button_bytkolega_all",
                        funnel: "kolega",
                        path: "step1"
                      }}
                    >
                      <Link bold greenReward to="/contributorPage">
                        Chci být kolega
                      </Link>
                    </DataLayerPush>
                  </li>
                  <li className={be(MODULE_NAME, "link")}>
                    <Link greenReward bold to="/projects">
                      Knihy
                    </Link>
                  </li>
                  <li className={be(MODULE_NAME, "link")}>
                    <Link greenReward bold to="/contributors">
                      Kolegové
                    </Link>
                  </li>
                  {/* <li className={be(MODULE_NAME, 'link')}>
                                        <Link bold to="/authors">
                                            Autoři
                                        </Link>
                                    </li> */}
                  <li className={be(MODULE_NAME, "link")}>
                    <Link greenReward bold target="_blank" to="https://blog.pointa.cz/">
                      Blog
                    </Link>
                  </li>
                  {(isAuthor || isUncompletedUser) && (
                    <li className={be(MODULE_NAME, "link")}>
                      <Link greenReward bold to={!isUser ? "/authorPage" : "/wizard"} style={{ paddingRight: "20px" }}>
                        Chci vydat knihu
                      </Link>
                    </li>
                  )}
                  {(isAuthor || isUncompletedUser) && (
                    <Link bold greenReward to="/knowledgebase">
                      Průvodce
                    </Link>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="col p-0 d-flex align-items-center justify-content-end">
            <div className={be(MODULE_NAME, "rightContent")}>{rightContent}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MainNavigation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isUser: PropTypes.bool,
  landing: PropTypes.bool,
  moveToFaq: PropTypes.func,
  rightContent: PropTypes.node,
  roles: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  roles: EnumsDuck.getRoles(state)
});

export default connect(mapStateToProps)(MainNavigation);
