import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

import "./rangeSlider.css";

const RangeSlider = ({ enableValues, label, ...props }) => (
  <div className="slider">
    <Slider {...props} />
    {enableValues && (
      <div className="values">
        <span>{props.min}</span>

        <span>{props.max}</span>
      </div>
    )}
  </div>
);

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  value: 0,
  enableValues: true,
  label: ""
};

RangeSlider.propTypes = {
  enableValues: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number
};

export default RangeSlider;
