import React, { Component } from "react";
import PropTypes from "prop-types";

import "./basicParametersBubble.css";
import { bm, be } from "../../../utils/bliss";
import Headline from "../../headline/headline";
import Text from "../../text/text";

const MODULE_NAME = "BasicParametersBubble";

const BasicParametersBubble = ({ selected, title, label, description, icon, onSelect }) => (
  <div className={bm(MODULE_NAME, { selected })} onClick={onSelect}>
    <div className={be(MODULE_NAME, "checkbox", { selected })}>
      {selected ? (
        <img alt="package" src="/images/svg/checkbox--active.svg" />
      ) : (
        <img alt="package" src="/images/svg/checkbox.svg" />
      )}
    </div>
    {icon && (
      <div className={be(MODULE_NAME, "icon")}>
        <img alt="package" className={be(MODULE_NAME, "image")} src={icon} />
      </div>
    )}
    <div className={be(MODULE_NAME, "body")}>
      <Headline bold component="h3" subheadline>
        {title}
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "md")} />
      <Text component="div" small>
        {label}
      </Text>
      <div className={be(MODULE_NAME, "spacing", "md")} />
      <Text component="div" light small>
        {description}
      </Text>
    </div>
  </div>
);

BasicParametersBubble.defaultProps = {
  selected: false,
  title: "",
  label: "",
  description: "",
  icon: "/images/svg/bookBinding.svg"
};

BasicParametersBubble.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string
};

export default BasicParametersBubble;
