import React, { Component } from "react";
import PropTypes from "prop-types";
import { get as g, isEmpty } from "lodash";
import UserService from "../../../../services/userService";
import Avatar from "../../../avatar/avatar";
import Button from "../../../button/button";
import { be, bm } from "../../../../utils/bliss";
import Headline from "../../../headline/headline";

const MODULE_NAME = "HandshakeModal";

class AcceptedHandshake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contributor: g(props, "data.handshake.contributor", {})
    };
  }

  componentWillMount() {
    const { data } = this.props;

    if (!isEmpty(data)) {
      if (g(data, "handshake.contributorId", "")) {
        UserService.getUserById(g(data, "handshake.contributorId", "")).then(contributor => {
          console.log(contributor);
          this.setState({
            contributor
          });
        });
      }
    }
  }

  render() {
    const { data, t, onClose } = this.props;
    let authors = g(data, "handshake.author", {});
    if (!Array.isArray(authors)) {
      // TODO: API fix, entity comes in different format for author / contributor
      authors = [authors];
    }
    const { contributor } = this.state;

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="container">
          <div className={be(MODULE_NAME, "title")}>
            <Headline component="h2" md>
              {t("modals.handshake.title3")}
            </Headline>
          </div>
          <div className={be(MODULE_NAME, "desc")}>
            <div className={be(MODULE_NAME, "author")}>
              {authors.map(author => (
                <div
                  key={author.id}
                  className="d-flex flex-column text-center align-items-center justify-content-center"
                >
                  <Avatar image={author.profilePic} md />
                  <div className={be(MODULE_NAME, "spacing", "md")} />
                  <Headline bold component="h4" dark xs>
                    {author.name} {author.surname}
                  </Headline>
                </div>
              ))}
            </div>
            <div className={be(MODULE_NAME, "icons")}>
              <img alt="handshake" src="/images/svg/hands.svg" />
            </div>
            <div className={be(MODULE_NAME, "author")}>
              <div
                key={contributor.id}
                className="d-flex flex-column text-center align-items-center justify-content-center"
              >
                <Avatar image={contributor.profilePic} md />
                <div className={be(MODULE_NAME, "spacing", "md")} />
                <Headline bold component="h4" dark xs>
                  {contributor.name} {contributor.surname}
                </Headline>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "button", null, "text-center")}>
            <Button onClick={onClose} sm>
              {t("modals.handshake.finish")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AcceptedHandshake.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default AcceptedHandshake;
