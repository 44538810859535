import React from "react";
import PropTypes from "prop-types";

import "./tip.css";

const Tip = ({ text }) => (
  <div className="tip-component">
    <img alt="100x100" src="/images/svg/tip.svg" />
    <span>{text}</span>
  </div>
);

Tip.propTypes = {
  text: PropTypes.string
};

export default Tip;
