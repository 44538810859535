import { get as g } from "lodash";
import _map from "lodash/map";

import CoreAPIService from "./coreAPIService";
import { getQueryFromObj } from "../utils/utils";

class WizardService {
  async createProject(project = {}) {
    return new Promise((res, rej) => {
      CoreAPIService.post("projects", project)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async fetchProjects(opt = {}) {
    const queryStr = getQueryFromObj(opt);
    return new Promise((res, rej) => {
      CoreAPIService.get(queryStr ? `projects${queryStr}` : `projects`)
        // axios.get(queryStr ? `${API}/projects${queryStr}` : `${API}/projects`)
        .then(async response => {
          response.data.data = await Promise.all(
            _map(response.data.data, async proj => {
              const data = proj;
              return data;
            })
          );
          res(response.data);
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectById(projectId, preventLogErr = false) {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/${projectId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          !preventLogErr && window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          !preventLogErr && console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectLightById(projectId, preventLogErr = false) {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects-light/${projectId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          !preventLogErr && window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          !preventLogErr && console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async fetchUserProjects(userId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`users/${userId}/projects`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deleteProject(id = "") {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${id}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async editProject(id = "", data = {}) {
    delete data.isVisible;
    return new Promise((res, rej) => {
      CoreAPIService.patch(`projects/${id}`, data)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectHandshakes(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/${projectId}/handshakes`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deleteProjectSample(projectId = "", sampleId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/samplefiles/${sampleId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectBasePrice(booktype = "", copies = "", pages = 0) {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/base-price/${booktype}/${copies}/${pages}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async hideProject(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.post(`users/projects/${projectId}/hide`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async unhideProject(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.post(`users/projects/${projectId}/unhide`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectChecklist(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/${projectId}/checklist`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getAllProjectChecklists(projectIds = []) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/checklists`, { data: projectIds })
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async updateProjectChecklistItem(projectId = "", itemId = "", handshakeId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/checklist/${itemId}/handshake/${handshakeId}`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deleteProjectChecklistItem(projectId = "", itemId = "", handshakeId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/checklist/${itemId}/handshake/${handshakeId}`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async uploadManuscript(projectId = "", file = "") {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/manuscript`, {
        data: file
      })
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deleteManuscript(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/manuscript`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async followProject(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/follow`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async unfollowProject(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/follow`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getFollowedProjects(userId = "", params) {
    const queryStr = getQueryFromObj(params);

    return new Promise((res, rej) => {
      CoreAPIService.get(`users/${userId}/followed-projects${queryStr}`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectNews(projectId = "", opt = {}) {
    const query = getQueryFromObj(opt);
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/${projectId}/news${query || ""}`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getProjectRewards(projectId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/${projectId}/alba`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async addProjectNews(projectId = "", params = {}) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/news`, params)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          window.alertify.error(g(err, "response.data.message", ""));
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async editProjectNews(projectId = "", newsId = "", params = {}) {
    return new Promise((res, rej) => {
      CoreAPIService.patch(`projects/${projectId}/news/${newsId}`, params)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          window.alertify.error(g(err, "response.data.message", ""));
          rej(err);
        });
    });
  }

  async deleteProjectNews(projectId = "", newsId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/news/${newsId}`)
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }
}

export default new WizardService();
