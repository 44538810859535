import React, { Component } from "react";
import "./shopCart.css";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _sumBy from "lodash/sumBy";
import _get from "lodash/get";

import { isMobile } from "../../utils/utils";
import { be, bm } from "../../utils/bliss";
import Screen from "../../components/screen/screen";
import Header from "../../components/header/header";
import Loader from "../../components/loader/loader";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import BookItem from "./components/bookItem/bookItem";

import CartDuck from "../../redux/ducks/cart";

const MODULE_NAME = "ShopCart";

class ShopCart extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  incrementItem(item) {
    this.props.dispatch(CartDuck.addItem(item.project, item.reward));
  }

  decrementItem(item) {
    this.props.dispatch(CartDuck.removeItem(item.project, item.reward));
  }

  removeItem(item) {
    this.props.dispatch(CartDuck.removeItem(item.project, item.reward, item.number));
  }

  render() {
    const { loading, cartItems } = this.props;
    const { delivery, payment } = _get(this.props.location, "state", {});

    const priceSum = _sumBy(cartItems, i => i.number * i.reward.price);

    if (loading) {
      return <Loader />;
    }
    return (
      <Screen header={<Header />}>
        {!isMobile() ? (
          <div className={be(MODULE_NAME)}>
            <div className="row">
              <div className="col-lg-6">
                <Headline page>Košík</Headline>
              </div>
              <div className="col-lg-6 col-md-12 d-flex align-items-end justify-content-end">
                <div>
                  <Text small>
                    <b>Košík</b>
                  </Text>{" "}
                  <Text small>
                    <img
                      alt="arrowImg"
                      src="/images/svg/leftCart.svg"
                      style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                    />{" "}
                    Doprava a platba
                  </Text>
                  <Text small>
                    <img
                      alt="arrowImg"
                      src="/images/svg/leftCart.svg"
                      style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                    />{" "}
                    Rekapitulace
                  </Text>
                </div>
              </div>
            </div>
            {_isEmpty(cartItems) ? (
              <div className={be(MODULE_NAME, "emptyItem")}>
                <span className="d-flex justify-content-center">Máte prázdný košík</span>
              </div>
            ) : (
              <div className={be(MODULE_NAME, "emptyItem")}>
                {_map(cartItems, item => (
                  <BookItem
                    project={item.project}
                    reward={item.reward}
                    decrement={() => this.decrementItem(item)}
                    increment={() => this.incrementItem(item)}
                    remove={() => this.removeItem(item)}
                    number={item.number}
                  />
                ))}
                <div className="row justify-content-end" style={{ padding: "15px 15px 0 0" }}>
                  <div className="d-flex flex-column-reverse" style={{ padding: "0 40px" }}>
                    <Text cartPrice>
                      Celkem: <span style={{ color: "#E8452C", fontWeight: "bold" }}>{Math.round(priceSum)} Kč</span>
                    </Text>
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/delivery",
                          state: { items: cartItems, fromCart: true, delivery, payment }
                        })
                      }
                      primary
                      semiWide
                      flex
                    >
                      KOUPIT
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={be(MODULE_NAME)}>
            <div className="row">
              <div className="col-lg-6">
                <Headline page>Košík</Headline>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-5">
                <div>
                  <Text small bold>
                    Košík
                  </Text>{" "}
                  <Text small>
                    <img
                      alt="arrowImg"
                      src="/images/svg/leftCart.svg"
                      style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                    />{" "}
                    Doprava a platba
                  </Text>
                  <Text small>
                    <img
                      alt="arrowImg"
                      src="/images/svg/leftCart.svg"
                      style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                    />{" "}
                    Rekapitulace
                  </Text>
                </div>
              </div>
            </div>
            {_isEmpty(cartItems) ? (
              <div className={be(MODULE_NAME, "emptyItem")}>
                <span className="d-flex justify-content-center">Máte prázdný košík</span>
              </div>
            ) : (
              ""
            )}
            {!_isEmpty(cartItems) ? (
              <div className={be(MODULE_NAME, "emptyItem")}>
                {_map(cartItems, item => (
                  <BookItem
                    project={item.project}
                    reward={item.reward}
                    decrement={() => this.decrementItem(item)}
                    increment={() => this.incrementItem(item)}
                    remove={() => this.removeItem(item)}
                    number={item.number}
                  />
                ))}
                {!isMobile() ? (
                  <div className="row justify-content-end" style={{ padding: "15px 15px 0 0" }}>
                    <div className="d-flex flex-column-reverse" style={{ padding: "0 40px" }}>
                      <Text cartPrice>
                        Celkem: <span style={{ color: "#E8452C", fontWeight: "bold" }}>{Math.round(priceSum)} Kč</span>
                      </Text>
                    </div>
                    <div>
                      <Button
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/delivery",
                            state: { items: cartItems, fromCart: true, delivery, payment }
                          })
                        }
                        primary
                        semiWide
                      >
                        KOUPIT
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingTop: "15px" }}>
                    <div className="d-flex flex-column-reverse align-items-end">
                      <Text cartPrice>
                        Celkem: <span style={{ color: "#E8452C", fontWeight: "bold" }}>{Math.round(priceSum)} Kč</span>
                      </Text>
                    </div>
                    <div className="text-center pt-4">
                      <Button
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/delivery",
                            state: { items: cartItems, fromCart: true, delivery, payment }
                          })
                        }
                        primary
                        wide
                      >
                        KOUPIT
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = state => ({
  cartItems: CartDuck.getItems(state)
});

export default compose(withRouter, connect(mapStateToProps))(ShopCart);
