import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import "./pagination.css";
import { be, bm } from "../../utils/bliss";

const MODULE_NAME = "Pagination";

const Pagination = ({ onChange, activePage, maxPage }) => (
  <div className={bm(MODULE_NAME)}>
    <ReactPaginate
      activeClassName="Pagination-item--active"
      breakClassName={be(MODULE_NAME, "item", "break")}
      breakLabel={<span>...</span>}
      containerClassName={be(MODULE_NAME, "container")}
      forcePage={activePage - 1}
      marginPagesDisplayed={1}
      nextClassName={be(MODULE_NAME, "button", "next")}
      nextLabel={null}
      nextLinkClassName="Button--pagination"
      onPageChange={({ selected }) => onChange(selected + 1)}
      pageClassName={be(MODULE_NAME, "item")}
      pageCount={maxPage}
      pageRangeDisplayed={2}
      previousClassName={be(MODULE_NAME, "button", "prev")}
      previousLabel={null}
      previousLinkClassName="Button--pagination"
      subContainerClassName="pages pagination"
    />
  </div>
);

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Pagination;
