import React from "react";
import PropTypes from "prop-types";
import { isEmpty, get as g, map, uniqBy, noop } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import "./userPanel.css";

import Avatar from "../avatar/avatar";
import UserBadge from "../userBadge/userBadge";
import Headline from "../headline/headline";
import UploadFile from "../input/uploadFile/uploadFile";
import { PREVIEWS_API } from "../../services/config";
import { bm, be } from "../../utils/bliss";
import Text from "../text/text";
import Button from "../button/button";
import EnumsDuck from "../../redux/ducks/enums";
import { getQueryObjFromString } from "../../utils/utils";

const MODULE_NAME = "UserPanel";

class UserPanel extends React.Component {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
  }

  componentDidMount() {
    // Workaround:
    // Google Tag Manager is capturing and forwarding all clicks in the document.
    // React's preventDefault does not work.
    // This allows middle click or right click to open user detail in new window,
    // but simple click will be still handled by React routing.

    if (this.linkRef.current) this.linkRef.current.addEventListener("click", e => e.preventDefault());
  }

  render() {
    let user = this.props.user;
    let className = this.props.className;
    let onClick = this.props.onClick;
    let horizontal = this.props.horizontal;
    let roles = this.props.roles;
    let selectedSubroles = this.props.selectedSubroles;
    let match = this.props.match;
    let location = this.props.location;

    const queryObj = getQueryObjFromString(location.search);
    const projectId = g(match, "params.projectId", "");
    const subroleId = g(queryObj, "subroles.0", "");
    const contributorUsed = g(user, "projectsWithContribution", []).find(
      info => info.projectId === projectId && info.subroleIds.includes(subroleId)
    );
    // TODO @malec kodovani
    if (contributorUsed) {
      className += " used";
    }
    const isAuthor = g(user, "roles", []).includes(roles.author.name);
    const userSubroles = uniqBy(
      [
        ...g(user, "subroles", []).filter(({ subrole }) => selectedSubroles.includes(subrole.id)),
        ...g(user, "subroles", [])
      ],
      "subrole.id"
    );

    return (
      <a ref={this.linkRef} href={`/profile/${user.id}`} className="UserPanel-link">
        <div className={bm(MODULE_NAME, null, className)} onClick={contributorUsed ? noop : onClick}>
          <div className={be(MODULE_NAME, "head")}>
            <Avatar image={user.profilePic} small />
            <div className={be(MODULE_NAME, "imageStep")} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <Headline bold primary small spacingXs>
                {user.name} {user.surname}
              </Headline>
              {user.isVerifiedAuthor ? <UserBadge mini /> : <React.Fragment />}
            </div>
          </div>
          {!isAuthor && (
            <div className={be(MODULE_NAME, "body")}>
              {!isAuthor &&
                userSubroles.slice(0, 2).map(subroles => (
                  <div key={subroles.subrole.id + user.id} className={be(MODULE_NAME, "subrole")}>
                    <Text secondary uppercase xs>
                      {subroles.subrole.name}
                    </Text>
                    <br />
                    <Text bold small>
                      od {subroles.rate} Kč
                    </Text>
                    {!isEmpty(subroles.unit) && <Text small>&nbsp;/ {subroles.unit.name}</Text>}
                  </div>
                ))}
            </div>
          )}
          <div className={be(MODULE_NAME, "gallery")}>
            {!isEmpty(user.portfolio) && (
              <UploadFile
                count3
                editable={false}
                gallery
                small
                value={user.portfolio.slice(0, 3).map(file => ({
                  ...file,
                  preview: `${PREVIEWS_API}${file.data}`
                }))}
              />
            )}
          </div>
          <div className={be(MODULE_NAME, "button")}>
            <Button onClick={onClick} white wide>
              Detail {isAuthor ? "autora" : "kolegy"}
            </Button>
          </div>
        </div>
      </a>
    );
  }
}

UserPanel.defaultProps = {
  user: {},
  className: "",
  onClick: () => {},
  horizontal: false,
  selectedSubroles: []
};

UserPanel.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  roles: PropTypes.object.isRequired,
  selectedSubroles: PropTypes.array,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  roles: EnumsDuck.getRoles(state)
});
export default compose(withRouter, connect(mapStateToProps))(UserPanel);
