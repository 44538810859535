import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { bm } from "../../utils/bliss";

import "./cookies.css";
import Text from "../text/text";
import Button from "../button/button";
import { API, COOKIE_NAME } from "../../services/config";
import Link from "../link/link";
import { getCookie, setCookie } from "../../utils/utils";
import UserService from "../../services/userService";
import UIDuck from "../../redux/ducks/ui";

const MODULE_NAME = "CookieBar";

class Cookies extends Component {
  state = {
    show: !getCookie(COOKIE_NAME)
  };

  cookiesAgreed = () => {
    UserService.cookiesAgreed().then(() => {
      setCookie(COOKIE_NAME, true);
      this.setState(
        {
          show: false
        },
        () => {
          this.props.dispatch(UIDuck.openModal(UIDuck.modalTypes.newsletterModal));
        }
      );
    });
  };

  render() {
    return this.state.show ? (
      <div className={bm(MODULE_NAME)}>
        <div className="container">
          <div className="row">
            <div className="col-md mb-4 mb-md-0">
              <Text inverted small>
                Tyto webové stránky používájí k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory
                cookie. Informace o tom, jak tyto webové stránky používáte, jsou sdíleny se společností Google.
                <br /> <br />
                Používáním těchto webových stránek souhlasíte s použitím souborů cookie. Více informací{" "}
                <Link target="_blank" to={`${API}/docs/gdpr`} underline white>
                  zde
                </Link>
              </Text>
            </div>
            <div className="col-md-auto d-flex align-items-center justify-content-center justify-content-md-center">
              <Button cookies onClick={this.cookiesAgreed}>
                ROZUMÍM
              </Button>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

Cookies.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect()(Cookies);
