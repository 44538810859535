import React, { Fragment } from "react";
import { get as g, isEmpty } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { be } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";
import { PREVIEWS_API } from "../../../../services/config";

import EnumsDuck from "../../../../redux/ducks/enums";
import UserDuck from "../../../../redux/ducks/user";

import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";
import UploadFile from "../../../../components/input/uploadFile/uploadFile";
import UserCard from "../../../../components/userCard/userCard";

const MODULE_NAME = "ProjectDetailScreen";

const GetProjectInfo = ({ user, project, handshakeStates, history }) => {
  const isUser = !isEmpty(user);
  const acceptedHandshakes = g(project, "handshakes", []).filter(
    ({ state }) => g(handshakeStates, "accepted.name", "") === state
  );

  const showUser = userId => {
    history.push(`/profile/${userId}`);
  };

  const samples =
    project && project.files && !isEmpty(project.files)
      ? project.files
          .filter(file => g(file, "type", "") !== "image-header")
          .map(file => ({
            ...file,
            data: `${file.url}`
          }))
      : [];

  return (
    <div>
      <Headline greenReward pageSub>
        {project.name}
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      <Text greenReward small>
        <div className="clearfix" dangerouslySetInnerHTML={{ __html: project.annotation }} />
      </Text>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      <div className={be(MODULE_NAME, "spacing", "sm")} />

      {!isEmpty(project.video) && project.type === "financing" && (
        <Fragment>
          <div className="row pb-4 pt-4 mb-5 mt-4">
            <iframe
              allow="autoplay; encrypted-media"
              allowFullScreen
              frameBorder="0"
              src={`https://www.youtube.com/embed/${project.video}`}
              style={
                !isMobile()
                  ? { width: "625px", paddingLeft: "15px", height: "335px" }
                  : { width: "100%", height: "auto" }
              }
              title="video"
            />
          </div>
        </Fragment>
      )}

      {!isEmpty(samples) && !project.issuuCode && (
        <Fragment>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
          <div className="row">
            <div className="col">
              <div className={be(MODULE_NAME, "samples")}>
                <Headline greenReward bold component="h2" pageSub>
                  Přečtěte si ukázku
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "lg")} />
                <UploadFile disableManual editable={false} samples value={samples} />
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
        </Fragment>
      )}

      {!isEmpty(samples) && project.issuuCode && (
        <Fragment>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
          <div className="row">
            <div className="col">
              <div className={be(MODULE_NAME, "samples")}>
                <Headline greenReward bold component="h2" pageSub>
                  Přečtěte si ukázku
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "lg")} />
                <div
                  style={{
                    width: "100%",
                    border: "1px solid grey",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "#353535"
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: project.issuuCode }} />
                </div>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
        </Fragment>
      )}

      {isEmpty(samples) && project.issuuCode && (
        <Fragment>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
          <div className="row">
            <div className="col">
              <div className={be(MODULE_NAME, "samples")}>
                <Headline greenReward bold component="h2" pageSub>
                  Přečtěte si ukázku
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "lg")} />
                <div
                  style={{
                    width: "100%",
                    border: "1px solid grey",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "#353535"
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: project.issuuCode }} />
                </div>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
        </Fragment>
      )}

      {!isEmpty(acceptedHandshakes) && (
        <Fragment>
          <div>
            <Text bold greenReward lg>
              Spolupracující kolegové
            </Text>
          </div>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row">
            {Object.keys(g(project, "acceptedContributors", {})).map(key => {
              const contributor = g(project, `acceptedContributors.${key}`, {});

              return (
                <div
                  key={`${key}_${contributor.id}_userCard`}
                  className={`col-12 col-sm-6${
                    Object.keys(g(project, "acceptedContributors", {})).length > 3 ? " col-lg-3" : " col-lg"
                  }`}
                >
                  {!isUser && (
                    <UserCard
                      description={key.toUpperCase()}
                      greenReward
                      picture={contributor.picture}
                      userName={contributor.name}
                    />
                  )}
                  {isUser && (
                    <UserCard
                      bold
                      description={key.toUpperCase()}
                      greenReward
                      hover
                      onClick={() => showUser(contributor.id)}
                      picture={contributor.picture}
                      pointer
                      userName={contributor.name}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  handshakeStates: EnumsDuck.getHandhakeStates(state)
});

export default compose(connect(mapStateToProps), withRouter)(GetProjectInfo);
