import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g, uniqBy, isEmpty } from "lodash";
import moment from "moment";

import { be, bm } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import Button from "../../components/button/button";
import UserDuck from "../../redux/ducks/user";
import Notification from "../../components/notification/notification";
import WizardService from "../../services/wizardService";
import UIDuck from "../../redux/ducks/ui";
import EnumsDuck from "../../redux/ducks/enums";
import WizardDuck from "../../redux/ducks/wizard";
import Text from "../../components/text/text";
import Loader from "../../components/loader/loader";

import "./notificationsPage.css";

const MODULE_NAME = "NotificationsPage";

class NotificationsPage extends Component {
  state = {
    limit: 10,
    page: 1
  };

  componentDidMount() {
    const { user, dispatch } = this.props;
    dispatch(UserDuck.loadSpecificUserNotifications(user.id, this.state));
  }

  loadLess = () => {
    const { user, dispatch } = this.props;

    this.setState(
      {
        page: this.state.page - 1
      },
      () => {
        dispatch(UserDuck.loadSpecificUserNotifications(user.id, this.state));
      }
    );
  };

  loadMore = () => {
    const { user, dispatch } = this.props;

    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        dispatch(UserDuck.loadSpecificUserNotifications(user.id, this.state));
      }
    );
  };

  showNotif = (notif = {}) => {
    console.log("notif", notif);
    const { dispatch, user, roles, handshakeStates, projectStates } = this.props;
    const isContributor = user.roles.includes(roles.contributor.name);

    dispatch(UserDuck.readNotification(notif)).then(() => {
      if (notif.handshake) {
        console.log("notif-hs.state", g(notif, "handshake.state", ""));
        const isRequested = g(notif, "handshake.state", "") === g(handshakeStates, "requested.name", "");
        const isSuggested = g(notif, "handshake.state", "") === g(handshakeStates, "suggested.name", "");
        const isAccepted = g(notif, "handshake.state", "") === g(handshakeStates, "accepted.name", "");
        const isDeclined = g(notif, "handshake.state", "") === g(handshakeStates, "declined.name", "");

        if (!isRequested && !isDeclined && !isAccepted) {
          WizardService.getProjectById(g(notif, "handshake.projectId", "")).then(project => {
            if (project.state === g(projectStates, "failed.name", "")) {
              window.alertify.error("Tento projekt bohužel neuspěl a tato operace není možná :-(");
              return;
            }
            let data = {
              handshake: {
                ...g(notif, "handshake", {})
              },
              project
            };
            if (isContributor) {
              data = {
                ...data,
                handshake: {
                  ...data.handshake,
                  contributor: user,
                  author: g(project, "author", [])
                }
              };
            } else {
              data = {
                ...data,
                handshake: {
                  ...data.handshake,
                  author: [user]
                }
              };
            }
            if (isSuggested) {
              dispatch(UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, data));
            } else if (isAccepted) {
              this.redirectFromNotifToLink(notif);
            }
          });
        } else if (isDeclined) {
          window.alertify.notify("Tato nabídka byla odmítnuta.", "error", 5);
          this.redirectFromNotifToLink(notif);
        } else if (isRequested) {
          window.alertify.notify(
            "Čeká se na vyplnění popisu a ceny. Vyřiďte spolu formality spolupráce přes email.",
            "success",
            5
          );
          this.redirectFromNotifToLink(notif);
        } else if (isAccepted) {
          window.alertify.notify("Tato spolupráce je již potvrzena.", "success", 5);
          this.redirectFromNotifToLink(notif);
        }
      }
      this.redirectFromNotifToLink(notif);
    });
  };

  redirectFromNotifToLink = notif => {
    const { history } = this.props;

    if (/.*\.com(.*)/.test(notif.link)) {
      const route = /.*\.com(.*)/.exec(notif.link)[1];
      if (route) {
        history.push(route);
      }
    }
  };

  rejectHandshakeFromNotif = (e, notif = {}) => {
    e.stopPropagation();
    const { t, dispatch } = this.props;

    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.removeHandshakeText"),
        callback: () => {
          dispatch(UserDuck.removeHandshake(g(notif, "handshake.projectId", ""), g(notif, "handshake.id", ""))).then(
            () => {
              window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
              dispatch(WizardDuck.setProjectById(g(notif, "handshake.projectId", "")));
            }
          );
        }
      })
    );
  };

  render() {
    const { history, canLoadMoreNotifications, canLoadLessNotifications, notifications, loading } = this.props;

    if (loading) {
      return <Loader />;
    }
    const unreadNotifications = notifications
      .filter(notif => !notif.seen)
      .sort((notifA, notifB) => moment(notifB.createdAt) >= moment(notifA.createdAt));
    const allNotifications = uniqBy(
      [
        ...unreadNotifications,
        ...notifications.sort((notifA, notifB) => moment(notifB.createdAt) >= moment(notifA.createdAt))
      ],
      "id"
    ).slice(0, unreadNotifications.length > 10 ? unreadNotifications.length : 10);

    return isEmpty(notifications) ? (
      <div className={bm(MODULE_NAME)}>
        <Text hover light pointer>
          <span onClick={history.goBack}>&#x2039; Zpět</span>
        </Text>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <Headline bold page>
          Vaše notifikace
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className="col-xs-12">
          <Text bold center lg>
            Nemáte žádné notifikace.
          </Text>
        </div>
      </div>
    ) : (
      <div className={bm(MODULE_NAME)}>
        <Text hover light pointer>
          <span onClick={history.goBack}>&#x2039; Zpět</span>
        </Text>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <Headline bold page>
          Vaše notifikace
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className={be(MODULE_NAME, "body")}>
          {allNotifications.map(notif => (
            <div className={be(MODULE_NAME, "panel")}>
              <Notification
                listing
                notif={notif}
                onClick={() => this.showNotif(notif)}
                onClickBtn={e => this.rejectHandshakeFromNotif(e, notif)}
              />
            </div>
          ))}
        </div>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className="col-xs-12 d-flex mt-5 justify-content-center align-items-center">
          {canLoadLessNotifications && (
            <Button medium onClick={this.loadLess} spacing>
              Zobrazit méně
            </Button>
          )}
          {canLoadMoreNotifications && (
            <Button medium onClick={this.loadMore} spacing>
              Zobrazit více
            </Button>
          )}
        </div>
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  canLoadLessNotifications: PropTypes.bool.isRequired,
  canLoadMoreNotifications: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  handshakeStates: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  projectStates: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  notifications: UserDuck.getNotifications(state),
  canLoadMoreNotifications: UserDuck.canLoadMoreNotifications(state),
  canLoadLessNotifications: UserDuck.canLoadLessNotifications(state),
  user: UserDuck.getUser(state),
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  roles: EnumsDuck.getRoles(state),
  loading: UserDuck.isNotificationsLoading(state),
  projectStates: EnumsDuck.getProjectStates(state)
});
export default connect(mapStateToProps)(NotificationsPage);
