import React from "react";
import { translate } from "react-i18next";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import { Mutate } from "restful-react";

import "./confirmBox.css";

import { be } from "../../utils/bliss";
import Headline from "../headline/headline";
import Text from "../text/text";
import UploadFile from "../input/uploadFile/uploadFile";
import ConfirmInput from "../input/confirmInput";
import Button from "../button/button";
import UserDuck from "../../redux/ducks/user";
import Loader from "../loader/loader";

const MODULE_NAME = "ConfirmBox";

class ConfirmBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      text: null,
      sent: false,
      isComplete: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.sent && this.state.sent) {
      UserDuck.loadUserFromToken(
        "/landing",
        true,
        false,
        false
      )(this.props.dispatch)
        .then(() => this.setState({ isComplete: true }))
        .catch(() => this.setState({ isComplete: true }));
    }
  }

  onFilesInsert = file => {
    this.setState({
      files: file
    });
  };

  onFileDelete = file => {
    this.setState({
      files: file
    });
  };

  onTextChange = event => {
    this.setState({
      text: event.target.value
    });
  };

  async onClick(upload) {
    upload({
      text: this.state.text,
      files: await Promise.all(this.state.files.map(f => this.normFile(f.file)))
    }).then(() => {
      this.setState({ sent: true });
    });
  }

  normFile = file => {
    console.log(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((res, rej) => {
      reader.onload = () => {
        res({
          src: file.preview,
          data: reader.result,
          file,
          mime: file.type,
          name: file.name
        });
      };
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Mutate path="/me/contributorVerification" verb="PUT">
        {(uploadVerification, { loading: isSending }) => {
          if (isSending || this.state.sent) {
            return <Loader />;
          }
          return (
            <div className={be(MODULE_NAME, "main")}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md">
                    <div className="text-center text-md-left">
                      <Headline bold inverted>
                        Zveřejnění profilu
                      </Headline>
                    </div>
                    <div className="text-center text-md-left mt-2" style={{ lineHeight: "20px" }}>
                      <Text lighter inverted small>
                        Předtím, než bude tvůj profil veřejný a pro autory viditelný a než tak začneš nabízet svoje
                        služby, potřebujeme, abys ho odeslal(a) Pointě k posouzení. Ujisti se, že ve tvém profilu autoři
                        najdou všechny potřebné informace i ukázky tvojí práce. Kromě toho nám zde pod textovým oknem
                        můžeš nahrát i životopis a reference a jiné relevantní materiály, které se tvojí práce týkají.
                        Uvidíme je pouze my, na platformě je uvidí jen ti, kterým to sám(sama) pošleš. Pokud nám chceš
                        něco ke svému profilu říct, můžeš nám napsat i krátkou zprávu.
                      </Text>
                    </div>
                    <div className="text-center text-md-left">
                      <UploadFile
                        disableManual
                        onFileDelete={this.onFileDelete}
                        onFilesInsert={this.onFilesInsert}
                        value={this.state.files}
                        white
                      />
                    </div>
                    <div className="text-center text-md-left mt-5 pt-2">
                      <ConfirmInput
                        placeHolder={t("profile.confirmMessage")}
                        maxLength="1000"
                        onChange={this.onTextChange}
                        value={this.state.text}
                        wide
                      />
                    </div>
                    <div className="text-center text-md-center mt-4">
                      <Button onClick={() => this.onClick(uploadVerification)} primary>
                        Odeslat profil
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Mutate>
    );
  }
}

export default compose(translate("translations"), connect())(ConfirmBox);
