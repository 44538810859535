import React, { Fragment } from "react";
import { noop } from "lodash";

import "./input.css";
import { bm, be } from "../../utils/bliss";

const MODULE_NAME = "Input";

const ConfirmInput = ({ placeHolder, maxLength, wide, ...restProps }) => (
  <Fragment>
    <div className={be(MODULE_NAME, "areaWrapper", `${wide ? "wide" : ""}`)}>
      <textarea
        className={bm(MODULE_NAME, "areaXs")}
        {...restProps}
        onKeyDown={noop}
        placeHolder={placeHolder}
        maxLength={maxLength}
      />
    </div>
  </Fragment>
);

export default ConfirmInput;
