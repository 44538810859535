import React from "react";
import { connect } from "react-redux";

import { isMobile } from "../../../../utils/utils";
import Link from "../../../../components/link/link";
import FundingTabs from "../../../../components/tabs/fundingTabs/fundingTabs";
import ReleasedTabs from "../../../../components/tabs/fundingTabs/releasedTabs";
import TabsComponent from "../../../../components/tabs/tabsComponent";

import EnumsDuck from "../../../../redux/ducks/enums";
import UserDuck from "../../../../redux/ducks/user";

import getFundingTabs from "./getFundingTabs";
import getReleasedTabs from "./getReleasedTabs";
import getTabs from "./getTabs";

const Tabs = ({ projectStates, project, user, genres, tips, handshakeStates, scrollToMyRef, dispatch, refetch }) => (
  <div className="row">
    <div className="col pr-5 mr-5">
      {(project.type === "making" || project.type == "waitingForPresale") && (
        <TabsComponent
          data={getTabs({
            projectStates,
            project,
            user,
            genres,
            tips,
            handshakeStates,
            dispatch,
            refetch
          })}
        />
      )}

      {project.type === "financing" && (
        <div>
          <hr
            style={{
              margin: "0 -1500px",
              borderTop: "1px solid #38675B",
              padding: "15px"
            }}
          />
          <FundingTabs
            data={getFundingTabs({ projectStates, project, user, genres, tips, handshakeStates, dispatch, refetch })}
            onClick={scrollToMyRef}
          />
        </div>
      )}

      {project.type === "released" && (
        <div>
          <hr
            style={{
              margin: "0 -1500px",
              borderTop: "1px solid #38675B",
              padding: "15px"
            }}
          />
          <ReleasedTabs
            data={getReleasedTabs({ projectStates, project, user, genres, tips, handshakeStates, dispatch, refetch })}
            onClick={scrollToMyRef}
          />
          {!isMobile() && (
            <div style={{ position: "absolute", top: "17px", right: "70px" }}>
              <Link
                target="_blank"
                to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
              >
                <b>SDÍLET</b> <img style={{ height: "30px", paddingLeft: "3px" }} src="/images/svg/shareNew.svg" />
              </Link>
            </div>
          )}
        </div>
      )}
      {project.type === "inProgress" && (
        <div>
          <hr
            style={{
              margin: "0 -1500px",
              borderTop: "1px solid #38675B",
              padding: "15px"
            }}
          />
          <ReleasedTabs
            data={getReleasedTabs({ projectStates, project, user, genres, tips, handshakeStates, dispatch, refetch })}
            onClick={scrollToMyRef}
          />
          {!isMobile() && (
            <div style={{ position: "absolute", top: "17px", right: "70px" }}>
              <Link
                target="_blank"
                to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
              >
                <b>SDÍLET</b> <img style={{ height: "30px", paddingLeft: "3px" }} src="/images/svg/shareNew.svg" />
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  genres: EnumsDuck.getGenres(state),
  tips: EnumsDuck.getTips(state),
  projectStates: EnumsDuck.getProjectStates(state)
});

export default connect(mapStateToProps)(Tabs);
