/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import "./presaleBox.css";

import { isMobile } from "../../utils/utils";
import { be } from "../../utils/bliss";
import Link from "../link/link";
import Forms from "../forms/form";
import Text from "../text/text";
import { API } from "../../services/variables";

import CoreAPIService from "../../services/coreAPIService";

const MODULE_NAME = "PresaleBox";

class PresaleBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribed: false
    };
  }

  subscribe = data => {
    CoreAPIService.post(`newsletterSubscribe/letuska`, {
      email: data.email
    }).then(postData => {
      if (postData.data.success) {
        this.setState({ subscribed: true });
      }
    });
  };

  render() {
    return !isMobile() ? (
      <div className={be(MODULE_NAME, "boxContainer")}>
        {!this.state.subscribed && (
          <div className="row">
            <div className="d-flex text-left" style={{ paddingBottom: "10px" }}>
              <Text greenReward small>
                Kniha Letuška z economy aneb co na Instagramu neuvidíte je momentálně vyprodána. V tiskárně se už ale
                chystají na svět další kusy. Pokud máš o knihu zájem, napiš nám svůj e-mail. Ozveme se, až bude opět k
                mání.
              </Text>
            </div>
            <div>
              <Forms
                onSubmit={this.subscribe}
                name="letuska-form"
                inputs={[
                  {
                    label: (
                      <div className="input-group-prepend" style={{ height: "38px" }}>
                        <button
                          className={be(MODULE_NAME, "newsletterButton", null, "btn btn-outline-secondary")}
                          name="_submit"
                          style={{ marginLeft: "-5px", borderLeft: "2px solid #DAD0CA" }}
                          type="submit"
                        >
                          ODESLAT
                        </button>
                      </div>
                    ),
                    placeholder: "Zadej svůj email...",
                    name: "email",
                    entityType: "email",
                    className: "col-8",
                    required: true,
                    type: "inlinePopup"
                  },
                  {
                    label: (
                      <Text small>
                        Souhlasím s{" "}
                        <Link target="_blank" to={`${API}/docs/gdpr`}>
                          <span style={{ textDecoration: "underline" }}>podmínkami ochrany osobních dat</span>
                        </Link>
                      </Text>
                    ),
                    name: "codexAgreed",
                    type: "checkbox",
                    required: true,
                    className: "Form-checkbox"
                  }
                ]}
              />
            </div>
          </div>
        )}
        {this.state.subscribed && (
          <div className="row">
            <div className="d-flex text-left" style={{ paddingBottom: "10px" }}>
              <Text greenReward small>
                Úspěšně jste se přihlásil k odběru novinek ke knížce Letuška z economy aneb co na Instagramu neuvidíte!
              </Text>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className={be(MODULE_NAME, "boxContainerMob")}>
        <div className="row">
          <div className="d-flex text-left" style={{ paddingBottom: "10px" }}>
            <Text greenReward small>
              Kniha Letuška z economy aneb co na Instagramu neuvidíte je momentálně vyprodána. V tiskárně se už ale
              chystají na svět další kusy. Pokud máš o knihu zájem, napiš nám svůj e-mail. Ozveme se, až bude opět k
              mání.
            </Text>
          </div>
          <div>
            <Forms
              onSubmit={this.subscribe}
              inputs={[
                {
                  label: (
                    <div className="input-group-prepend" style={{ height: "38px" }}>
                      <button
                        className={be(MODULE_NAME, "newsletterButton", null, "btn btn-outline-secondary")}
                        name="_submit"
                        style={{ marginLeft: "-5px", borderLeft: "2px solid #DAD0CA" }}
                        type="submit"
                      >
                        ODESLAT
                      </button>
                    </div>
                  ),
                  placeholder: "Zadejte svůj email...",
                  name: "email",
                  entityType: "email",
                  className: "col-12",
                  required: true,
                  type: "inlinePopup"
                },
                {
                  label: (
                    <Text small>
                      Souhlasím s{" "}
                      <Link target="_blank" to={`${API}/docs/gdpr`}>
                        <span style={{ textDecoration: "underline" }}>podmínkami ochrany osobních dat</span>
                      </Link>
                    </Text>
                  ),
                  name: "codexAgreed",
                  type: "checkbox",
                  required: true,
                  className: "Form-checkbox"
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PresaleBox;
