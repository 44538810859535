import React, { Component } from "react";
import PropTypes from "prop-types";

import "./tipsCarousel.css";

import Carousel from "../carousel";

class TipsCarousel extends Component {
  state = {
    index: 0,
    direction: null
  };

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction
    });
  };

  render() {
    const { tips } = this.props;
    const { direction, index } = this.state;

    return <Carousel data={tips} direction={direction} handleSelect={this.handleSelect} index={index} />;
  }
}

TipsCarousel.defaultProps = {
  tips: []
};

TipsCarousel.propTypes = {
  tips: PropTypes.array
};

export default TipsCarousel;
