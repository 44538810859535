import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

import "./forgottenPassword.css";

import { bm, be } from "../../utils/bliss";
import Header from "../../components/header/header";
import FormDuck from "../../redux/ducks/forms";
import Forms from "../../components/forms/form";
import Button from "../../components/button/button";
import UserService from "../../services/userService";
import Headline from "../../components/headline/headline";
import Link from "../../components/link/link";
import Screen from "../../components/screen/screen";
import Text from "../../components/text/text";

const MODULE_NAME = "ForgottenPassword";

class ForgottenPassword extends Component {
  state = {
    step: 1,
    email: ""
  };

  onSubmit = ({ email }) => {
    UserService.forgottenPassword(email).then(() => {
      this.setState({
        step: 2,
        email
      });
    });
  };

  redirectToLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    const { t } = this.props;
    const { step } = this.state;

    return (
      <Screen header={<Header {...this.props} />}>
        <div className={bm(MODULE_NAME)}>
          <div className="row">
            <div className="col">
              <div className="text-center">
                <Headline page primary>
                  {t("forgottenPassword.title")}
                </Headline>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "headlineSpacing")} />
          {step === 2 && (
            <div className={be(MODULE_NAME, "success")}>
              <img alt="success" className={be(MODULE_NAME, "successImage")} src="/images/svg/check--small.svg" />
              Na zadaný e-mail jsme odeslali pokyny k obnovení hesla.
              <Button inverted succes to="/login">
                ok
              </Button>
            </div>
          )}
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <div className="text-center">
                <Text component="div" lineHeight>
                  Zadej e-mail, pod kterým ses registroval/a.
                </Text>
                <Text component="div" lineHeight>
                  Nové heslo si pak nastavíš během minuty.
                </Text>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "body")}>
            <div className="row">
              <div className="col-sm-6 offset-sm-3">
                <Forms
                  inputs={[
                    {
                      placeholder: t("forgottenPassword.placeholder"),
                      label: <Text small>E-mail</Text>,
                      name: "email",
                      entityType: "email",
                      className: "col-xs-12"
                    }
                  ]}
                  name={FormDuck.formTypes.forgottenPassword}
                  onSubmit={this.onSubmit}
                >
                  <div className="col">
                    <div className="text-center">
                      <div className={be(MODULE_NAME, "formSpacing")} />
                      <Button medium type="submit">
                        {t("forgottenPassword.submit1")}
                      </Button>
                    </div>
                  </div>
                </Forms>
              </div>
            </div>
            <div className={be(MODULE_NAME, "buttonSpacing")} />
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <Link big to="/login">
                    Vzpomněl jsem si na heslo
                  </Link>
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "linkSpacing")} />
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <Text>Nemám účet.</Text>
                  <Link big to="/register">
                    {" "}
                    Registrovat se
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

ForgottenPassword.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default translate("translations")(ForgottenPassword);
