/* global dataLayer */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-sort-props */
import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Checkbox } from "react-bootstrap";
import Input from "@material-ui/core/Input";

import _every from "lodash/every";
import _toArray from "lodash/toArray";
import _merge from "lodash/merge";
import _find from "lodash/find";
import _get from "lodash/get";
import _sumBy from "lodash/sumBy";
import _map from "lodash/map";

import { isMobile } from "../../utils/utils";
import { be, bm } from "../../utils/bliss";
import { ADDRESS_COMPLETE, FB_APP_ID, PACKETA_API_KEY } from "../../services/variables";

import FormDuck from "../../redux/ducks/forms";
import UserDuck from "../../redux/ducks/user";
import Screen from "../../components/screen/screen";
import Button from "../../components/button/button";
import Header from "../../components/header/header";
import Loader from "../../components/loader/loader";
import Forms from "../../components/forms/form";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import Link from "../../components/link/link";
import "./shopDelivery.css";
import { consoleSandbox } from "@sentry/utils";
import { STATES_WHITE_LIST } from "../projects/projectsPage";
import SettingsService from "../../services/settingsService";
import { Helmet } from "react-helmet";

let smartformRebinded = 0;

const smartform = {
  // https://www.smartform.cz/dokumentace/uvod/
  callbacks: {
    "smartform-instance-delivery-address": {},
    "smartform-instance-invoice-address": {},
    "smartform-instance-email": {}
  },

  initSmartform: (
    country,
    deliveryAddressValidationCallback,
    invoiceAddressValidationCallback,
    emailValidationCallback,
    deliveryAddressSelectionCallback,
    invoiceAddressSelectionCallback
  ) => {
    smartform.callbacks["smartform-instance-delivery-address"].validationCallback = deliveryAddressValidationCallback;
    smartform.callbacks["smartform-instance-invoice-address"].validationCallback = invoiceAddressValidationCallback;
    smartform.callbacks["smartform-instance-email"].validationCallback = emailValidationCallback;
    smartform.callbacks["smartform-instance-delivery-address"].selectionCallback = deliveryAddressSelectionCallback;
    smartform.callbacks["smartform-instance-invoice-address"].selectionCallback = invoiceAddressSelectionCallback;

    if (typeof window !== "undefined") {
      window.smartform = window.smartform || {};
      window.smartform.beforeInit = () => {
        window.smartform.setClientId(process.env.REACT_APP_SMARTFORM_CLIENTID);
      };

      window.smartform.afterInit = () => {
        // když je objednáváno něco bez dodání, nezobrazuje se dodací adresa,
        // pak instance není
        const instanceDeliveryAddress = window.smartform.getInstance("smartform-instance-delivery-address");
        if (instanceDeliveryAddress) {
          if (typeof deliveryAddressValidationCallback === "function") {
            instanceDeliveryAddress.addressControl.addValidationCallback(deliveryAddressValidationCallback);
          }
          if (country) {
            instanceDeliveryAddress.addressControl.setCountry(country);
          }
          if (typeof deliveryAddressSelectionCallback === "function") {
            instanceDeliveryAddress.addressControl.setSelectionCallback(deliveryAddressSelectionCallback);
          }
        }

        if (typeof invoiceAddressValidationCallback === "function") {
          window.smartform
            .getInstance("smartform-instance-invoice-address")
            .addressControl.addValidationCallback(invoiceAddressValidationCallback);
        }
        if (country) {
          window.smartform.getInstance("smartform-instance-invoice-address").addressControl.setCountry(country);
        }

        if (typeof emailValidationCallback === "function") {
          // ve formulářích pro přihlášeného uživatele se email nedá měnit, takže tam není ani
          // defaultní instance
          const instance = window.smartform.getInstance();
          if (instance) {
            instance.emailControl.addValidationCallback(emailValidationCallback);
          }
        }

        if (typeof deliveryAddressSelectionCallback === "function") {
          window.smartform
            .getInstance("smartform-instance-invoice-address")
            .addressControl.setSelectionCallback(invoiceAddressSelectionCallback);
        }
      };
    }
  },

  setCountry: country => {
    // rebinding forms is needed first
    if (window.smartform && window.smartform.rebindAllForms) {
      window.smartform.rebindAllForms(() => {
        ["smartform-instance-delivery-address", "smartform-instance-invoice-address"].forEach(instanceId => {
          const instance = window.smartform.getInstance(instanceId);
          if (instance) {
            instance.addressControl.setCountry(country);
            if (smartform.callbacks[instanceId].validationCallback) {
              instance.addressControl.addValidationCallback(smartform.callbacks[instanceId].validationCallback);
            }
            if (smartform.callbacks[instanceId].selectionCallback) {
              instance.addressControl.setSelectionCallback(smartform.callbacks[instanceId].selectionCallback);
            }
          }
        });
        const instance = window.smartform.getInstance();
        if (instance) {
          if (smartform.callbacks["smartform-instance-email"].validationCallback) {
            instance.emailControl.addValidationCallback(
              smartform.callbacks["smartform-instance-email"].validationCallback
            );
          }
        }
      });
    }
  },

  renderScriptTag: () => (
    <Helmet>
      <script type="text/javascript" src="https://client.smartform.cz/v2/smartform.js" async />
    </Helmet>
  )
};

const MODULE_NAME = "ShopDelivery";
const DISABLE_DELIVERY_PERSONAL_PICKUP = true;
const DISABLE_DELIVERY_PERSONAL_PICKUP_AT_AUTHOR = false;
const SMALLER_PERSONAL_PICKUP_CODE_FIELD = false;

const FORM = {
  orderFormInvoice: "ORDER_FORM_INVOICE",
  orderFormDelivery: "ORDER_FORM_DELIVERY"
};

export const DELIVERY = {
  sendToCZ: "1",
  sendToSK: "3",
  pickUp: "2",
  packeta: "4",
  pickUpAtAuthor: "5"
};

const deliveryPriceMapping = {
  [DELIVERY.sendToCZ]: "CP_CZ",
  [DELIVERY.sendToSK]: "CP_SK",
  [DELIVERY.packeta]: "Zásilkovna"
};

export const UNKNOWN_DELIVERY_PRICE = 10000;

const PAYMENT = {
  card: "csob",
  transfer: "comgate"
};

class ShopDelivery extends Component {
  constructor(props) {
    super(props);

    const { delivery, payment, areAddressesSame } = _get(props.location, "state", {});

    const deliveryForm = props.form(FORM.orderFormDelivery);

    this.state = {
      payment: payment || "comgate",
      delivery: deliveryForm && deliveryForm._delivery ? deliveryForm._delivery : delivery || "1",
      checkedAddress: deliveryForm._delivery !== DELIVERY.packeta && (areAddressesSame || true),
      handleCompanyInfoChange: false,
      submits: {},
      validation: {},
      deliveryChangeTimeStamp: 0, // tohle je nutné kvůli rebindování formulářů po změně dopravy
      scrollTo: null,
      deliveryPrices: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCompanyInfoChange = this.handleCompanyInfoChange.bind(this);
    this.resetDeliveryAddress = this.resetDeliveryAddress.bind(this);
    this.smartformEmailCallback = this.smartformEmailCallback.bind(this);
    this.setScrollTo = this.setScrollTo.bind(this);
  }

  componentDidMount() {
    this.setState({
      requiredPersonalPickUpCode: this.getCodeForPickupAtAuthor()
    });
    dataLayer.push({
      event: "initiateCheckout",
      value: _sumBy(this.props.location.state.items, i => i.number * i.reward.price),
      content_ids: _map(this.props.location.state.items, "project.id")
    });

    this.isPickUpAllowed();

    window.scrollTo(0, 0);

    this.loadDeliveryPrices();

    smartform.initSmartform(
      this.state.delivery === "3" ? "SK" : "CZ",
      this.smartformAddressCallback(FORM.orderFormDelivery),
      this.smartformAddressCallback(FORM.orderFormInvoice),
      this.smartformEmailCallback,
      this.smartformAddressSelectionCallback(FORM.orderFormDelivery),
      this.smartformAddressSelectionCallback(FORM.orderFormInvoice)
    );
  }

  componentDidUpdate() {
    if (this.state.deliveryChangeTimeStamp > smartformRebinded) {
      smartform.setCountry(this.state.delivery === DELIVERY.sendToSK ? "SK" : "CZ");
      smartformRebinded = this.state.deliveryChangeTimeStamp;
    }
  }

  loadDeliveryPrices = async () => {
    SettingsService.getDeliveryPrices().then(prices => {
      const deliveryPrices = {};
      Object.keys(deliveryPriceMapping).forEach(deliveryType => {
        deliveryPrices[deliveryType] =
          "undefined" !== typeof prices[deliveryPriceMapping[deliveryType]]
            ? prices[deliveryPriceMapping[deliveryType]]
            : UNKNOWN_DELIVERY_PRICE;
      });
      this.setState({ deliveryPrices });
    });
  };

  handlePayment = e => {
    this.setState({
      payment: e.target.value
    });
  };

  handleDelivery = e => {
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "_delivery", e.target.value));

    const isPacketa = e.target.value === DELIVERY.packeta;
    const wasPacketa = this.state.delivery === DELIVERY.packeta;

    if ((wasPacketa && !isPacketa) || (!wasPacketa && isPacketa)) {
      this.resetDeliveryAddress(isPacketa);
    }

    if (isPacketa) this.setState({ checkedAddress: false });

    this.setState({
      delivery: e.target.value,
      deliveryChangeTimeStamp: new Date().getTime()
    });
  };

  handleLogin = userToLogin => {
    const {
      location: { search }
    } = this.props;

    this.props.dispatch(
      UserDuck.loginUser(userToLogin, search, { pathname: "/delivery", state: this.props.location.state })
    );
  };

  handleChange() {
    this.setState({
      checkedAddress: !this.state.checkedAddress
    });
  }

  handleCompanyInfoChange() {
    this.setState({
      checkedCompanyInfo: !this.state.checkedCompanyInfo
    });
  }

  resetDeliveryAddress(isPacketa) {
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "street", ""));
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "district", ""));
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "postcode", ""));

    if (isPacketa) {
      this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "name", ""));
      this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "surname", ""));
      this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "deliveryCompanyName", ""));
    }

    if (!isPacketa) {
      this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "packetaPlace", null));
      this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "packetaPlaceId", null));
    }
  }

  submit(e) {
    const { submits, checkedAddress, delivery } = this.state;
    this.setState({ scrollTo: null });
    if (
      delivery === DELIVERY.pickUpAtAuthor &&
      this.state.personalPickUpCode !== this.state.requiredPersonalPickUpCode
    ) {
      if (typeof window !== "undefined") {
        window.scroll(0, 200); //
      }
      this.setState({ personalPickupCodeWrong: true });
      return;
    }
    const isEverythingVirtual = this.isEverythingVirtual();
    const subs = _toArray(submits).map(s => s(e));

    const isValid = _every(subs, s => {
      switch (s.name) {
        case FORM.orderFormInvoice:
          if (!isEverythingVirtual) {
            if (checkedAddress) {
              if (delivery === DELIVERY.sendToCZ || delivery === DELIVERY.sendToSK) {
                return true;
              }
            }
          }
          break;

        case FORM.orderFormDelivery:
          if (delivery === DELIVERY.pickUp || delivery === DELIVERY.pickUpAtAuthor) {
            return true;
          }
          break;

        default:
          break;
      }

      return !s.failed;
    });
    console.log("Data is valid", isValid);

    if (isValid) {
      const { data } = _find(subs, { name: "ORDER_FORM_EMAIL_AND_PHONE" });

      data.deliveryAddress = _get(_find(subs, { name: "ORDER_FORM_DELIVERY" }), "data", null);

      // TODO: Check whether it is safe to replace the else branch with the 'data.invoiceAddress = data.deliveryAddress'
      //       assignment before the if condition.
      const emptyInvoiceAddress =
        !isEverythingVirtual && checkedAddress && delivery !== DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor;
      if (emptyInvoiceAddress) {
        data.invoiceAddress = data.deliveryAddress;
      } else {
        data.invoiceAddress = _get(_find(subs, { name: "ORDER_FORM_INVOICE" }), "data", null);
        let companyInfo = _get(_find(subs, { name: "ORDER_FORM_INVOICE_COMPANY" }), "data", null);
        if (companyInfo) data.invoiceAddress = { ...data.invoiceAddress, ...companyInfo };
      }

      if (isEverythingVirtual || delivery === DELIVERY.pickUp || delivery === DELIVERY.pickUpAtAuthor) {
        data.deliveryAddress = data.invoiceAddress;
      }

      if (!isEverythingVirtual) {
        data.areAddressesSame = checkedAddress;
      }

      data.name = data.deliveryAddress.name ? data.deliveryAddress.name : data.invoiceAddress.name;
      data.surname = data.deliveryAddress.surname ? data.deliveryAddress.surname : data.invoiceAddress.surname;
      data.delivery = this.state.delivery;
      data.payment = this.state.payment;
      data.items = this.props.location.state.items;
      data.fromCart = Boolean(this.props.location.state.fromCart);
      data.deliveryPrices = this.state.deliveryPrices;

      console.log(data);
      this.props.history.push("/recap", data);
    }
  }

  isPickUpAllowed() {
    if (DISABLE_DELIVERY_PERSONAL_PICKUP) {
      return false;
    }
    const cartItems = this.props.location.state.items;
    return _every(cartItems, i => i.project.state === STATES_WHITE_LIST.funding.name);
  }

  isPickupAtAuthorAllowed() {
    if (DISABLE_DELIVERY_PERSONAL_PICKUP_AT_AUTHOR) {
      return false;
    }
    return !!this.state.requiredPersonalPickUpCode;
  }

  getCodeForPickupAtAuthor() {
    const items =
      (this.props &&
        this.props.location &&
        this.props.location.state &&
        Array.isArray(this.props.location.state.items) &&
        this.props.location.state.items.length > 0 &&
        this.props.location.state.items) ||
      null;

    if (!items) {
      return null;
    }

    let personalPickupCode = null;
    if (
      _every(items, i => {
        // Checks if all projects are in the funding state and if all project have the same personal pick up code
        const condition =
          i.project &&
          i.project.state === STATES_WHITE_LIST.funding.name &&
          (i.project.personalPickUpCode === personalPickupCode || personalPickupCode === null);
        if (i.project && personalPickupCode === null) {
          // eslint-disable-next-line prefer-destructuring
          personalPickupCode = i.project.personalPickupCode || false;
        }
        return condition;
      })
    ) {
      return personalPickupCode;
    }

    return null;
  }

  // The isEverythingVirtual method checks that the all items in the cart are the virtual books, etc.
  isEverythingVirtual() {
    const cartItems = this.props.location.state.items;
    return _every(cartItems, i => !i.reward.physical);
  }

  handlePacketaPointPicked = point => {
    if (point === null) return;

    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "packetaPlace", point.place));
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "packetaPlaceId", point.id));
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "street", point.street ? point.street : "-"));
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "district", point.city ? point.city : "-"));
    this.props.dispatch(FormDuck.setInput(FORM.orderFormDelivery, "postcode", point.zip ? point.zip : "-"));
  };

  isButtonKRekapitulaciDisabled() {
    if (this.state.delivery === DELIVERY.pickUpAtAuthor) {
      if (this.state.personalPickUpCode === this.state.requiredPersonalPickUpCode) {
        return false;
      }
      return true;
    }
    return false;
  }

  smartformAddressCallback(formId) {
    return result => {
      if (!result || !result.result) {
        return;
      }
      const { dispatch } = this.props;
      if (!Array.isArray(result.result.addressArray) || result.result.addressArray.length !== 1) {
        return;
      }
      dispatch(FormDuck.setInput(formId, "street", result.result.addressArray[0].FORMATTED_ADDRESS_FIRST_LINE));
      dispatch(FormDuck.setInput(formId, "district", result.result.addressArray[0].FORMATTED_ADDRESS_SECOND_LINE));
      dispatch(FormDuck.setInput(formId, "postcode", result.result.addressArray[0].ZIP));
    };
  }

  smartformAddressSelectionCallback(formId) {
    return (el, text, fieldType, suggestion) => {
      this.props.dispatch(FormDuck.setInput(formId, el.name, text));
    };
  }

  smartformEmailCallback(result) {
    // hack - bez tohoto by se po výběru emailu ze suggestion vrátil do formuláře původní email
    // .emailControl nemá obdobu metody .setSelectionCallback z .addressControl
    if (result && result.result && result.result.inputEmail) {
      this.props.dispatch(FormDuck.setInput("ORDER_FORM_EMAIL_AND_PHONE", "email", result.result.inputEmail));
    }

    // normal code ;-)
    if (!result || !result.result || !result.result.resultType) {
      return;
    }
    if ("NOT_EXISTS" === result.result.resultType) {
      this.setState(state => ({
        ...state,
        // Na https://www.albatrosmedia.cz/ mají u neexistujícího emailu hlášení Neplatný email
        validation: { ...state.validation, email: { type: "error", message: "Neplatný email" } }
      }));
    } else {
      this.setState(state => ({ ...state, validation: { ...state.validation, email: null } }));
    }
  }

  setScrollTo(anchor) {
    this.setState(
      state => ({ scrollTo: state.scrollTo ? state.scrollTo : anchor }),
      () => {
        // using router does not work well
        // this.props.history.replace({ hash: this.state.scrollTo, state: this.props.history.location.state });
        // using https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
        if (this.state.scrollTo && "undefined" !== typeof document) {
          const el = document.querySelector(`a[name='${this.state.scrollTo}']`);
          if (el) {
            el.scrollIntoView();
            // el.scrollBy(0, -60); // el.scrollBy nefunguje dobře
            if (!isMobile() && "undefined" !== typeof window) {
              window.scrollBy(0, -70); // scroll down a little bit more because of fixed header in Desktop version
            }
          }
        }
      }
    );
  }

  render() {
    const { loading, t, user, location } = this.props;
    const { payment, delivery } = this.state;
    const isUser = !isEmpty(user);
    const isNotUser = isEmpty(user);
    const { deliveryAddress, invoiceAddress } = _get(location, "state", {});
    const deliveryForm = this.props.form(FORM.orderFormDelivery);

    const fromCart = Boolean(_get(location.state, "fromCart", false));
    const isEverythingVirtual = this.isEverythingVirtual();
    const hidden =
      !this.state.checkedAddress ||
        isEverythingVirtual ||
        delivery === DELIVERY.pickUp ||
        delivery === DELIVERY.pickUpAtAuthor
        ? ""
        : be(MODULE_NAME, "hidden");
    const isPacketaDelivery = this.state.delivery === DELIVERY.packeta;

    const isButtonKRekapitulaciDisabled = this.isButtonKRekapitulaciDisabled();

    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "14px",
            color: "white",
            backgroundColor: "grey"
          }
        },
        MuiFormControlLabel: {
          width: "100%"
        }
      }
    });

    if (loading) {
      return <Loader />;
    }
    return (
      <Screen header={<Header />}>
        {smartform.renderScriptTag()}
        <div>
          {!isMobile() && (
            <div className="row">
              <div className="col-lg-6">
                <Headline page>Doprava a platba</Headline>
              </div>
              <div className="col-lg-6 col-md-12 d-flex align-items-end justify-content-end">
                <div>
                  {fromCart && (
                    <Text small>
                      <Link to={{ pathname: "/cart", state: { delivery, payment } }}>Košík</Link>{" "}
                    </Text>
                  )}
                  <Text small>
                    {fromCart && (
                      <React.Fragment>
                        <img
                          alt="arrowImg"
                          src="/images/svg/leftCart.svg"
                          style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                        />{" "}
                      </React.Fragment>
                    )}
                    <b>Doprava a platba</b>
                  </Text>
                  <Text small>
                    <img
                      alt="arrowImg"
                      src="/images/svg/leftCart.svg"
                      style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                    />{" "}
                    Rekapitulace
                  </Text>
                </div>
              </div>
            </div>
          )}
          {isMobile() && (
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <Headline page>Doprava a platba</Headline>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-5">
                  <div>
                    {fromCart && (
                      <Text small>
                        <Link to={{ pathname: "/cart", state: { delivery, payment } }}>Košík</Link>{" "}
                      </Text>
                    )}
                    <Text small>
                      {fromCart && (
                        <React.Fragment>
                          <img
                            alt="arrowImg"
                            src="/images/svg/leftCart.svg"
                            style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                          />{" "}
                        </React.Fragment>
                      )}
                      <b>Doprava a platba</b>
                    </Text>
                    <Text small>
                      <img
                        alt="arrowImg"
                        src="/images/svg/leftCart.svg"
                        style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                      />{" "}
                      Rekapitulace
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={be(MODULE_NAME, "item")}>
            {!isEverythingVirtual && (
              <div className="row" style={{ paddingTop: "20px" }}>
                <div className="col-12 col-lg-6">
                  <div className="pb-4">
                    <Text bold>Doprava</Text>
                  </div>
                  <div>
                    <hr style={{ margin: "0" }} />
                    <hr style={{ margin: "0", position: "relative", top: "52px" }} />
                    <hr style={{ margin: "0", position: "relative", top: "104px" }} />
                    <hr style={{ margin: "0", position: "relative", top: "156px" }} />
                    {this.isPickUpAllowed() && this.isPickupAtAuthorAllowed() && (
                      <hr style={{ margin: "0", position: "relative", top: "208px" }} />
                    )}
                    <div className="row">
                      <div className="col-9 col-lg-10">
                        <FormControl component="fieldset">
                          <RadioGroup name="delivery" value={delivery} onChange={this.handleDelivery}>
                            <FormControlLabel
                              value={DELIVERY.sendToCZ}
                              control={<Radio style={{ color: "#E8452C" }} />}
                              label={
                                <Typography style={{ fontSize: "14px" }}>
                                  Česká pošta – Balík do ruky (Česko)
                                </Typography>
                              }
                              labelPlacement="end"
                              style={{ marginBottom: "5px" }}
                            />
                            <FormControlLabel
                              value={DELIVERY.sendToSK}
                              control={<Radio style={{ color: "#E8452C" }} />}
                              label={<Typography style={{ fontSize: "14px" }}>Česká pošta (Slovensko)</Typography>}
                              labelPlacement="end"
                              style={{ marginBottom: "5px" }}
                            />
                            <FormControlLabel
                              value={DELIVERY.packeta}
                              control={<Radio style={{ color: "#E8452C" }} />}
                              label={<Typography style={{ fontSize: "14px" }}>Zásilkovna</Typography>}
                              labelPlacement="end"
                              style={{ marginBottom: "5px" }}
                            />
                            {this.isPickUpAllowed() && (
                              <FormControlLabel
                                value={DELIVERY.pickUp}
                                control={<Radio style={{ color: "#E8452C" }} />}
                                label={
                                  <Typography style={{ fontSize: "14px" }}>
                                    Osobní odběr (<span style={{ whiteSpace: "nowrap" }}>{ADDRESS_COMPLETE}</span>)
                                  </Typography>
                                }
                                labelPlacement="end"
                                style={{
                                  fontSize: "14px",
                                  marginBottom: this.isPickupAtAuthorAllowed() ? "5px" : "0px"
                                }}
                              />
                            )}
                            {this.isPickupAtAuthorAllowed() && (
                              // okolo FormControlLabel se nesmí použít <></>, jinak nefunguje správně radio button
                              <FormControlLabel
                                value={DELIVERY.pickUpAtAuthor}
                                control={<Radio style={{ color: "#E8452C" }} />}
                                label={
                                  <Typography style={{ fontSize: "14px" }}>
                                    Osobní předání autorem (autorkou)<sup>*</sup>
                                    {delivery === DELIVERY.pickUpAtAuthor && SMALLER_PERSONAL_PICKUP_CODE_FIELD && (
                                      <>
                                        <br />
                                        Kód pro osobní odběr u autora&nbsp;
                                        <Input
                                          value={this.state.personalPickUpCode}
                                          onChange={e => this.setState({ personalPickUpCode: e.target.value })}
                                        />
                                      </>
                                    )}
                                  </Typography>
                                }
                                labelPlacement="end"
                                style={{ fontSize: "14px", marginBottom: "0px" }}
                              />
                            )}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="col-3 col-lg-2 text-right">
                        <div style={{ padding: "13px 0 30px 0" }}>
                          <Text bold primary small>
                            {this.state.deliveryPrices[DELIVERY.sendToCZ]}&nbsp;Kč
                          </Text>
                        </div>
                        <div style={{ padding: "0px 0 30px 0" }}>
                          <Text bold primary small>
                            {this.state.deliveryPrices[DELIVERY.sendToSK]}&nbsp;Kč
                          </Text>
                        </div>
                        <div style={{ padding: "0px 0 30px 0" }}>
                          <Text bold primary small>
                            {this.state.deliveryPrices[DELIVERY.packeta]}&nbsp;Kč
                          </Text>
                        </div>
                        {this.isPickUpAllowed() && (
                          <div style={{ padding: `0px 0 ${this.isPickupAtAuthorAllowed() ? 30 : 0}px 0` }}>
                            <Text bold primary small>
                              Zdarma
                            </Text>
                          </div>
                        )}
                        {this.isPickupAtAuthorAllowed() && (
                          <div>
                            <Text bold primary small>
                              Zdarma
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                    {(this.isPickupAtAuthorAllowed() &&
                      delivery === DELIVERY.pickUpAtAuthor &&
                      !SMALLER_PERSONAL_PICKUP_CODE_FIELD && (
                        <input
                          type="text"
                          className="form-control"
                          onChange={e => this.setState({ personalPickUpCode: e.target.value })}
                          onKeyDown={() => this.setState({ personalPickupCodeWrong: false })}
                          placeholder="Vyplň prosím kód, který jsi dostal(a) od autora (autorky)"
                          style={(() => {
                            const style = { marginLeft: "3em", width: "95%" };
                            if (this.state.personalPickupCodeWrong) {
                              style.borderColor = "#8a6d3b";
                              style.borderWidth = "0.2em";
                            }
                            return style;
                          })()}
                        />
                      )) ||
                      ""}
                    {(this.isPickupAtAuthorAllowed() &&
                      delivery === DELIVERY.pickUpAtAuthor &&
                      !SMALLER_PERSONAL_PICKUP_CODE_FIELD && (
                        <div
                          style={{
                            marginLeft: "2.5em",
                            width: "95%",
                            paddingTop: "0.5em",
                            paddingBottom: "0.5em",
                            fontSize: "14px",
                            color: "gray"
                          }}
                          className="has-warning"
                        >
                          {(this.state.personalPickupCodeWrong && (
                            <span className="help-block">Zadaný kód není platný</span>
                          )) ||
                            ""}
                          * Výběrem této možnosti potvrzuješ, že ses s autorem (autorkou) na osobním předání odměny
                          předem domluvil(a).
                        </div>
                      )) ||
                      ""}
                    {(this.isPickUpAllowed() || this.isPickupAtAuthorAllowed()) && <hr style={{ marginTop: "2px" }} />}
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="row" style={{ paddingTop: "20px" }}>
                <div className="col-lg-6">
                  <div className="pb-4">
                    <Text bold>Způsob platby</Text>
                  </div>
                  <div>
                    <hr style={{ margin: "0" }} />
                    <FormControl component="fieldset">
                      <RadioGroup name="payment" value={payment} onChange={this.handlePayment}>
                        {/* <FormControlLabel
                            value="csob"
                            control={<Radio style={{ color: "#E8452C" }} />}
                            label={<Typography style={{ fontSize: "14px" }}>Online kartou</Typography>}
                            labelPlacement="end"
                            style={{ marginBottom: "5px" }}
                          /> */}
                        <FormControlLabel
                          value="comgate"
                          control={<Radio style={{ color: "#E8452C" }} />}
                          label={
                            <Typography style={{ fontSize: "14px", marginBottom: "-2px" }}>
                              Platební brána (platba kartou online / bankovním převodem)
                            </Typography>
                          }
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <hr style={{ margin: "0" }} />
                </div>
              </div>
            </div>
            {isNotUser && (
              <div className="row">
                <div className="col-md-6">
                  <div style={{ padding: "40px 0" }}>
                    <Text bold>Kontakt</Text>
                    <Forms
                      setSubmit={submit =>
                        this.setState(pS => {
                          const prevState = pS;
                          prevState.submits.ORDER_FORM_EMAIL_AND_PHONE = submit;
                          return {
                            submits: prevState.submits
                          };
                        })
                      }
                      onSubmitError={this.setScrollTo}
                      name="ORDER_FORM_EMAIL_AND_PHONE"
                      inputs={[
                        {
                          label: <Text small>Tvůj e-mail: *</Text>,
                          name: "email",
                          entityType: "email",
                          required: true,
                          className: "smartform-email",
                          // tady se pro Smartform nesmí použít validationType a validationMessage
                          // protože to přepíše výsledek interní validace a nezobrazovalo by se varování,
                          // že hodnota je povinná
                          errorType: this.state.validation.email && this.state.validation.email.type,
                          errorMessage: this.state.validation.email && this.state.validation.email.message
                        },
                        {
                          label: (
                            <Text small>
                              Telefon: *{" "}
                              <MuiThemeProvider theme={theme}>
                                <Tooltip
                                  title="Telefon potřebujeme pro vyjasnění případných nejasností a dopravce."
                                  enterTouchDelay={0}
                                >
                                  <i className="fa fa-info-circle" />
                                </Tooltip>
                              </MuiThemeProvider>
                            </Text>
                          ),
                          name: "phone",
                          entityType: "phone",
                          required: true
                        }
                      ]}
                    />
                  </div>
                  {!isEverythingVirtual && delivery !== DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor && (
                    <div>
                      <Text bold>Adresa pro doručení</Text>
                      {delivery === DELIVERY.packeta && (
                        <PacketaDelivery
                          packetaPlace={deliveryForm.packetaPlace}
                          onPicked={this.handlePacketaPointPicked}
                        />
                      )}

                      <Forms
                        name="ORDER_FORM_DELIVERY"
                        setSubmit={submit =>
                          this.setState(pS => {
                            const prevState = pS;
                            prevState.submits.ORDER_FORM_DELIVERY = submit;
                            return {
                              submits: prevState.submits
                            };
                          })
                        }
                        onSubmitError={this.setScrollTo}
                        inputs={[
                          {
                            label: <Text small>Jméno: *</Text>,
                            name: "name",
                            required: !isPacketaDelivery,
                            defaultValue: !isPacketaDelivery && deliveryAddress ? deliveryAddress.name : "",
                            hidden: isPacketaDelivery
                          },
                          {
                            label: <Text small>Příjmení: *</Text>,
                            name: "surname",
                            required: !isPacketaDelivery,
                            defaultValue: !isPacketaDelivery && deliveryAddress ? deliveryAddress.surname : "",
                            hidden: isPacketaDelivery
                          },
                          // {
                          //   label: <Text small>Název firmy pro doručení:</Text>,
                          //   name: "deliveryCompanyName",
                          //   required: false,
                          //   defaultValue:
                          //     !isPacketaDelivery && deliveryAddress ? deliveryAddress.deliveryCompanyName : "",
                          //   hidden: isPacketaDelivery
                          // },
                          {
                            label: <Text small>Ulice a číslo popisné: *</Text>,
                            name: "street",
                            required: true,
                            defaultValue: deliveryAddress ? deliveryAddress.street : "",
                            disabled: isPacketaDelivery,
                            className: "smartform-instance-delivery-address smartform-address-street-and-number"
                          },
                          {
                            label: <Text small>Obec: *</Text>,
                            name: "district",
                            required: true,
                            defaultValue: deliveryAddress ? deliveryAddress.district : "",
                            disabled: isPacketaDelivery,
                            className: "smartform-instance-delivery-address smartform-address-city-extended"
                          },
                          {
                            label: <Text small>PSČ: *</Text>,
                            name: "postcode",
                            required: true,
                            defaultValue: deliveryAddress ? deliveryAddress.postcode : "",
                            disabled: isPacketaDelivery,
                            className: "smartform-instance-delivery-address smartform-address-zip"
                          }
                        ]}
                      />
                    </div>
                  )}
                  <div>
                    {!isEverythingVirtual && delivery !== DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor && (
                      <React.Fragment>
                        {!isPacketaDelivery &&
                          (!isMobile() ? (
                            <div style={{ padding: "20px 0" }}>
                              <input
                                id="delivery-sameAddress"
                                type="checkbox"
                                checked={this.state.checkedAddress}
                                onChange={this.handleChange}
                                style={{ verticalAlign: "top" }}
                              />
                              <label
                                style={{ color: "#E8452C", fontWeight: "500", padding: "0 4px" }}
                                for="delivery-sameAddress"
                              >
                                Doručovací adresa je shodná s fakturační
                              </label>
                            </div>
                          ) : (
                            <div style={{ padding: "20px 0", display: "flex" }}>
                              <input
                                id="delivery-sameAddress"
                                type="checkbox"
                                checked={this.state.checkedAddress}
                                onChange={this.handleChange}
                                style={{ verticalAlign: "top" }}
                              />
                              <label
                                style={{ color: "#E8452C", fontWeight: "500", padding: "0 4px" }}
                                for="delivery-sameAddress"
                              >
                                Doručovací adresa je shodná s fakturační
                              </label>
                            </div>
                          ))}
                      </React.Fragment>
                    )}
                    <div className={hidden} style={{ paddingBottom: "20px" }}>
                      <Text bold>Fakturační adresa</Text>
                      <Forms
                        name="ORDER_FORM_INVOICE"
                        setSubmit={submit =>
                          this.setState(pS => {
                            const prevState = pS;
                            prevState.submits.ORDER_FORM_INVOICE = submit;
                            return {
                              submits: prevState.submits
                            };
                          })
                        }
                        onSubmitError={this.setScrollTo}
                        inputs={[
                          {
                            label: <Text small>Jméno: *</Text>,
                            name: "name",
                            required: true,
                            defaultValue: invoiceAddress ? invoiceAddress.name : ""
                          },
                          {
                            label: <Text small>Příjmení: *</Text>,
                            name: "surname",
                            required: true,
                            defaultValue: invoiceAddress ? invoiceAddress.surname : ""
                          },
                          {
                            label: <Text small>Ulice a číslo popisné: *</Text>,
                            name: "street",
                            required: true,
                            defaultValue: invoiceAddress ? invoiceAddress.street : "",
                            className: "smartform-instance-invoice-address smartform-address-street-and-number"
                          },
                          {
                            label: <Text small>Obec: *</Text>,
                            name: "district",
                            required: true,
                            defaultValue: invoiceAddress ? invoiceAddress.district : "",
                            className: "smartform-instance-invoice-address smartform-address-city-extended"
                          },
                          {
                            label: <Text small>PSČ: *</Text>,
                            name: "postcode",
                            required: true,
                            defaultValue: invoiceAddress ? invoiceAddress.postcode : "",
                            className: "smartform-instance-invoice-address smartform-address-zip"
                          }
                        ]}
                      />

                      <div style={{ padding: "20px 0" }}>
                        <input
                          id="delivery-companyInfo"
                          type="checkbox"
                          checked={this.state.checkedCompanyInfo}
                          onChange={this.handleCompanyInfoChange}
                          style={{ verticalAlign: "top" }}
                        />
                        <label
                          style={{ color: "#E8452C", fontWeight: "500", padding: "0 4px" }}
                          for="delivery-companyInfo"
                        >
                          Chci doplnit firemní údaje
                        </label>
                      </div>

                      {this.state.checkedCompanyInfo && (
                        <Forms
                          name="ORDER_FORM_INVOICE_COMPANY"
                          setSubmit={submit =>
                            this.setState(pS => {
                              const prevState = pS;
                              prevState.submits.ORDER_FORM_INVOICE_COMPANY = submit;
                              return {
                                submits: prevState.submits
                              };
                            })
                          }
                          inputs={[
                            {
                              label: <Text small>Název firmy:</Text>,
                              name: "invoiceCompanyName",
                              required: false,
                              defaultValue: invoiceAddress ? invoiceAddress.invoiceCompanyName : ""
                            },
                            {
                              label: <Text small>IČ:</Text>,
                              name: "ico",
                              required: false,
                              defaultValue: invoiceAddress ? invoiceAddress.ico : ""
                            },
                            {
                              label: <Text small>DIČ:</Text>,
                              name: "dic",
                              required: false,
                              defaultValue: invoiceAddress ? invoiceAddress.dic : ""
                            }
                          ]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-100" style={{ paddingBottom: "40px" }}>
                    {/* Pozor, buttony tu jsou dva */}
                    <Button
                      type="submit"
                      onClick={e => this.submit(e)}
                      primary
                      wide
                      // disabled={isButtonKRekapitulaciDisabled}
                      style={isButtonKRekapitulaciDisabled ? { backgroundColor: "gray" } : {}}
                    >
                      POKRAČOVAT K REKAPITULACI
                    </Button>
                  </div>
                </div>
                {!isMobile() ? (
                  <div className="col-md-6">
                    <div style={{ padding: "40px" }}>
                      <Text bold>Máš již účet?</Text>
                      <Forms
                        inputs={[
                          {
                            label: <Text small>{t("loginPage.email")}</Text>,
                            name: "email",
                            entityType: "email",
                            className: "col-xs-12"
                          },
                          {
                            label: (
                              <div className="center space">
                                <Text small>{t("loginPage.password")}</Text>
                              </div>
                            ),
                            name: "password",
                            entityType: "password",
                            type: "password",
                            className: "col-xs-12"
                          }
                        ]}
                        name={FormDuck.formTypes.login}
                        onSubmit={this.handleLogin}
                      >
                        <div>
                          <div className="text-center" style={{ paddingTop: "20px" }}>
                            <Button wide type="submit">
                              {t("loginPage.submit")}
                            </Button>
                          </div>
                        </div>
                      </Forms>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="d-flex w-100 justify-content-center">
                            <FacebookLogin
                              appId={FB_APP_ID}
                              callback={this.responseFacebook}
                              className="col-xs-12"
                              disableMobileRedirect
                              fields="name,email,picture,first_name,last_name"
                              isMobile={isMobile()}
                              render={renderProps => (
                                <Button fb wide onClick={renderProps.onClick}>
                                  {t("loginPage.fbButton")}
                                </Button>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {isUser && (
              <div>
                <div style={{ padding: "40px 0" }}>
                  <Text bold>Kontakt</Text>
                  <Forms
                    setSubmit={submit =>
                      this.setState(pS => {
                        const prevState = pS;
                        prevState.submits.ORDER_FORM_EMAIL_AND_PHONE = submit;
                        return {
                          submits: prevState.submits
                        };
                      })
                    }
                    name="ORDER_FORM_EMAIL_AND_PHONE"
                    inputs={[
                      {
                        label: <Text small>Váš email: *</Text>,
                        name: "email",
                        required: true,
                        disabled: true,
                        defaultValue: user.email
                      },
                      {
                        label: <Text small>Telefon: *</Text>,
                        name: "phone",
                        disabled: true,
                        defaultValue: user.phone,
                        required: true
                      }
                    ]}
                  />
                </div>
                {!isEverythingVirtual && delivery !== DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor && (
                  <div>
                    <Text bold>Adresa pro doručení: *</Text>
                    {delivery === DELIVERY.packeta && (
                      <PacketaDelivery
                        packetaPlace={deliveryForm.packetaPlace}
                        onPicked={this.handlePacketaPointPicked}
                      />
                    )}
                    <Forms
                      name="ORDER_FORM_DELIVERY"
                      setSubmit={submit =>
                        this.setState(pS => {
                          const prevState = pS;
                          prevState.submits.ORDER_FORM_DELIVERY = submit;
                          return {
                            submits: prevState.submits
                          };
                        })
                      }
                      onSubmitError={this.setScrollTo}
                      inputs={[
                        {
                          label: <Text small>Jméno: *</Text>,
                          name: "name",
                          required: !isPacketaDelivery,
                          defaultValue: isPacketaDelivery ? "" : deliveryAddress ? deliveryAddress.name : user.name,
                          hidden: isPacketaDelivery
                        },
                        {
                          label: <Text small>Příjmení: *</Text>,
                          name: "surname",
                          required: !isPacketaDelivery,
                          defaultValue: isPacketaDelivery
                            ? ""
                            : deliveryAddress
                              ? deliveryAddress.surname
                              : user.surname,
                          hidden: isPacketaDelivery
                        },
                        // {
                        //   label: <Text small>Název firmy pro doručení:</Text>,
                        //   name: "deliveryCompanyName",
                        //   required: false,
                        //   defaultValue:
                        //     !isPacketaDelivery && deliveryAddress ? deliveryAddress.deliveryCompanyName : "",
                        //   hidden: isPacketaDelivery
                        // },
                        {
                          label: <Text small>Ulice a číslo popisné: *</Text>,
                          name: "street",
                          required: true,
                          defaultValue: deliveryAddress ? deliveryAddress.street : "",
                          disabled: isPacketaDelivery,
                          className: "smartform-instance-delivery-address smartform-address-street-and-number"
                        },
                        {
                          label: <Text small>Obec: *</Text>,
                          name: "district",
                          required: true,
                          defaultValue: deliveryAddress ? deliveryAddress.district : "",
                          disabled: isPacketaDelivery,
                          className: "smartform-instance-delivery-address smartform-address-city-extended"
                        },
                        {
                          label: <Text small>PSČ: *</Text>,
                          name: "postcode",
                          required: true,
                          defaultValue: deliveryAddress ? deliveryAddress.postcode : "",
                          disabled: isPacketaDelivery,
                          className: "smartform-instance-delivery-address smartform-address-zip"
                        }
                      ]}
                    />
                  </div>
                )}
                <div>
                  {!isEverythingVirtual && delivery !== DELIVERY.pickUp && delivery !== DELIVERY.pickUpAtAuthor && (
                    <React.Fragment>
                      {!isPacketaDelivery &&
                        (!isMobile() ? (
                          <div style={{ padding: "20px 0" }}>
                            <input
                              type="checkbox"
                              checked={this.state.checkedAddress}
                              onChange={this.handleChange}
                              style={{ verticalAlign: "top" }}
                            />
                            <label style={{ color: "#E8452C", fontWeight: "500", padding: "0 4px" }}>
                              Doručovací adresa je shodná s fakturační
                            </label>
                          </div>
                        ) : (
                          <div style={{ padding: "20px 0", display: "flex" }}>
                            <input
                              type="checkbox"
                              checked={this.state.checkedAddress}
                              onChange={this.handleChange}
                              style={{ verticalAlign: "top" }}
                            />
                            <label style={{ color: "#E8452C", fontWeight: "500", padding: "0 4px" }}>
                              Doručovací adresa je shodná s fakturační
                            </label>
                          </div>
                        ))}
                    </React.Fragment>
                  )}
                  <div className={hidden} style={{ paddingBottom: "20px" }}>
                    <Text bold>Fakturační adresa</Text>
                    <Forms
                      name="ORDER_FORM_INVOICE"
                      setSubmit={submit =>
                        this.setState(pS => {
                          const prevState = pS;
                          prevState.submits.ORDER_FORM_INVOICE = submit;
                          return {
                            submits: prevState.submits
                          };
                        })
                      }
                      onSubmitError={this.setScrollTo}
                      inputs={[
                        {
                          label: <Text small>Jméno: *</Text>,
                          name: "name",
                          required: true,
                          defaultValue: invoiceAddress ? invoiceAddress.name : ""
                        },
                        {
                          label: <Text small>Příjmení: *</Text>,
                          name: "surname",
                          required: true,
                          defaultValue: invoiceAddress ? invoiceAddress.surname : ""
                        },
                        {
                          label: <Text small>Ulice a číslo popisné: *</Text>,
                          name: "street",
                          required: true,
                          defaultValue: invoiceAddress ? invoiceAddress.street : "",
                          className: "smartform-instance-invoice-address smartform-address-street-and-number"
                        },
                        {
                          label: <Text small>Obec: *</Text>,
                          name: "district",
                          required: true,
                          defaultValue: invoiceAddress ? invoiceAddress.district : "",
                          className: "smartform-instance-invoice-address smartform-address-city-extended"
                        },
                        {
                          label: <Text small>PSČ: *</Text>,
                          name: "postcode",
                          required: true,
                          defaultValue: invoiceAddress ? invoiceAddress.postcode : "",
                          className: "smartform-instance-invoice-address smartform-address-zip"
                        }
                      ]}
                    />

                    <div style={{ padding: "20px 0" }}>
                      <input
                        id="delivery-companyInfo"
                        type="checkbox"
                        checked={this.state.checkedCompanyInfo}
                        onChange={this.handleCompanyInfoChange}
                        style={{ verticalAlign: "top" }}
                      />
                      <label
                        style={{ color: "#E8452C", fontWeight: "500", padding: "0 4px" }}
                        for="delivery-companyInfo"
                      >
                        Chci doplnit firemní údaje
                      </label>
                    </div>

                    {this.state.checkedCompanyInfo && (
                      <Forms
                        name="ORDER_FORM_INVOICE_COMPANY"
                        setSubmit={submit =>
                          this.setState(pS => {
                            const prevState = pS;
                            prevState.submits.ORDER_FORM_INVOICE_COMPANY = submit;
                            return {
                              submits: prevState.submits
                            };
                          })
                        }
                        inputs={[
                          {
                            label: <Text small>Název firmy:</Text>,
                            name: "invoiceCompanyName",
                            required: false,
                            defaultValue: invoiceAddress ? invoiceAddress.invoiceCompanyName : ""
                          },
                          {
                            label: <Text small>IČ:</Text>,
                            name: "ico",
                            required: false,
                            defaultValue: invoiceAddress ? invoiceAddress.ico : ""
                          },
                          {
                            label: <Text small>DIČ:</Text>,
                            name: "dic",
                            required: false,
                            defaultValue: invoiceAddress ? invoiceAddress.dic : ""
                          }
                        ]}
                      />
                    )}
                  </div>
                </div>
                <div className="w-100" style={{ paddingBottom: "40px" }}>
                  {/* Pozor, buttony tu jsou dva */}
                  <Button
                    type="submit"
                    onClick={e => this.submit(e)}
                    primary
                    wide
                    // disabled={isButtonKRekapitulaciDisabled}
                    style={isButtonKRekapitulaciDisabled ? { backgroundColor: "gray" } : {}}
                  >
                    POKRAČOVAT K REKAPITULACI
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Screen>
    );
  }
}

function PacketaDelivery({ packetaPlace, onPicked }) {
  return (
    <>
      <div className="my-4">
        <Button small secondary onClick={() => window.Packeta.Widget.pick(PACKETA_API_KEY, onPicked)}>
          {packetaPlace ? "Změnit" : "Zvolit"} výdejní místo Zásilkovny
        </Button>
      </div>

      {packetaPlace && (
        <div className="mb-2">
          <Text small>Výdejní místo:</Text>
          <br />
          <Text primary bold>
            {packetaPlace}
          </Text>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  form: formName => FormDuck.getData(state, formName)
});

export default compose(translate("translations"), connect(mapStateToProps), withRouter)(ShopDelivery);
