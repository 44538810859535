import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import "./input.css";
import { bm, be } from "../../utils/bliss";
import Text from "../text/text";

const MODULE_NAME = "Input";

const InputComponent = ({ className = "", textArea = false, wide, max, small, optional, ...restProps }) =>
  textArea ? (
    <Fragment>
      <div className={be(MODULE_NAME, "areaWrapper", `${max ? "hasMax" : ""}  ${wide ? "wide" : ""}`)}>
        <textarea
          className={bm(MODULE_NAME, small ? "area small" : " area", className)}
          {...restProps}
          onKeyDown={noop}
        />
        {max && <div className={be(MODULE_NAME, "max")}>Maximálně {max} znaků</div>}
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <input className={bm(MODULE_NAME, { wide }, className)} {...restProps} />
      {optional ? (
        <div className="text-right">
          <Text label light>
            Nepovinné
          </Text>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );

InputComponent.propTypes = {
  className: PropTypes.string,
  max: PropTypes.string,
  textArea: PropTypes.bool
};

export default InputComponent;
