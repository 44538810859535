/* global dataLayer */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import "./registerPage.css";

import { bm, be } from "../../utils/bliss";
import FormDuck from "../../redux/ducks/forms";
import UserDuck from "../../redux/ducks/user";
import EnumsDuck from "../../redux/ducks/enums";
import Forms from "../../components/forms/form";
import DataLayerPush from "../../components/gtm/dataLayerPush";
import Button from "../../components/button/button";
import FacebookButton from "../../components/facebookButton/facebookButton";
import Link from "../../components/link/link";
import Screen from "../../components/screen/screen";
import Text from "../../components/text/text";
import Headline from "../../components/headline/headline";
import Header from "../../components/header/header";
import { API, maxBirthDate, minBirthDate } from "../../services/variables";
import Info from "../../components/info/info";
import imageHow1 from "../landing/img/how1.svg";
import imageHow2 from "../landing/img/how2.svg";
import imageHow3 from "../landing/img/how3.svg";
import imageHow4 from "../landing/img/how4.svg";
import imageHow5 from "../landing/img/how5.svg";
import imageHow6 from "../landing/img/col1.svg";
import imageHow7 from "../landing/img/col2.svg";
import imageHow8 from "../landing/img/col3.svg";
import imageHow9 from "../landing/img/col4.svg";
import imageHow10 from "../landing/img/read1.svg";
import imageHow11 from "../landing/img/read2.svg";
import imageHow12 from "../landing/img/read3.svg";
import GaSend from "../../components/ga/gaSend";
import { dynamicTitle } from "../../utils/utils";
import GA from "../../components/ga/ga";

const MODULE_NAME = "RegisterPage";

class RegisterPage extends Component {
  state = {
    step: 1,
    fb: false,
    fbData: {},
    data1: {},
    role: ""
  };

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    window.scrollTo(0, 0);
    const {
      match: {
        params: { role }
      }
    } = this.props;

    const roles = [`ROLE_${role.toUpperCase()}`, "ROLE_USER"];
    this.setState({
      data1: {
        ...this.state.data1,
        roles
      }
    });
  }

  setStep = (step = 1) => {
    this.setState({
      step
    });
  };

  completeStep1 = data1 => {
    this.sendEventGA();
    const { fbData } = this.state;
    const { roles } = this.state.data1;
    this.setState({ step: 2, data1: { ...data1, roles } }, () => {
      !isEmpty(fbData) &&
        this.props.dispatch(
          FormDuck.setData(FormDuck.formTypes.register2, {
            name: fbData.first_name,
            surname: fbData.last_name,
            profilePic: [
              {
                preview: `https://graph.facebook.com/${fbData.id}/picture?type=large&height=256&width=256`
              }
            ]
          })
        );
    });
  };

  handleFBClick = () => {
    this.sendEventGA();
    this.setState({
      fb: true
    });
  };

  handleFB = (response = {}) => {
    const {
      registrationRoles,
      match: { params }
    } = this.props;

    this.setState(
      {
        fbData: response
      },
      () => {
        this.props.dispatch(
          FormDuck.setData(FormDuck.formTypes.register1, {
            email: response.email,
            fbEmail: response.email,
            fbAccessToken: response.accessToken
          })
        );
        setTimeout(() => {
          this.nastyHackBtn && this.nastyHackBtn.click();
          if (params.role === registrationRoles.author) {
            dataLayer.push({
              event: "registrace_fb",
              funnel: "autor",
              path: "step3"
            });
          } else if (params.role === registrationRoles.contributor) {
            dataLayer.push({
              event: "registrace_fb",
              funnel: "kolega",
              path: "step3"
            });
          }
        }, 0);
      }
    );
  };

  sendEventGA(role) {
    GA.sendEvent({
      eventCategory: "registration",
      eventAction: "submit",
      eventLabel: role
    });
  }

  onRegisterSubmit = data => {
    const {
      dispatch,
      registrationRoles,
      match: { params }
    } = this.props;

    const registerData = {
      ...this.state.data1,
      ...data
    };
    const picture = g(registerData, "profilePic", [])[0] || {};
    registerData.profilePic = picture.preview || picture.data || "";
    if (registerData.fbAccessToken) {
      delete registerData.password;
    }
    delete registerData.confirm_password;
    dispatch(UserDuck.registerUser(registerData))
      .then(() => {
        if (params.role === registrationRoles.author) {
          dataLayer.push({
            event: "registrovano",
            funnel: "autor",
            path: "step4"
          });
        } else if (params.role === registrationRoles.contributor) {
          dataLayer.push({
            event: "registrovano",
            funnel: "kolega",
            path: "step4"
          });
        }
        dispatch(FormDuck.resetRegistrationForms());
      })
      .catch(() => {});
  };

  getStep1Form() {
    const {
      t,
      registrationRoles,
      match: { params }
    } = this.props;
    const { fb, role } = this.state;

    let formInputs = [
      {
        placeholder: t("registerPage.email"),
        label: <Text small>{t("registerPage.email")}</Text>,
        name: "email",
        entityType: "email",
        className: "col-xs-12",
        required: true,
        disabled: fb
      }
    ];

    if (!fb) {
      formInputs = [
        ...formInputs,
        {
          placeholder: t("registerPage.password"),
          label: <Text small>{t("registerPage.password")}</Text>,
          name: "password",
          entityType: "password",
          type: "password",
          className: "col-xs-12",
          required: true
        },
        {
          placeholder: t("registerPage.passwordAgain"),
          label: <Text small>{t("registerPage.passwordAgain")}</Text>,
          name: "confirm_password",
          entityType: "password",
          type: "password",
          className: "col-xs-12"
        }
      ];
    }
    formInputs = [
      ...formInputs,
      {
        label: (
          <Text small>
            Souhlasím s{" "}
            <Link target="_blank" to={`${API}/docs/vop`}>
              všeobecnými podmínkami
            </Link>{" "}
            Pointy
          </Text>
        ),
        name: "termsAgreed",
        type: "checkbox",
        required: true,
        className: "Form-checkbox"
      },
      {
        placeholder: t("registerPage.gdpr"),
        label: (
          <Text small>
            Souhlasím s{" "}
            <Link target="_blank" to={`${API}/docs/codex`}>
              etickým kodexem
            </Link>{" "}
            Pointy
          </Text>
        ),
        name: "gdprAgreed",
        type: "checkbox",
        required: true,
        className: "Form-checkbox"
      },
      {
        label: (
          <Text small>
            Souhlasím se{" "}
            <Link target="_blank" to={`${API}/docs/gdpr`}>
              zpracováním osobních údajů
            </Link>
          </Text>
        ),
        name: "codexAgreed",
        type: "checkbox",
        required: true,
        className: "Form-checkbox"
      }
    ];
    return (
      <div className={be(MODULE_NAME, "block")}>
        <Forms
          center
          inputs={formInputs}
          name={FormDuck.formTypes.register1}
          onSubmit={this.completeStep1}
          scrollToFirstInvalid
        >
          <div className={be(MODULE_NAME, "formSpacing")} />
          <div>
            <button ref={node => (this.nastyHackBtn = node)} style={{ display: "none" }} type="submit" />
            {!fb && (
              <GaSend action="click" category="Registration" label={role}>
                {params.role === registrationRoles.author && (
                  <DataLayerPush
                    event={{
                      event: "registrace",
                      funnel: "autor",
                      path: "step3"
                    }}
                  >
                    <Button medium type="submit" wide>
                      {t("registerPage.completeStep1")}
                    </Button>
                  </DataLayerPush>
                )}
                {params.role === registrationRoles.contributor && (
                  <DataLayerPush
                    event={{
                      event: "registrace",
                      funnel: "kolega",
                      path: "step3"
                    }}
                  >
                    <Button medium type="submit" wide>
                      {t("registerPage.completeStep1")}
                    </Button>
                  </DataLayerPush>
                )}
                {params.role === registrationRoles.reader && (
                  <Button medium type="submit" wide>
                    {t("registerPage.completeStep1")}
                  </Button>
                )}
              </GaSend>
            )}
          </div>
        </Forms>
        <div className={be(MODULE_NAME, "buttonSpacing")} />
        <GaSend action="click" category="Registration" label={role}>
          <FacebookButton
            label={t("registerPage.fbButton")}
            login
            onClick={this.handleFBClick}
            onResponse={this.handleFB}
            whiteIcon
          >
            {t("registerPage.fbButton")}
          </FacebookButton>
        </GaSend>
      </div>
    );
  }

  getStep1Content() {
    const {
      registrationRoles,
      match: {
        params: { role }
      }
    } = this.props;

    const formContent = this.getStep1Form();
    let roleContent = "";

    switch (role) {
      case registrationRoles.author:
        roleContent = this.getAuthorContent(formContent);
        break;

      case registrationRoles.contributor:
        roleContent = this.getContributorContent(formContent);
        break;

      case registrationRoles.reader:
        roleContent = this.getReaderContent(formContent);
        break;
    }
    return roleContent;
  }

  getStep2Content() {
    const { t } = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="text-center">
              <Headline page primary>
                {t("registerPage.title2")}
              </Headline>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "headlineSpacing")} />
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <div className="text-center">
              <Text component="div" lineHeight>
                Neboj, adresu, telefon i datum narození si necháme pro sebe. Využijeme je až ve chvíli, kdy budeš
                uzavírat smlouvy s knižním týmem.
              </Text>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "body")}>
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              <Forms
                onSubmitInvalid={this.moveToInvalid}
                inputs={[
                  {
                    placeholder: t("registerPage.name"),
                    label: <Text small>{t("registerPage.name")}</Text>,
                    name: "name",
                    required: true
                  },
                  {
                    placeholder: t("registerPage.surname"),
                    label: <Text small>{t("registerPage.surname")}</Text>,
                    name: "surname",
                    required: true
                  },
                  {
                    placeholder: "Adresa",
                    label: (
                      <Text small>
                        Adresa <Info>Údaj potřebný pro uzavírání smluv s knižními kolegy a kolegyněmi.</Info>
                      </Text>
                    ),
                    name: "address",
                    required: true
                  },
                  {
                    label: (
                      <Text small>
                        Datum narození <Info>Údaj potřebný pro uzavírání smluv s knižními kolegy a kolegyněmi.</Info>
                      </Text>
                    ),
                    name: "birthDate",
                    type: "date",
                    entityType: "formatted-date",
                    format: "YYYY-MM-DD",
                    required: true,
                    min: minBirthDate(),
                    max: maxBirthDate()
                  },
                  {
                    placeholder: t("registerPage.phone"),
                    label: <Text small>{t("registerPage.phone")}</Text>,
                    name: "phone",
                    entityType: "phone",
                    className: "col-xs-12",
                    required: true
                  },
                  {
                    dropZoneClassName: "upload-profile-picture",
                    label: <Text small>{t("registerPage.uploadPictureLabel")}</Text>,
                    inputPlaceholder: <div className="UploadFile-placeholder" />,
                    name: "profilePic",
                    type: "file",
                    disableManual: true,
                    multiple: false,
                    profilePic: true,
                    dropzoneProps: {
                      accept: ["image/jpeg", "image/png"]
                    }
                  }
                ]}
                name={FormDuck.formTypes.register2}
                onSubmit={this.onRegisterSubmit}
              >
                <div className="col-xs-12 center">
                  <div className={be(MODULE_NAME, "headlineSpacing")} />
                  <div className="text-center">
                    <Button onClick={this.moveToInvalid} medium type="submit">
                      {t("registerPage.submit")}
                    </Button>
                    <div className={be(MODULE_NAME, "buttonSpacing")} />
                    <div className={be(MODULE_NAME, "back")}>
                      <span onClick={() => this.setStep(1)}>
                        <Text light>{t("registerPage.back")}</Text>
                      </span>
                    </div>
                  </div>
                </div>
              </Forms>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  getAuthorContent = formContent => {
    const { t } = this.props;

    const roleContent = (
      <div className="col-sm d-none d-md-flex">
        <div className="row">
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "first")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>1.</span> Založ si&nbsp;profil
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "second")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow2} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>2.</span> Sestav si&nbsp;tým
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "fourth")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow3} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>3.</span> Vyber s&nbsp;námi peníze na&nbsp;vydání
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "third")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow4} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>4.</span> Spolu dáme knížce život
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "fifth")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow5} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>5.</span> Pošleme ji do&nbsp;světa
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="text-center">
              <Headline page primary>
                {t("registerPage.helloAuthor")}
              </Headline>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "headlineSpacing")} />
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <div className="text-center">
              <Text component="div" lineHeight>
                Cesta ke splněnému snu začíná tady. Registrací. Nejdřív tě požádáme o pár údajů. Pak si můžeš založit
                autorský profil, knižní projekt a začít se poohlížet po knižním týmu.
              </Text>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "headlineSpacing")} />
        <div className={be(MODULE_NAME, "body")}>
          <div className="row align-items-center">
            {roleContent}
            <div className="col-sm">{formContent}</div>
          </div>
        </div>
      </Fragment>
    );
  };

  getContributorContent = formContent => {
    const { t } = this.props;

    const roleContent = (
      <div className="col-sm d-none d-md-flex">
        <div className="row">
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "first")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>1.</span> Založ si&nbsp;profil
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "second")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow6} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>2.</span> Krátce se&nbsp;představ a&nbsp;ukaž svoji
                  práci
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "fourth")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow7} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>3.</span> Naceň svůj čas, podle schopností
                  a&nbsp;libosti
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "third")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow8} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>4.</span> Jestli ses dobře prodal/a, nabídky se&nbsp;ti
                  začnou sypat
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "fifth")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow9} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>5.</span> Podílej se&nbsp;na&nbsp;tvorbě knihy
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="text-center">
              <Headline page primary>
                {t("registerPage.helloColleague")}
              </Headline>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "headlineSpacing")} />
        <div className={be(MODULE_NAME, "body")}>
          <div className="row align-items-center">
            {roleContent}
            <div className="col-sm">{formContent}</div>
          </div>
        </div>
      </Fragment>
    );
  };

  getReaderContent = formContent => {
    const { t } = this.props;

    const roleContent = (
      <div className="col-sm d-none d-md-flex">
        <div className="row">
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "first")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>1.</span> Založ si&nbsp;profil
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "second")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow10} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>2.</span> Zamiluj se&nbsp;do&nbsp;knižního projektu.
                  Nebo několika
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "fourth")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow11} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>3.</span> Prober s&nbsp;autorem všechno, co tě
                  o&nbsp;knížce zajímá
                </Text>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className={be(MODULE_NAME, "how", "third")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow12} />
              <div className="d-flex flex-column">
                <Text bold lg>
                  <span className={be(MODULE_NAME, "howNumber")}>4.</span> Podpoř knihu v&nbsp;předprodeji
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="text-center">
              <Headline page primary>
                {t("registerPage.helloReader")}
              </Headline>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "headlineSpacing")} />
        <div className={be(MODULE_NAME, "body")}>
          <div className="row align-items-center">
            {roleContent}
            <div className="col-sm">{formContent}</div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { step } = this.state;
    let content = null;

    switch (step) {
      case 1:
        content = this.getStep1Content();
        break;

      case 2:
        content = this.getStep2Content();
        break;

      default:
        break;
    }

    return (
      <Screen header={<Header {...this.props} />}>
        {dynamicTitle(this.props.t("registerPage.title2"), false, this.props.location.pathname)}
        <div className={bm(MODULE_NAME)}>{content}</div>
        <div className="text-center mt-4 mb-4">
          <Text>Mám účet. </Text>
          <GaSend action="click" category="button" label="Přihlásit se">
            <Link big to="/login">
              Přihlásit se
            </Link>
          </GaSend>
        </div>
      </Screen>
    );
  }
}

RegisterPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  registrationRoles: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  roles: EnumsDuck.getRoles(state),
  registrationRoles: EnumsDuck.getRegistrationRoles(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(RegisterPage);
