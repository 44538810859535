import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { get as g, uniqBy, isEmpty } from "lodash";

import { bm, be } from "../../../../utils/bliss";

import "./projectCard.css";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";
import ProgressBarLanding from "../../../../components/progressBar/progressBarLanding/progressBarLanding";
import EnumsDuck from "../../../../redux/ducks/enums";
import { getProjectProgress } from "../../../../utils/utils";
import { PREVIEWS_API } from "../../../../services/variables";

const MODULE_NAME = "ProjectCard";

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
  }

  componentDidMount() {
    // Workaround:
    // Google Tag Manager is capturing and forwarding all clicks in the document.
    // React's preventDefault does not work.
    // This allows middle click or right click to open project detail in new window,
    // but simple click will be still handled by React routing.
    if (this.linkRef.current) this.linkRef.current.addEventListener("click", e => e.preventDefault());
  }

  getType = state => {
    const { projectStates } = this.props;

    switch (state) {
      case g(projectStates, "proposal.name", ""):
        return "making";

      case g(projectStates, "funding.name", ""):
        return "financing";

      case g(projectStates, "released.name", ""):
        return "released";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "failed.name", ""):
        return "failed";

      default:
        return "making";
    }
  };

  setStyle() {
    const { project } = this.props;
    const type = this.getType(project.state);

    let styles = {};
    if (project.hiddenElement === true) {
      const firstStyle = {
        pointerEvents: "none",
        visibility: "hidden"
      };
      styles = Object.assign(styles, firstStyle);

      return styles;
    }

    if (type === "making") {
      const secondStyle = {
        backgroundColor: "white"
      };
      styles = Object.assign(styles, secondStyle);

      return styles;
    }
    const thirdStyle = {
      backgroundColor: `${project.bgColor ? project.bgColor : "#ebf0ee"}`
    };
    styles = Object.assign(styles, thirdStyle);

    return styles;
  }

  render() {
    const { project, onClick, wrapper, projectStates, genres: propsGenres, handshakesStates } = this.props;
    const authors = g(project, "author", []);
    const type = this.getType(project.state);
    const followed = g(project, "followed", false);
    const emptyNews = isEmpty(g(project, "news", []));
    const genres = g(project, "genres", []).filter(g => !isEmpty(g));

    const { required } = g(project, "genres", []).reduce(
      (res, gnr) => {
        const genre = {
          ...gnr,
          ...g(propsGenres, gnr.id, {})
        };
        const required = uniqBy([...res.required, ...g(genre, "requiredSubroles", [])], "id");

        return {
          required
        };
      },
      {
        required: []
      }
    );
    const progress = getProjectProgress(project, projectStates, required, handshakesStates);
    const image = project.moonImage || (project.coverPhoto ? `${PREVIEWS_API}${project.coverPhoto}` : null);

    let price = null;
    if ((type === "inProgress" || type === "released") && project && project.rewards) {
      const filteredRewards = project.rewards.filter(r => r.physical === 1 && r.state === "active");
      price = filteredRewards.length === 1 ? filteredRewards[0].price : null;
    }

    return (
      <a ref={this.linkRef} href={"/project/" + project.id} className="ProjectCard-link">
        <div className={bm(MODULE_NAME, { wrapper, followed })} onClick={onClick} style={this.setStyle()}>
          {type !== "making" && image && (
            <div className={be(MODULE_NAME, "bibliography")}>
              {" "}
              <img src={image} className={be(MODULE_NAME, "coverPhoto")} alt="coverPhoto" />
            </div>
          )}
          {type === "making" && (
            <Fragment>
              <div className="d-flex justify-content-start pt-5 pl-5 pr-5">
                <Headline card component="h2">
                  {project.name}
                </Headline>
              </div>
            </Fragment>
          )}
          {type === "financing" && !project.moonTarget && (
            <Fragment>
              <div className="d-flex justify-content-start pt-5 pl-5 pr-5">
                <Headline card component="h2">
                  {project.name}
                </Headline>
              </div>
            </Fragment>
          )}
          {type === "making" && (
            <div className={(be(MODULE_NAME, "genres"), "d-flex flex-wrap justify-content-start pt-3 pl-5 pr-5")}>
              {genres.map((genre, idx) => (
                <span key={project.id + genre.id}>
                  <Text firstUp medium>{`${genre.name}${idx !== genres.length - 1 ? ", " : ""}`}</Text>
                </span>
              ))}
            </div>
          )}
          {type === "making" && project.annotation && (
            <div className={(be(MODULE_NAME, "bibliography"), "d-flex justify-content-start pt-3 pl-5 pr-5")}>
              {project.annotation.length > 5 ? (
                <Text small>{project.annotation.substring(0, project.annotation.lastIndexOf(" ", 148)) + "..."}</Text>
              ) : (
                ""
              )}
            </div>
          )}
          {type === "financing" && !project.moonTarget && (
            <div className={(be(MODULE_NAME, "genres"), "d-flex flex-wrap justify-content-start pt-3 pl-5 pr-5")}>
              {genres.map((genre, idx) => (
                <span key={project.id + genre.id}>
                  <Text firstUp medium>{`${genre.name}${idx !== genres.length - 1 ? ", " : ""}`}</Text>
                </span>
              ))}
            </div>
          )}
          {type === "financing" && !project.moonTarget && (
            <div className={(be(MODULE_NAME, "bibliography"), "d-flex justify-content-start pt-3 pl-5 pr-5")}>
              {project.annotation && project.annotation.length > 10 ? (
                <Text small>{project.annotation.substring(0, project.annotation.lastIndexOf(" ", 148)) + "..."}</Text>
              ) : (
                ""
              )}
            </div>
          )}
          <div
            className={be(MODULE_NAME, "progress", {
              followed,
              emptyNews
            })}
          >
            <div className={be(MODULE_NAME, "headline")}>
              {type === "financing" && project.moonTarget && project.name.length < 37 && (
                <Fragment>
                  <Text small bold greenReward>
                    {project.name}
                  </Text>
                </Fragment>
              )}
              {type === "financing" && project.moonTarget && project.name.length > 37 && (
                <Fragment>
                  <Text longName small bold greenReward>
                    {project.name}
                  </Text>
                </Fragment>
              )}
              {type === "released" && (
                <Fragment>
                  <Text small bold greenReward>
                    {project.name}
                  </Text>
                </Fragment>
              )}
              {type === "inProgress" && (
                <Fragment>
                  <Text small bold greenReward>
                    {project.name}
                  </Text>
                </Fragment>
              )}
            </div>
            <div className={be(MODULE_NAME, "spacing", "xs")} />
            {type !== "making" && type !== "financing" && (
              <div className={be(MODULE_NAME, "authors")}>
                {authors.map(author => (
                  <React.Fragment key={project.id + author.id}>
                    <div className={be(MODULE_NAME, "author")}>
                      <div className={be(MODULE_NAME, "authorName")}>
                        <Text greenReward small>
                          {author.name} {author.surname}
                        </Text>
                      </div>
                    </div>
                    {price && (
                      <div className={be(MODULE_NAME, "price")}>
                        <Text small>{Math.round(price)} Kč</Text>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}

            {type === "financing" && project.moonTarget && (
              <div className={be(MODULE_NAME, "authors")}>
                {authors.map(author => (
                  <div key={project.id + author.id} className={be(MODULE_NAME, "author")}>
                    <div className={be(MODULE_NAME, "authorName")}>
                      <Text greenReward small>
                        {author.name} {author.surname}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {type === "financing" && !project.moonTarget && (
              <div className={be(MODULE_NAME, "authors")}>
                {authors.map(author => (
                  <div key={project.id + author.id} className={be(MODULE_NAME, "author")}>
                    <div className={be(MODULE_NAME, "authorName")}>
                      <Text small>
                        {author.name} {author.surname}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {type === "making" && (
              <div className={be(MODULE_NAME, "authors")}>
                {authors.map(author => (
                  <div key={project.id + author.id} className={be(MODULE_NAME, "author")}>
                    <div className={be(MODULE_NAME, "authorName")}>
                      <Text small>
                        {author.name} {author.surname}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className={be(MODULE_NAME, "spacing", "md")} />

            {type === "financing" && project.moonTarget && project.name.length < 37 && (
              <Fragment>
                <div className="mt-2">
                  <Text component="div" greenReward float medium bold>
                    Financováno
                  </Text>
                  <ProgressBarLanding progress={progress} />
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                </div>
              </Fragment>
            )}

            {type === "financing" && project.moonTarget && project.name.length > 37 && (
              <Fragment>
                <div style={{ marginTop: "-15px" }}>
                  <Text component="div" greenReward float medium bold>
                    Financováno
                  </Text>
                  <ProgressBarLanding progress={progress} />
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                </div>
              </Fragment>
            )}

            {type === "financing" && !project.moonTarget && (
              <div className="d-flex justify-content-center">
                <Text greenReward medium bold small>
                  Čeká se na předprodej
                </Text>
              </div>
            )}
          </div>
        </div>
      </a>
    );
  }
}

ProjectCard.defaultProps = {
  project: {},
  className: "",
  onClick: () => {}
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  handshakesStates: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  project: PropTypes.object,
  projectStates: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  handshakesStates: EnumsDuck.getHandhakeStates(state),
  projectStates: EnumsDuck.getProjectStates(state),
  genres: EnumsDuck.getGenres(state)
});

export default compose(connect(mapStateToProps), withRouter)(ProjectCard);
