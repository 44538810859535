/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import moment from "moment";

import _sumBy from "lodash/sumBy";
import _find from "lodash/find";
import _filter from "lodash/filter";

import { isMobile } from "../../../../utils/utils";
import Text from "../../../../components/text/text";

const stateMapping = {
  recived: "Přijatá",
  paid: "Zaplacená",
  stornop: "Storno",
  print: "Připravujeme",
  printed: "Vytištěno",
  boorewsend: "Vytištěno",
  returnpay: "Vrácená platba"
};

class OrderRow extends Component {
  render() {
    const { eshop, order } = this.props;

    const price = _sumBy(order.items, i => i.num * i.price);

    const orderData = {};
    orderData.postage = _find(order.items, i => i.id_book === -1) || { price: 0 };
    if (eshop) {
      orderData.items = _filter(order.items, i => i.id_book !== -1);
    } else {
      orderData.item = _find(order.items, i => i.id_book !== -1);
    }

    return eshop ? (
      !isMobile() ? (
        <div>
          <div className="row">
            <div className="col-2">
              <Text medium>{moment(order.create_time).format("DD.MM.YYYY")}</Text>
            </div>
            <div className="col-5">
              {orderData.items.map(item => (
                <div key={item.id} className="row">
                  <div className="col-7">
                    <div>
                      <Text bold medium>
                        {item.book.title}
                      </Text>
                    </div>
                    <div>
                      <Text disabled medium>
                        {item.reward.title}
                      </Text>
                    </div>
                    {item.download && (
                      <div>
                        {item.download.epub && (
                          <React.Fragment>
                            <a href={item.download.epub} rel="noopener noreferrer" target="_blank">
                              <img alt=".epub" height="30" src="/images/bookFormats/epub.png" />
                            </a>
                            &nbsp;
                          </React.Fragment>
                        )}
                        {item.download.pdf && (
                          <React.Fragment>
                            <a href={item.download.pdf} rel="noopener noreferrer" target="_blank">
                              <img alt=".pdf" height="30" src="/images/bookFormats/pdf.png" />
                            </a>
                            &nbsp;
                          </React.Fragment>
                        )}
                        {item.download.mobi && (
                          <React.Fragment>
                            <a href={item.download.mobi} rel="noopener noreferrer" target="_blank">
                              <img alt=".mobi" height="30" src="/images/bookFormats/mobi.png" />
                            </a>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-5">
                    <div>
                      <Text medium>{item.num}</Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-2">
              <Text medium>{Math.round(orderData.postage.price)} Kč</Text>
            </div>
            <div className="col-2">
              <Text bold medium>
                {price} Kč
              </Text>
            </div>
            <div className="col-1">
              <Text medium>{stateMapping[order.state]}</Text>
            </div>
          </div>
          <hr />
        </div>
      ) : (
        <div>
          {orderData.items.map((item, index) => (
            <div key={item.id}>
              <div>
                <div className="row">
                  <div className="col">
                    <Text greenReward bold medium>
                      DATUM
                    </Text>
                  </div>
                  <div className="col">
                    <Text medium>{moment(order.create_time).format("DD.MM.YYYY")}</Text>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Text greenReward bold medium>
                      KNIHA
                    </Text>
                  </div>
                  <div className="col">
                    <div>
                      <Text bold medium>
                        {item.book.title}
                      </Text>
                    </div>
                    <div>
                      <Text disabled medium>
                        {item.reward.title}
                      </Text>
                    </div>
                    {item.download && (
                      <div>
                        {item.download.epub && (
                          <React.Fragment>
                            <a href={item.download.epub} rel="noopener noreferrer" target="_blank">
                              <img alt=".epub" height="30" src="/images/bookFormats/epub.png" />
                            </a>
                            &nbsp;
                          </React.Fragment>
                        )}
                        {item.download.pdf && (
                          <React.Fragment>
                            <a href={item.download.pdf} rel="noopener noreferrer" target="_blank">
                              <img alt=".pdf" height="30" src="/images/bookFormats/pdf.png" />
                            </a>
                            &nbsp;
                          </React.Fragment>
                        )}
                        {item.download.mobi && (
                          <React.Fragment>
                            <a href={item.download.mobi} rel="noopener noreferrer" target="_blank">
                              <img alt=".mobi" height="30" src="/images/bookFormats/mobi.png" />
                            </a>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Text greenReward bold medium>
                      KS
                    </Text>
                  </div>
                  <div className="col">
                    <Text medium>{item.num}</Text>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Text greenReward bold medium>
                      DOPRAVA
                    </Text>
                  </div>
                  <div className="col">
                    <Text medium>{index === 0 ? `${Math.round(orderData.postage.price)} Kč` : ""}</Text>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Text greenReward bold medium>
                      CELKEM
                    </Text>
                  </div>
                  <div className="col">
                    <Text bold medium>
                      {price} Kč
                    </Text>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Text greenReward bold medium>
                      STAV
                    </Text>
                  </div>
                  <div className="col">
                    <Text medium>{stateMapping[order.state]}</Text>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <hr />
        </div>
      )
    ) : !isMobile() ? (
      <div>
        <div className="row">
          <div className="col-2">
            <Text medium>{moment(order.create_time).format("DD.MM.YYYY")}</Text>
          </div>
          <div className="col-3">
            <Text bold medium>
              {orderData.item.book.title}
            </Text>
          </div>
          <div className="col-2">
            <Text medium>{orderData.item.reward.title}</Text>
          </div>
          <div className="col-2">
            <Text medium>{Math.round(orderData.postage.price)} Kč</Text>
          </div>
          <div className="col-2 pl-0" style={{ marginLeft: "-30px" }}>
            <Text bold medium noWrap>
              {price} Kč
            </Text>
          </div>
          <div className="col-1 pl-0" style={{ marginLeft: "-30px" }}>
            <Text medium noWrap>
              {stateMapping[order.state]}
            </Text>
          </div>
        </div>
        <div className="row">
          {orderData.item.download && (
            <div>
              {orderData.item.download.epub && (
                <React.Fragment>
                  <a href={orderData.item.download.epub} rel="noopener noreferrer" target="_blank">
                    <img alt=".epub" height="30" src="/images/bookFormats/epub.png" />
                  </a>
                  &nbsp;
                </React.Fragment>
              )}
              {orderData.item.download.pdf && (
                <React.Fragment>
                  <a href={orderData.item.download.pdf} rel="noopener noreferrer" target="_blank">
                    <img alt=".pdf" height="30" src="/images/bookFormats/pdf.png" />
                  </a>
                  &nbsp;
                </React.Fragment>
              )}
              {orderData.item.download.mobi && (
                <React.Fragment>
                  <a href={orderData.item.download.mobi} rel="noopener noreferrer" target="_blank">
                    <img alt=".mobi" height="30" src="/images/bookFormats/mobi.png" />
                  </a>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <hr />
      </div>
    ) : (
      <div>
        <div>
          <div>
            <div className="row">
              <div className="col">
                <Text greenReward bold medium>
                  DATUM
                </Text>
              </div>
              <div className="col">
                <Text medium>{moment(order.create_time).format("DD.MM.YYYY")}</Text>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Text greenReward bold medium>
                  KNIHA
                </Text>
              </div>
              <div className="col">
                <Text bold medium>
                  {orderData.item.book.title}
                </Text>
                {orderData.item.download && (
                  <div>
                    {orderData.item.download.epub && (
                      <React.Fragment>
                        <a href={orderData.item.download.epub} rel="noopener noreferrer" target="_blank">
                          <img alt=".epub" height="30" src="/images/bookFormats/epub.png" />
                        </a>
                        &nbsp;
                      </React.Fragment>
                    )}
                    {orderData.item.download.pdf && (
                      <React.Fragment>
                        <a href={orderData.item.download.pdf} rel="noopener noreferrer" target="_blank">
                          <img alt=".pdf" height="30" src="/images/bookFormats/pdf.png" />
                        </a>
                        &nbsp;
                      </React.Fragment>
                    )}
                    {orderData.item.download.mobi && (
                      <React.Fragment>
                        <a href={orderData.item.download.mobi} rel="noopener noreferrer" target="_blank">
                          <img alt=".mobi" height="30" src="/images/bookFormats/mobi.png" />
                        </a>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Text greenReward bold medium>
                  ODMĚNA
                </Text>
              </div>
              <div className="col">
                <Text medium>{orderData.item.reward.title}</Text>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Text bold greenReward medium>
                  DOPRAVA
                </Text>
              </div>
              <div className="col">
                <Text medium>{Math.round(orderData.postage.price)} Kč</Text>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Text greenReward bold medium>
                  PŘÍSPĚVEK
                </Text>
              </div>
              <div className="col">
                <Text bold medium>
                  {price} Kč
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Text greenReward bold medium>
                  STAV
                </Text>
              </div>
              <div className="col">
                <Text medium>{stateMapping[order.state]}</Text>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default OrderRow;
