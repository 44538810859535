import React from "react";

import { be } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";

import Headline from "../../../../components/headline/headline";
import RewardInfo from "../../../../components/rewardInfo/rewardInfo";

const MODULE_NAME = "ProjectDetailScreen";

const FinancingState = ({ project, myRef }) => (
  <div>
    {!isMobile() && (
      <div
        ref={myRef}
        style={{
          backgroundColor: "white",
          margin: "-65px -1500px 0 -1500px",
          padding: "65px 1500px"
        }}
      />
    )}
    {isMobile() && (
      <div
        ref={myRef}
        style={{
          height: "5px",
          backgroundColor: "white",
          margin: "0 -1500px",
          padding: "30px 1500px 0 1500px"
        }}
      />
    )}
    {project.rewards && project.rewards.length > 0 && (
      <div className={be(MODULE_NAME, "rewardsBackground")}>
        <div className="row ml-2 mb-5 pb-2">
          <Headline bold component="h2" greenReward pageSub>
            Vyberte formu příspěvku
          </Headline>
        </div>
        <div className="row">
          {project.rewards.map(reward => (
            <div key={reward.id} className="col-sm-6 col-lg-4">
              <RewardInfo reward={reward} />
            </div>
          ))}
        </div>
        {/*
        {!isMobile() ? (
          <div className="row justify-content-center" style={{ paddingBottom: '50px' }}>
            <div
              className="text-center"
              style={{ width: '700px', border: '1px solid grey', padding: '10px', borderRadius: '25px' }}
            >
              <iframe
                id="diky-za-navstevu-pointy-radi-bychom-se-te-nakonec-zeptali-na-tvuj-dojem-a-nazor-pokud-se-rozhodnes-na-knihu-neprispet-zajimalo-by-nas-proc-2"
                title="Surfey iframe"
                width="100%"
                height="670"
                frameBorder="0"
                src="https://pointa.surfey.cz/diky-za-navstevu-pointy-radi-bychom-se-te-nakonec-zeptali-na-tvuj-dojem-a-nazor-pokud-se-rozhodnes-na-knihu-neprispet-zajimalo-by-nas-proc-2?iframe"
              />
            </div>
          </div>
        ) : (
          <div className="row" style={{ paddingBottom: '50px' }}>
            <div
              className="text-center"
              style={{
                width: '550px',
                border: '1px solid grey',
                padding: '10px',
                margin: '0 15px',
                borderRadius: '25px',
              }}
            >
              <iframe
                id="diky-za-navstevu-pointy-radi-bychom-se-te-nakonec-zeptali-na-tvuj-dojem-a-nazor-pokud-se-rozhodnes-na-knihu-neprispet-zajimalo-by-nas-proc-2"
                title="Surfey iframe"
                width="100%"
                height="780"
                frameBorder="0"
                src="https://pointa.surfey.cz/diky-za-navstevu-pointy-radi-bychom-se-te-nakonec-zeptali-na-tvuj-dojem-a-nazor-pokud-se-rozhodnes-na-knihu-neprispet-zajimalo-by-nas-proc-2?iframe"
              />
            </div>
          </div>
        )}
        */}
        <div className="clearfix" />
      </div>
    )}
  </div>
);

export default FinancingState;
