import React, { Component } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import Button from "../../../button/button";
import { be, bm } from "../../../../utils/bliss";
import "./promoThanks.css";
import Headline from "../../../headline/headline";
import Text from "../../../text/text";
import mask from "./img/mask.png";

const MODULE_NAME = "PromoThanks";

class PromoThanks extends Component {
  render() {
    return (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "left")}>
          <Headline bold component="h2" subheadline>
            Děkujeme za zájem o knihu.
          </Headline>
          <div className={be(MODULE_NAME, "spacing", "xs")} />
          <Text small>Až bude opět dostupná, zašleme ti upozornění na zadanou e-mailovou adresu.</Text>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <Button mini onClick={this.props.onClose} type="submit">
            HOTOVO
          </Button>
        </div>
        <div className={be(MODULE_NAME, "right")}>
          <div className={be(MODULE_NAME, "imageBox")}>
            <img alt="maska" className={be(MODULE_NAME, "image")} src={mask} />
          </div>
        </div>
      </div>
    );
  }
}

PromoThanks.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PromoThanks;
