import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty, head } from "lodash";

import "./onboardingPage.css";

import { bm, be } from "../../utils/bliss";
import UserDuck from "../../redux/ducks/user";
import Avatar from "../../components/avatar/avatar";
import Button from "../../components/button/button";
import EnumsDuck from "../../redux/ducks/enums";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import List from "../../components/list/list";
import InputComponent from "../../components/input/input";
import FormDuck from "../../redux/ducks/forms";
import Forms from "../../components/forms/form";
import TipsCarousel from "../../components/carousel/tips/tipsCarousel";
import TodosList from "../../components/list/todos/todos";
import SelectComponent from "../../components/input/select/select";
import Tip from "../../components/carousel/tips/tip";
import GenresList from "../../components/genres/genresList";
import Loader from "../../components/loader/loader";

const MODULE_NAME = "OnboardingScreen";

class OnboardingPage extends Component {
  state = {
    loading: false
  };

  componentWillMount() {
    const {
      dispatch,
      user: { roles }
    } = this.props;
    const { result, role } = this.isUserAlreadyHasARole(roles);
    if (result) {
      dispatch(UserDuck.setRole(role));
    } else {
      dispatch(UserDuck.addToOnboarding({ step: 1 }));
    }
  }

  isUserAlreadyHasARole = roles => {
    const result = { result: false };
    // if the user has an existing valid role, go directly to step 2
    if (roles.length === 2) {
      const role = roles.filter(r => r.toUpperCase() !== "ROLE_USER").pop();
      result.result = true;
      result.role = role;
    }
    return result;
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.onboarding.step === 1 && this.props.onboarding.step !== nextProps.onboarding.step) {
      const {
        dispatch,
        user: { roles }
      } = this.props;
      const { result, role } = this.isUserAlreadyHasARole(roles);
      if (result) {
        dispatch(UserDuck.setRole(role));
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("white");
  }

  handleRoleSelect = role => {
    this.props.dispatch(UserDuck.setRole(role));
  };

  getContent1() {
    document.body.classList.remove("white");
    const { t, roles, tooltips } = this.props;

    return (
      <Fragment>
        <div className={be(MODULE_NAME, "headlineStep", "medium mobile")} />
        <Headline center page primary>
          Vítej v Pointě.
        </Headline>
        <div className={be(MODULE_NAME, "headlineStep")} />
        <Headline center subheadline>
          Zde vznikají knihy, které si přeješ.
        </Headline>
        <div className={be(MODULE_NAME, "headlineStep", "large")} />
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className={be(MODULE_NAME, "buttonGroup")}>
              <Button group onClick={() => this.handleRoleSelect(g(roles, "author.name", ""))} primary>
                <span>{t("onboardingPage.authorBtn")}</span> <span>&#x203A;</span>
              </Button>
              <br />
              <Button group onClick={() => this.handleRoleSelect(g(roles, "contributor.name", ""))} primary>
                <span>{t("onboardingPage.servicesBtn")}</span>
                &nbsp;<span>&#x203A;</span>
              </Button>
              <br />
              <Button group onClick={() => this.handleRoleSelect(g(roles, "reader.name", ""))} primary>
                <span>{t("onboardingPage.readerBtn")}</span>
                &nbsp;<span>&#x203A;</span>
              </Button>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "blockStep")} />
        <div className="row">
          <div className="col-sm-6 offset-sm-3">
            <Tip text={g(tooltips, "onboarding1", [])[0].text} />
          </div>
        </div>
      </Fragment>
    );
  }

  onSelectSubrole = subroleId => {
    const {
      dispatch,
      onboarding: { subroles },
      units
    } = this.props;
    let res = [];

    if (subroles.includes(subroleId)) {
      res = subroles.filter(id => id !== subroleId);
    } else {
      res = [...subroles, subroleId];
    }
    dispatch(UserDuck.setSubroles(res));
    this.handleUnit(subroleId, isEmpty(res) ? "" : head(units).id);
  };

  onUnitChange = ({ target: { value, name } }) => {
    const {
      onboarding: { units },
      dispatch
    } = this.props;
    const v = value.reduce(
      (res, val) => ({
        ...res,
        [val.name]: {
          ...(units[val.name] || {}),
          rate: parseFloat(val.value),
          id: val.name
        }
      }),
      {}
    );
    dispatch(UserDuck.setUnit(v));
  };

  handleUnit = (subroleId, unitId) => {
    const {
      onboarding: { units },
      dispatch,
      units: propUnits
    } = this.props;
    const newUnit = {
      ...units,
      [subroleId]: {
        ...(units[subroleId] || {}),
        unit: unitId,
        id: subroleId
      }
    };
    if (!unitId) {
      delete newUnit[subroleId];
    }
    dispatch(UserDuck.setUnit(newUnit));
  };

  isAuthor = () => {
    const {
      roles,
      onboarding: { role }
    } = this.props;
    return role === g(roles, "author.name", "");
  };

  isReader = () => {
    const {
      roles,
      onboarding: { role }
    } = this.props;
    return role === g(roles, "reader.name", "");
  };

  completeStep2 = () => {
    const { onboarding, dispatch } = this.props;

    if (this.isReader() || !isEmpty(g(onboarding, "subroles", []))) {
      dispatch(UserDuck.setOnboardingStep(3));
    } else {
      window.alertify.error("Nevybrali jste žádnou subroli. Učiňte tak prosím.");
    }
  };

  getContent2() {
    document.body.classList.add("white");

    const {
      roles,
      onboarding: { role, subroles, promo, units: selectedUnits, web },
      user,
      t,
      dispatch,
      units,
      tooltips
    } = this.props;

    if (this.isAuthor()) {
      return this.getContent3(role === roles.author.name);
    }
    if (this.isReader()) {
      return (
        <Fragment>
          <div className={be(MODULE_NAME, "navigation")}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="text-center text-md-left">Profil čtenáře</div>
                </div>
                <div className="col-md-6">
                  <div className="text-center text-md-right">Krok 1/2</div>
                </div>
              </div>
            </div>
            <Avatar bigger image={user.profilePic} onboarding />
          </div>
          <div className={be(MODULE_NAME, "navigationSpacing")} />
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <Headline bold>Představ se nám</Headline>
                <div className={be(MODULE_NAME, "headlineStep")} />
                <div className={be(MODULE_NAME, "textBlock")}>
                  <Text component="div">
                    Pointa je komunita a ať už budeš autora peskovat, nebo obdivovat, sluší se nejprve pořádně
                    představit. Napiš o sobě, svých oblíbených autorech a žánrech.
                  </Text>
                </div>
                <div className={be(MODULE_NAME, "blockStep", "smaller")} />

                <InputComponent onChange={this.handlePromo} placeholder="Začněte psát…" textArea value={promo} wide />

                <div className={be(MODULE_NAME, "blockStep", "smaller")} />

                <Tip text={g(tooltips, "onboarding2-reader.0.text", "")} />

                <div className={be(MODULE_NAME, "blockStep", "large")} />
                <Headline bold component="h2" pageSub>
                  Máš blog nebo webovky?
                  <br /> Pochlub se.
                </Headline>
                <div className={be(MODULE_NAME, "headlineStep", "md")} />
                <InputComponent
                  onChange={this.handleLink}
                  optional
                  placeholder="Např. odkaz na tvůj blog"
                  value={web}
                  wide
                />
                <div className={be(MODULE_NAME, "blockStep")} />
                <Button medium onClick={this.completeStep2}>
                  Pokračovat na výběr žánrů
                </Button>
                <div className={be(MODULE_NAME, "back")}>
                  <span onClick={() => dispatch(UserDuck.setOnboardingStep(1))}>
                    &#8592; Zpět na <b>Výběr typu profilu</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className={be(MODULE_NAME, "navigation")}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-center text-md-left">Profil kolegy</div>
              </div>
              <div className="col-md-6">
                <div className="text-center text-md-right">Krok 1/3</div>
              </div>
            </div>
          </div>
          <Avatar bigger image={user.profilePic} onboarding />
        </div>
        <div className={be(MODULE_NAME, "navigationSpacing")} />
        <Headline bold>{t("onboardingPage.servicesBtn")}</Headline>
        <div className={be(MODULE_NAME, "headlineStep")} />
        <div className={be(MODULE_NAME, "textBlock")}>
          <Text component="div">{t("onboardingPage.servicesTitle")}</Text>
        </div>
        <div className={be(MODULE_NAME, "blockStep")} />
        <div className={be(MODULE_NAME, "block")}>
          <List data={g(roles, "contributor.subroles", [])} onSelect={this.onSelectSubrole} selectedItems={subroles} />
        </div>
        <div className={be(MODULE_NAME, "blockStep", "smaller")} />
        <br />
        <Headline bold component="h2" pageSub>
          {t("onboardingPage.servicesPriceTitle")}
        </Headline>
        <div className={be(MODULE_NAME, "headlineStep")} />
        <div className={be(MODULE_NAME, "textBlock")}>
          <Text component="div">{t("onboardingPage.servicesPriceText")}</Text>
          <TodosList
            currency
            disableDelete
            inputs={subroles.map(subroleId => {
              const subrole = g(roles, "contributor.subroles", []).find(subrole => subrole.id === subroleId);
              const subroleUnit = selectedUnits[subroleId] || {};
              const currentUnit = units.find(u => u.id === subroleUnit.unit) || {};

              return {
                key: subroleId,
                name: subroleId,
                label: g(subrole, "name", ""),
                value: subroleUnit.rate || 0,
                extra: (
                  <SelectComponent
                    defaultValue={{
                      ...currentUnit,
                      label: currentUnit.name
                    }}
                    name="unit"
                    onChange={({ target }) => {
                      this.handleUnit(subroleId, target.value);
                    }}
                    options={units.map(unit => ({
                      value: unit.id,
                      label: unit.name
                    }))}
                    placeholder="Jednotka"
                  />
                )
              };
            })}
            onboarding
            onChange={this.onUnitChange}
          />
        </div>
        <div className={be(MODULE_NAME, "headlineStep")} />
        <Button medium onClick={this.completeStep2}>
          {t("onboardingPage.continue")}
        </Button>
        <div className={be(MODULE_NAME, "back")}>
          <span onClick={() => dispatch(UserDuck.setOnboardingStep(1))}>
            &#8592; Zpět na <b>Výběr typu profilu</b>
          </span>
        </div>
      </Fragment>
    );
  }

  handlePromo = ({ target }) => {
    this.props.dispatch(UserDuck.setPromo(target.value));
  };

  handleLink = ({ target }) => {
    this.props.dispatch(UserDuck.setLink(target.value));
  };

  onSelectGenre = genreId => {
    const {
      onboarding: { genres = [] },
      dispatch
    } = this.props;
    let newGenres = [];
    if (genres.includes(genreId)) {
      newGenres = genres.filter(gId => gId !== genreId);
    } else {
      newGenres = [...genres, genreId];
    }
    dispatch(UserDuck.setGenres(newGenres));
  };

  getContent3() {
    document.body.classList.add("white");
    const {
      t,
      user,
      onboarding: { promo, genres },
      dispatch,
      tooltips: { onboarding2 = [] }
    } = this.props;

    if (this.isReader()) {
      return (
        <Fragment>
          <div className={be(MODULE_NAME, "navigation")}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="text-center text-md-left">Profil čtenáře</div>
                </div>
                <div className="col-md-6">
                  <div className="text-center text-md-right">Krok 2/2</div>
                </div>
              </div>
            </div>
            <Avatar image={user.profilePic} onboarding />
          </div>
          <div className={be(MODULE_NAME, "navigationSpacing")} />
          <Headline bold>Vyber žánry, které tě zajímají</Headline>
          <div className={be(MODULE_NAME, "headlineStep")} />
          <div className={be(MODULE_NAME, "textBlock")}>
            <Text component="div">
              Jaké žánry knih jsou tvé oblíbené? Vyber jeden nebo více z následujícího seznamu, abysme mohli pro tebe
              najít knihy, které se ti budou líbit.
            </Text>
          </div>
          <div className={be(MODULE_NAME, "blockStep")} />
          <div className={be(MODULE_NAME, "block")}>
            <GenresList medium onSelectGenre={this.onSelectGenre} selectedGenres={genres} />
          </div>
          <div className={be(MODULE_NAME, "blockStep", "smaller")} />
          <Button medium onClick={this.completeOnboarding}>
            Dokončit profil
          </Button>
          <div className={be(MODULE_NAME, "back")}>
            <span onClick={() => dispatch(UserDuck.setOnboardingStep(2))}>
              &#8592; Zpět na <b>Představení se</b>
            </span>
          </div>
        </Fragment>
      );
    }

    var linkToMedailonek = (
      <a href="https://pointa.cz/knowledgebase/jak-napsat-dobry-medailonek" target="_blank">
        Medailonek
      </a>
    );

    return (
      <Fragment>
        <div className={be(MODULE_NAME, "navigation")}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-center text-md-left">{this.isAuthor() ? "Profil autora" : "Profil kolegy"}</div>
              </div>
              <div className="col-md-6">
                <div className="text-center text-md-right">{this.isAuthor() ? "Krok 1/2" : "Krok 2/3"}</div>
              </div>
            </div>
          </div>
          <Avatar image={user.profilePic} onboarding />
        </div>
        <div className={be(MODULE_NAME, "navigationSpacing")} />
        <Headline bold>{this.isAuthor() ? "Prozraď nám něco o sobě" : t("onboardingPage.whyHireMe")}</Headline>
        <div className={be(MODULE_NAME, "headlineStep")} />
        <div className={be(MODULE_NAME, "textBlock")}>
          {this.isAuthor() ? (
            <Text component="div">
              {linkToMedailonek}
              {` tě představí budoucím kolegům a kolegyním, později i čtenářům a čtenářkám. Můžeš začít svým povoláním a koníčky. A potom napsat, proč ses rozhodl(a) vydat knihu, jaké máš zkušenosti se psaním, literárními soutěžemi nebo publikováním. Nic tě teď nenapadá? Tenhle krok můžeš zatím přeskočit a vrátit se k němu později. Medailonek taky můžeš zpětně upravovat.`}
            </Text>
          ) : (
            <Text component="div">{t("onboardingPage.whyHireMeText")}</Text>
          )}
        </div>
        <div className={be(MODULE_NAME, "blockStep")} />
        <InputComponent
          onChange={this.handlePromo}
          placeholder={t("onboardingPage.whyHireMePlaceholder")}
          textArea
          value={promo}
        />
        <div className={be(MODULE_NAME, "blockStep", "small")} />
        {/*
                <div className="row">
                    <TipsCarousel tips={onboarding2} />
                </div>
                */}
        <div className={be(MODULE_NAME, "blockStep")} />
        <Button medium onClick={() => dispatch(UserDuck.setOnboardingStep(4))}>
          {t("onboardingPage.continue")}
        </Button>
        <div className={be(MODULE_NAME, "back")}>
          <span onClick={() => dispatch(UserDuck.setOnboardingStep(this.isAuthor() ? 1 : 2))}>
            &#8592; Zpět na <b>{this.isAuthor() ? "Výběr typu profilu" : "Role"}</b>
          </span>
        </div>
      </Fragment>
    );
  }

  completeOnboarding = (data = {}) => {
    this.setState({ loading: true });
    var promise = new Promise(() => {
      const { dispatch } = this.props;
      dispatch(UserDuck.addToOnboarding(data));
      dispatch(
        UserDuck.completeOnboarding({
          isAuthor: this.isAuthor(),
          isReader: this.isReader()
        })
      );
    });

    promise.then(() => {
      this.setState({
        isLoading: false
      });
    });
  };

  getContent4() {
    document.body.classList.add("white");
    const {
      t,
      user,
      onboarding: { references }
    } = this.props;
    const isAuthor = this.isAuthor();

    return (
      <Fragment>
        <div className={be(MODULE_NAME, "navigation")}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-center text-md-left">{this.isAuthor() ? "Profil autora" : "Profil kolegy"}</div>
              </div>
              <div className="col-md-6">
                <div className="text-center text-md-right">{this.isAuthor() ? "Krok 2/2" : "Krok 3/3"}</div>
              </div>
            </div>
          </div>
          <Avatar image={user.profilePic} onboarding />
        </div>
        <div className={be(MODULE_NAME, "navigationSpacing")} />
        <Headline bold>{t("onboardingPage.myWork")}</Headline>
        <div className={be(MODULE_NAME, "headlineStep")} />
        <div className={be(MODULE_NAME, "textBlock")}>
          <Text component="div">
            {this.isAuthor()
              ? "Tvůj profil je skoro hotov. Ještě přidej ukázky tvé knihy, které ji nejlépe vystihují."
              : t("onboardingPage.myWorkText")}
          </Text>
        </div>
        <div className={be(MODULE_NAME, "blockStep")} />
        <Forms
          className="col-xs-12"
          inputs={[
            {
              label: (
                <div>
                  <div className="row">
                    <div className="col-xs-12 text-left">
                      <Headline bold component="h3" subheadline>
                        {this.isAuthor() ? "Ukázky" : "Obrázky"}
                      </Headline>
                      <Text component="div" small>
                        Můžeš nahrát jakýkoliv textový nebo obrázkový formát (do 5MB).
                      </Text>
                    </div>
                  </div>
                  <div className={be(MODULE_NAME, "blockStep", "smaller")} />
                </div>
              ),
              inputPlaceholder: "",
              disableManual: true,
              name: "samples",
              type: "file",
              allowList: false,
              wrapperClassName: be(MODULE_NAME, "block", "wide")
            },
            {
              type: "list",
              newBtn: t("onboardingPage.addNewReference"),
              name: "references",
              wrapperClassName: be(MODULE_NAME, "block", "wide"),
              label: (
                <div>
                  <div className="row">
                    <div className="col-md-8 text-left">
                      <Headline bold component="h3" subheadline>
                        {isAuthor ? "Publikovaná díla" : t("onboardingPage.references")}
                      </Headline>
                      {isAuthor && (
                        <Text component="div" small>
                          Pochlub se, co a kde, už jsi publikoval
                        </Text>
                      )}
                      {!isAuthor && (
                        <Text component="div" small>
                          Pochlub se pro koho jsi dělal, vystav odkazy na svoje portfolio a tak dále
                        </Text>
                      )}
                    </div>
                    <div className="col-md-4 text-right d-none d-md-block">
                      <Text label light>
                        {t("onboardingPage.optional")}
                      </Text>
                    </div>
                  </div>
                  <div className={be(MODULE_NAME, "blockStep", "smaller")} />
                </div>
              ),
              inputs: (references || []).map((ref, idx) => ({
                ...ref,
                placeholder: t(`onboardingPage.reference${idx + 1}`),
                className: "col-xs-12"
              }))
            }
          ]}
          name={FormDuck.formTypes.samples}
          onSubmit={this.completeOnboarding}
        >
          <div className="center button-container">
            <Button medium type="submit">
              {t("onboardingPage.finish")}
            </Button>

            <div className={be(MODULE_NAME, "back")}>
              <span onClick={() => this.props.dispatch(UserDuck.setOnboardingStep(isAuthor ? 2 : 3))}>
                &#8592; Zpět na <b>{this.isAuthor() ? "Představ se" : "Proč si tě přát"}</b>
              </span>
            </div>
          </div>
        </Forms>
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const {
      onboarding: { step }
    } = this.props;
    let content = null;
    switch (step) {
      case 1:
        content = this.getContent1();
        break;

      case 2:
        content = this.getContent2();
        break;

      case 3:
        content = this.getContent3();
        break;

      case 4:
        content = this.getContent4();
        break;

      default:
        content = <span>{step}</span>;
        break;
    }
    return <div className={MODULE_NAME}>{content}</div>;
  }
}

OnboardingPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onboarding: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tooltips: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  onboarding: UserDuck.getOnboarding(state),
  roles: EnumsDuck.getRoles(state),
  tooltips: EnumsDuck.getTips(state),
  units: EnumsDuck.getUnits(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(OnboardingPage);
