import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g } from "lodash";

import { be, bm } from "../../utils/bliss";
import UserDuck from "../../redux/ducks/user";
import { canFollowProject } from "../../utils/utils";
import WizardDuck from "../../redux/ducks/wizard";
import Button from "../button/button";

import "./fundingFollow.css";

const MODULE_NAME = "FundingFollow";

class FundingFollow extends Component {
  followToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    const { project, dispatch, detail, user } = this.props;

    if (project.followed) {
      dispatch(WizardDuck.unfollowProject(user.id, project.id, detail));
    } else {
      dispatch(WizardDuck.followProject(user.id, project.id, detail));
    }
  };

  render() {
    const { project, user, detail } = this.props;

    if (canFollowProject(user, project)) {
      const followed = g(project, "followed", false);

      return detail ? (
        <Button smallFunding half onClick={this.followToggle}>
          <img style={{ padding: "0 3px" }} src="/images/svg/follow.svg" />
          {followed ? "Sledujete" : "Sledovat"}
        </Button>
      ) : (
        <img
          alt="follow"
          className={be(MODULE_NAME, "bookmark", { followed })}
          onClick={this.followToggle}
          src={`${followed ? "/images/svg/follow.svg" : "/images/svg/follow.svg"}`}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  user: UserDuck.getUser(state)
});

FundingFollow.defaultProps = {
  detail: false
};

FundingFollow.propTypes = {
  detail: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(FundingFollow);
