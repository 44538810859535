import React from "react";
import { get as g } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { be } from "../../../../utils/bliss";

import DetailBubble from "../../../../components/bubbles/detailBubble/detailBubble";
import Headline from "../../../../components/headline/headline";

import EnumsDuck from "../../../../redux/ducks/enums";

const MODULE_NAME = "ProjectDetailScreen";

const GetBasicParameters = ({ history, bindings, project }) => {
  let title = "Typ knihy, velikost a náklad tisku";

  if (project.basePrice) {
    title = `${(bindings.find(b => b.type === g(project, "basePrice.bookType", "")) || {}).name}, ${g(
      project,
      "printer.copies",
      0
    )} kusů po ${g(project, "printer.pages", 0)} stranách`;
  }

  return (
    <div className={be(MODULE_NAME, "parameters")}>
      <div className="row">
        <div className="col-sm-10">
          <Headline bold component="h2" pageSub>
            Nastavte parametry a najděte si kolegy, kteří vám knihu připraví
          </Headline>
        </div>
      </div>
      <div className={be(MODULE_NAME, "spacing", "lg")} />
      <Headline bold component="h3" light xs>
        Základní parametry knihy
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      <DetailBubble
        complete={!!project.printer}
        icon="/images/svg/book.svg"
        label="Upravit"
        onClick={() => history.push(`/basic-parameters/${project.id}`)}
        parameters
        price={g(project, "financing.basePrice", null)}
        title={title}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  bindings: EnumsDuck.getBindings(state)
});

export default compose(connect(mapStateToProps), withRouter)(GetBasicParameters);
