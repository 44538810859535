import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Button from "../../../button/button";
import { be, bm } from "../../../../utils/bliss";

import "./alert.css";

const MODULE_NAME = "Alert";

class Alert extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClose, prompt, onSubmit, data } = this.props;
    const { danger, success } = data;

    return (
      <div className={bm(MODULE_NAME, { danger, success })}>
        <div className={be(MODULE_NAME, "header", { danger, success })}>
          {success ? (
            <img src="/images/svg/check--black.svg" />
          ) : danger ? (
            <img src="/images/svg/cross--white.svg" />
          ) : (
            <img src="/images/svg/info--black.svg" />
          )}
        </div>
        <div className={be(MODULE_NAME, "body")}>{data.text}</div>
        <div className={be(MODULE_NAME, "button", null, "text-center")}>
          {!prompt && (
            <Button onClick={onClose} spacing white xxs>
              OK
            </Button>
          )}
          {prompt && (
            <Fragment>
              <Button onClick={onSubmit} spacing white xxs>
                Ano
              </Button>
              <Button onClick={onClose} spacing white xxs>
                Ne
              </Button>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

Alert.defaultProps = {
  prompt: false,
  success: false,
  danger: false,
  onClose: () => {},
  onSubmit: () => {},
  data: {}
};

Alert.propTypes = {
  danger: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prompt: PropTypes.bool,
  success: PropTypes.bool
};

export default Alert;
