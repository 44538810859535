import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import { isEmpty, last, noop, get as g } from "lodash";

import { bm, be } from "../../../utils/bliss";

import Button from "../../button/button";
import { PREVIEWS_API } from "../../../services/config";

import "./uploadFile.css";
import Text from "../../text/text";
import MobileSlider from "../../mobileSlider/mobileSlider";
import SampleFilesSlider from "./sampleFilesSlider";

const MODULE_NAME = "UploadFile";

const maxFileSize = 6000000;

class UploadFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.value || []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value.length !== this.state.files.length) {
      this.setState({
        files: nextProps.value
      });
    }
  }

  handleOnDrop = (accepted, rejected) => {
    if (!isEmpty(accepted)) {
      const promisedFiles = accepted
        .filter(file => {
          if (file.size > maxFileSize) {
            window.alertify.notify(
              `Soubor ${file.name} je moc velký. Maximální velikost je ${maxFileSize / 1000000}MB.`,
              "error",
              5
            );
            return false;
          }
          return true;
        })
        .map(file => {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          return new Promise((res, rej) => {
            reader.onload = () => {
              res({
                src: file.preview,
                data: reader.result,
                file,
                mime: file.type,
                name: file.name
              });
            };
          });
        });
      Promise.all(promisedFiles).then(files => {
        let newFiles = [...this.state.files, ...files];
        if (!this.props.multiple) {
          newFiles = [last(newFiles)];
        }

        this.setState(
          {
            files: newFiles
          },
          () => {
            this.props.onFilesInsert(newFiles);
          }
        );
      });
    } else if (!isEmpty(rejected)) {
      console.warn("UploadFile.handleOnDrop", rejected);
    }
  };

  deleteFile = (e, idx = 0, file) => {
    e.stopPropagation();
    e.preventDefault();
    const { onFileDelete, onFilesInsert } = this.props;

    if (!isEmpty(file) && file.id) {
      onFileDelete(file);
    }

    const newFiles = this.state.files
      .filter((f, i) => {
        if (file.id && f.id) {
          return file.id !== f.id;
        }
        if (((f.name || f.filename) && file.name) || (file.preview && !file.name)) {
          return (f.name || f.filename) !== file.name || (!file.name && file.preview !== file.preview);
        }
        return i !== idx;
      })
      .filter(f => !!f);
    this.setState(
      {
        files: newFiles
      },
      () => {
        onFilesInsert(newFiles);
      }
    );
    // onFilesInsert(newFiles);
  };

  getListFiles() {
    const { files } = this.state;

    return (
      <ul className="files-list mb-4">
        {files.map((file, idx) => (
          <li key={file.name} className="col-xs-12 mb-3">
            {file.name} <span onClick={e => this.deleteFile(e, idx, file)}>DELETE</span>
          </li>
        ))}
      </ul>
    );
  }

  getImage(file = {}) {
    const { editable, big, samples, profile, profilePic } = this.props;
    let pic = file.preview;
    let sample = false;
    const mime = file.mime || file.type || "";

    if (mime) {
      if (mime.includes("image")) {
        pic = file.data || file.preview;
        if (file.preview && file.preview.includes(PREVIEWS_API)) {
          pic = file.preview;
        }
      } else if (mime.includes("pdf")) {
        pic = "/images/pdf.png";
        sample = true;
      } else if (mime.includes("document") || mime.includes("pages") || mime.includes("x") || mime.includes("vnd")) {
        pic = "/images/msWord.png";
        sample = true;
      } else {
        pic = "images/msWord.png";
        sample = true;
      }
    }
    let link = file.data;
    if (file.preview && file.preview.includes(PREVIEWS_API)) {
      link = file.preview;
    }
    const key = `${file.name || file.filename}-${file.id || Math.random()}`;
    return editable ? (
      <div key={key} className={be(MODULE_NAME, "file", { big, profilePic })}>
        <span
          className={be(MODULE_NAME, "trash", null, "fa fa-2x fa-trash")}
          onClick={e => this.deleteFile(e, null, file)}
        />
        <div
          key={`${file.id || Math.random()}-${pic}`}
          className={be(MODULE_NAME, "fileImage", { sample })}
          style={{ backgroundImage: `url(${pic})` }}
        />
        <span className={be(MODULE_NAME, "name")}>{file.name}</span>
      </div>
    ) : (
      <div
        key={key}
        className={be(MODULE_NAME, "file", {
          samples: "samples",
          profilePic
        })}
      >
        <a key={`${file.id || Math.random()}-${pic}`} href={link} target="_blank">
          <div className={be(MODULE_NAME, "fileImage", { sample })} style={{ backgroundImage: `url(${pic})` }} />
          {samples ? (
            <div className={be(MODULE_NAME, "desc")}>
              <Text bold component="div" hover small>
                Číst ukázku
              </Text>
              <Text primary xs>{`${file.mimeTypeFormatted || mime}${
                file.sizeFormatted ? `, ${file.sizeFormatted}` : ""
              }`}</Text>
            </div>
          ) : (
            <div>{file.name}</div>
          )}
        </a>
      </div>
    );
  }

  turnOnSlider() {
    const { inputPlaceholder, profile, samples, gallery } = this.props;
    const { files } = this.state;
    if (gallery) {
      return (
        <Fragment>
          <div className={be(MODULE_NAME, "files")}>
            {files.map(f => this.getImage(f))}
            {isEmpty(files) && <p>{inputPlaceholder}</p>}
          </div>
          <div className="clearfix" />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className={be(MODULE_NAME, "files", { samples, profile })}>
          <SampleFilesSlider files={files.map(f => this.getImage(f))} />
          {isEmpty(files) && <p>{inputPlaceholder}</p>}
        </div>
        <div className="clearfix" />
      </Fragment>
    );
  }

  render() {
    let {
      buttonPlaceholder,
      editable,
      inputPlaceholder,
      disableManual,
      allowList,
      dropzoneProps,
      small,
      gallery,
      profile,
      preview,
      samples,
      count3,
      border,
      center,
      multiple,
      match,
      white
    } = this.props;
    const { files } = this.state;

    const isProjectDetail = g(match, "path", "").includes("/project");
    inputPlaceholder = multiple ? null : (
      <div
        className="UploadFile-placeholder"
        onClick={
          !isProjectDetail
            ? noop
            : () => {
                this.dropZone.open();
              }
        }
      />
    );
    return (
      <div
        className={bm(MODULE_NAME, {
          small,
          count3,
          gallery,
          profile,
          preview,
          center
        })}
      >
        {allowList && !isEmpty(files) && this.getListFiles()}
        {editable ? (
          <div>
            <div>
              <Fragment>
                <Dropzone
                  ref={node => (this.dropZone = node)}
                  accept="image/jpeg,image/png,image/gif,application/pdf"
                  className={be(MODULE_NAME, "files", `editable ${border ? "border" : ""} ${center ? "center" : ""}`)}
                  disableClick={!disableManual}
                  multiple={multiple}
                  onDrop={this.handleOnDrop}
                  {...dropzoneProps}
                >
                  {!isEmpty(files) && files[0] !== undefined && files.map(file => this.getImage(file))}
                  {(isEmpty(files) || files[0] === undefined) && (
                    <Text light small>
                      {inputPlaceholder}
                    </Text>
                  )}
                </Dropzone>
                {multiple && (
                  <div
                    className={be(MODULE_NAME, "plusWrapper")}
                    onClick={() => {
                      this.dropZone.open();
                    }}
                  >
                    <div className={be(MODULE_NAME, "plus")} />
                  </div>
                )}
                <div className="clearfix" />
              </Fragment>
            </div>
            {white ? (
              <div>
                <Text inverted lighter small>
                  Povolené formáty jsou PDF, JPEG, PNG a GIF. Textové dokumenty vyexportujte do PDF.
                </Text>
              </div>
            ) : (
              <div style={{ paddingTop: "15px" }}>
                <Text light small>
                  Povolené formáty jsou PDF, JPEG, PNG a GIF. Textové dokumenty vyexportujte do PDF.
                </Text>
              </div>
            )}
          </div>
        ) : (
          this.turnOnSlider()
        )}

        {!disableManual && buttonPlaceholder && (
          <div className={be(MODULE_NAME, "button", null, "text-center")}>
            <Button
              border
              invertedPrimary
              onClick={() => {
                this.dropZone.open();
              }}
              xs
            >
              {buttonPlaceholder}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

UploadFile.defaultProps = {
  disableManual: false,
  allowList: false,
  editable: true,
  profile: false,
  buttonPlaceholder: "",
  inputPlaceholder: "",
  onFilesInsert: () => {},
  onFileDelete: () => {},
  className: "",
  dropZoneClassName: "",
  value: [],
  dropzoneProps: {},
  multiple: true
};

UploadFile.propTypes = {
  allowList: PropTypes.bool,
  buttonPlaceholder: PropTypes.string,
  className: PropTypes.string,
  disableManual: PropTypes.bool,
  dropZoneClassName: PropTypes.string,
  dropzoneProps: PropTypes.object,
  editable: PropTypes.bool,
  multiple: PropTypes.bool,
  onFileDelete: PropTypes.func,
  onFilesInsert: PropTypes.func,
  value: PropTypes.any
};

export default withRouter(UploadFile);
