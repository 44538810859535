import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { get as g, isEmpty } from "lodash";
import { connect } from "react-redux";

import "./handshake.css";
import Avatar from "../../../avatar/avatar";
import Tip from "../../../carousel/tips/tip";
import EnumsDuck from "../../../../redux/ducks/enums";
import Button from "../../../button/button";
import FormDuck from "../../../../redux/ducks/forms";
import Forms from "../../../forms/form";
import UserDuck from "../../../../redux/ducks/user";
import WizardDuck from "../../../../redux/ducks/wizard";
import UserService from "../../../../services/userService";
import { bm, be } from "../../../../utils/bliss";
import Headline from "../../../headline/headline";
import Text from "../../../text/text";
import UIDuck from "../../../../redux/ducks/ui";
import UserEditor from "../../../editor/userEditor";
import { generateMuster } from "../../../../utils/contractUtil";
import projectService from "../../../../services/projectService";

const MODULE_NAME = "HandshakeModal";

class Handshake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: g(props, "data.handshake.state", "") === g(props, "handshakeStates.accepted.name", "") ? 2 : 1,
      contributor: g(props, "data.handshake.contributor", {}),
      author: {},
      contract: false,
      data: {}
    };
  }

  componentWillMount() {
    const { dispatch, data } = this.props;

    if (!isEmpty(data)) {
      dispatch(
        FormDuck.setData(FormDuck.formTypes.handshake, {
          price: g(data, "handshake.price", ""),
          description: g(data, "handshake.description", "")
        })
      );

      const projectId = g(data, "project.id", null);
      const handshakeId = g(data, "handshake.id", null);

      projectService.getContract(projectId, handshakeId).then(contract => {
        this.setState({
          author: contract.author,
          contributor: contract.contributor
        });
      });
    }
  }

  closeModal = () => {
    const { dispatch, onClose, user } = this.props;
    const { data } = this.state;
    onClose();
    if (!isEmpty(data)) {
      dispatch(WizardDuck.setProjectById(g(data, "project.id", "")));
      dispatch(WizardDuck.setDetail(g(data, "project.id", ""), user.id));
    }
  };

  generateContract = () => {
    this.setState({
      contract: true
    });
  };

  onSubmit = (data = {}) => {
    const { data: propsData, handshakeStates, t, dispatch, user, roles, onClose } = this.props;
    const isUserContributor = g(user, "roles", []).includes(g(roles, "contributor.name", ""));
    const isSuggested = g(propsData, "handshake.state", "") === g(handshakeStates, "suggested.name", "");
    data = {
      ...data,
      ...propsData,
      price: `${data.price}`.includes(",")
        ? parseFloat(`${data.price}`.replace(",", "."), 10)
        : parseInt(data.price, 10)
    };
    if (isSuggested && isUserContributor) {
      dispatch(UserDuck.confirmHandshake(g(propsData, "project.id", ""), g(propsData, "handshake.id"))).then(hs => {
        console.log("hs", hs);
        window.alertify.success(
          t("modals.handshake.handshakeConfirm", {
            projectName: g(propsData, "project.name", ""),
            role: g(propsData, "handshake.subrole.name", "")
          })
        );
        dispatch(UserDuck.updateUserHandshake(hs));
        dispatch(UserDuck.fetchUserNotifications(user.id));
        onClose();
      });
    } else {
      UserService.updateHandshake(g(data, "project.id", ""), g(data, "handshake.id", ""), {
        price: g(data, "price", 0),
        description: g(data, "description", "")
      }).then(() => {
        dispatch(UserDuck.fetchUserNotifications(user.id));
        dispatch(WizardDuck.setDetail(g(data, "project.id", ""), g(user, "id", "")));
        window.alertify.success("Návrh dohody byl upraven!");
        onClose();
      });
    }
  };

  rejectHandshake = () => {
    const {
      t,
      data: { handshake, project },
      onClose,
      dispatch
    } = this.props;
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.removeHandshakeText"),
        callback: () => {
          dispatch(UserDuck.removeHandshake(project.id, handshake.id)).then(() => {
            window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
            dispatch(WizardDuck.setProjectById(project.id)).then(() => {
              onClose();
            });
          });
        }
      })
    );
  };

  onSetInput = () => {
    const { contract } = this.state;

    if (contract) {
      this.setState({
        contract: false
      });
    }
  };

  render() {
    const { data, t, tips, handshakeStates, user, roles, formData } = this.props;
    const { author, contributor, contract: showContract } = this.state;
    let authors = g(data, "handshake.author", {});
    if (!Array.isArray(authors)) {
      // TODO: API fix, entity comes in different format for author / contributor
      authors = [authors];
    }
    const isSuggested = g(data, "handshake.state", "") === handshakeStates.suggested.name;
    const isAccepted = g(data, "handshake.state", "") === handshakeStates.accepted.name;
    const isUserContributor = g(user, "roles", []).includes(roles.contributor.name);

    return this.state.step === 1 ? (
      <div className={bm(MODULE_NAME)}>
        <div className="container">
          <div className={be(MODULE_NAME, "title")}>
            <Headline component="h2" md>
              {t("modals.handshake.title")}
            </Headline>
            <Headline bold component="h3" md>
              {g(data, "project.name", "")}
            </Headline>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <div className="row">
                <div className="col-6">
                  <div className="row flex-nowrap justify-content-start">
                    <div className="col">
                      {authors.map(author => (
                        <div key={author.id} className="d-flex flex-column align-items-center justify-content-center">
                          <Avatar image={author.profilePic} md />
                        </div>
                      ))}
                    </div>
                    <div className="col-auto pl-0">
                      <div className={be(MODULE_NAME, "hand", "left")}>
                        <img alt="" className="img-fluid" src="/images/svg/leftHand.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={be(MODULE_NAME, "description", "left")}>
                        {authors.map(author => (
                          <Fragment>
                            <div className={be(MODULE_NAME, "spacing", "md")} />
                            <div className="text-center">
                              <Headline bold component="h4" dark xs>
                                {author.name} {author.surname}
                              </Headline>
                              <div className={be(MODULE_NAME, "spacing", "sm")} />
                              <Text component="div" light medium>
                                Autor knihy
                              </Text>
                            </div>
                            {isSuggested && (
                              <Fragment>
                                <div className={be(MODULE_NAME, "spacing", "lg")} />
                                <Text component="div" light medium textBreak>
                                  <b>E&#8209;mail:</b> <br className="d-md-none" />
                                  <a
                                    className={be(MODULE_NAME, "link")}
                                    href={`mailto:${g(author, "email", "")}`}
                                    target="_blank"
                                  >
                                    {g(author, "email", "")}
                                  </a>
                                </Text>
                              </Fragment>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row flex-nowrap justify-content-end">
                    <div className="col-auto pr-0">
                      <div className={be(MODULE_NAME, "hand", "right")}>
                        <img alt="" className="img-fluid" src="/images/svg/rightHand.svg" />
                      </div>
                    </div>
                    <div className="col">
                      <div
                        key={contributor.id}
                        className="d-flex flex-column align-items-center justify-content-center"
                      >
                        <Avatar image={contributor.profilePic} md />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={be(MODULE_NAME, "description")}>
                        <div className={be(MODULE_NAME, "spacing", "md")} />
                        <div className="text-center">
                          <Headline bold component="h4" dark xs>
                            {contributor.name} {contributor.surname}
                          </Headline>
                          <div className={be(MODULE_NAME, "spacing", "sm")} />
                          <Text component="div" light medium>
                            {g(data, "handshake.subrole.name", "")}
                          </Text>
                        </div>
                        <div className={be(MODULE_NAME, "spacing", "lg")} />
                        <Text component="div" light medium textBreak>
                          <b>E&#8209;mail:</b> <br className="d-md-none" />
                          <a
                            className={be(MODULE_NAME, "link")}
                            href={`mailto:${g(contributor, "email", "")}`}
                            target="_blank"
                          >
                            {g(contributor, "email", "")}
                          </a>
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "xl")} />
          <Forms
            className="col-12"
            inputs={[
              {
                label: (
                  <div className="text-center">
                    <Text small>Domluvený rozsah práce </Text>
                    <br />
                    <Text light small>
                      (např. počet normostran, na kterých budete pracovat, množství ilustrací, které dodáte)
                    </Text>
                  </div>
                ),
                name: "description",
                className: "col-12 small bold mb-3",
                type: "textarea",
                required: true,
                disabled: isUserContributor
              },
              {
                label: (
                  <div className="text-center">
                    <Text small>Domluvená cena za rozsah</Text>
                  </div>
                ),
                name: "price",
                type: "number",
                className: "col-sm-4 offset-sm-4",
                required: true,
                disabled: isUserContributor,
                currency: true
              }
            ]}
            name={FormDuck.formTypes.handshake}
            onSetInput={this.onSetInput}
            onSubmit={this.onSubmit}
          >
            <div className="col-xs-12">
              <div className="mt-3 container">
                {(tips.handshake || []).map(t => (
                  <Tip key={t.id} text={t.text} />
                ))}
              </div>
              <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
                <Button medium type="submit">
                  {t(`modals.handshake.handshakeBtn${isSuggested ? 2 : 1}`)}
                </Button>
                {!isUserContributor && (
                  <div className="mt-3">
                    <Button medium onClick={this.generateContract} oval>
                      Vygenerovat smlouvu
                    </Button>
                  </div>
                )}
              </div>
              {isUserContributor && (
                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <Button onClick={this.rejectHandshake} white>
                    {t("modals.handshake.refuse")}
                  </Button>
                </div>
              )}
            </div>
          </Forms>
          {(showContract || (isSuggested && isUserContributor)) && (
            <div
              className="contract-muster"
              dangerouslySetInnerHTML={{
                __html: generateMuster({
                  ...data.handshake,
                  author,
                  contributor,
                  price: formData.price,
                  description: formData.description,
                  project: data.project
                })
              }}
            />
          )}
        </div>
      </div>
    ) : (
      <div className={bm(MODULE_NAME)}>
        <div className="container">
          <div className={be(MODULE_NAME, "title")}>
            <Headline bold component="h2" subheadline>
              {t(`modals.handshake.title${isSuggested || isAccepted ? 3 : 2}`)}
            </Headline>
          </div>
          <div className={be(MODULE_NAME, "desc")}>
            <div className={be(MODULE_NAME, "author")}>
              {authors.map(author => (
                <div
                  key={author.id}
                  className="d-flex flex-column text-center align-items-center justify-content-center"
                >
                  <Avatar image={author.profilePic} md />
                  <div className={be(MODULE_NAME, "spacing", "md")} />
                  <Headline bold component="h4" dark xs>
                    {author.name} {author.surname}
                  </Headline>
                </div>
              ))}
            </div>
            <div className={be(MODULE_NAME, "icons", !isSuggested && !isAccepted && "pending")}>
              {!isSuggested && !isAccepted ? (
                <Fragment>
                  <img alt="pending" className={be(MODULE_NAME, "pending", "clock")} src="/images/svg/clock.svg" />
                  <img alt="pending" className={be(MODULE_NAME, "pending")} src="/images/svg/pendingHands.svg" />
                </Fragment>
              ) : (
                <img alt="handshake" className={be(MODULE_NAME, "hands")} src="/images/svg/hands.svg" />
              )}
            </div>
            <div className={be(MODULE_NAME, "author")}>
              <div
                key={contributor.id}
                className="d-flex flex-column text-center align-items-center justify-content-center"
              >
                <Avatar image={contributor.profilePic} md />
                <div className={be(MODULE_NAME, "spacing", "md")} />
                <Headline bold component="h4" dark xs>
                  {contributor.name} {contributor.surname}
                </Headline>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "button", null, "text-center")}>
            <Button onClick={this.closeModal} sm>
              {t("modals.handshake.finish")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Handshake.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object.isRequired,
  handshakeStates: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tips: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

Handshake.defaultProps = {
  onClose: () => {},
  data: {},
  t: () => {}
};

const mapStateToProps = state => ({
  tips: EnumsDuck.getTips(state),
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  user: UserDuck.getUser(state),
  roles: EnumsDuck.getRoles(state),
  formData: FormDuck.getData(state, FormDuck.formTypes.handshake)
});

export default connect(mapStateToProps)(Handshake);
