import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Checkbox, ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";
import { remove } from "lodash";
import "./inputField.css";

import UploadFile from "../input/uploadFile/uploadFile";
import InputComponent from "../input/input";
import Select from "../input/select/select";
import Radio from "../button/radio/radio";
import TodosList from "../list/todos/todos";
import List from "../list/list";
import { bm, be } from "../../utils/bliss";
import { isMobile } from "../../utils/utils";

const MODULE_NAME = "InputField";

class InputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type
    };
  }

  handlePasswordType = () => {
    this.setState({
      type: this.state.type === "password" ? "text" : "password"
    });
  };

  handleCheckboxListChange = e => {
    const checkboxList = remove(this.props.value, item => item !== e.target.value);

    if (e.target.checked) {
      checkboxList.push(e.target.value);
    }
    this.props.onChange({
      target: { value: checkboxList, name: this.props.name }
    });
  };

  renderCheckboxList = () => {
    const { id, validationType, validationMessage, value, items, label } = this.props;

    return (
      <FormGroup className={bm(MODULE_NAME)} controlId={`${id || Math.random()}`} validationState={validationType}>
        {label && <ControlLabel>{label}</ControlLabel>}
        {items.map(item => (
          <Checkbox
            key={item.value}
            checked={value.indexOf(item.value) >= 0}
            disabled={item.disabled}
            onChange={this.handleCheckboxListChange}
            value={item.value}
          >
            <span className={be(MODULE_NAME, "checkbox")} />
            {item.label}
          </Checkbox>
        ))}
        <HelpBlock>{validationMessage}</HelpBlock>
      </FormGroup>
    );
  };

  render() {
    const { id, validationType, validationMessage, label, wrapperClassName, anchor, ...restProps } = this.props;

    switch (restProps.type) {
      case "checkbox-list":
        return this.renderCheckboxList();
      case "checkbox":
        return (
          <FormGroup className={bm(MODULE_NAME)} controlId={`${id || Math.random()}`} validationState={validationType}>
            <Checkbox {...restProps}>
              <span className={be(MODULE_NAME, "checkbox")} />
              {label}
            </Checkbox>
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );
      case "inline":
        return (
          <FormGroup className={bm(MODULE_NAME)} controlId={`${id || Math.random()}`} validationState={validationType}>
            {!isMobile() && (
              <div className="d-flex justify-content-center">
                <input
                  {...restProps}
                  className={`form-control ${restProps.className} ${restProps.type === "password" ? "has-append" : ""}`}
                  type={this.state.type}
                />
                {label}
              </div>
            )}
            {isMobile() && (
              <div className="d-flex justify-content-center" style={{ maxWidth: "200px", margin: "auto" }}>
                <input
                  {...restProps}
                  className={`form-control ${restProps.className} ${restProps.type === "password" ? "has-append" : ""}`}
                  type={this.state.type}
                />
                {label}
              </div>
            )}
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );
      case "inlinePopup":
        return (
          <FormGroup className={bm(MODULE_NAME)} controlId={`${id || Math.random()}`} validationState={validationType}>
            {!isMobile() && (
              <div className="d-flex" style={{ maxWidth: "300px" }}>
                <input
                  {...restProps}
                  className={`form-control ${restProps.className} ${restProps.type === "password" ? "has-append" : ""}`}
                  type={this.state.type}
                />
                {label}
              </div>
            )}
            {isMobile() && (
              <div className="d-flex" style={{ maxWidth: "200px" }}>
                <input
                  {...restProps}
                  className={`form-control ${restProps.className} ${restProps.type === "password" ? "has-append" : ""}`}
                  type={this.state.type}
                />
                {label}
              </div>
            )}
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      case "radio":
        return (
          <FormGroup controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <Radio {...restProps} />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      case "file":
        return (
          <FormGroup className={wrapperClassName} controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <UploadFile
              editable
              {...restProps}
              onFilesInsert={value =>
                restProps.onChange({
                  target: { value, name: restProps.name }
                })
              }
            />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      case "textarea":
        return (
          <FormGroup className={wrapperClassName} controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <InputComponent {...restProps} textArea />
            <FormControl.Feedback />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      case "select":
        return (
          <FormGroup controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <Select {...restProps} />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      case "customSelect":
        return (
          <FormGroup controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <List
              {...restProps}
              form
              onSelect={({ id }) => {
                let values = restProps.value || [];
                if (values.includes(id)) {
                  values = values.filter(valId => valId !== id);
                } else {
                  if (!Array.isArray(restProps.enabledItemIds) || restProps.enabledItemIds.includes(id)) {
                    values = [...values, id];
                  }
                }
                restProps.onChange({
                  target: {
                    value: values,
                    name: restProps.name
                  }
                });
              }}
              selectedItems={restProps.value}
              enabledItems={restProps.enabledItemIds}
            />
            <HelpBlock>{validationMessage}</HelpBlock>
            {restProps.footer ? restProps.footer : ""}
          </FormGroup>
        );

      case "list":
        return (
          <FormGroup className={wrapperClassName} controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <TodosList
              {...restProps}
              inputs={restProps.value || []}
              onDelete={data => console.log(data)}
              originalInputs={restProps.inputs}
            />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      case "number":
        return (
          <FormGroup className={wrapperClassName} controlId={`${id || Math.random()}`} validationState={validationType}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <div className="input-group mb-3">
              <input
                {...restProps}
                className={`form-control ${restProps.className}`}
                onChange={({ target }) => {
                  const value = /^(\d{1,},?\d{0,})/.exec(target.value)[1] || "";
                  restProps.onChange({
                    target: {
                      value,
                      name: target.name
                    }
                  });
                }}
                type="text"
                value={restProps.value || ""}
              />
              {restProps.currency && <span className={be(MODULE_NAME, "currency")}>Kč</span>}
            </div>
            <FormControl.Feedback />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );

      default:
        return (
          <FormGroup className={wrapperClassName} controlId={`${id || Math.random()}`} validationState={validationType}>
            {(anchor && <a name={anchor}/>) || ""}
            {label && <ControlLabel>{label}</ControlLabel>}
            <div className="input-group mb-3">
              <input
                {...restProps}
                className={`form-control ${restProps.className} ${restProps.type === "password" ? "has-append" : ""}`}
                type={this.state.type}
              />
              {restProps.currency && <span className={be(MODULE_NAME, "currency")}>Kč</span>}
              {restProps.type === "password" && (
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2" onClick={this.handlePasswordType}>
                    <i
                      className="fa fa-eye"
                      style={{
                        color: this.state.type === "password" ? "#000" : "blue"
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <FormControl.Feedback />
            <HelpBlock>{validationMessage}</HelpBlock>
          </FormGroup>
        );
    }
  }
}

InputField.propTypes = {
  entityType: PropTypes.oneOf(["phone", "email", "password", "checkbox", "none"]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validationMessage: PropTypes.string,
  validationType: PropTypes.oneOf([null, "error", "warning"])
};

export default InputField;
