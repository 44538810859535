import moment from "moment";

export let API;
export let PREVIEWS_API;
export let TOKEN_STORAGE_NAME;
export let FB_APP_ID;
export let COOKIE_NAME;
export let DISQUS_SHORT_NAME;
export let DISQUS_URL;
export const DISQUS_API_KEY = "kdxDHAEeq0cGqtyaGKgXYBROpxTTdDppHpzoP7Zg1EfwvGv3WALApSbkkdtzkH5F";

export const ADDRESS_ORG = "Albatros Media a.s.";
export const ADDRESS_ORG_STAFF = "Albatros Media a.s. (redakce Pointa)";
export const ADDRESS_STREET = "5. května 1746/22";
export const ADDRESS_CITY = "140 00 Praha 4";
export const ADDRESS_COMPLETE = ADDRESS_STREET + ", " + ADDRESS_CITY;
export const ADDRESS_ICO = "283 77 141";
export const ADDRESS_DIC = "CZ28377141";

export function minBirthDate() {
  return moment()
    .subtract(100, "years")
    .format("YYYY-01-01");
}

export function maxBirthDate() {
  return moment()
    .subtract(10, "years")
    .format("YYYY-01-01");
}

// export const CONTACT_PHONE = "734 592 087";

export const PROJECT_STATES = {
  FUNDING: "Financování",
  IN_PROGRESS: "Výroba"
};

export const PACKETA_API_KEY = process.env.REACT_APP_PACKETA_API_KEY;

function initialize() {
  switch (process.env.REACT_APP_ENV) {
    case "development":
    default:
      API = process.env.REACT_APP_API || "http://127.0.0.1:8000/api";
      PREVIEWS_API = process.env.REACT_APP_PREVIEWS_API || "http://127.0.0.1:8000/";
      TOKEN_STORAGE_NAME = "POINTA_TOKEN_DEV";
      FB_APP_ID = "169969630208951";
      COOKIE_NAME = "pointa.cz_dev";
      DISQUS_SHORT_NAME = "pointa-env-test";
      DISQUS_URL = "https://pointa.cz";
      break;

    case "staging":
      API = process.env.REACT_APP_API || "https://api.staging.pointa.cz/api";
      PREVIEWS_API = process.env.REACT_APP_PREVIEWS_API || "https://api.staging.pointa.cz/";
      TOKEN_STORAGE_NAME = "POINTA_TOKEN_STAGING";
      FB_APP_ID = "414432953013321";
      COOKIE_NAME = "pointa.cz_staging";
      DISQUS_SHORT_NAME = "pointa-env-staging";
      DISQUS_URL = "https://staging.pointa.cz";
      break;

    case "production":
      API = process.env.REACT_APP_API || "https://api.pointa.cz/api";
      PREVIEWS_API = process.env.REACT_APP_PREVIEWS_API || "https://api.pointa.cz/";
      TOKEN_STORAGE_NAME = "POINTA_TOKEN_PROD";
      FB_APP_ID = "414432953013321";
      COOKIE_NAME = "pointa.cz";
      DISQUS_SHORT_NAME = "pointa";
      DISQUS_URL = "https://pointa.cz";
      break;
  }
  return {
    API,
    PREVIEWS_API,
    FB_APP_ID,
    TOKEN_STORAGE_NAME
  };
}

export default initialize();
