import React, { Component } from "react";
import Get from "restful-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import "./projectStateRow.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Headline from "../../../../components/headline/headline";
import Button from "../../../../components/button/button";
import EnumsDuck from "../../../../redux/ducks/enums";
import StorageService from "../../../../services/storageService";
import WizardService from "../../../../services/wizardService";
import Text from "../../../../components/text/text";
import UserDuck from "../../../../redux/ducks/user";
import ProjectCard from "../projectCard/projectCard";
import MobileSlider from "../../../../components/mobileSlider/mobileSlider";
import Loader from "../../../../components/loader/loader";
import { showProject } from "../../../../utils/goTo";

const MODULE_NAME = "ProjectStateRow";

export const STATES_WHITE_LIST = {
  released: {
    name: "Vydáno"
  }
};

class ProjectStateRow extends Component {
  state = {
    loading: true,
    filters: {
      page: 1,
      order: "desc",
      limit: "16"
    }
  };

  componentWillMount() {
    const { dispatch } = this.props;
    let promises = [dispatch(EnumsDuck.setRoles()), dispatch(EnumsDuck.loadCatalog())];
    if (StorageService.hasToken()) {
      promises = [...promises, dispatch(UserDuck.setUserByToken("", true, false)), dispatch(EnumsDuck.setBindings())];
    }
    Promise.all(promises).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  renderRow = (key, projects) => {
    const { t } = this.props;
    return (
      <div key={key} className={be(MODULE_NAME, "row", false, "row")}>
        {!isEmpty(projects) && (
          <React.Fragment>
            <div className="d-flex justify-content-center w-100" style={{ padding: "0 10px" }}>
              <div className="col-md-10 text-center" style={{ paddingTop: "30px" }}>
                <Headline
                  greenReward
                  className={(be(MODULE_NAME, "rowHeading"), false, be(MODULE_NAME, "rowHeading", key))}
                >
                  Knihy vydané s Pointou
                </Headline>
                <div className={be(MODULE_NAME, "headlineStep", "medium")} />
                <div style={{ padding: "0 10px" }}>
                  <Text greenReward className={be(MODULE_NAME, "rowSubheading")}>
                    Podívej se na knihy, které už najdeš v knihkupectvích a kterým už jiní kolegové a kolegyně dali
                    život.
                  </Text>
                </div>
                <div className={be(MODULE_NAME, "headlineStep", "medium")} />
                <div className={be(MODULE_NAME, "headlineStep", "large")} />
              </div>
            </div>
            <div className="col-12">
              <MobileSlider
                variableWidth={true}
                responsive={[
                  {
                    breakpoint: 1900,
                    infinite: false,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 3,
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 920,
                    infinite: false,
                    settings: {
                      slidesToShow: 2.2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 480,
                    infinite: false,
                    settings: {
                      slidesToShow: 1.2,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                {projects.map(project => (
                  <div key={project.id} className="col-12 mobileSlider-slide">
                    <ProjectCard
                      className="col-12"
                      followed={project.genres.length > 1}
                      onClick={() => showProject(project.id)}
                      project={project}
                    />
                  </div>
                ))}
              </MobileSlider>
              {this.renderMoreButton(key, t)}
              {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
              {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderList = projectsGroups => {
    const output = [];

    Object.keys(STATES_WHITE_LIST).forEach(groupKey => {
      output.push(this.renderRow(groupKey, projectsGroups[groupKey]));
    });
    return output;
  };

  renderMoreButton = (key, t) => {
    return (
      <div className="text-center">
        <Button small smallRadius xl lightPrimary to={`/projects/${key}`}>
          {t("moreProjects")}
        </Button>
      </div>
    );
  };

  getType = ({ state, moonTarget }) => {
    const { projectStates } = this.props;
    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        if (!moonTarget) return "waitingForPresale";
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
        return "released";
    }
  };

  render() {
    const { projectStates } = this.props;

    if (isEmpty(projectStates)) return <Loader />;

    return (
      <Get
        path={`projects?page=${this.state.filters.page}&order=${this.state.filters.order}&limit=${this.state.filters.limit}&groupByState=true`}
        resolve={async projects => {
          const mapProject = async projectData => {
            const project = projectData;
            project.type = this.getType(project);
            return project;
          };
          const data = projects;
          return {
            ...data,
            released: await Promise.all(data.released.map(p => mapProject(p)))
          };
        }}
      >
        {(projects, { loading }) => {
          if (this.state.loading || loading) return <Loader />;
          return (
            <React.Fragment>
              <div style={{ background: "#ffffff", margin: "0 -106%", padding: "0 100%" }} className="NewLandingPage">
                {this.renderList(projects)}
              </div>
            </React.Fragment>
          );
        }}
      </Get>
    );
  }
}

ProjectStateRow.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ProjectStateRow);
