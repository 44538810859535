import React from "react";

import "./blog.css";
import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Link from "../../../../components/link/link";
import Text from "../../../../components/text/text";
import Headline from "../../../../components/headline/headline";

const MODULE_NAME = "Blog";

const Blog = ({ posts }) => {
  const styleObj = {
    textAlign: "left"
  };
  return (
    <div className={be(MODULE_NAME, "container")}>
      {posts.slice(0, 1).map((item, index) => (
        <div className={be(MODULE_NAME, "wrapper")} key={item.id}>
          <a href={item.link} style={{ textDecoration: "none" }}>
            <div className={be(MODULE_NAME, "photoSide")}>
              <img
                className={be(MODULE_NAME, "imgLeft")}
                alt="blogPhoto"
                src={item._embedded["wp:featuredmedia"][0].media_details.sizes.medium_large.source_url}
              />
            </div>
            <div className={be(MODULE_NAME, "info")}>
              <div>
                {isMobile() && (
                  <div className="mb-2" style={{ textAlign: "left" }}>
                    <Text small bold greenReward>
                      {item.title.rendered}
                    </Text>
                  </div>
                )}
                {!isMobile() && (
                  <div
                    className="mb-4 pb-3"
                    style={{ display: "flex", justifyContent: "flex-start", textAlign: "left" }}
                  >
                    <Headline subheadline bold greenReward>
                      {item.title.rendered}
                    </Headline>
                  </div>
                )}
              </div>
              {isMobile() && (
                <Text lighter greenReward>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.content.rendered.substring(0, item.content.rendered.lastIndexOf(" ", 50)) + "..."
                    }}
                    style={styleObj}
                  />
                </Text>
              )}
              {!isMobile() && (
                <Text small lighter greenReward>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.content.rendered.substring(0, item.content.rendered.lastIndexOf(" ", 320)) + "..."
                    }}
                    style={styleObj}
                  />
                </Text>
              )}
            </div>
            <div style={{ width: "65%", marginLeft: "35%" }} className={be(MODULE_NAME, "moreButton")}>
              <Link bold underline leftSide to={item.link}>
                Číst více &#8594;
              </Link>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Blog;
