import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { syncHistoryWithStore, routerMiddleware, LOCATION_CHANGE } from "react-router-redux";
import createHistory from "history/createBrowserHistory";

import UIDuck from "./ducks/ui";
import FormDuck from "./ducks/forms";
import UserDuck from "./ducks/user";
import EnumsDuck from "./ducks/enums";
import WizardDuck from "./ducks/wizard";
import ThreadDuck from "./ducks/thread";
import CartDuck from "./ducks/cart";

const routerReducer = (state = { pathname: "", search: "", hash: "", key: "" }, { type, payload } = {}) => {
  if (type === LOCATION_CHANGE) {
    return { ...state, ...payload };
  }

  return state;
};

const combineDucks = (...ducks) =>
  combineReducers(
    ducks.reduce((root, duck) => ({ ...root, [duck.name]: duck.reducer }), {
      Router: routerReducer
    })
  );

const rootReducer = combineDucks(UIDuck, FormDuck, UserDuck, EnumsDuck, WizardDuck, ThreadDuck, CartDuck);

const initialState = {};

const reduxDevToolExtension =
  window.devToolsExtension && process.env.NODE_ENV === "development" ? window.devToolsExtension() : f => f;

export const history = createHistory();

export const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(reduxThunk, routerMiddleware(history)), reduxDevToolExtension)
);

syncHistoryWithStore(history, store, {
  selectLocationState: state => state.Router
});
