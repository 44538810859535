/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { get as g, isEmpty } from "lodash";

import { Modal } from "react-bootstrap";

import { be } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";

import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

import AuthorInfoFinancing from "./authorInfoFinancing";
import ShowInfoTable from "./showInfoTable";

const MODULE_NAME = "ProjectDetailScreen";

class FinancingState extends Component {
  state = {
    modal: false
  };

  showModal = () => {
    this.setState({
      modal: true
    });
  };

  hideModal = () => {
    this.setState({
      modal: false
    });
  };

  render() {
    const { project, scrollToMyRef } = this.props;
    const { modal } = this.state;

    return (
      <div>
        <div>
          {" "}
          <Modal animation={false} onHide={this.handleClose} show={modal}>
            <Modal.Header>
              <img className="close-btn" onClick={this.hideModal} src="/images/svg/cross--black.svg" alt="x" />
            </Modal.Header>
            <AuthorInfoFinancing project={project} extended />
          </Modal>
        </div>
        <div className="row">
          {!isMobile() && (
            <div className="col-md-3">
              {" "}
              <AuthorInfoFinancing project={project} onClick={this.showModal} />{" "}
            </div>
          )}
          <div className="col-md-9">
            <Headline fundingPage greenReward>
              {project.name}
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className={be(MODULE_NAME, "genres")}>
              {g(project, "genres", [])
                .filter(gen => !isEmpty(gen))
                .map((genre, i) => (
                  <div key={genre.id} className={be(MODULE_NAME, "genre")}>
                    <Text bold greenReward>
                      {genre.name}
                      {i === project.genres.length - 1 ? "" : ","}{" "}
                    </Text>
                  </div>
                ))}
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <Text greenReward>{project.bibliographyDescription}</Text>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
          </div>
        </div>
        <div className="row">
          <ShowInfoTable project={project} scrollToMyRef={scrollToMyRef} showOnMobile />
          <ShowInfoTable project={project} scrollToMyRef={scrollToMyRef} showOnMobile={false} />
        </div>
      </div>
    );
  }
}

export default FinancingState;
