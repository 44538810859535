import React, { Component } from "react";
import PropTypes from "prop-types";
import { get as g } from "lodash";

import "./tabsComponent.sass";
import { be, bm } from "../../utils/bliss";

const MODULE_NAME = "Tabs";

class TabsComponent extends Component {
  state = {
    tab: 0,
    id: Math.random()
  };

  onTabChange = tab => {
    this.setState({
      tab
    });
  };

  render() {
    const { data } = this.props;
    const { tab, id } = this.state;
    const activeDataContent = g(data, `${tab}.content`, null);

    return (
      <div className={bm(MODULE_NAME)}>
        <div key={`tab-${id}`} className={be(MODULE_NAME, "headerContainer")}>
          {data.map((d, idx) => (
            <div
              key={idx}
              className={be(MODULE_NAME, "header", tab === idx ? "active" : "")}
              onClick={() => this.onTabChange(idx)}
            >
              {d.header}
            </div>
          ))}
        </div>
        <div className={be(MODULE_NAME, "body")}>{activeDataContent}</div>
      </div>
    );
  }
}

TabsComponent.defaultProps = {
  defaultTab: 0,
  data: []
};

TabsComponent.propTypes = {
  data: PropTypes.array,
  defaultTab: PropTypes.number
};

export default TabsComponent;
