import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Radio, FormGroup } from "react-bootstrap";

class RadioComponent extends Component {
  onChange = ({ target }) => {
    const { onChange, name } = this.props;

    onChange({
      target: {
        value: target.value,
        name
      }
    });
  };

  render() {
    const { name, onChange, options, ...restProps } = this.props;

    return (
      <FormGroup {...restProps} onChange={this.onChange}>
        {options.map(opt => {
          const { label, ...restOptProps } = opt;
          return (
            <Fragment>
              <Radio inline name="radioGroup" {...restOptProps}>
                {opt.label}
              </Radio>
              &nbsp;
            </Fragment>
          );
        })}
      </FormGroup>
    );
  }
}

RadioComponent.defaultProps = {
  options: [],
  name: "",
  onChange: () => {}
};

RadioComponent.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array
};

export default RadioComponent;
