import storageService from "./storageService";

const SEARCH_EXPRESSION_STORAGE_KEY = "search_Expression";

export class SearchStorageService {
  static storeSearchExpression(expression) {
    storageService.set(SEARCH_EXPRESSION_STORAGE_KEY, JSON.stringify(expression), true);
  }

  static getSearchExpression() {
    const expression = storageService.get(SEARCH_EXPRESSION_STORAGE_KEY, true);

    if ("string" === typeof expression) {
      try {
        return JSON.parse(expression);
      } catch (e) {
        console.error("Error getting stored search expression", e);
      }
    }

    return null;
  }

  static clearSearchExpression() {
    storageService.remove(SEARCH_EXPRESSION_STORAGE_KEY, true);
  }
}
