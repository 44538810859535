import { push } from "react-router-redux";
import { get as g, isEmpty } from "lodash";

import { createActionTypes } from "../createActionTypes";
import ThreadService from "../../services/threadService";
import WizardService from "../../services/wizardService";
import WizardDuck from "./wizard";

const ThreadDuck = {
  name: "Thread"
};

const initialState = {
  thread: {},
  loading: {
    thread: true
  }
};

export const actionTypes = createActionTypes(
  {
    setThread: "set_thread",
    clearThread: "clear_thread",
    setLoading: "set_loading",
    removeComment: "remove_comment"
  },
  ThreadDuck.name
);

ThreadDuck.reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setThread:
      return {
        ...state,
        thread: action.payload
      };

    case actionTypes.clearThread:
      return {
        ...state,
        thread: action.payload
      };

    case actionTypes.setLoading:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.entity]: action.payload
        }
      };

    default:
      return state;
  }
};

ThreadDuck.getThreadByProjectId = (projectId = "", showLoading = true) => dispatch => {
  if (showLoading) {
    dispatch(ThreadDuck.setLoading("thread", true));
  }

  ThreadService.getThreadByProjectId(projectId).then(data => {
    dispatch({
      type: actionTypes.setThread,
      payload: data
    });
    if (showLoading) {
      dispatch(ThreadDuck.setLoading("thread", false));
    }
  });
};

ThreadDuck.createComment = (threadId, data = {}) => ThreadService.createComment(threadId, data);

ThreadDuck.setLoading = (entity, isLoading) => ({
  type: actionTypes.setLoading,
  payload: isLoading,
  entity
});

ThreadDuck.clearThread = () => ({
  type: actionTypes.clearThread,
  payload: {}
});

ThreadDuck.deleteComment = commentId => ThreadService.deleteComment(commentId);

const getOwnState = state => state[ThreadDuck.name] || initialState;
ThreadDuck.getThread = state => g(getOwnState(state), "thread", {});
ThreadDuck.isThreadLoading = state => g(getOwnState(state), "loading.thread", false);

export default ThreadDuck;
