import React from "react";
import PropTypes from "prop-types";
import GA from "./ga";

export default class GaSend extends React.Component {
  render() {
    return <span onClick={this.onClickHandler}>{this.props.children}</span>;
  }

  onClickHandler = () => {
    GA.sendEvent({
      eventCategory: this.props.category,
      eventAction: this.props.action,
      eventLabel: this.props.label
    });
  };

  set(event) {
    GA.sendEvent({
      category: event.category
    });
  }
}

GaSend.propTypes = {
  action: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
