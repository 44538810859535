import { get as g } from "lodash";

import { createActionTypes } from "../createActionTypes";
import EnumsService from "../../services/enumsService";

const EnumsDuck = {
  name: "Enums"
};

const initialState = {
  genres: {},
  tags: [],
  tooltips: {},
  roles: {},
  registrationRoles: {
    author: "author",
    reader: "reader",
    contributor: "contributor"
  },
  bindings: [],
  projectStates: {},
  handshakeStates: {},
  units: []
};

const actionTypes = createActionTypes(
  {
    setCatalog: "set_catalog".toUpperCase(),
    setBindings: "set_bindings".toUpperCase(),
    setRoles: "set_roles".toUpperCase(),
    addTag: "add_tag".toUpperCase()
  },
  EnumsDuck.name
);

EnumsDuck.reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.addTag:
      return {
        ...state,
        tags: [...state.tags, action.payload]
      };

    case actionTypes.setRoles:
      return {
        ...state,
        roles: action.payload
      };

    case actionTypes.setCatalog:
      return {
        ...state,
        ...action.payload,
        roles: state.roles
      };

    case actionTypes.setBindings:
      return {
        ...state,
        bindings: action.payload
      };

    default:
      return state;
  }
};

EnumsDuck.addTag = (tag = {}) => ({
  type: actionTypes.addTag,
  payload: tag
});

EnumsDuck.setRoles = () => dispatch =>
  EnumsService.getRoles().then(data => {
    dispatch({
      type: actionTypes.setRoles,
      payload: data
    });
  });

EnumsDuck.createTag = text => dispatch =>
  new Promise(res => {
    EnumsService.createTag(text).then(newTag => {
      dispatch(EnumsDuck.addTag(newTag));
      res(newTag);
    });
  });

EnumsDuck.loadCatalog = () => dispatch =>
  EnumsService.getCatalog().then(data => {
    dispatch({
      type: actionTypes.setCatalog,
      payload: {
        ...data,
        tooltips: g(data, "tooltips", []).reduce((res, tooltip) => {
          const locationTooltips = res[tooltip.section] || [];
          return {
            ...res,
            [tooltip.section]: [...locationTooltips, tooltip]
          };
        }, {})
      }
    });
  });

EnumsDuck.setBindings = () => dispatch =>
  EnumsService.getBindings().then(data => {
    dispatch({
      type: actionTypes.setBindings,
      payload: data
    });
  });

const getOwnState = state => g(state, EnumsDuck.name, initialState);
EnumsDuck.getGenres = state => getOwnState(state).genres;
EnumsDuck.getTags = state => getOwnState(state).tags;
EnumsDuck.getTips = state => getOwnState(state).tooltips;
EnumsDuck.getRoles = state => getOwnState(state).roles;
EnumsDuck.getRegistrationRoles = state => getOwnState(state).registrationRoles;
EnumsDuck.getBindings = state => g(getOwnState(state), "bindings.data", []);
EnumsDuck.getProjectStates = state => getOwnState(state).projectStates;
EnumsDuck.getHandhakeStates = state => getOwnState(state).handshakeStates;
EnumsDuck.getUnits = state => getOwnState(state).units;

EnumsDuck.getStateByLabel = (state, label) => {
  const { projectStates } = getOwnState(state);
  return Object.keys(projectStates).find(key => projectStates[key].name.toLowerCase() === label.toLowerCase());
};

export default EnumsDuck;
