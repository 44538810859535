import React from "react";
import { translate } from "react-i18next";

import "./card.css";

import { be } from "../../utils/bliss";
import Link from "../link/link";
import Text from "../text/text";

const MODULE_NAME = "Card";

const Card = ({ t, confirm }) =>
  confirm ? (
    <div className="col-lg-4 d-flex justify-content-center  justify-content-lg-end align-items-center">
      <div className={be(MODULE_NAME, "main")}>
        <Text inverted small>
          Odeslal jsi svůj profil na posouzení Pointou. Brzy se ti ozveme.
        </Text>
      </div>
    </div>
  ) : (
    <div className="col-lg-4 d-flex justify-content-center  justify-content-lg-end align-items-center">
      <div className={be(MODULE_NAME, "main")}>
        <Text inverted bold small>
          {t("dashboard.notPublic")}
        </Text>
        <Text inverted small>
          Pro zveřejnění{" "}
          <Link to="/profile" underline white bold>
            zde
          </Link>{" "}
          zkontroluj všechny údaje a následně nám ho pošli k posouzení.
        </Text>
      </div>
    </div>
  );

export default translate("translations")(Card);
