import React from "react";
import { get as g, isEmpty } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";

import { be } from "../../../../utils/bliss";

import EnumsDuck from "../../../../redux/ducks/enums";
import UIDuck from "../../../../redux/ducks/ui";
import UserDuck from "../../../../redux/ducks/user";
import WizardDuck from "../../../../redux/ducks/wizard";

import DetailBubble from "../../../../components/bubbles/detailBubble/detailBubble";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

import imageHow1 from "../img/info--info.svg";
import imageHow2 from "../img/info--mail.svg";
import imageHow3 from "../img/info--handshake.svg";

const MODULE_NAME = "ProjectDetailScreen";

const GetMinimumContent = ({
  user,
  project = {},
  data = [],
  history,
  handshakeStates,
  dispatch,
  t,
  match: { params }
}) => {
  const { handshakesBySubroleId } = project;

  const confirmHandshake = (handshake = {}) => {
    // if (g(handshakeStates, 'requested.name', '') === handshake.state) {
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
        handshake,
        project
      })
    );
    // }
  };

  const removeHandshake = (handshake = {}) => {
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.removeHandshakeText"),
        callback: () => {
          dispatch(UserDuck.removeHandshake(params.id, handshake.id)).then(() => {
            window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
            dispatch(WizardDuck.setProjectById(params.id));
            dispatch(WizardDuck.setDetail(params.id, user.id));
          });
        }
      })
    );
  };

  return (
    <div className={be(MODULE_NAME, "parameters")}>
      <div className={be(MODULE_NAME, "spacing", "lg")} />
      <Headline bold component="h3" light xs>
        Jak to bude dál probíhat
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className={be(MODULE_NAME, "how")}>
            <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
            <div className="d-flex flex-column w-100">
              <Text bold>1. Vyber si kolegu z nabídky</Text>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className={be(MODULE_NAME, "how")}>
            <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow2} />
            <div className="d-flex flex-column w-100">
              <Text bold>2. Napište si mimo platformu a domluvte se na detailech</Text>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className={be(MODULE_NAME, "how")}>
            <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow3} />
            <div className="d-flex flex-column w-100">
              <Text bold>3. Plácněte si na spolupráci</Text>
            </div>
          </div>
        </div>
      </div>
      <div className={be(MODULE_NAME, "spacing", "lg")} />
      <Headline bold component="h3" light xs>
        Povinní kolegové pro vydání knihy
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      {data.map(d => {
        const handshakes = (handshakesBySubroleId || {})[d.id] || [];
        return (
          <DetailBubble
            key={d.id}
            cancelHandshake={removeHandshake}
            complete={!!handshakes.find(hs => hs.state === g(handshakeStates, "accepted.name", ""))}
            confirmHandshake={confirmHandshake}
            description={d.description}
            handshakes={handshakes}
            icon="/images/svg/required.svg"
            iconLabel="Nezbytný kolega"
            label="Najít kolegu"
            locked={!project.printer}
            onClick={() => {
              if (isEmpty(g(project, "handshakes", []))) {
                dispatch(
                  UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
                    text:
                      "Jste si jistí, že máte vhodně zvolené žánry a tagy? Po poptání kolegů již nebude možnost to změnit...",
                    callback: () => {
                      history.push(`/contributors/${project.id}?subroles=[${d.id}]`);
                    }
                  })
                );
              } else {
                history.push(`/contributors/${project.id}?subroles=[${d.id}]`);
              }
            }}
            title={d.name}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  user: UserDuck.getUser(state)
});

export default compose(translate("translations"), connect(mapStateToProps), withRouter)(GetMinimumContent);
