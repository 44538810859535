import React, { Component } from "react";
import axios from "axios";
import { isMobile } from "../../utils/utils";

import Screen from "../../components/screen/screen";
import Header from "../../components/header/header";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import Link from "../../components/link/link";

class PostView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {}
    };
  }

  componentWillMount() {
    const slug = this.props.match.params.slug;
    axios.get(`https://blog.pointa.cz/wp-json/wp/v2/posts?slug=${slug}`).then(post => {
      this.setState({
        post: post.data[0]
      });
    });
  }

  render() {
    let build;
    const {
      history,
      location: { search }
    } = this.props;
    const isUser = !!search;
    if (this.state.post.title) {
      build = (
        <Screen
          className="/src/pages/newLandingPage/newLandingPage"
          header={<Header history={history} isUser={isUser} landing />}
        >
          <div>
            <div style={{ paddingBottom: "10px" }}>
              <Link to="/knowledgebase" primary>
                {" "}
                Průvodce vydáním knihy
              </Link>{" "}
              <Text greenReward small>
                <img
                  src="/images/svg/left.svg"
                  style={{ height: "20px", width: "20px", transform: "rotate(268deg)" }}
                />{" "}
                {this.state.post.knowledgebase_section.label}
              </Text>
            </div>
            <div style={{ paddingBottom: "30px" }}>
              {!isMobile() ? (
                <Headline greenReward bold fundingPage>
                  {this.state.post.title.rendered}
                </Headline>
              ) : (
                <Headline greenReward bold semiMd>
                  {this.state.post.title.rendered}
                </Headline>
              )}
            </div>
            <div
              style={{
                background: "#ffffff",
                margin: "0 -1500px",
                padding: "30px 1500px 30px 1500px",
                height: "auto",
                minHeight: "550px"
              }}
            >
              <div style={{ maxWidth: "620px", margin: "0 auto" }}>
                <div>
                  <Text greenReward small>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.post.content.rendered
                      }}
                    />
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </Screen>
      );
    } else {
      build = <div />;
    }
    return build;
  }
}

export default PostView;
