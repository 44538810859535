/* global dataLayer */
import React, { Component } from "react";
import Get from "restful-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import _map from "lodash/map";
import _take from "lodash/take";

import "./projectsPage.css";

import { isMobile } from "../../utils/utils";
import { be } from "../../utils/bliss";
import SelectComponent from "../../components/input/select/select";
import Headline from "../../components/headline/headline";
import Button from "../../components/button/button";
import Screen from "../../components/screen/screen";
import EnumsDuck from "../../redux/ducks/enums";
import UIDuck from "../../redux/ducks/ui";
import WizardDuck from "../../redux/ducks/wizard";
import StorageService from "../../services/storageService";
import WizardService from "../../services/wizardService";
import Header from "../../components/header/header";
import Text from "../../components/text/text";
import UserDuck from "../../redux/ducks/user";
import ProjectCard from "../landing/components/projectCard/projectCard";
import MobileSlider from "../../components/mobileSlider/mobileSlider";
import Loader from "../../components/loader/loader";
import { showProject } from "../../utils/goTo";

const MODULE_NAME = "ProjectsPage";

export const STATES_WHITE_LIST = {
  released: {
    name: "Vydáno",
    label: "Vydané knihy"
  },
  funding: {
    name: "Financování",
    label: "Přispěj na vznik knihy"
  },
  inProgress: {
    name: "Výroba",
    label: "Knihy ve výrobě"
  },
  proposal: {
    name: "V šuplíku",
    label: "Hledám kolegy"
  }
  // failed: {
  //     name: 'Neúspěšný',
  //     label: 'Neúspěšný'
  // }
};

class ProjectsPage extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.state = {
      loading: true,
      filters: {
        page: 1,
        order: "desc",
        limit: "16"
      },
      isActive: true,
      showSuccessPayment: params.get("success") === "true",
      showErrorPayment: params.get("success") === "false"
    };
  }

  componentWillMount() {
    const { dispatch, history } = this.props;
    let promises = [dispatch(EnumsDuck.setRoles()), dispatch(EnumsDuck.loadCatalog())];
    if (StorageService.hasToken()) {
      promises = [...promises, dispatch(UserDuck.setUserByToken("", true, false)), dispatch(EnumsDuck.setBindings())];
    }
    Promise.all(promises).then(() => {
      this.setState({
        loading: false
      });
    });
    if (this.state.showSuccessPayment) {
      dispatch(UIDuck.openModal(UIDuck.modalTypes.successfulPayment));
      setTimeout(() => {
        history.push(window.location.href.length - 13);
      }, 500);
    } else if (this.state.showErrorPayment) {
      dispatch(UIDuck.openModal(UIDuck.modalTypes.unsuccessfulPayment));
      setTimeout(() => {
        history.push(window.location.href.length - 13);
      }, 500);
    }
  }

  renderRow = (key, projects) => {
    const { t } = this.props;
    return (
      <div key={key} className={be(MODULE_NAME, "row", false, "row")}>
        {!isEmpty(projects) && (
          <React.Fragment>
            <div className="d-flex justify-content-center w-100" style={{ padding: "0 10px" }}>
              <div className="col-md-10 text-center">
                <Headline
                  greenReward
                  className={(be(MODULE_NAME, "rowHeading"), false, be(MODULE_NAME, "rowHeading", key))}
                >
                  {t(`projectsPageStates.${key}.title`)}
                </Headline>
                <div className={be(MODULE_NAME, "headlineStep", "medium")} />
                <div style={{ padding: "0 10px" }}>
                  <Text greenReward className={be(MODULE_NAME, "rowSubheading")}>
                    {t(`projectsPageStates.${key}.desc`)}
                  </Text>
                </div>
                <div className={be(MODULE_NAME, "headlineStep", "large")} />
              </div>
            </div>
            <div className="col-12">
              <MobileSlider
                variableWidth={true}
                responsive={[
                  {
                    breakpoint: 1900,
                    infinite: false,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 3,
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 920,
                    infinite: false,
                    settings: {
                      slidesToShow: 2.2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 480,
                    infinite: false,
                    settings: {
                      slidesToShow: 1.2,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                {projects.map(project => (
                  <div key={project.id} className="col-12 mobileSlider-slide">
                    <ProjectCard
                      className="col-12"
                      followed={project.genres.length > 1}
                      onClick={() => showProject(project.id)}
                      project={project}
                    />
                  </div>
                ))}
              </MobileSlider>
              {this.renderMoreButton(key, t)}
              {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
              {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderList = projectsGroups => {
    const output = [];

    Object.keys(STATES_WHITE_LIST).forEach(groupKey => {
      output.push(this.renderRow(groupKey, projectsGroups[groupKey]));
    });
    return output;
  };

  renderMoreButton = (key, t) => {
    return (
      <div className="text-center">
        <Button small smallRadius xl lightPrimary to={`/projects/${key}`}>
          {t("moreProjects")}
        </Button>
      </div>
    );
  };

  getType = ({ state, moonTarget }) => {
    const { projectStates } = this.props;
    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        if (!moonTarget) return "waitingForPresale";
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
        return "released";
    }
  };

  handleFilters = ({ target }) => {
    const {
      filters: { page }
    } = this.state;

    this.setState({
      filters: {
        ...this.state.filters,
        [target.name]: target.value,
        page: target.name === "genres" && page > 1 ? 1 : page
      }
    });
  };

  renderFilters() {
    const { t, projectStates, genres } = this.props;
    const { filters } = this.state;

    return (
      <div className={be(MODULE_NAME, "filters")}>
        <div className="row">
          <div className="col-sm pb-5">
            <Headline page>{t("header.books")}</Headline>
          </div>
        </div>
        <div className="row d-flex justify-content-between w-100 ml-0">
          <div className="pull-left">
            <div className={be(MODULE_NAME, "state", "first")}>
              <Button primary={this.state.isActive} black lowerCase to="/projects">
                Všechny
              </Button>
              {Object.keys(projectStates)
                .filter(st => STATES_WHITE_LIST.hasOwnProperty(st))
                .map(st => {
                  const state = projectStates[st];
                  return (
                    <Button
                      key={state.name}
                      black
                      lowerCase
                      onClick={() => this.handleStateChange(st)}
                      primary={state.name === filters.state ? true : undefined}
                    >
                      {STATES_WHITE_LIST[st].label}
                    </Button>
                  );
                })}
            </div>
          </div>
          <div className="pull-right mb-5">
            <div className={be(MODULE_NAME, "select")}>
              <SelectComponent
                classNamePrefix="select-box"
                clearable
                isMulti
                name="genres"
                onChange={this.handleFilters}
                options={genres.map(genre => ({
                  value: genre.id,
                  label: genre.name
                }))}
                placeholder={t("dashboard.genresPlaceholder")}
                value={filters.genres}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleStateChange(newState) {
    const {
      match: {
        params: { state }
      }
    } = this.props;

    if (state !== newState) {
      const newStateName = STATES_WHITE_LIST[newState].name;
      this.setState(
        {
          loading: true,
          filters: {
            ...this.state.filters,
            page: 1,
            state: newStateName
          }
        },
        () => this.props.history.push(`/projects/${newState}`)
      );
    }
  }

  render() {
    const {
      history,
      location: { search }
    } = this.props;
    const isUser = !!search;

    return (
      <Screen
        className="/src/pages/newLandingPage/newLandingPage"
        header={<Header history={history} isUser={isUser} landing />}
      >
        <Get
          path={`projects?page=${this.state.filters.page}&order=${this.state.filters.order}&limit=${this.state.filters.limit}&groupByState=true`}
          resolve={async projects => {
            const data = projects;
            const mapProject = async project => {
              const projectData = project;
              projectData.type = this.getType(projectData);
              return projectData;
            };
            dataLayer.push({
              event: "viewCategory",
              content_name: "Knihy",
              content_category: `Knihy`,
              content_ids: _map(_take(data.released, 6), "id")
            });
            return {
              ...data,
              released: await Promise.all(data.released.map(p => mapProject(p))),
              funding: await Promise.all(data.funding.map(p => mapProject(p)))
            };
          }}
        >
          {(projects, { loading }) => {
            if (this.state.loading || loading) return <Loader />;
            return (
              <React.Fragment>
                <div>
                  {this.renderFilters()}
                  <div
                    style={{ background: "#ffffff", margin: "0 -106%", padding: "0 100%" }}
                    className="NewLandingPage"
                  >
                    {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                    {isMobile() && <div style={{ height: "29px" }} />}
                    {this.renderList(projects)}
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Get>
      </Screen>
    );
  }
}

ProjectsPage.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  projects: WizardDuck.getProjects(state),
  projectStates: EnumsDuck.getProjectStates(state),
  genres: Object.values(EnumsDuck.getGenres(state)),
  projectsPagesCount: WizardDuck.getProjectsPagesCount(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ProjectsPage);
