import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Disqus from "disqus-react";
import { isEmpty } from "lodash";

import { DISQUS_SHORT_NAME, DISQUS_URL, DISQUS_API_KEY } from "../../services/variables";
import UserDuck from "../../redux/ducks/user";
import Link from "../link/link";
import DisqusExtended from "./DisqusExtended";
import "./DisqusComponent.css";

class DisqusComponent extends React.Component {
  getConfig = () => {
    const { remote_auth_s3, project } = this.props;
    return {
      identifier: `${DISQUS_URL}/project/${project.id}/`,
      url: `${DISQUS_URL}/project/${project.id}/`,
      title: project.name,
      api_key: DISQUS_API_KEY,
      remote_auth_s3: this.props.isSsoValid ? remote_auth_s3 : undefined
    };
  };

  componentWillMount() {
    const { dispatch, isSsoValid } = this.props;
    if (!isSsoValid) {
      dispatch(UserDuck.setUserSso());
    }
  }

  resetDisqus = nextProps => {
    console.log("window.DISQUS.reset");
    window.DISQUS.reset({
      reload: true,
      config() {
        this.page.remote_auth_s3 = nextProps.remote_auth_s3;
      }
    });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!this.props.isSsoValid && nextProps.isSsoValid) {
      // Reload the plugin when there's a user available
      if (window && window.DISQUS) {
        this.resetDisqus(nextProps);
      }
      setTimeout(nextProps => this.resetDisqus(nextProps), 1000);
    }
    return true;
  }

  render() {
    const disqusExtended = <DisqusExtended config={this.getConfig()} shortname={DISQUS_SHORT_NAME} />;
    const showOverlayMessage = isEmpty(this.props.user);
    return (
      <div className="article">
        {isEmpty(this.props.user) ? (
          <div className="DisqusComponent">
            <div className="DisqusComponent-message">
              Pro vstup do diskuze se musíš nejprve přihlásit do svého účtu v Pointě{" "}
              <Link underline to="/login">
                zde
              </Link>
              .
            </div>
            {disqusExtended}
          </div>
        ) : (
          disqusExtended
        )}
      </div>
    );
  }
}

DisqusComponent.defaultProps = {
  project: {}
};

DisqusComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.object,
  remote_auth_s3: PropTypes.string
};

const mapStateToProps = state => ({
  isSsoValid: UserDuck.isSsoValid(state),
  user: UserDuck.getUser(state),
  remote_auth_s3: UserDuck.getUserSso(state).remote_auth_s3
});

export default connect(mapStateToProps)(DisqusComponent);
