import { isEmpty, get as g, uniqBy } from "lodash";
import moment from "moment";

import { FB_APP_ID, PREVIEWS_API } from "../services/variables";
import { Helmet } from "react-helmet";
import React from "react";

export const transformPrice = (price = "", toFixedIndex = 0) => {
  price = `${price}`;
  return `${parseFloat(price).toFixed(toFixedIndex)}`.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getQueryFromObj = (obj = {}) => {
  const str = Object.keys(obj).reduce((res, key) => {
    const val = obj[key];
    if (!val) {
      return res;
    }
    if (Array.isArray(val)) {
      if (isEmpty(val)) {
        return res;
      }
      return `${res}${res ? "&" : ""}${key}[]=${val.join(`&${key}[]=`)}`;
    }
    return `${res}${res ? "&" : ""}${key}=${val}`;
  }, "");
  // console.log(obj, str ? `?${str}` : str)
  return str ? `?${str}` : str;
};

export const getQueryObjFromString = (str = "") => {
  if (!str) {
    return {};
  }
  return (str.split("?")[1] || "").split("&").reduce((res, entity) => {
    const entities = entity.split("=");
    let val = entities[1];
    if (val.includes("[")) {
      val = /\[(.*)\]/
        .exec(val)[1]
        .split(",")
        .map(v => v);
    }
    return {
      ...res,
      [entities[0]]: val
    };
  }, {});
};

export function getProjectProgress(project = {}, projectStates = {}, required = [], handshakesStates = {}) {
  required = required.filter(r => !!r);
  let progress = 0;

  if (project.state === g(projectStates, "proposal.name", "")) {
    if (project.name) {
      progress += 5;
    }
    if (project.genres && !isEmpty(project.genres)) {
      progress += 5;
    }
    if (project.tags && !isEmpty(project.tags)) {
      progress += 5;
    }
    if (project.annotation) {
      progress += 5;
    }
    if (project.printer && !isEmpty(project.printer)) {
      progress += 35;
    }
    const isRequiredFullfilled = required.reduce(
      (isDone, subrole) =>
        isDone &&
        !!g(project, "handshakes", []).find(handshake => {
          const { subrole: hsSubrole, state } = handshake;

          return state === g(handshakesStates, "accepted.name", "") && hsSubrole.id === subrole.id;
        }),
      true
    );
    if (isRequiredFullfilled) {
      progress += 35;
    }
  } else if (project.state === g(projectStates, "inProgress.name", "")) {
    progress = g(project, "projectChecklist", []).reduce((res, c, idx, arr) => {
      if (c.deliveredAt) {
        return res + 100 / arr.length;
      }
      return res;
    }, progress);
  } else if (project.state === g(projectStates, "funding.name", "")) {
    if (project.moonTarget) {
      progress = (g(project, "moonCollected", 0) / g(project, "moonTarget", 1)) * 100;
    } else {
      progress = 0;
    }
  }
  return Number.parseInt(progress.toFixed(0), 10);
}

export const formatPrice = (price = "", toFixedIndex = 0) =>
  `${parseFloat(price).toFixed(toFixedIndex)}`.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const formatPhone = (phoneNo = "") => phoneNo.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const isMobile = () => {
  const width = g(window, "innerWidth", 0);
  const userAgent = g(window, "navigator.userAgent", "");

  if (userAgent) {
    return (
      userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/BlackBerry/i) ||
      userAgent.match(/Windows Phone/i) ||
      width < 767
    );
  }
  return width < 767;
};

export const getProjectDetailSubroles = (project = {}, genres = {}) => {
  const initials = {
    required: [],
    recommended: [],
    other: [],
    all: []
  };

  return !isEmpty(genres)
    ? g(project, "genres", []).reduce((res, gnr) => {
        const genre = {
          ...gnr,
          ...g(genres, gnr.id, {})
        };
        const required = uniqBy([...res.required, ...g(genre, "requiredSubroles", [])], "id") || [];
        const recommended =
          uniqBy([...res.recommended, ...g(genre, "recommendedSubroles", [])], "id").filter(
            ({ id }) => !required.find(subrole => subrole.id === id)
          ) || [];
        const other =
          uniqBy([...res.other, ...g(genre, "otherSubroles", [])], "id").filter(
            ({ id }) => ![...required, ...recommended].find(subrole => subrole.id === id)
          ) || [];

        return {
          required,
          recommended,
          other,
          all: uniqBy(
            [
              ...res.all,
              ...g(genre, "otherSubroles", []),
              ...g(genre, "recommendedSubroles", []),
              ...g(genre, "requiredSubroles", [])
            ],
            "id"
          )
        };
      }, initials)
    : initials;
};

export function detectIE() {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  const trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  const edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
}

export const openInNewTab = (link = "") => {
  window.open(link, "_blank");
};

export const documentCookies = window.document.cookie.split(";").reduce((cookies, cookie) => {
  const [name, value] = cookie.split("=").map(c => c.trim());
  cookies[name] = value;
  return cookies;
}, {});

export const setCookie = (name, value, duration = "31.12.9999", path = "/") => {
  const expires = new Date(moment("31.12.9999", "DD.MM.YYYY"));
  window.document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
};

export const getCookie = name =>
  window.document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");

export const deleteCookie = (name, path) => {
  setCookie(name, "", "11.1.1980", path);
};

export const getImagePreview = (image = "") => {
  if (image.includes("http")) {
    return image;
  }
  return `${PREVIEWS_API}/${image}`;
};

export const canFollowProject = (user = {}, project = {}) =>
  g(project, "author.0.id", "") !== user.id &&
  !Object.values(g(project, "acceptedContributors", {})).find(ac => ac.id === user.id) &&
  !isEmpty(user);

export const getActiveNewsCount = (news = []) => {
  const start = moment().subtract(3, "days");
  const end = moment();

  return news.reduce((res, n) => {
    const nDate = moment(n.updatedAt);
    if (nDate <= end && nDate >= start) {
      return res + 1;
    }
    return res;
  }, 0);
};

export function dynamicTitle(title, description = false, url, image = false, tags = false) {
  return (
    <Helmet>
      <title>{title}</title>
      {/*{description && <meta content={description} name="description" />}*/}
      {tags && <meta content={tags} name="keywords" />}
      {image && <meta content={`${PREVIEWS_API}${image}`} name="image" />}
      {/* FB meta */}
      <meta property="fb:app_id" content={FB_APP_ID} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://pointa.cz${url}`} />
      {image && <meta property="og:image" content={`${PREVIEWS_API}${image}`} />}
      {/*{description && <meta property="og:description" content={description}/>} */}

      <meta name="twitter:title" content={title} />
      {/* {description && <meta name="twitter:description" content={description}/>}*/}
      {image && <meta name="twitter:image" content={`${PREVIEWS_API}${image}`} />}
      <meta name="twitter:url" content={`https://pointa.cz${url}`} />
    </Helmet>
  );
}
