import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g, uniqBy } from "lodash";

import { bm, be } from "../../utils/bliss";
import { isMobile } from "../../utils/utils";
import "./fundingInfo.css";

import EnumsDuck from "../../redux/ducks/enums";
import WizardDuck from "../../redux/ducks/wizard";
import UserDuck from "../../redux/ducks/user";
import { getProjectProgress, canFollowProject } from "../../utils/utils";
import Text from "../text/text";
import Button from "../button/button";
import ProgressBarLinear from "../progressBar/progressBarLinear/progressBarLinear";
import ProgressBarFunding from "../progressBar/progressBarFunding/progressBarFunding";
import FundingFollow from "../fundingFollow/fundingFollow";
import moment from "moment";
import { PREVIEWS_API } from "../../services/variables";

const MODULE_NAME = "FundingInfo";

class FundingInfo extends Component {
  getType = state => {
    const { projectStates } = this.props;

    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "released.name", ""):
        return "released";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";
    }
  };

  getFinancingStatus() {
    const { project, projectStates } = this.props;

    switch (project.state) {
      case g(projectStates, "funding.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
      case g(projectStates, "inProgress.name", ""):
        return "done";

      case g(projectStates, "failed.name", ""):
        return "failed";

      default:
        return "waiting";
    }
  }

  getMoonRemain() {
    const { project } = this.props;
    if (project.moonRemain < 1) {
      return (
        <Text small greenReward component="div">
          Zbývá <b>méně než den</b>
        </Text>
      );
    }
    if (project.moonTarget) {
      return (
        <Text small greenReward component="div">
          Zbývá <b>{project.moonRemain || ""}</b> dní
        </Text>
      );
    }
  }

  renderReleaseDate = project =>
    project.releaseDate && (
      <div className="col-md mt-5 mb-4 text-center">
        <div className={be(MODULE_NAME, "spacing", "xs")} />
        <div className="row pt-4" style={{ borderTop: "1px solid lightGrey" }}>
          <Text component="div" float medium>
            Datum vydání:
          </Text>
          <div className="ml-auto">
            <Text component="div" floatRight medium>
              <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
            </Text>
          </div>
        </div>
      </div>
    );

  getRequiredSubRoles = () => {
    const { required } = g(this.props.project, "genres", []).reduce(
      (res, gnr) => {
        const genre = { ...gnr, ...g(this.props.genres, gnr.id, {}) };
        const required = uniqBy([...res.required, ...g(genre, "requiredSubroles", [])], "id");
        return { required };
      },
      { required: [] }
    );
    return required;
  };

  followToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    const { project, dispatch, detail, user } = this.props;

    if (project.followed) {
      dispatch(WizardDuck.unfollowProject(user.id, project.id, detail));
    } else {
      dispatch(WizardDuck.followProject(user.id, project.id, detail));
    }
  };

  showButtonRow = () => {
    const { project, user } = this.props;
    if (canFollowProject(user, project)) {
      const followed = g(project, "followed", false);
      return (
        <div className="d-flex justify-content-between">
          <FundingFollow detail project={project} />
          <Button
            smallFunding
            half
            target="_blank"
            to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
          >
            <img src="/images/svg/shareNew.svg" /> Sdílet
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          smallFunding
          wide
          target="_blank"
          to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
        >
          <img src="/images/svg/shareNew.svg" /> Sdílet
        </Button>
      );
    }
  };

  render() {
    const {
      project,
      reward,
      files,
      projectStates,
      handshakesStates,
      showHandshakeRequestButton,
      user,
      detail,
      onClick
    } = this.props;
    const type = this.getType(project.state);
    const required = this.getRequiredSubRoles();
    const progress = getProjectProgress(project, projectStates, required, handshakesStates);
    const image = project.moonImage || (project.coverPhoto ? `${PREVIEWS_API}${project.coverPhoto}` : null);

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="row">
          <div className="col-md-7 d-flex justify-content-center">
            {project.issuuCode ? (
              <div
                style={{
                  width: "100%",
                  height: "360px",
                  marginTop: "10px",
                  marginBottom: isMobile() ? "40px" : "0",
                  border: "1px solid grey",
                  padding: "10px",
                  borderRadius: "25px",
                  background: "#353535"
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: project.issuuCode }} />
              </div>
            ) : (
              <img style={{ maxHeight: "350px", maxWidth: "100%" }} src={image} />
            )}
          </div>

          <div className="col-md-5">
            {(type === "financing" || type === "failed") && (
              <Fragment>
                <div className={be(MODULE_NAME, "container")}>
                  {project.moonTarget && (
                    <Fragment>
                      <ProgressBarFunding progress={progress} />
                      {!isMobile() && <div className={be(MODULE_NAME, "spacing", "sm")} />}
                      <Text xxl primary component="div" medium>
                        <b>
                          {parseInt(project.moonCollected || 0).toLocaleString("cs-CZ")} {" Kč"}
                        </b>
                      </Text>
                      <div className={be(MODULE_NAME, "spacing", "sm")} />
                      <Text small greenReward component="div">
                        vybráno z cílové částky <b>{parseInt(project.moonTarget || 0).toLocaleString("cs-CZ")}</b>
                        {" Kč"}
                      </Text>
                      <div className={be(MODULE_NAME, "spacing", "sm")} />
                      <Text small primary component="div">
                        Když se částka nevybere, kniha nevyjde. Je to i ve tvých rukách!
                      </Text>
                      <div className={be(MODULE_NAME, "spacing", "sm")} />
                      {this.getMoonRemain()}
                      <div className={be(MODULE_NAME, "spacing", "sm")} />
                      <Text small greenReward component="div">
                        Přispělo <b>{project.moonContributors || 0}</b> lidí
                      </Text>
                      <div className={be(MODULE_NAME, "spacing", "xl")} />
                      <Button onClick={onClick} smallRadius xl wide>
                        Přispět na vydání
                      </Button>
                      <div className={be(MODULE_NAME, "spacing", "xs")} />
                      {this.showButtonRow()} <div className={be(MODULE_NAME, "spacing", "sm")} />
                      {!isMobile() && <div className={be(MODULE_NAME, "spacing", "xl")} />}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FundingInfo.defaultProps = {
  project: {},
  reward: {},
  showMore: true
};

FundingInfo.propTypes = {
  genres: PropTypes.object.isRequired,
  handshakesStates: PropTypes.object.isRequired,
  project: PropTypes.object,
  projectStates: PropTypes.object.isRequired,
  showHandshakeRequest: PropTypes.bool,
  showHandshakeRequestButton: PropTypes.bool,
  showMore: PropTypes.bool
};

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state),
  genres: EnumsDuck.getGenres(state),
  handshakesStates: EnumsDuck.getHandhakeStates(state),
  user: UserDuck.getUser(state),
  files: WizardDuck.getIntroImage(state),
  rewards: WizardDuck.getRewards(state),
  project: WizardDuck.getDetail(state)
});
export default connect(mapStateToProps)(FundingInfo);
