import React, { Component } from "react";
import PropTypes from "prop-types";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

class UserBadge extends Component {
  render() {
    const { mini } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "14px",
            color: "white",
            backgroundColor: "grey"
          }
        }
      }
    });

    return mini ? (
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title="Tento kolega nebo kolegyně s námi pracoval(a) už na několika knižních projektech. Díky tomu máme od autorů
      ověřeno, že je s ním(ní) dobrá spolupráce."
        >
          <div
            style={{
              background: "#4B8795",
              color: "#ffffff",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              padding: "5px 15px",
              fontWeight: "bold",
              fontSize: "13px",
              borderRadius: "4px",
              boxShadow: "2px 2px 0 0 rgba(60, 60, 59, 0.16)"
            }}
          >
            <img style={{ width: "15px", marginRight: "5px" }} src="/images/star.png" alt="img" />
            Ověřen(a) Pointou
          </div>
        </Tooltip>
      </MuiThemeProvider>
    ) : (
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title="Tento kolega nebo kolegyně s námi pracoval(a) už na několika knižních projektech. Díky tomu máme od autorů
    ověřeno, že je s ním(ní) dobrá spolupráce."
        >
          <div
            style={{
              background: "#4B8795",
              color: "#ffffff",
              width: "190px",
              display: "flex",
              alignItems: "center",
              padding: "5px 10px",
              fontWeight: "bold",
              cursor: "pointer",
              borderRadius: "4px",
              boxShadow: "2px 2px 0 0 rgba(60, 60, 59, 0.16)"
            }}
          >
            <img style={{ width: "23px", marginRight: "5px" }} src="/images/star.png" alt="img" />
            Ověřen(a) Pointou
          </div>
        </Tooltip>
      </MuiThemeProvider>
    );
  }
}

UserBadge.defaultProps = {
  mini: false
};

UserBadge.propTypes = {
  mini: PropTypes.bool
};

export default UserBadge;
