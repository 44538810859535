import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { be } from "../../utils/bliss";

import "./info.css";
import Text from "../text/text";

const MODULE_NAME = "Info";

const Info = ({ children, ...restProps }) => (
  <span {...restProps}>
    <OverlayTrigger
      overlay={
        <Popover bsClass={MODULE_NAME} placement="top">
          <Text inverted medium>
            {children}
          </Text>
        </Popover>
      }
      placement="top"
      trigger={["hover", "focus"]}
    >
      <img alt="info" className={be(MODULE_NAME, "icon")} src="/images/svg/info.svg" />
    </OverlayTrigger>
  </span>
);

export default Info;
