import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g } from "lodash";

import { be, bm } from "../../utils/bliss";
import UserDuck from "../../redux/ducks/user";
import { canFollowProject } from "../../utils/utils";
import WizardDuck from "../../redux/ducks/wizard";
import Text from "../text/text";

import "./followToggler.css";

const MODULE_NAME = "FollowToggler";

class FollowToggler extends Component {
  followToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    const { project, dispatch, detail, user } = this.props;

    if (project.followed) {
      dispatch(WizardDuck.unfollowProject(user.id, project.id, detail));
    } else {
      dispatch(WizardDuck.followProject(user.id, project.id, detail));
    }
  };

  render() {
    const { project, user, detail } = this.props;

    if (canFollowProject(user, project)) {
      const followed = g(project, "followed", false);

      return detail ? (
        <div className={bm(MODULE_NAME, { followed })} onClick={this.followToggle}>
          <img
            alt="follow"
            className={be(MODULE_NAME, "followImage", { followed })}
            src={`${followed ? "/images/svg/bookmark-active.svg" : "/images/svg/bookmark.svg"}`}
          />
          <Text bold primary={followed}>
            {followed ? "Sledujete projekt" : "Sledovat projekt"}
          </Text>
        </div>
      ) : (
        <img
          alt="follow"
          className={be(MODULE_NAME, "bookmark", { followed })}
          onClick={this.followToggle}
          src={`${followed ? "/images/svg/bookmark-active.svg" : "/images/svg/bookmark.svg"}`}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  user: UserDuck.getUser(state)
});

FollowToggler.defaultProps = {
  detail: false
};

FollowToggler.propTypes = {
  detail: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(FollowToggler);
