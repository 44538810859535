import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./bell.css";

import { be, bm } from "../../utils/bliss";

const MODULE_NAME = "Bell";

class Bell extends Component {
  render() {
    const { number, className } = this.props;

    return (
      <div className={bm(MODULE_NAME, null, className)}>
        {number > 0 ? (
          <Fragment>
            <i className="fas fa-bell" style={{ color: "#E8452C", width: "23px", fontSize: "19px" }} />
            <span className={be(MODULE_NAME, "numberActive")}>{number}</span>
          </Fragment>
        ) : (
          <Fragment>
            <i className="fas fa-bell" style={{ color: "#DCD1C9", width: "23px", fontSize: "19px" }} />
          </Fragment>
        )}
      </div>
    );
  }
}

Bell.defaultProps = {
  number: 0,
  className: ""
};

Bell.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number
};

export default Bell;
