import React, { Component } from "react";
import Get from "restful-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import "./newLandingPage.css";

import { isMobile } from "../../utils/utils";
import { be } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import Button from "../../components/button/button";
import Screen from "../../components/screen/screen";
import EnumsDuck from "../../redux/ducks/enums";
import StorageService from "../../services/storageService";
import WizardService from "../../services/wizardService";
import Header from "../../components/header/header";
import Text from "../../components/text/text";
import UserDuck from "../../redux/ducks/user";
import ProjectCard from "./components/projectCard/projectCard";
import MobileSlider from "../../components/mobileSlider/mobileSlider";
import MainNewsletter from "./components/mainNewsletter/mainNewsletter";
import BaseInfo from "./components/baseInfo/baseInfo";
import Feed from "./components/feed/feed";
import Blog from "./components/blog/blog";
import BlogGroup from "./components/blog/blogGroup";
import Reviews from "./components/reviews/reviews";
import ReviewsPagination from "./components/reviews/reviewsPagination";
import Loader from "../../components/loader/loader";
import { showProject } from "../../utils/goTo";

const MODULE_NAME = "NewLandingPage";

export const STATES_WHITE_LIST = {
  released: {
    name: "Vydáno"
  },
  funding: {
    name: "Financování"
  },
  proposal: {
    name: "V šuplíku"
  }
};

class NewLandingPage extends Component {
  state = {
    loading: true,
    filters: {
      page: 1,
      order: "desc",
      limit: "16"
    }
  };

  componentWillMount() {
    const { dispatch } = this.props;
    let promises = [dispatch(EnumsDuck.setRoles()), dispatch(EnumsDuck.loadCatalog())];
    if (StorageService.hasToken()) {
      promises = [...promises, dispatch(UserDuck.setUserByToken("", true, false)), dispatch(EnumsDuck.setBindings())];
    }
    Promise.all(promises).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  renderRow = (key, projects) => {
    const { t } = this.props;
    return (
      <div key={key} className={be(MODULE_NAME, "row", false, "row")}>
        {!isEmpty(projects) && (
          <React.Fragment>
            <div className="d-flex justify-content-center w-100" style={{ padding: "0 10px" }}>
              <div className="col-md-10 text-center">
                <Headline
                  greenReward
                  className={(be(MODULE_NAME, "rowHeading"), false, be(MODULE_NAME, "rowHeading", key))}
                >
                  {t(`projectsLandingStates.${key}.title`)}
                </Headline>
                <div className={be(MODULE_NAME, "headlineStep", "medium")} />
                <div style={{ padding: "0 10px" }}>
                  <Text greenReward className={be(MODULE_NAME, "rowSubheading")}>
                    {t(`projectsLandingStates.${key}.desc`)}
                  </Text>
                </div>
                <div className={be(MODULE_NAME, "headlineStep", "large")} />
              </div>
            </div>
            <div className="col-12">
              <MobileSlider
                variableWidth={true}
                responsive={[
                  {
                    breakpoint: 1900,
                    infinite: false,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 3,
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 920,
                    infinite: false,
                    settings: {
                      slidesToShow: 2.2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 480,
                    infinite: false,
                    settings: {
                      slidesToShow: 1.2,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                {projects.map(project => (
                  <div key={project.id} className="col-12 mobileSlider-slide">
                    <ProjectCard
                      className="col-12"
                      followed={project.genres.length > 1}
                      onClick={() => showProject(project.id)}
                      project={project}
                    />
                  </div>
                ))}
              </MobileSlider>
              {this.renderMoreButton(key, t)}
              {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
              {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderList = projectsGroups => {
    const output = [];

    Object.keys(STATES_WHITE_LIST).forEach(groupKey => {
      output.push(this.renderRow(groupKey, projectsGroups[groupKey]));
    });
    return output;
  };

  renderMoreButton = (key, t) => {
    return (
      <div className="text-center">
        <Button small smallRadius xl lightPrimary to={`/projects/${key}`}>
          {t("moreProjects")}
        </Button>
      </div>
    );
  };

  getType = ({ state, moonTarget }) => {
    const { projectStates } = this.props;
    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        if (!moonTarget) return "waitingForPresale";
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
        return "released";
    }
  };

  render() {
    const {
      history,
      location: { search },
      projectStates
    } = this.props;
    const isUser = !!search;

    if (isEmpty(projectStates)) return <Loader />;

    return (
      <Screen
        className="/src/pages/newLandingPage/newLandingPage"
        header={<Header history={history} isUser={isUser} landing />}
      >
        <Get
          path={`projects?page=${this.state.filters.page}&order=${this.state.filters.order}&limit=${this.state.filters.limit}&groupByState=true`}
          resolve={async projects => {
            const mapProject = async projectData => {
              const project = projectData;
              project.type = this.getType(project);
              return project;
            };
            const data = projects;
            return {
              ...data,
              released: await Promise.all(data.released.map(p => mapProject(p)))
            };
          }}
        >
          {(projects, { loading }) => {
            if (this.state.loading || loading) return <Loader />;
            return (
              <React.Fragment>
                <div className={be(MODULE_NAME, "head")}>
                  <div className={be(MODULE_NAME, "net")} />
                  <div className={be(MODULE_NAME, "introWrapper")}>
                    <div className="row">
                      <div className="col-md-6">
                        {isMobile() && (
                          <Headline greenReward component="h2">
                            Pomoz knihám na svět. Je ve tvých rukách, které vyjdou
                          </Headline>
                        )}
                        {!isMobile() && (
                          <Headline greenReward component="h1" hero>
                            Pomoz knihám na svět. Je ve tvých rukách, které vyjdou
                          </Headline>
                        )}

                        {!isMobile() && (
                          <div className="pt-5">
                            <Text greenReward>
                              Bez čtenářů by knihy neměly smysl. Buď tím, kdo pomůže autorům splnit si sen o vydání
                              knihy. Rozhodni se, jak jim pomůžeš, a vyber si třeba i speciální odměnu. Všechno je ve
                              tvých rukách.
                            </Text>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className={be(MODULE_NAME, "introImageWrapper")}>
                          <img className={be(MODULE_NAME, "introImage")} src="/images/svg/landing.svg" />
                        </div>
                      </div>
                    </div>
                    {isMobile() && (
                      <div className="row pt-3">
                        <div className="col-md-6">
                          <Text greenReward>
                            Bez čtenářů by knihy neměly smysl. Buď tím, kdo pomůže autorům splnit si sen o vydání knihy.
                            Rozhodni se, jak jim pomůžeš, a vyber si třeba i speciální odměnu. Všechno je ve tvých
                            rukách.
                          </Text>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={be(MODULE_NAME, "headWrap")}>
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className={be(MODULE_NAME, "panelBox", "author")}>
                          <div className={be(MODULE_NAME, "typeHeadline")}>
                            <Headline greenReward card nowrap component="h2">
                              Knihy, které <br /> už vyšly
                            </Headline>
                          </div>
                          <div className={be(MODULE_NAME, "headlineStep")} />
                          <div className={be(MODULE_NAME, "introButton")}>
                            <Button small smallRadius xl half href="/projects/released">
                              Kup knihu
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className={be(MODULE_NAME, "panelBox", "contributor")}>
                          <div className={be(MODULE_NAME, "typeHeadline")}>
                            <Headline greenReward card component="h2">
                              Pomoz knize, <br /> aby vyšla
                            </Headline>
                          </div>
                          <div className={be(MODULE_NAME, "headlineStep")} />
                          <div className={be(MODULE_NAME, "introButton")}>
                            <Button small smallRadius xl half to="/projects/funding">
                              Podpoř knihu
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 offset-md-3 offset-lg-0 col-lg-4">
                        <div className={be(MODULE_NAME, "panelBox", "read")}>
                          <div className={be(MODULE_NAME, "typeHeadline")}>
                            <Headline greenReward card component="h2">
                              Čti rukopisy <br /> jako první
                            </Headline>
                          </div>
                          <div className={be(MODULE_NAME, "headlineStep")} />
                          <div className={be(MODULE_NAME, "introButton")}>
                            <Button small smallRadius xl half to="/register/reader">
                              Buď betačtenář
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={be(MODULE_NAME, "headlineStep", "large")} />
                <div style={{ background: "#ffffff", margin: "0 -106%", padding: "0 100%" }} className="NewLandingPage">
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                  {this.renderList(projects)}
                </div>
                <div className={be(MODULE_NAME, "baseInfoWrapper")}>
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  <div className="text-center">
                    <Headline semi greenReward>
                      Jak Pointa funguje?
                    </Headline>
                  </div>
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  <BaseInfo />
                </div>
                {/* <div style={{ background: '#ffffff', margin: '0 -106.3%', padding: '0 99.5%' }}>
                  <div className={be(MODULE_NAME, 'headlineStep', 'large')} />
                  <div className="text-center">
                    <Headline greenReward>Instagram</Headline>
                  </div>
                  {!isMobile() && (
                    <div>
                      <div className={be(MODULE_NAME, 'headlineStep', 'large')} />
                      <div className="row">
                        <Feed />
                      </div>
                      <div className={be(MODULE_NAME, 'headlineStep', 'large')} />
                    </div>
                  )}
                  {isMobile() && (
                    <div>
                      <div className={be(MODULE_NAME, 'headlineStep', 'medium')} />
                      <div className="col-12">
                        <Feed />
                      </div>
                      <div className={be(MODULE_NAME, 'headlineStep', 'medium')} />
                    </div>
                  )}
                  <div className="text-center">
                    <Button small smallRadius xl lightPrimary href="https://www.instagram.com/pointa.cz/">
                      SLEDUJ NÁS!
                    </Button>
                  </div>
                </div> */}
                <Get path="/pointaReviews?type=pointaReview">
                  {(reviews, { loading: reviewsLoading }) => {
                    if (isEmpty(reviews) || reviewsLoading)
                      return (
                        <div
                          style={{ background: "#ffffff", margin: "0 -1500px", padding: "0 1500px", height: "20px" }}
                        />
                      );
                    return (
                      <div style={{ background: "#ffffff", margin: "0 -1500px", padding: "0 1500px" }}>
                        <div className={be(MODULE_NAME, "headlineStep", "large")} />
                        <div className="text-center">
                          <Headline greenReward>Lidé s Pointou</Headline>
                          <div className="pt-4 pb-4">
                            <div className={be(MODULE_NAME, "headlineStep", "medium")} />

                            {isMobile() && (
                              <div className="row justify-content-center mt-6">
                                {" "}
                                <ReviewsPagination reviews={reviews} />
                              </div>
                            )}
                            {!isMobile() && (
                              <div className="row mt-6">
                                {" "}
                                <Reviews reviews={reviews} />{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Get>
                {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                <Get base="https://blog.pointa.cz/" path="/wp-json/wp/v2/posts?_embed">
                  {(posts, { loading: blogLoading, error }) => {
                    if (isEmpty(posts) || blogLoading || error) {
                      return <React.Fragment />;
                    }

                    return (
                      <div className="text-center">
                        {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                        <Headline greenReward>I my píšeme </Headline>
                        <div className="pt-4 pb-4">
                          <div className="row">
                            <div className="col">
                              <Blog posts={posts} />
                            </div>
                          </div>
                          <div className="row">
                            <BlogGroup posts={posts} />
                          </div>
                          <div className="text-center pt-4">
                            <Button small smallRadius xl lightPrimary href="https://blog.pointa.cz/">
                              Další články
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Get>
                {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                <div
                  style={{
                    background: "#ffffff",
                    margin: "0 -1500px",
                    padding: "0 1500px",
                    borderTop: "1px solid #E6E3DF",
                    borderBottom: "1px solid #E6E3DF"
                  }}
                >
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "small")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                  <div>
                    <div className={be(MODULE_NAME, "newsletter")}>
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-12 text-center mb-4">
                            <Text lg bold greenReward>
                              Chcete odebírat novinky emailem?
                            </Text>
                          </div>
                          <div className="col-md-6 col-12 d-flex justify-content-center mb-4">
                            <MainNewsletter />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "small")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                </div>
              </React.Fragment>
            );
          }}
        </Get>
      </Screen>
    );
  }
}

NewLandingPage.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(NewLandingPage);
