import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty, last, noop, get as g } from "lodash";
import MobileSlider from "../../mobileSlider/mobileSlider";
import { be } from "../../../utils/bliss";

const MODULE_NAME = "UploadFile";

class SampleFilesSlider extends Component {
  state = {
    showSlider: window.innerWidth < 768
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions = () => {
    if (window.innerWidth < 768) {
      this.setState({ showSlider: true });
    } else {
      this.setState({ showSlider: false });
    }
  };

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const files = this.props.files;
    const { showSlider } = this.state;
    if (showSlider) {
      return (
        <MobileSlider
          responsive={[
            {
              breakpoint: 1024,
              infinite: false,
              settings: {
                variableWidth: true,
                slidesToShow: 4,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 920,
              infinite: false,
              settings: {
                slidesToShow: 3.5,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              infinite: false,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 400,
              infinite: false,
              settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2
              }
            }
          ]}
        >
          {!isEmpty(files) && files.map(file => <div className="mobileSlider-slide">{file}</div>)}
        </MobileSlider>
      );
    }
    return (
      <div className={be(MODULE_NAME, "files", "samples-grid")}>
        {files.map((file, index) => (
          <div key={index} className="mobileSlider-slide">
            {file}
          </div>
        ))}
      </div>
    );
  }
}

SampleFilesSlider.defaultProps = {};

SampleFilesSlider.propTypes = {
  files: PropTypes.array.isRequired
};

export default SampleFilesSlider;
