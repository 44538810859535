import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px !important",
        color: "white",
        backgroundColor: "grey"
      }
    },
    MuiFormControlLabel: {
      width: "100%"
    }
  }
});

export default function CommonPriceInfo() {
  return (
    <span className="position-relative ml-1">
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title="Běžná maloobchodní cena (MOC) – je cena určena nakladatelstvím, za kterou je možné produkt standardně zakoupit u maloobchodních prodejců."
          enterTouchDelay={0}
          leaveTouchDelay={6000}
        >
          <i className="fa fa-info-circle" style={{ color: "#3C3C3B", fontSize: 16 }} />
        </Tooltip>
      </MuiThemeProvider>
    </span>
  );
}
