import React, { Component } from "react";
import Get from "restful-react";

import _orderBy from "lodash/orderBy";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _map from "lodash/map";

import "./orderList.css";

import { isMobile } from "../../utils/utils";
import { be, bm } from "../../utils/bliss";
import Screen from "../../components/screen/screen";
import Header from "../../components/header/header";
import Loader from "../../components/loader/loader";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import Button from "../../components/button/button";
import OrderRow from "./components/orderRow/orderRow";

const MODULE_NAME = "OrderList";

class OrderList extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loader />;
    }
    return (
      <Get path="/me/books">
        {(myBooks, { loading: booksLoading }) => (
          <Get path="/me/orders">
            {(orders, { loading: requestLoading }) => {
              if (requestLoading || booksLoading) {
                return <Loader />;
              }

              const sortedOrders = _orderBy(orders, "create_time", "desc");
              const sortedOrdersMapped = _map(sortedOrders, o => {
                const order = o;
                if (order.state === "paid") {
                  order.items = _map(o.items, i => {
                    const item = i;
                    if (i.reward && !i.reward.physical && i.reward.pk_id) {
                      item.download = _find(myBooks, { id_book: i.reward.pk_id });
                    }
                    return item;
                  });
                }
                return order;
              });

              const saleOrders = _filter(sortedOrdersMapped, o => _find(o.items, { book: { type: "sale" } }));
              const presaleOrders = _filter(sortedOrdersMapped, o => _find(o.items, { book: { type: "pre-sale" } }));

              return (
                <Screen header={<Header />}>
                  <div className={be(MODULE_NAME)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <Headline page>Moje objednávky</Headline>
                      </div>
                    </div>
                    {!isMobile() && <div className="spacing" style={{ height: "57px" }} />}
                    {isMobile() && <div className="spacing" style={{ height: "30px" }} />}
                    <div className="row">
                      <div className="col-lg-6">
                        <Text bold cartItem>
                          E-shop (vydané knihy)
                        </Text>
                      </div>
                    </div>
                    <div className={be(MODULE_NAME, "rowItem")}>
                      {!isMobile() ? (
                        <div className="row">
                          <div className="col-2">
                            <Text greenReward bold medium>
                              DATUM
                            </Text>
                          </div>
                          <div className="col-5">
                            <div className="row">
                              <div className="col-7">
                                <Text greenReward bold medium>
                                  KNIHA
                                </Text>
                              </div>
                              <div className="col-5">
                                <Text greenReward bold medium>
                                  KS
                                </Text>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <Text greenReward bold medium>
                              DOPRAVA
                            </Text>
                          </div>
                          <div className="col-2">
                            <Text greenReward bold medium>
                              CENA CELKEM
                            </Text>
                          </div>
                          <div className="col-1">
                            <Text greenReward bold medium>
                              STAV
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {saleOrders.map(order => (
                            <OrderRow key={order.id} eshop order={order} />
                          ))}
                        </div>
                      )}
                      {!isMobile() && <hr />}
                      {!isMobile() && (
                        <div>
                          {saleOrders.map(order => (
                            <OrderRow key={order.id} eshop order={order} />
                          ))}
                        </div>
                      )}
                    </div>
                    {!isMobile() && <div className="spacing" style={{ height: "57px" }} />}
                    {isMobile() && <div className="spacing" style={{ height: "30px" }} />}

                    <div className="row">
                      <div className="col-lg-6">
                        <Text bold cartItem>
                          Příspěvky
                        </Text>
                      </div>
                    </div>
                    <div className={be(MODULE_NAME, "rowItem")}>
                      {!isMobile() ? (
                        <div className="row">
                          <div className="col-2">
                            <Text greenReward bold medium>
                              DATUM
                            </Text>
                          </div>
                          <div className="col-3">
                            <Text greenReward bold medium>
                              KNIHA
                            </Text>
                          </div>
                          <div className="col-2">
                            <Text greenReward bold medium>
                              ODMĚNA
                            </Text>
                          </div>
                          <div className="col-2">
                            <Text greenReward bold medium>
                              DOPRAVA
                            </Text>
                          </div>
                          <div className="col-2 pl-0" style={{ marginLeft: "-30px" }}>
                            <Text greenReward bold medium>
                              PŘÍSPĚVEK
                            </Text>
                          </div>
                          <div className="col-1 pl-0" style={{ marginLeft: "-30px" }}>
                            <Text greenReward bold medium>
                              STAV
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {presaleOrders.map(order => (
                            <OrderRow key={order.id} order={order} />
                          ))}
                        </div>
                      )}
                      {!isMobile() && <hr />}

                      {!isMobile() && (
                        <div>
                          {presaleOrders.map(order => (
                            <OrderRow key={order.id} order={order} />
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="spacing" style={{ height: "57px" }} />
                  </div>
                </Screen>
              );
            }}
          </Get>
        )}
      </Get>
    );
  }
}

export default OrderList;
