import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import EnumsDuck from "../../redux/ducks/enums";
import Genre from "./genre/genre";
import GenreWrapper from "./genreWrapper/genreWrapper";

const MODULE_NAME = "GenresList";

const GenresList = ({ genres, onSelectGenre, selectedGenres, medium }) => (
  <div className={MODULE_NAME}>
    <GenreWrapper
      genres={genres.map(genre => (
        <Genre
          key={genre.id}
          medium={medium}
          onClick={onSelectGenre}
          selected={selectedGenres.includes(genre.id)}
          {...genre}
        />
      ))}
      medium={medium}
    />
  </div>
);

GenresList.defaultProps = {
  onSelectGenre: () => {},
  selectedGenres: []
};

GenresList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  genres: PropTypes.array.isRequired,
  onSelectGenre: PropTypes.func,
  selectedGenres: PropTypes.array
};

const mapStateToProps = state => ({
  genres: Object.values(EnumsDuck.getGenres(state))
});
export default connect(mapStateToProps)(GenresList);
