import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import Select from "react-select";
import { compose } from "recompose";
import { isEmpty, get as g } from "lodash";

import "./tagsList.css";

import EnumsDuck from "../../redux/ducks/enums";
import Tag from "./tag/tag";
import GenreWrapper from "../genres/genreWrapper/genreWrapper";
import Button from "../button/button";

const MODULE_NAME = "TagsList";

const customStyles = {
  option: () => ({
    display: "none"
  }),
  menu: () => ({
    width: "0px",
    height: "0px",
    display: "none",
    pointerEvents: "none"
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#ccc",
    color: "#000",
    borderRadius: "10px"
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#000",
    fontSize: 13
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#ccc",
    color: "#000",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  })
};

class TagsList extends Component {
  state = {
    searchText: ""
  };

  handleChange = tags => {
    this.setState(
      {
        searchText: ""
      },
      () => {
        this.props.onSelectTag(tags.map(t => g(t, "value", t)));
      }
    );
  };

  onSelectTag = tag => {
    const { selectedTags } = this.props;
    let newTags = [];

    if (selectedTags.includes(tag.id)) {
      newTags = selectedTags.filter(t => t !== tag.id);
    } else {
      newTags = [...selectedTags, tag.id];
    }
    this.handleChange(newTags);
  };

  onInputChange = (searchText = "", { action }) => {
    if (action === "input-change") {
      this.setState({
        searchText
      });
    }
  };

  createTag = () => {
    const { searchText } = this.state;

    this.props.dispatch(EnumsDuck.createTag(searchText)).then(newTag => {
      window.alertify.success(`Nový tag ${searchText} vytvořen a poslán ke schválení.`);
      this.onSelectTag(newTag);
    });
  };

  buildData = () => {
    const { tags, selectedTags } = this.props;
    const { searchText } = this.state;

    return tags.reduce(
      (res, tag) => {
        if (!selectedTags.includes(tag.id)) {
          return {
            ...res,
            options: [...res.options, { label: tag.name, value: tag.id }],
            contentTags: g(tag, "name", "").toLowerCase().includes(searchText.toLowerCase())
              ? [...res.contentTags, tag]
              : res.contentTags
          };
        }
        return {
          ...res,
          selectedOptions: [...res.selectedOptions, { value: tag.id, label: tag.name }]
        };
      },
      {
        contentTags: [],
        options: [],
        selectedOptions: []
      }
    );
  };

  render() {
    const { t, selectedTags, secondary } = this.props;
    const { searchText } = this.state;
    const { contentTags, options, selectedOptions } = this.buildData();

    return (
      <div className={MODULE_NAME}>
        <Select
          allowCreate
          classNamePrefix="multi-select"
          filterBy={(tag, searchText) => tag.label.includes(searchText)}
          inputValue={searchText}
          isMulti
          onChange={this.handleChange}
          onInputChange={this.onInputChange}
          onInputKeyDown={this.handleKeyDown}
          options={options}
          placeholder={t("tagsList.inputPlaceholder")}
          styles={customStyles}
          value={selectedOptions}
        />
        {isEmpty(contentTags) && (
          <Button onClick={this.createTag} secondary>
            <i className="fa fa-plus" /> {t("tagsList.addTag")}
          </Button>
        )}
        <GenreWrapper
          genres={contentTags.map(tag => (
            <Tag
              key={tag.id}
              onClick={e => {
                e.stopPropagation();
                this.onSelectTag(tag);
              }}
              secondary={secondary}
              selected={selectedTags.includes(tag.id)}
              {...tag}
            />
          ))}
        />
      </div>
    );
  }
}

TagsList.defaultProps = {
  onSelectTag: () => {},
  selectedTags: []
};

TagsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSelectTag: PropTypes.func,
  selectedTags: PropTypes.array,
  t: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  tags: EnumsDuck.getTags(state)
});
export default compose(connect(mapStateToProps), translate("translations"))(TagsList);
