/* eslint-disable class-methods-use-this */
import axios from "axios";
import { get as g } from "lodash";

import { API } from "./config";
import CoreAPIService from "./coreAPIService";
import StorageService from "./storageService";
import { getQueryFromObj } from "../utils/utils";

class UserService {
  async createUser(user) {
    return new Promise((res, rej) => {
      axios
        .post(`${API}/users`, user)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async loginUser(user) {
    return new Promise((res, rej) => {
      axios
        .post(`${API}/token/user`, user)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async forgottenPassword(email) {
    return new Promise((res, rej) => {
      axios
        .post(`${API}/forgotten-password`, { email })
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getUserByToken() {
    return new Promise((res, rej) => {
      CoreAPIService.get("me")
        .then(response => {
          res(response.data || {});
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getUserSsoToken() {
    return new Promise((res, rej) => {
      CoreAPIService.get("ssotoken")
        .then(response => {
          res(response.data || "");
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getAllContributors(opt = {}) {
    const queryStr = getQueryFromObj(opt);
    return new Promise((res, rej) => {
      // contributors?subroles[]=f7720736-7928-11e8-a9f6-0242ac120003&subroles[]=f7720736-7928-11e8-a9f6-0242ac120003&order=desc
      CoreAPIService.get(queryStr ? `contributors${queryStr}` : "contributors")
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getAllAuthors(opt = {}) {
    const queryStr = getQueryFromObj(opt);
    return new Promise((res, rej) => {
      CoreAPIService.get(queryStr ? `authors${queryStr}` : "authors")
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async patchUser(userId, data) {
    return new Promise((res, rej) => {
      CoreAPIService.patch(`users/${userId}`, data)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getAllUsers() {
    return new Promise(res => {
      res([]);
    });
  }

  async resendConfirmEmailByUserId(userId) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`users/${userId}/reconfirm`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deletePortfolio(userId, portfolioId) {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`users/${userId}/portfolio/${portfolioId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deleteReference(userId, referenceId) {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`users/${userId}/references/${referenceId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async setCooperation({ subroleId, projectId, contributorId, contributorDescription }) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/handshakes`, {
        contributor: contributorId,
        subrole: subroleId,
        contributorDescription
      })
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async updateHandshake(projectId, handshakeId, data = {}) {
    return new Promise((res, rej) => {
      CoreAPIService.patch(`projects/${projectId}/handshakes/${handshakeId}`, data)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async removeCooperation(projectId, handshakeId) {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/handshakes/${handshakeId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async rejectHandshakeRequest(projectId, handshakeId) {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`projects/${projectId}/handshakes/${handshakeId}/rejectRequest`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async acceptHandshake(projectId, handshakeId) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/handshakes/${handshakeId}/accept`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async requestHandshake(projectId, handshakeId) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/handshakes/${handshakeId}/request`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deliverHandshake(projectId, handshakeId) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`projects/${projectId}/handshakes/${handshakeId}/delivered`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async readNotifications(notificationId) {
    return new Promise((res, rej) => {
      CoreAPIService.get(`notifications/${notificationId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async resetPassword(opt = {}) {
    return new Promise((res, rej) => {
      axios
        .post(`${API}/reset-password`, opt)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getUserById(id = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`users/${id}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getUserLightById(id = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`users-light/${id}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async resendConfirmEmailByEmail(email = "") {
    return new Promise((res, rej) => {
      axios
        .post(`${API}/resend-confirmation`, { email })
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getNotificationById(notifId = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`notifications/${notifId}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getNotificationsByUserId(userId = "", opt = { limit: 10 }) {
    return new Promise((res, rej) => {
      if (StorageService.hasToken()) {
        CoreAPIService.get(`users/${userId}/notifications${getQueryFromObj(opt)}`)
          .then(response => {
            res(response.data);
          })
          .catch(err => {
            // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
            console.error(g(err, "response.data", ""));
            rej(err);
          });
      } else {
        return [];
      }
    });
  }

  async subscribe(senderEmail = "", gdprAgreed = "", showAlerts = true) {
    return new Promise((res, rej) => {
      axios
        .post(`${API}/newsletterSubscribe`, {
          email: senderEmail
        })
        .then(() => {
          res();
          if (showAlerts) {
            window.alertify.success("Váš požadavek na odebírání novinek byl zpracován!");
          } else {
            console.log("Newsletter subscribe: Váš požadavek na odebírání novinek byl zpracován!");
          }
        })
        .catch(err => {
          if (showAlerts) {
            window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          } else {
            console.log("Newsletter subscribe: ", g(err, "response.data.message", ""));
          }
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async makeOrder(data) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`orders`, data)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async cookiesAgreed() {
    return new Promise((res, rej) => {
      axios
        .get("https://api.ipdata.co?api-key=80ea26a4535f0b06e233c5554dad1a65d7a53f336997463093f3f098")
        .then(({ data }) => {
          // console.log(data)
          axios
            .post(`${API}/cookies`, {
              ipAddress: data.ip
            })
            .then(() => {
              res({
                ipAddress: data.ip
              });
            })
            .catch(err => {
              console.error(g(err, "response.data", ""));
              rej(err);
            });
        });
    });
  }

  async hideUser() {
    return new Promise((res, rej) => {
      CoreAPIService.post("users/hide")
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async unhideUser() {
    return new Promise((res, rej) => {
      CoreAPIService.post("users/unhide")
        .then(({ data }) => {
          res(data);
        })
        .catch(err => {
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }
}

export default new UserService();
