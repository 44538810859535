import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import "./fundingNewsTab.css";

import { get as g, isEmpty } from "lodash";
import { be, bm } from "../../utils/bliss";
import Text from "../text/text";
import Headline from "../headline/headline";
import Avatar from "../avatar/avatar";
import Forms from "../forms/form";
import Button from "../button/button";
import FormDuck from "../../redux/ducks/forms";
import WizardDuck from "../../redux/ducks/wizard";
import UIDuck from "../../redux/ducks/ui";

const MODULE_NAME = "FundingNewsTab";

class FundingNewsTab extends Component {
  state = {
    edit: false
  };

  handleEdit = () => {
    this.setState(
      {
        edit: true
      },
      () => {
        const { dispatch, item } = this.props;
        dispatch(FormDuck.setData(FormDuck.formTypes.editNews, item));
      }
    );
  };

  onDelete = () => {
    const { dispatch, item, projectId } = this.props;
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: "Opravdu chcete smazat novinku?",
        danger: true,
        callback: () => {
          dispatch(WizardDuck.removeNews(projectId, item.id));
        }
      })
    );
  };

  onNewsEdit = (data = {}) => {
    const { dispatch, item, projectId } = this.props;
    dispatch(WizardDuck.editProjectNews(projectId, item.id, data)).then(() => {
      this.setState({ edit: false });
    });
  };

  onSetInput = (formName, name, value) => {
    if (name === "content" && value.length >= 600) {
      window.alertify.error("Maximální počet znaků textu je 600!");
      this.props.dispatch(FormDuck.setInput(formName, name, value.slice(0, 600)));
    }
    if (name === "title" && value.length >= 65) {
      window.alertify.error("Maximální počet znaků textu je 65!");
      this.props.dispatch(FormDuck.setInput(formName, name, value.slice(0, 65)));
    }
  };

  render() {
    const { data, user, updatedAt, title, content, link } = this.props.item;
    const { project } = this.props;
    const { edit } = this.state;

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="row">
          {this.props.isEditable && (
            <div className="col-auto">
              <img
                alt="edit"
                className={be(MODULE_NAME, "icon", "edit")}
                onClick={this.handleEdit}
                src="/images/svg/edit.svg"
              />
              <img
                alt="edit"
                className={be(MODULE_NAME, "icon", "trash")}
                onClick={this.onDelete}
                src="/images/svg/trashbin.svg"
              />
            </div>
          )}
        </div>
        {edit && (
          <Fragment>
            <Forms
              inputs={[
                {
                  placeholder: "Nadpis novinky",
                  label: null,
                  name: "title",
                  required: true
                },
                {
                  placeholder: "Text novinky",
                  label: null,
                  type: "textarea",
                  wide: true,
                  name: "content",
                  required: true
                },
                {
                  placeholder: "Hypertextový odkaz",
                  label: null,
                  name: "link",
                  entityType: "link"
                }
              ]}
              name={FormDuck.formTypes.editNews}
              onSetInput={this.onSetInput}
              onSubmit={this.onNewsEdit}
            >
              <div className="text-center">
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <Button type="submit" wide>
                  Upravit novinku
                </Button>
              </div>
            </Forms>
          </Fragment>
        )}
        {!edit && (
          <Fragment>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className="row">
              <div className="col">
                <Headline greenReward bold component="h4" xs>
                  {title}
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "sm")} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Text greenReward small>
                  {content}
                </Text>
              </div>
            </div>
            {link && (
              <div className="row">
                <div className="col">
                  <a href={link} target="_blank">
                    {link}
                  </a>
                </div>
              </div>
            )}
            <div className={be(MODULE_NAME, "spacing", "md")} />
            <div className={be(MODULE_NAME, "spacing", "sm")} />

            <div className="row mt-2 pb-3">
              <div className="col pull-left">
                <div className={be(MODULE_NAME, "authors")}>
                  {g(project, "author", []).map(author => (
                    <div key={project.id + author.id} className={be(MODULE_NAME, "author")}>
                      <div className={be(MODULE_NAME, "authorAvatar")}>
                        <Avatar image={author.profilePic} shadow />
                      </div>
                      <div className={be(MODULE_NAME, "authorName")}>
                        <Text bold greenReward small>
                          {author.name} {author.surname}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col d-flex justify-content-end align-items-center">
                <div className="pull-right">
                  <Text greenReward bold xs>
                    {moment(updatedAt).format("DD. M. YYYY")}
                  </Text>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

FundingNewsTab.defaultProps = {
  isEditable: false
};

FundingNewsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  item: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  project: WizardDuck.getDetail(state)
});

export default connect(mapStateToProps)(FundingNewsTab);
