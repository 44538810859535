import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import "./newsTab.css";

import { be, bm } from "../../utils/bliss";
import Text from "../text/text";
import Headline from "../headline/headline";
import FormDuck from "../../redux/ducks/forms";
import Forms from "../forms/form";
import Button from "../button/button";
import WizardDuck from "../../redux/ducks/wizard";
import UIDuck from "../../redux/ducks/ui";

const MODULE_NAME = "NewsTab";

class NewsTab extends Component {
  state = {
    edit: false
  };

  handleEdit = () => {
    this.setState(
      {
        edit: true
      },
      () => {
        const { dispatch, item } = this.props;
        dispatch(FormDuck.setData(FormDuck.formTypes.editNews, item));
      }
    );
  };

  onDelete = () => {
    const { dispatch, item, projectId } = this.props;
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: "Opravdu chcete smazat novinku?",
        danger: true,
        callback: () => {
          dispatch(WizardDuck.removeNews(projectId, item.id));
        }
      })
    );
  };

  onNewsEdit = (data = {}) => {
    const { dispatch, item, projectId } = this.props;
    dispatch(WizardDuck.editProjectNews(projectId, item.id, data)).then(() => {
      this.setState({ edit: false });
    });
  };

  onSetInput = (formName, name, value) => {
    if (name === "content" && value.length >= 600) {
      window.alertify.error("Maximální počet znaků textu je 600!");
      this.props.dispatch(FormDuck.setInput(formName, name, value.slice(0, 600)));
    }
    if (name === "title" && value.length >= 65) {
      window.alertify.error("Maximální počet znaků textu je 65!");
      this.props.dispatch(FormDuck.setInput(formName, name, value.slice(0, 65)));
    }
  };

  render() {
    const { updatedAt, title, content, link } = this.props.item;
    const { edit } = this.state;

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="row">
          <div className="col">
            <Text light xs>
              {moment(updatedAt).format("DD. M. YYYY")}
            </Text>
          </div>
          {this.props.isEditable && (
            <div className="col-auto">
              <img
                alt="edit"
                className={be(MODULE_NAME, "icon", "edit")}
                onClick={this.handleEdit}
                src="/images/svg/edit.svg"
              />
              <img
                alt="edit"
                className={be(MODULE_NAME, "icon", "trash")}
                onClick={this.onDelete}
                src="/images/svg/trashbin.svg"
              />
            </div>
          )}
        </div>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        {edit && (
          <Fragment>
            <Forms
              inputs={[
                {
                  placeholder: "Nadpis novinky",
                  label: null,
                  name: "title",
                  required: true
                },
                {
                  placeholder: "Text novinky",
                  label: null,
                  type: "textarea",
                  wide: true,
                  name: "content",
                  required: true
                },
                {
                  placeholder: "Hypertextový odkaz",
                  label: null,
                  name: "link",
                  entityType: "link"
                }
              ]}
              name={FormDuck.formTypes.editNews}
              onSetInput={this.onSetInput}
              onSubmit={this.onNewsEdit}
            >
              <div className="text-center">
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <Button type="submit" wide>
                  Upravit novinku
                </Button>
              </div>
            </Forms>
          </Fragment>
        )}
        {!edit && (
          <Fragment>
            <div className="row">
              <div className="col">
                <Headline bold component="h4" xs>
                  {title}
                </Headline>
              </div>
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className="row">
              <div className="col">
                <Text small>{content}</Text>
              </div>
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            {link && (
              <div className="row">
                <div className="col">
                  <a href={link} target="_blank">
                    {link}
                  </a>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

NewsTab.defaultProps = {
  isEditable: false
};

NewsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  item: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
};

export default connect()(NewsTab);
