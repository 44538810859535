import React, { Component } from "react";
import PropTypes from "prop-types";

import DisqusComponent from "../../components/disqus/disqusComponent";

class TestDisqusPage extends Component {
  render() {
    return <DisqusComponent project={{ id: "test-1", name: "Test Project" }} />;
  }
}

TestDisqusPage.propTypes = {};

export default TestDisqusPage;
