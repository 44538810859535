/* global dataLayer */
import React from "react";
import PropTypes from "prop-types";

export default class DataLayerPush extends React.Component {
  render() {
    return <span onClick={this.onClickHandler}>{this.props.children}</span>;
  }

  onClickHandler = () => {
    dataLayer.push(this.props.event);
  };
}

DataLayerPush.propTypes = {
  action: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
