import React from "react";

import "./confirmBottomBanner.css";

import { be } from "../../utils/bliss";
import Text from "../text/text";

const MODULE_NAME = "ConfirmBottomBanner";

const ConfirmBottomBanner = () => (
  <div className={be(MODULE_NAME, "main")}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md">
          <div className="text-center text-md-center">
            <Text inverted small>
              Odeslal(a) jsi nám svůj profil k posouzení. Děkujeme a brzy se ti ozve někdo z týmu Pointy.
            </Text>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmBottomBanner;
