import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { be } from "../../utils/bliss";
import "./mobileSlider.css";

const MODULE_NAME = "mobileSlider";

const MobileSlider = ({
  arrows,
  dots,
  infinite,
  responsive,
  speed,
  slidesToShow,
  slidesToScroll,
  variableWidth,
  ...props
}) => (
  <Slider
    variableWidth={variableWidth}
    arrows={arrows}
    centerMode={false}
    dots={dots}
    infinite={false}
    responsive={responsive}
    slidesToScroll={1}
    slidesToShow={4}
    {...props}
  >
    {props.children}
  </Slider>
);

MobileSlider.defaultProps = {
  infinite: false,
  responsive: [],
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4
};

MobileSlider.propTypes = {
  arrows: PropTypes.bool,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  responsive: PropTypes.array,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  speed: PropTypes.number
};

export default MobileSlider;
