import axios from "axios";
import { get as g } from "lodash";

import { API } from "./config";
import CoreAPIService from "./coreAPIService";
import { getQueryFromObj } from "../utils/utils";

class ThreadService {
  async getThreadByProjectId(id = "") {
    return new Promise((res, rej) => {
      CoreAPIService.get(`threads/${id}`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async createComment(threadId, params = {}) {
    return new Promise((res, rej) => {
      CoreAPIService.post(`threads/${threadId}/comment`, params)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async deleteComment(commentId) {
    return new Promise((res, rej) => {
      CoreAPIService.delete(`threads/comments/${commentId}/delete`)
        .then(response => {
          res(g(response, "data", {}));
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }
}

export default new ThreadService();
