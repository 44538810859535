import React from "react";

import "./baseInfo.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Text from "../../../../components/text/text";

const MODULE_NAME = "BaseInfo";

const BaseInfo = () => (
  <div>
    {!isMobile() && (
      <div className="row">
        <div className="col-sm pull-left" style={{ padding: "0" }}>
          <div className="d-flex justify-content-center mb-4">
            <p className={be(MODULE_NAME, "number")}>1</p>
          </div>
          <Text bold small greenReward>
            Na Pointu přicházejí s rukopisy začínající autoři i ti, kterým nevyhovují tradiční nakladatelé. Vybírají si
            knižní kolegy, jako jsou ilustrátor, redaktorka a další.
          </Text>
        </div>
        <div style={{ padding: "0 150px" }} className="col-sm justify-content-center">
          <div className="d-flex justify-content-center mb-4" style={{ padding: "0" }}>
            <p className={be(MODULE_NAME, "middle")}>2</p>
          </div>
          <Text bold small greenReward>
            Po sestavení knižního týmu kniha během 30denního předprodeje vybírá částku potřebnou na vydání. Čtenáři si
            za příspěvek vybírají z jedinečných odměn.
          </Text>
        </div>
        <div className="col-sm pull-right" style={{ padding: "0" }}>
          <div className="d-flex justify-content-center mb-4">
            <p className={be(MODULE_NAME, "number")}>3</p>
          </div>
          <Text bold small greenReward>
            Když se peníze úspěšně vyberou, knižní tým začne pracovat na finální podobě knihy. Ta po vytištění putuje
            mezi předplatitele, do knihkupectví a e-shopů.
          </Text>
        </div>
      </div>
    )}
    {isMobile() && (
      <div style={{ paddingLeft: "5px" }}>
        <div className="row justify-content-start">
          <div className="col-sm d-flex justify-content-start" style={{ width: "20%" }}>
            <p className={be(MODULE_NAME, "number")}>1</p>
          </div>
          <div className="col-sm pb-5" style={{ width: "71%" }}>
            <Text bold small greenReward>
              Na Pointu přicházejí se svými rukopisy nejen začínající autoři, ale i ti, kterým nevyhovují tradiční
              nakladatelé. Vybírají si z knižních kolegů, mezi které patří třeba ilustrátor, redaktorka nebo korektor.
            </Text>
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-sm d-flex justify-content-start" style={{ width: "20%" }}>
            <p className={be(MODULE_NAME, "middleMobile")}>2</p>
          </div>
          <div className="col-sm pb-5" style={{ width: "71%" }}>
            <Text bold small greenReward>
              Po sestavení knižního týmu jde kniha do předprodeje, během 30 dnů vybírá částku potřebnou na vydání.
              Čtenáři přispívají na vydání knih a za podporu si vybírají z jedinečných odměn.
            </Text>
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-sm d-flex justify-content-start" style={{ width: "20%" }}>
            <p className={be(MODULE_NAME, "number")}>3</p>
          </div>
          <div className="col-sm" style={{ width: "71%" }}>
            <Text bold small greenReward>
              Když se peníze úspěšně vyberou, knižní tým začne pracovat na finální podobě knihy. Po vytištění kniha
              putuje do knihkupectví a e-shopů.
            </Text>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default BaseInfo;
