import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { get as g, isEmpty } from "lodash";

import "./select.css";

class SelectComponent extends Component {
  handleChange = selectedOption => {
    const { name, onChange, isMulti } = this.props;
    if (isMulti) {
      onChange({
        target: {
          name,
          value: (selectedOption || []).map(o => o.value)
        }
      });
    } else {
      onChange({
        target: {
          name,
          value: g(selectedOption, "value", null)
        }
      });
    }
  };

  render() {
    const { onChange, className, value, options, ...restProps } = this.props;

    return (
      <div className="select-input">
        <Select
          classNamePrefix="select-input"
          name="select"
          onChange={this.handleChange}
          options={options}
          value={
            restProps.isMulti ? options.filter(o => value.includes(o.value)) : options.find(o => o.value === value)
          }
          {...restProps}
        />
      </div>
    );
  }
}

SelectComponent.defaultProps = {
  onChange: () => {},
  options: [],
  className: "",
  value: [],
  defaultValue: ""
};

SelectComponent.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object)
};

export default SelectComponent;
