import React from "react";
import { bm } from "../../utils/bliss";

import "./line.css";

const MODULE_NAME = "Line";

const Line = () => <div className={bm(MODULE_NAME)} />;

export default Line;
