import React, { Component } from "react";
import PropTypes from "prop-types";

import { be, bm } from "../../utils/bliss";
import Avatar from "../avatar/avatar";
import Text from "../text/text";

import "./userCard.css";

const MODULE_NAME = "UserCard";

class UserCard extends Component {
  render() {
    const { picture, description, userName, pointer, hover, greenReward, dark, ...restProps } = this.props;
    // TODO: onClick redirect to profile detail when BE is returning the userId
    return (
      <div {...restProps} className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "avatar")}>
          <Avatar image={picture} xs />
        </div>
        <div className={be(MODULE_NAME, "body")}>
          <div className={be(MODULE_NAME, "author")}>
            <Text greenReward={greenReward} bold dark={dark} hover={hover} lineHeightSmall pointer={pointer}>
              {userName}
            </Text>
          </div>
          <div className={be(MODULE_NAME, "role")}>
            <Text component="div" greenReward={greenReward} light lowercase medium>
              {description}
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

UserCard.defaultProps = {
  description: "",
  userName: "",
  picture: ""
};

UserCard.propTypes = {
  description: PropTypes.string,
  picture: PropTypes.string,
  userName: PropTypes.string
};

export default UserCard;
