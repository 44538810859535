import React from "react";
import moment from "moment";

import { get as g } from "lodash";

import Text from "../../../../components/text/text";

import { be } from "../../../../utils/bliss";

const MODULE_NAME = "ProjectDetailScreen";

const GetBibliographyContent = ({ project }) => (
  <div>
    <Text bold greenReward xl>
      Bibliografické údaje
    </Text>
    <div className={be(MODULE_NAME, "spacing", "sm")} />
    <ul style={{ listStyleType: "none", padding: "0", overflow: "auto" }}>
      <li style={{ float: "left", width: "20em", paddingBottom: "15px" }}>
        <div>
          <Text greenReward small>
            Název
          </Text>
        </div>
        <div>
          <Text bold greenReward>
            {project.name}
          </Text>
        </div>
      </li>
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      <li style={{ float: "left", width: "20em", paddingBottom: "15px" }}>
        <div>
          <Text greenReward small>
            Autor
          </Text>
        </div>
        <div>
          {g(project, "author", []).map(author => (
            <div key={author.id}>
              <Text bold greenReward>
                {author.name} {author.surname}
              </Text>
            </div>
          ))}
        </div>
      </li>
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.bibliographyEan ? (
        <li style={{ float: "left", width: "20em", paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              EAN
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {project.bibliographyEan}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.bibliographyIsbn ? (
        <li style={{ float: "left", width: "20em", paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              ISBN
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {project.bibliographyIsbn}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.numberOfPages ? (
        <li style={{ float: "left", width: "20em", paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              Počet stran
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {project.numberOfPages}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.bibliographyFormat ? (
        <li style={{ paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              Formát
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {project.bibliographyFormat}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.bibliographyBindings ? (
        <li style={{ float: "left", width: "20em", paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              Vazba
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {project.bibliographyBindings}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.bibliographyDimensions ? (
        <li style={{ paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              Rozměry
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {project.bibliographyDimensions}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
      <div className={be(MODULE_NAME, "spacing", "xs", "d-sm-block d-md-none")} />
      {project.releaseDate ? (
        <li style={{ paddingBottom: "15px" }}>
          <div>
            <Text greenReward small>
              Datum vydání
            </Text>
          </div>
          <div>
            <Text bold greenReward>
              {moment(project.releaseDate).format("DD. MM. YYYY")}
            </Text>
          </div>
        </li>
      ) : (
        ""
      )}
    </ul>
  </div>
);

export default GetBibliographyContent;
