/* eslint-disable class-methods-use-this */
import { get as g } from "lodash";

import CoreAPIService from "./coreAPIService";

class ProjectService {
  async getContract(projectId, handshakeId) {
    return new Promise((res, rej) => {
      CoreAPIService.get(`projects/${projectId}/handshakes/${handshakeId}/contract`)
        .then(response => {
          res(response.data);
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }
}

export default new ProjectService();
