import React, { Component } from "react";
import { Provider } from "react-redux";
import { Switch } from "react-router";
import { Router } from "react-router-dom";

import { RestfulProvider } from "restful-react";

import StorageService from "./services/storageService";

import "bootstrap/dist/css/bootstrap.css";
import "./AppMain.css";
import "./stylesheets/index.css";

import * as config from "./services/config";

import { store, history } from "./redux/store";
import LoginPage from "./pages/login/loginPage";
import RegisterPage from "./pages/register/registerPage";
import LayoutWrapper from "./layoutWrapper";
import { AuthenticatedRoute } from "./components/authenticatedRoute/authenticatedRoute";
import PublicRoute from "./components/publicRoute/publicRoute";
import "./i18n/i18n";
import ForgottenPassword from "./pages/forgottenPassword/forgottenPassword";
import ConfirmEmail from "./pages/confirmEmail/confirmEmail";
import LandingPage from "./pages/landing/newLandingPage";
import ProjectsPage from "./pages/projects/projectsPage";
import ProjectStatePage from "./pages/projects/projectsStatePage";
import ResetPasswordPage from "./pages/resetPassword/resetPasswordPage";
import ProjectDetail from "./pages/details/project/projectDetail";
import AuthorHomePage from "./pages/home/authorHomePage";
import ContributorHomePage from "./pages/home/contributorHomePage";
import NotAllowedPage from "./pages/notAllowed/notAllowedPage";
import ContactPage from "./pages/contact/contactPage";
import PostList from "./pages/knowledgeBase/postList";
import PostView from "./pages/knowledgeBase/postView";
import ShopCart from "./pages/shopCart/shopCart";
import ShopDelivery from "./pages/shopDelivery/shopDelivery";
import ShopRecap from "./pages/shopRecap/shopRecap";
import OrderList from "./pages/orderList/orderList";
import MessagePage from "./pages/messagePage/messagePage";
import MessageListPage from "./pages/messageListPage/messageListPage";
import GA from "./components/ga/ga";

const requestOptions = () => {
  const options = {};
  if (StorageService.hasToken()) {
    options.headers = { Authorization: `Bearer ${StorageService.get(config.TOKEN_STORAGE_NAME)}` };
  }
  return options;
};

class App extends Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    history.listen((location, action) => {
      GA.sendEvent({
        eventCategory: "virtualPV",
        eventAction: "virtualUrl",
        eventLabel: location.pathname
      });
    });
  }

  render() {
    console.log("Environmnt " + process.env.REACT_APP_ENV);

    return (
      <>
        {(process.env.REACT_APP_ENV == "staging" || process.env.REACT_APP_ENV == "development") && (
          <div className="env-info">{process.env.REACT_APP_ENV == "staging" ? "STAGING" : "DEV"}</div>
        )}
        <RestfulProvider base={config.API} requestOptions={requestOptions}>
          <Provider store={store}>
            <Router history={history}>
              <Switch>
                <PublicRoute component={NotAllowedPage} path="/not-allowed" {...this.props} history={history} />
                <PublicRoute component={LandingPage} path="/landing/:faq?" {...this.props} history={history} />
                <PublicRoute component={ProjectStatePage} path="/projects/:state" {...this.props} history={history} />
                <PublicRoute component={ProjectsPage} path="/projects" {...this.props} history={history} />
                <PublicRoute component={ProjectDetail} path="/project/:id" {...this.props} history={history} />
                <PublicRoute component={ConfirmEmail} path="/confirm-email/:email" {...this.props} history={history} />
                <PublicRoute component={ResetPasswordPage} path="/reset-password" {...this.props} history={history} />
                <PublicRoute
                  component={ForgottenPassword}
                  path="/forgotten-password"
                  {...this.props}
                  history={history}
                />
                <PublicRoute component={RegisterPage} path="/register/:role?" {...this.props} history={history} />
                <PublicRoute component={AuthorHomePage} path="/authorPage" {...this.props} history={history} />
                <PublicRoute
                  component={ContributorHomePage}
                  path="/contributorPage"
                  {...this.props}
                  history={history}
                />
                <PublicRoute component={LoginPage} path="/login" {...this.props} history={history} />
                <PublicRoute component={LoginPage} path="/thankyou" {...this.props} history={history} />
                <PublicRoute component={ContactPage} path="/contact" {...this.props} history={history} />
                <PublicRoute component={PostView} path="/knowledgebase/:slug" {...this.props} history={history} />
                <PublicRoute component={PostList} path="/knowledgebase" {...this.props} history={history} />
                <PublicRoute component={ShopCart} path="/cart" {...this.props} history={history} />
                <PublicRoute component={ShopDelivery} path="/delivery" {...this.props} history={history} />
                <PublicRoute component={ShopRecap} path="/recap" {...this.props} history={history} />
                <PublicRoute component={OrderList} path="/orderlist" {...this.props} history={history} />
                <PublicRoute component={MessagePage} path="/messenger" {...this.props} history={history} />
                <PublicRoute component={MessageListPage} path="/inbox" {...this.props} history={history} />
                <AuthenticatedRoute component={LayoutWrapper} path="/" {...this.props} history={history} />
              </Switch>
            </Router>
          </Provider>
        </RestfulProvider>
      </>
    );
  }
}

export default App;
