import React from "react";
import Disqus from "disqus-react";

export default class DisqusExtended extends Disqus.DiscussionEmbed {
  getDisqusConfig(config) {
    return function () {
      this.page.identifier = config.identifier;
      this.page.url = config.url;
      this.page.title = config.title;

      this.callbacks.onNewComment = [config.onNewComment];
      // SSO
      this.page.api_key = config.api_key;
      this.page.remote_auth_s3 = config.remote_auth_s3;
      this.page.sso = config.sso;
    };
  }
}
