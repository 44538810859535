import React, { Component } from "react";
import PropTypes from "prop-types";
import { get as g } from "lodash";
import { connect } from "react-redux";

import "./fundingTabs.sass";
import { be, bm } from "../../../utils/bliss";
import { canFollowProject, isMobile } from "../../../utils/utils";
import Button from "../../../components/button/button";
import Link from "../../../components/link/link";
import EnumsDuck from "../../../redux/ducks/enums";
import WizardDuck from "../../../redux/ducks/wizard";
import UserDuck from "../../../redux/ducks/user";
import FundingFollow from "../../../components/fundingFollow/fundingFollow";

import { isEmpty } from "lodash";

const MODULE_NAME = "FundingTabs";

class FundingTabs extends Component {
  state = {
    tab: 0,
    id: Math.random(),
    position: "relative",
    top: "-55px",
    margin: "0 -1500px",
    padding: "0 1500px",
    index: 0,
    background: "#FAEDE5",
    isHide: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.fixTabs);
    window.addEventListener("scroll", this.hideBar);
    this.setState({
      isHide: true
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar);
  }

  onTabChange = tab => {
    this.setState({
      tab
    });
    if (window.scrollY > 660) {
      window.scrollTo(0, 660);
    }
  };

  fixTabs = () => {
    if (window.scrollY > 660 && !isMobile()) {
      this.setState({
        position: "fixed",
        top: "0px",
        margin: "0 -1500px",
        padding: "0 1500px",
        index: 100,
        background: "#FAEDE5"
      });
    } else {
      this.setState({
        position: "relative",
        top: "-55px",
        margin: "0",
        padding: "0",
        index: 0,
        background: "#FAEDE5"
      });
    }
  };

  hideBar = () => {
    const { isHide } = this.state;

    window.scrollY < 660 ? !isHide && this.setState({ isHide: true }) : isHide && this.setState({ isHide: false });
  };

  followToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    const { project, dispatch, detail, user } = this.props;

    if (project.followed) {
      dispatch(WizardDuck.unfollowProject(user.id, project.id, detail));
    } else {
      dispatch(WizardDuck.followProject(user.id, project.id, detail));
    }
  };

  render() {
    const { data, onClick, project, user } = this.props;
    const { tab, id } = this.state;
    const activeDataContent = g(data, `${tab}.content`, null);
    const classHide = this.state.isHide ? "hide" : "";

    var isUserAuthor = false;
    project.author.forEach(author => {
      if (author.id === user.id) {
        isUserAuthor = true;
        return;
      }
    });

    const styles = {
      maxWidth: tab === 0 && !isEmpty(user) && isUserAuthor ? "100%" : ""
    };

    return (
      <div className={bm(MODULE_NAME)}>
        <div
          key={`tab-${id}`}
          style={{
            position: this.state.position,
            top: this.state.top,
            margin: this.state.margin,
            padding: this.state.padding,
            zIndex: this.state.index,
            background: this.state.background
          }}
          className={be(MODULE_NAME, "headerContainer")}
        >
          {data.map((d, idx) => (
            <div
              key={idx}
              className={be(MODULE_NAME, "header", tab === idx ? "active" : "")}
              onClick={() => this.onTabChange(idx)}
            >
              {d.header}
            </div>
          ))}{" "}
          {!isMobile() && (
            <div className={classHide}>
              <div className={be(MODULE_NAME, "donateButton")}>
                <Button onClick={onClick} middleRadius widePrimary target="_blank">
                  Přispět na vydání
                </Button>
                <FundingFollow project={project} />
                <Link
                  target="_blank"
                  to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                >
                  <img style={{ height: "30px", paddingLeft: "3px" }} src="/images/svg/shareNew.svg" />
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className={be(MODULE_NAME, "body")} style={styles}>
          {activeDataContent}
        </div>
      </div>
    );
  }
}

FundingTabs.defaultProps = {
  defaultTab: 0,
  data: [],
  project: {}
};

FundingTabs.propTypes = {
  data: PropTypes.array,
  defaultTab: PropTypes.number
};

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state),
  user: UserDuck.getUser(state),
  project: WizardDuck.getDetail(state)
});

export default connect(mapStateToProps)(FundingTabs);
