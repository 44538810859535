import React, { Fragment } from "react";

import { connect } from "react-redux";

import { be } from "../../../../utils/bliss";

import Button from "../../../../components/button/button";
import Forms from "../../../../components/forms/form";
import News from "../../../../components/news/news";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

import FormDuck from "../../../../redux/ducks/forms";
import WizardDuck from "../../../../redux/ducks/wizard";

const MODULE_NAME = "ProjectDetailScreen";

const GetNewsContent = ({ isEditable, project, dispatch }) => {
  const onNewsSubmit = (data = {}) => {
    dispatch(FormDuck.resetForm(FormDuck.formTypes.news));
    dispatch(WizardDuck.createProjectNews(project.id, data));
  };

  const onSetInput = (formName, name, value) => {
    if (name === "content" && value.length >= 600) {
      window.alertify.error("Maximální počet znaků textu je 600!");
      dispatch(FormDuck.setInput(formName, name, value.slice(0, 600)));
    }
    if (name === "title" && value.length >= 65) {
      window.alertify.error("Maximální počet znaků textu je 65!");
      dispatch(FormDuck.setInput(formName, name, value.slice(0, 65)));
    }
  };

  return (
    <Fragment>
      <Headline bold component="h3" pageSub>
        {isEditable ? "" : ""}
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "lg")} />
      <div className="row">
        {isEditable && (
          <div className="col-md-7">
            <div className={be(MODULE_NAME, "newsPanel")}>
              <Text light uppercase xs>
                Nová novinka
              </Text>
              <div className={be(MODULE_NAME, "spacing", "xs")} />
              <Forms
                inputs={[
                  {
                    placeholder: "Nadpis novinky",
                    label: null,
                    name: "title",
                    required: true
                  },
                  {
                    placeholder: "Text novinky",
                    label: null,
                    type: "textarea",
                    wide: true,
                    name: "content",
                    required: true
                  },
                  {
                    placeholder: "Hypertextový odkaz",
                    label: null,
                    name: "link",
                    entityType: "link"
                  }
                ]}
                name={FormDuck.formTypes.news}
                onSetInput={onSetInput}
                onSubmit={onNewsSubmit}
              >
                <div className="col">
                  <div className="text-center">
                    <div className={be(MODULE_NAME, "spacing", "xs")} />
                    <Button type="submit">Publikovat novinku</Button>
                  </div>
                </div>
              </Forms>
            </div>
          </div>
        )}
        <div className={isEditable ? "mt-4 mt-md-0 col-md-5 col-lg-4 offset-lg-1" : "col-md-12"}>
          <News isEditable={isEditable} project={project} />
        </div>
      </div>
    </Fragment>
  );
};

export default connect()(GetNewsContent);
