import React, { Fragment } from "react";
import { get as g, isEmpty } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";

import { be } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";

import UIDuck from "../../../../redux/ducks/ui";
import EnumsDuck from "../../../../redux/ducks/enums";
import UserDuck from "../../../../redux/ducks/user";
import WizardDuck from "../../../../redux/ducks/wizard";

import Button from "../../../../components/button/button";
import DetailBubble from "../../../../components/bubbles/detailBubble/detailBubble";
import Headline from "../../../../components/headline/headline";

const MODULE_NAME = "ProjectDetailScreen";

class GetOtherContent extends React.Component {
  constructor() {
    super();
    this.state = {
      showOthers: isMobile()
    };
  }

  confirmHandshake = (handshake = {}) => {
    const { dispatch, project } = this.props;
    // if (g(handshakeStates, 'requested.name', '') === handshake.state) {
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
        handshake,
        project
      })
    );
    // }
  };

  removeHandshake = (handshake = {}) => {
    const {
      t,
      dispatch,
      match: { params },
      user
    } = this.props;

    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.removeHandshakeText"),
        callback: () => {
          dispatch(UserDuck.removeHandshake(params.id, handshake.id)).then(() => {
            window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
            dispatch(WizardDuck.setProjectById(params.id));
            dispatch(WizardDuck.setDetail(params.id, user.id));
          });
        }
      })
    );
  };

  render() {
    const { project = {}, data = [], history, handshakeStates, dispatch } = this.props;
    const { handshakesBySubroleId } = project;
    const { showOthers } = this.state;

    return (
      <Fragment>
        {showOthers && (
          <div className={be(MODULE_NAME, "parameters")}>
            <Headline bold component="h3" light xs>
              Ostatní možnosti
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            {data.map(d => {
              const handshakes = handshakesBySubroleId[d.id] || [];

              return (
                <DetailBubble
                  key={d.id}
                  cancelHandshake={this.removeHandshake}
                  complete={!!handshakes.find(hs => hs.state === g(handshakeStates, "accepted.name", ""))}
                  confirmHandshake={this.confirmHandshake}
                  description={d.description}
                  handshakes={handshakes}
                  icon="/images/svg/korektura.svg"
                  label="Najít kolegu"
                  locked={!project.printer}
                  onClick={() => {
                    if (isEmpty(g(project, "handshakes", []))) {
                      dispatch(
                        UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
                          text:
                            "Jste si jistí, že máte vhodně zvolené žánry a tagy? Po poptání kolegů již nebude možnost to změnit...",
                          callback: () => {
                            history.push(`/contributors/${project.id}?subroles=[${d.id}]`);
                          }
                        })
                      );
                    } else {
                      history.push(`/contributors/${project.id}?subroles=[${d.id}]`);
                    }
                  }}
                  title={d.name}
                />
              );
            })}
          </div>
        )}
        <div className="row">
          <div className="col">
            {
              <div className="text-center">
                <div className={be(MODULE_NAME, "spacing", "lg")} />
                <Button
                  onClick={() =>
                    this.setState({
                      showOthers: !showOthers
                    })
                  }
                  secondary
                  sm
                >
                  {showOthers ? "Ukázat méně" : "Ukázat více"}
                </Button>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  user: UserDuck.getUser(state)
});

export default compose(translate("translations"), connect(mapStateToProps), withRouter)(GetOtherContent);
