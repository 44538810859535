import React from "react";
import T from "prop-types";
import { startsWith } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { compose, pure } from "recompose";
import { bm, be } from "../../utils/bliss";

import "./link.css";

const MODULE_NAME = "Link";

const withLink = compose(pure);

const renderLink = ({
  children,
  to,
  white,
  light,
  small,
  bold,
  underline,
  uppercase,
  big,
  align,
  greenReward,
  regular,
  paddingMob,
  leftSide,
  medium,
  onClick,
  ...otherProps
}) => {
  const moduleClassName = bm(MODULE_NAME, {
    white,
    light,
    bold,
    small,
    uppercase,
    greenReward,
    regular,
    underline,
    medium,
    align,
    leftSide,
    paddingMob,
    big
  });

  if (startsWith(to, "http") || startsWith(to, "mailto")) {
    return (
      <a className={moduleClassName} href={to} onClick={onClick} {...otherProps}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink className={moduleClassName} onClick={onClick} to={to} {...otherProps}>
      {children}
    </RouterLink>
  );
};

const Link = withLink(renderLink);

Link.propTypes = {};

export default Link;
