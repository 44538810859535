import React from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { bm, be } from "../../../utils/bliss";

import "./tag.css";

const MODULE_NAME = "Tag";

const tpComponent = (id, text) => (
  <Tooltip key={id} className="in" id={id} placement="bottom">
    {text}
  </Tooltip>
);

const Tag = ({ id, name, onClick, selected, className, secondary }) => (
  <div className={bm(MODULE_NAME, { secondary }, [{ isActive: selected }, className])} id={id} onClick={onClick}>
    <OverlayTrigger overlay={tpComponent(id, `test tooltip ${name}`)} placement="bottom">
      <span>{name}</span>
    </OverlayTrigger>
  </div>
);

Tag.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

export default Tag;
