import React, { Component } from "react";
import Get from "restful-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";

import "./newLandingPage.css";

import { isMobile } from "../../utils/utils";
import { be } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import Button from "../../components/button/button";
import Screen from "../../components/screen/screen";
import EnumsDuck from "../../redux/ducks/enums";
import StorageService from "../../services/storageService";
import WizardService from "../../services/wizardService";
import Header from "../../components/header/header";
import Text from "../../components/text/text";
import UserDuck from "../../redux/ducks/user";
import HowToContributor from "./components/howToContributor/howToContributor";
import Reviews from "./components/reviews/reviews";
import ReviewsPagination from "./components/reviews/reviewsPagination";
import StatisticsLine from "./components/statisticsLine/statisticsLine";
import ProjectStateRow from "./components/projectStateRow/projectStateRow";
import MainNewsletter from "./components/mainNewsletter/mainNewsletter";
import Loader from "../../components/loader/loader";
import DataLayerPush from "../../components/gtm/dataLayerPush";

const MODULE_NAME = "UserHomePage";

export const STATES_WHITE_LIST = {
  released: {
    name: "Vydáno"
  },
  funding: {
    name: "Financování"
  },
  proposal: {
    name: "V šuplíku"
  }
};

class ContributorHomePage extends Component {
  state = {
    loading: true,
    filters: {
      page: 1,
      order: "desc",
      limit: "16"
    }
  };

  componentWillMount() {
    const { dispatch } = this.props;
    let promises = [dispatch(EnumsDuck.setRoles()), dispatch(EnumsDuck.loadCatalog())];
    if (StorageService.hasToken()) {
      promises = [...promises, dispatch(UserDuck.setUserByToken("", true, false)), dispatch(EnumsDuck.setBindings())];
    }
    Promise.all(promises).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  getType = ({ state, moonTarget }) => {
    const { projectStates } = this.props;
    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        if (!moonTarget) return "waitingForPresale";
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
        return "released";
    }
  };

  render() {
    const {
      history,
      location: { search },
      projectStates
    } = this.props;
    const isUser = !!search;

    if (isEmpty(projectStates)) return <Loader />;

    return (
      <Screen
        className="/src/pages/newLandingPage/newLandingPage"
        header={<Header history={history} isUser={isUser} landing />}
      >
        <Get
          path={`projects?page=${this.state.filters.page}&order=${this.state.filters.order}&limit=${this.state.filters.limit}&groupByState=true`}
          resolve={async projects => {
            const mapProject = async projectData => {
              const project = projectData;
              project.type = this.getType(project);
              return project;
            };
            const data = projects;
            return {
              ...data,
              released: await Promise.all(data.released.map(p => mapProject(p)))
            };
          }}
        >
          {(projects, { loading }) => {
            if (this.state.loading || loading) return <Loader />;
            return (
              <React.Fragment>
                <div className={be(MODULE_NAME, "head")}>
                  <div className={be(MODULE_NAME, "net")} />
                  <div className={be(MODULE_NAME, "introWrapper")}>
                    <div className="row">
                      <div className="col-md-6">
                        {!isMobile() ? (
                          <Headline greenReward component="h1" hero>
                            Pracuj na knihách, které si vybereš
                          </Headline>
                        ) : (
                          <Headline greenReward component="h1" heroMobile>
                            Pracuj na knihách, které si vybereš
                          </Headline>
                        )}
                        <div className="pt-5">
                          <Text greenReward>
                            Máš praxi s grafikou, sazbou, redakcí či korekturou pro nakladatelství? Jsi marketér(ka)
                            nebo třeba influencer(ka)? Přidej se k Pointě a pomoz svými dovednostmi na svět novým
                            knihám.
                          </Text>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={be(MODULE_NAME, "introImageWrapper")}>
                          <img className={be(MODULE_NAME, "introImage")} src="/images/svg/contributorHome.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={be(MODULE_NAME, "headWrap")}>
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className={be(MODULE_NAME, "panelBox", "author")}>
                          <div className={be(MODULE_NAME, "introButton")}>
                            <DataLayerPush
                              event={{
                                event: "button_bytkolega_contributorpage",
                                funnel: "kolega",
                                path: "step2"
                              }}
                            >
                              <Button small smallRadius xl href="/register/contributor">
                                Přidám se k Pointě
                              </Button>
                            </DataLayerPush>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobile() ? (
                    <div className={be(MODULE_NAME, "headlineStep", "medium")} />
                  ) : (
                    <div className={be(MODULE_NAME, "headlineStep", "large")} />
                  )}
                </div>
                <div style={{ background: "#ffffff", margin: "0 -1500px", padding: "0 1500px" }}>
                  <HowToContributor />
                </div>
                <Get path="/pointaStatistics">
                  {(statistics, { loading: statisticsLoading }) => {
                    if (isEmpty(statistics) || statisticsLoading)
                      return <div style={{ background: "#DFEFF6", margin: "0 -1500px", padding: "0 1500px" }} />;
                    return (
                      <div
                        style={{
                          background: "#DFEFF6",
                          margin: "0 -1500px",
                          padding: "0 1500px 0 1500px",
                          textAlign: "center"
                        }}
                      >
                        <StatisticsLine contributors statistics={statistics} />
                      </div>
                    );
                  }}
                </Get>
                <Get path="/pointaReviews?type=contributorReview">
                  {(reviews, { loading: reviewsLoading }) => {
                    if (isEmpty(reviews) || reviewsLoading)
                      return (
                        <div
                          style={{ background: "#ffffff", margin: "0 -1500px", padding: "0 1500px", height: "20px" }}
                        />
                      );
                    return (
                      <div style={{ background: "#ffffff", margin: "0 -1500px", padding: "0 1500px" }}>
                        <div className={be(MODULE_NAME, "headlineStep", "large")} />
                        <div className="text-center">
                          <Headline greenReward>Kolegové o Pointě</Headline>
                          <div className="pt-4 pb-4">
                            <div className={be(MODULE_NAME, "headlineStep", "medium")} />

                            {isMobile() && (
                              <div className="row justify-content-center mt-6">
                                {" "}
                                <ReviewsPagination reviews={reviews} />
                              </div>
                            )}
                            {!isMobile() && (
                              <div className="row mt-6">
                                {" "}
                                <Reviews reviews={reviews} />{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Get>
                <ProjectStateRow />
                <div
                  style={{
                    background: "#ffffff",
                    margin: "0 -1500px",
                    padding: "0 1500px",
                    borderTop: "1px solid #E6E3DF",
                    borderBottom: "1px solid #E6E3DF"
                  }}
                >
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "small")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                  <div>
                    <div className={be(MODULE_NAME, "newsletter")}>
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-12 text-center mb-4">
                            <Text lg bold greenReward>
                              Chcete odebírat novinky emailem?
                            </Text>
                          </div>
                          <div className="col-md-6 col-12 d-flex justify-content-center mb-4">
                            <MainNewsletter />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobile() && <div className={be(MODULE_NAME, "headlineStep", "medium")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "small")} />}
                  {!isMobile() && <div className={be(MODULE_NAME, "headlineStep", "large")} />}
                </div>
              </React.Fragment>
            );
          }}
        </Get>
      </Screen>
    );
  }
}

ContributorHomePage.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ContributorHomePage);
