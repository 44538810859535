/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { Fragment } from "react";
import { get as g, isEmpty } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { be } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";

import UserDuck from "../../../../redux/ducks/user";

import Avatar from "../../../../components/avatar/avatar";
import Text from "../../../../components/text/text";

const MODULE_NAME = "ProjectDetailScreen";

const AuthorInfo = ({ project, user, history }) => {
  const isUser = !isEmpty(user);

  const showUser = userId => {
    history.push(`/profile/${userId}`);
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {!isMobile() && project.isPublicProject && project.type === "financing" ? (
        <Fragment>
          <div className={be(MODULE_NAME, "authors")}>
            {g(project, "author", []).map(author => (
              <div key={author.id} className={be(MODULE_NAME, "authorInfo")}>
                <div className={be(MODULE_NAME, "authorAvatar")}>
                  <Avatar image={author.profilePic} xs />
                </div>
                <div key={author.id} className={be(MODULE_NAME, "authorFinancing")}>
                  {!isUser && (
                    <div className={be(MODULE_NAME, "authorFinancingName")}>
                      <Text bold greenReward>
                        {author.name} {author.surname}
                      </Text>
                    </div>
                  )}
                  {isUser && (
                    <div className={be(MODULE_NAME, "authorFinancingName")} onClick={() => showUser(author.id)}>
                      <Text bold greenReward hover pointer>
                        {author.name} {author.surname}
                      </Text>
                    </div>
                  )}
                  <div>
                    <Text greenReward>Autor</Text>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={be(MODULE_NAME, "authorTitle")}>Autor knihy</div>
          <div className={be(MODULE_NAME, "authors")}>
            {g(project, "author", []).map(author => (
              <div key={author.id} className={be(MODULE_NAME, "author")}>
                <div className={be(MODULE_NAME, "authorAvatar")}>
                  <Avatar image={author.profilePic} xs />
                </div>
                {!isUser && (
                  <div className={be(MODULE_NAME, "authorName")}>
                    <Text bold dark>
                      {author.name} {author.surname}
                    </Text>
                  </div>
                )}
                {isUser && (
                  <div className={be(MODULE_NAME, "authorName")} onClick={() => showUser(author.id)}>
                    <Text bold dark hover pointer>
                      {author.name} {author.surname}
                    </Text>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state)
});

export default compose(connect(mapStateToProps), withRouter)(AuthorInfo);
