import React, { Component } from "react";
import PropTypes from "prop-types";
import { last } from "lodash";
import { be, bm } from "../../../utils/bliss";

import "./todos.css";

const MODULE_NAME = "TodosList";

class TodosList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: props.inputs
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.inputs.length !== nextProps.inputs.length) {
      this.setState({
        inputs: nextProps.inputs
      });
    }
  }

  handleChange = (inputs = this.state.inputs) => {
    const { onChange, name } = this.props;
    onChange({
      target: {
        name,
        value: inputs
      }
    });
  };

  addNew = () => {
    const { inputs } = this.state;
    const lastInput = last(inputs);
    const newInputs = [
      ...inputs,
      {
        ...lastInput,
        name: `${inputs.length}`,
        value: "",
        placeholder: "",
        data: undefined
      }
    ];
    this.setState({
      inputs: newInputs
    });
  };

  onChange = ({ target }) => {
    let value = target.value;

    if (value.length >= 65) {
      window.alertify.error("Maximální počet znaků textu je 65!");
      value = value.slice(0, 65);
    }
    const { inputs } = this.state;
    const newInputs = inputs.map(input => {
      if (`${input.name}` === `${target.name}`) {
        return {
          ...input,
          value: target.value
        };
      }
      return input;
    });

    this.setState(
      {
        inputs: newInputs
      },
      () => this.handleChange(newInputs)
    );
  };

  onDelete = (input = {}) => {
    if (input.data) {
      this.props.onDelete(input.data);
    } else {
      const newInputs = this.state.inputs.filter(i => i.name !== input.name);
      this.setState(
        {
          inputs: newInputs
        },
        () => this.handleChange(newInputs)
      );
    }
  };

  render() {
    const {
      newBtn,
      onDelete,
      className,
      inputs: propsInputs,
      originalInputs,
      disableDelete,
      onboarding,
      long,
      currency,
      ...restProps
    } = this.props;

    const { inputs } = this.state;

    return (
      <ul className={bm(MODULE_NAME, { onboarding, long })}>
        {inputs.map((input, idx) => {
          const { label, extra, ...restInputProps } = input;

          return (
            <li key={`input-${idx}`} className={be(MODULE_NAME, "item")}>
              <div key={`input-${idx}`} className="form-group">
                <div key={`input-${idx}`} className="input-group">
                  {label && <label className={be(MODULE_NAME, "label")}>{label}</label>}
                  <div className="d-flex flex-md-grow-1 align-items-center">
                    <input
                      className={be(MODULE_NAME, "input", { onboarding, currency, long }, "form-control")}
                      {...restProps}
                      name={`${idx}`}
                      {...restInputProps}
                      onChange={this.onChange}
                      type={currency ? "number" : "text"}
                    />
                    {currency && <span className={be(MODULE_NAME, "currency")}>Kč</span>}
                    {(originalInputs[idx] || {}).extra || input.extra || null}
                  </div>
                  {!disableDelete && (
                    <div className={be(MODULE_NAME, "remove")} onClick={() => this.onDelete(input)}>
                      <i className="fa fa-trash" />
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}

        {newBtn && (
          <li className={be(MODULE_NAME, "item")}>
            <div className={be(MODULE_NAME, "addNew")} onClick={this.addNew}>
              {newBtn}
            </div>
          </li>
        )}
      </ul>
    );
  }
}

TodosList.defaultProps = {
  inputs: [],
  newBtn: "",
  className: "",
  name: "",
  onChange: () => {},
  onDelete: () => {},
  originalInputs: [],
  disableDelete: false,
  currency: false
};

TodosList.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.bool,
  disableDelete: PropTypes.bool,
  inputs: PropTypes.array,
  name: PropTypes.string,
  newBtn: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string]),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  originalInputs: PropTypes.array
};

export default TodosList;
