import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { isEmpty, get as g } from "lodash";

import "./thread.css";

import ThreadDuck from "../../redux/ducks/thread";
import WizardDuck from "../../redux/ducks/wizard";
import UserDuck from "../../redux/ducks/user";
import Headline from "../../components/headline/headline";
import Avatar from "../../components/avatar/avatar";
import Text from "../../components/text/text";
import Comment from "./comment/comment";
import CommentForm from "./comment/commentForm";
import Loader from "../loader/loader";
import Link from "../link/link";

class Thread extends Component {
  componentDidMount() {
    this.reloadThread();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(ThreadDuck.clearThread());
    dispatch(ThreadDuck.setLoading("thread", true));
  }

  get showCommentsForm() {
    return this.props.isLoggedIn && this.props.thread.isCommentable;
  }

  reloadThread = (showLoading = true) => {
    const { project, dispatch } = this.props;
    dispatch(ThreadDuck.getThreadByProjectId(g(project, "id"), showLoading));
  };

  renderLoading = () => {
    return <Loader />;
  };

  renderForm = () => {
    const {
      thread: { id },
      user
    } = this.props;
    if (this.showCommentsForm) {
      return <CommentForm reloadThread={this.reloadThread} isSubComment={false} threadId={id} user={user} />;
    } else {
      return (
        <div className="Thread-message">
          Pro vstup do diskuze se musíš nejprve přihlásit do svého účtu v Pointě{" "}
          <Link underline to="/login">
            zde
          </Link>
          .
        </div>
      );
    }
  };

  render() {
    const {
      loading,
      thread: { id, commentsTree },
      user
    } = this.props;

    if (loading) {
      return this.renderLoading();
    }

    return (
      <div className="Thread">
        {this.renderForm()}
        {commentsTree.map(c => (
          <Comment
            key={c.id}
            showCommentsForm={this.showCommentsForm}
            threadId={id}
            user={user}
            comment={c}
            reloadThread={this.reloadThread}
            isSubComment={false}
          />
        ))}
      </div>
    );
  }
}

Thread.defaultProps = {};

Thread.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  thread: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  thread: ThreadDuck.getThread(state),
  loading: ThreadDuck.isThreadLoading(state),
  isLoggedIn: !isEmpty(UserDuck.getUser(state)),
  user: UserDuck.getUser(state)
});
export default compose(connect(mapStateToProps), translate("translations"))(Thread);
