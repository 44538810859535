import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";

import Tip from "./tips/tip";

const name = `carousel-${Math.random()}`;

const CarouselComponent = ({ data, index, direction = null, handleSelect = () => {} }) => (
  <Carousel activeIndex={index} className="w-100" direction={direction} onSelect={handleSelect}>
    {data.map((d, idx) => (
      <Carousel.Item key={`${name}-${idx} ${d.className || ""}`}>
        <Tip {...d} />
      </Carousel.Item>
    ))}
  </Carousel>
);

CarouselComponent.propTypes = {
  data: PropTypes.array,
  direction: PropTypes.any,
  handleSelect: PropTypes.func,
  index: PropTypes.number
};

export default CarouselComponent;
