import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";

import "./authorsPage.css";

import { get as g, isEmpty } from "lodash";
import { Checkbox } from "react-bootstrap";
import UserDuck from "../../redux/ducks/user";
import { be, bm } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import SelectComponent from "../../components/input/select/select";
import { getQueryObjFromString, isMobile } from "../../utils/utils";
import Button from "../../components/button/button";
import Loader from "../../components/loader/loader";
import UserPanel from "../../components/userPanel/userPanel";
import Text from "../../components/text/text";
import EnumsDuck from "../../redux/ducks/enums";
import Pagination from "../../components/pagination/pagination";

const MODULE_NAME = "AuthorsScreen";

class AuthorsPage extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search = "" },
      genres: propGenres
    } = props;
    const query = getQueryObjFromString(search);
    const genres = Object.values(propGenres);
    const genre = genres.filter(genre => g(query, "genres", []).includes(genre.id)).map(genre => genre.id);
    this.state = {
      selectedGenres: genre,
      filters: {
        order: "desc",
        page: 1,
        genres: genre,
        limit: 9
      },
      loading: true
    };
    props.dispatch(UserDuck.loadAuthors(this.state.filters));
  }

  loadMore = () => {
    window.scrollTo(0, 0);
    this.props.dispatch(UserDuck.fetchMoreAuthors(this.state.filters));
  };

  loadLess = () => {
    window.scrollTo(0, 0);
    this.props.dispatch(UserDuck.fetchLessAuthors(this.state.filters));
  };

  getContent() {
    const { authors, history } = this.props;

    if (!isEmpty(authors)) {
      return Object.values(authors).map(user => (
        <div key={user.id} className="col-sm-6 col-lg-4">
          <UserPanel onClick={() => history.push(`/profile/${user.id}`)} user={user} />
        </div>
      ));
    }
    return (
      <div className="col-xs-12">
        <Text bold center lg>
          Pro specifikované parametry, nikdo nenalezen!
        </Text>
      </div>
    );
  }

  handleMobileGenres = ({ target: { value: genres = [] } }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          genres
        }
      },
      () => {
        this.props.dispatch(UserDuck.loadAuthors(this.state.filters));
      }
    );
  };

  handleSort = ({ target }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          order: target.value
        }
      },
      () => {
        this.props.dispatch(UserDuck.loadAuthors(this.state.filters));
      }
    );
  };

  onPaginationChange = page => {
    window.scrollTo(0, 0);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page
        }
      },
      () => {
        this.props.dispatch(UserDuck.loadAuthors(this.state.filters));
      }
    );
  };

  resetGenres = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          genres: []
        }
      },
      () => {
        this.props.dispatch(UserDuck.loadAuthors(this.state.filters));
      }
    );
  };

  handleCheckbox = genreId => {
    const {
      filters: { genres: selectedGenres = [] }
    } = this.state;
    let genre = [];

    if (selectedGenres.includes(genreId)) {
      genre = selectedGenres.filter(id => id !== genreId);
    } else {
      genre = [genreId];
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          genres: genre
        }
      },
      () => {
        this.props.dispatch(UserDuck.loadAuthors(this.state.filters));
      }
    );
  };

  render() {
    const { loading, t, canLoadLess, canLoadMore, genres, maxPage } = this.props;
    const {
      filters: { genres: selectedGenres, page }
    } = this.state;

    if (loading) {
      return <Loader />;
    }
    return (
      <div className={bm(MODULE_NAME, null, "authors-page")}>
        <div className="row">
          <div className="col-md-auto">
            <Headline bold page>
              Autoři
            </Headline>
          </div>
          <div className="col-md d-flex align-items-center justify-content-end ">
            <div className={be(MODULE_NAME, "selector")}>
              <SelectComponent
                classNamePrefix="select-box"
                clearable
                defaultValue={{
                  label: t("dashboard.sortDesc"),
                  value: "desc"
                }}
                name="order"
                onChange={this.handleSort}
                options={[
                  {
                    label: t("dashboard.sortDesc"),
                    value: "desc"
                  },
                  {
                    label: t("dashboard.sortAsc"),
                    value: "asc"
                  }
                ]}
                placeholder={t("dashboard.sortPlaceholder")}
              />
            </div>
          </div>
          {/* {isMobile() && ( */}
          {/* <div className='col-sm d-flex align-items-center justify-content-end '> */}
          {/* <div className={be(MODULE_NAME, 'selector' )}> */}
          {/* <SelectComponent */}
          {/* classNamePrefix="select-box" */}
          {/* name="genres" */}
          {/* placeholder={t('dashboard.rolesPlaceholder')} */}
          {/* onChange={this.handleMobileGenres} */}
          {/* clearable */}
          {/* isMulti */}
          {/* value={selectedGenres} */}
          {/* options={Object.values(genres).map(genre => { */}
          {/* return { */}
          {/* label: genre.name, */}
          {/* value: genre.id */}
          {/* }; */}
          {/* })} */}
          {/* /> */}
          {/* </div> */}
          {/* </div> */}
          {/* )} */}
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className="row">
          {/* {!isMobile() && ( */}
          {/* <div className="col-sm-2"> */}
          {/* <Checkbox */}
          {/* key="000" */}
          {/* className={be(MODULE_NAME, 'checkbox', null, `${isEmpty(selectedGenres) ? 'checked' : ''}` )} */}
          {/* onChange={this.resetGenres} */}
          {/* checked={isEmpty(selectedGenres)} */}
          {/* > */}
          {/* Všechny žánry */}
          {/* </Checkbox> */}
          {/* {Object.values(genres).map(genre => { */}
          {/* const checked = selectedGenres.includes(genre.id); */}
          {/* return ( */}
          {/* <Checkbox */}
          {/* key={genre.id} */}
          {/* className={be(MODULE_NAME, 'checkbox', null, `${checked ? 'checked' : ''}` )} */}
          {/* onChange={() => this.handleCheckbox(genre.id)} */}
          {/* checked={selectedGenres.includes(genre.id)} */}
          {/* > */}
          {/* {genre.name} */}
          {/* </Checkbox> */}
          {/* ); */}
          {/* })} */}
          {/* </div> */}
          {/* )} */}
          <div className="col-sm-12">
            <div className="row">{this.getContent()}</div>
            <div className="row">
              <div className="col d-flex mt-5 justify-content-center align-items-center">
                {(canLoadMore || canLoadLess) && (
                  <Pagination activePage={page} maxPage={maxPage} onChange={this.onPaginationChange} />
                )}
                {/* {canLoadLess && <Button spacing medium onClick={this.loadLess}>{t('loadPrevious')}</Button>} */}
                {/* {canLoadMore && <Button spacing medium onClick={this.loadMore}>{t('loadNext')}</Button>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AuthorsPage.propTypes = {
  canLoadLess: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  genres: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  maxPage: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  loading: UserDuck.isAuthorsLoading(state),
  authors: UserDuck.getAuthors(state),
  canLoadMore: UserDuck.canLoadMoreAuthors(state),
  canLoadLess: UserDuck.canLoadLessAuthors(state),
  genres: EnumsDuck.getGenres(state),
  maxPage: UserDuck.getAuthorsMaxPage(state)
});
export default compose(connect(mapStateToProps), translate("translations"))(AuthorsPage);
