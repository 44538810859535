import React, { Fragment } from "react";
import { get as g, intersectionBy, isEmpty, head } from "lodash";

import { connect } from "react-redux";

import { PREVIEWS_API } from "../../../../services/config";

import { be } from "../../../../utils/bliss";

import Button from "../../../../components/button/button";
import DetailBubble from "../../../../components/bubbles/detailBubble/detailBubble";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";
import UploadFile from "../../../../components/input/uploadFile/uploadFile";

import UserDuck from "../../../../redux/ducks/user";
import WizardDuck from "../../../../redux/ducks/wizard";

import imageHow1 from "../img/info--info.svg";

const MODULE_NAME = "ProjectDetailScreen";

class GetInProgressContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectChecklistCompleted: props.project.projectChecklistCompleted
    };
  }

  updateChecklist = (item = {}) => {
    const { dispatch, project, refetch } = this.props;
    if (!item.deliveredAt) {
      dispatch(WizardDuck.updateChecklistItem(project.id, g(item, "itemType.id", ""), g(item, "handshake.id", 0)));
    } else {
      dispatch(WizardDuck.deleteChecklistItem(project.id, g(item, "itemType.id", ""), g(item, "handshake.id", 0)));
    }
    refetch();
  };

  uploadManuscript = (files = []) => {
    const { dispatch, project } = this.props;

    if (!isEmpty(files)) {
      dispatch(WizardDuck.uploadManuscript(project.id, g(head(files), "data", "")));
    } else {
      dispatch(WizardDuck.deleteManuscript(project.id));
    }
  };

  render() {
    const { project, user } = this.props;
    const { projectChecklist, manuscript } = project;
    const { projectChecklistCompleted } = this.state;

    let manuScript = [];

    if (manuscript) {
      manuScript = [
        {
          ...(manuscript || {}),
          data: `${PREVIEWS_API}${manuscript.data}`
        }
      ];
    }

    return (
      <Fragment>
        <Headline bold component="h3" light xs>
          Kontrolní seznam tvorby knihy
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className={be(MODULE_NAME, "how")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
              <div className="d-flex flex-column w-100">
                <Text bold>1. Každou dodanou práci od kolegy nám potvrď tlačítkem.</Text>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className={be(MODULE_NAME, "how")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
              <div className="d-flex flex-column w-100">
                <Text bold>2. Na konci se ti odemkne možnost odeslat kniho do tisku.</Text>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className={be(MODULE_NAME, "how")}>
              <img alt="how it works" className={be(MODULE_NAME, "howImage")} src={imageHow1} />
              <div className="d-flex flex-column w-100">
                <Text bold>3. Nahraj soubor, který jsi dostal od sazeče a…a je to!</Text>
              </div>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "spacing", "xl")} />
        {(projectChecklist || []).map(da => {
          let d = da;
          if (!d.handshake) {
            d = {
              ...d,
              handshake: {
                contributor: {
                  ...user,
                  name: "Já",
                  surname: ""
                }
              }
            };
          }
          const requirements = intersectionBy(
            g(d, "itemType.dependsOn", []),
            (projectChecklist || []).map(pc => pc.itemType),
            "id"
          );
          const requirementsCompleted = requirements.reduce((res, o) => {
            const item = (projectChecklist || []).find(ci => g(ci, "itemType.id", "") === o.id);
            return res && item.deliveredAt;
          }, true);
          const itemTypeId = g(d, "itemType.id", "");

          return (
            <DetailBubble
              key={d.id}
              complete={d.deliveredAt}
              data={d}
              icon="/images/svg/pending.svg"
              inProgress
              locked={!requirementsCompleted}
              onCancel={() => this.updateChecklist(d)}
              onClick={() => this.updateChecklist(d)}
              onWrapperClick={() => {
                if (d.deliveredAt) {
                  this.setState({
                    projectChecklistCompleted: projectChecklistCompleted.includes(itemTypeId)
                      ? projectChecklistCompleted.filter(c => c !== itemTypeId)
                      : [...projectChecklistCompleted, itemTypeId]
                  });
                }
              }}
              requirements={requirements}
              small={projectChecklistCompleted.includes(itemTypeId)}
              title={g(d, "itemType.name", "")}
            />
          );
        })}
        <div className={be(MODULE_NAME, "spacing", "xl")} />
        <Headline bold center component="h4" subheadline>
          Teď už zbývá jen knihu odeslat do tisku
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className="row">
          <div className="col-sm text-center">
            <UploadFile
              center
              inputPlaceholder={<div className="UploadFile-placeholder" />}
              multiple={false}
              onFilesInsert={this.uploadManuscript}
              value={manuScript}
            />
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            <Button isDisabled large>
              Odeslat knihu do tisku
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: UserDuck.getUser(state)
});

export default connect(mapStateToProps)(GetInProgressContent);
