import React from "react";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";

import Text from "../../../text/text";

const MODULE_NAME = "ProjectDetailScreen";

const AcceptMessage = () => (
  <div className={be(MODULE_NAME, "acceptContainer")} style={{ textAlign: "center" }}>
    <div style={{ height: "15px" }} />
    <div style={{ textAlign: "center", marginBottom: "15px" }}>
      <img src="/images/blackLogo.png" style={{ width: "100px" }} alt="logo" />
    </div>
    <div>
      <div>
        <Text bold primary>
          Platba byla zrušena
        </Text>
      </div>
    </div>
    {!isMobile() ? (
      <div style={{ margin: "0 70px", display: "flex", justifyContent: "center" }}>
        <iframe
          id="pointu-neustale-vylepsujeme-proto-bychom-se-te-radi-zeptali-proc-ses-rozhodla-na-knihu-neprispet-3"
          title="Surfey iframe"
          width="100%"
          height="620"
          frameBorder="0"
          src="https://pointa.surfey.cz/pointu-neustale-vylepsujeme-proto-bychom-se-te-radi-zeptali-proc-ses-rozhodla-na-knihu-neprispet-3?iframe"
        />
      </div>
    ) : (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <iframe
          id="pointu-neustale-vylepsujeme-proto-bychom-se-te-radi-zeptali-proc-ses-rozhodla-na-knihu-neprispet-3"
          title="Surfey iframe"
          width="100%"
          height="620"
          frameBorder="0"
          src="https://pointa.surfey.cz/pointu-neustale-vylepsujeme-proto-bychom-se-te-radi-zeptali-proc-ses-rozhodla-na-knihu-neprispet-3?iframe"
        />
      </div>
    )}
  </div>
);

export default AcceptMessage;
