import React from "react";
import T from "prop-types";
import { compose, pure } from "recompose";
import { bm, be } from "../../utils/bliss";

import logoImage from "./img/logo.svg";

import "./logo.css";

const MODULE_NAME = "Logo";

const withLogo = compose(pure);

const renderLogo = ({ small }) => (
  <div className={bm(MODULE_NAME, { small })}>
    <img alt="Pointa" className={be(MODULE_NAME, "logo")} src={logoImage} />
  </div>
);

const Logo = withLogo(renderLogo);

Logo.propTypes = {
  small: T.bool
};

export default Logo;
