import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route } from "react-router";
import { isEmpty, get as g } from "lodash";

import { AuthenticatedRoute } from "./components/authenticatedRoute/authenticatedRoute";
import { getRoutes } from "./utils/routeUtils";
import Screen from "./components/screen/screen";
import Header from "./components/header/header";
import UserDuck from "./redux/ducks/user";
import Loader from "./components/loader/loader";
import EnumsDuck from "./redux/ducks/enums";

/**
 * Layout wrapper
 */
class LayoutWrapper extends Component {
  state = {
    loading: true
  };

  componentWillMount() {
    const { dispatch, history } = this.props;
    Promise.all([
      dispatch(UserDuck.setUserByToken("/landing", true, false)),
      dispatch(EnumsDuck.setRoles()),
      dispatch(EnumsDuck.loadCatalog()),
      dispatch(EnumsDuck.setBindings())
    ])
      .then(() => {
        this.setState({
          loading: false
        });
      })
      .catch(err => {
        if (g(err, "response.data.code", "")) {
          // history.push('/login');
          // window.alertify.error('Prosím přihlaste se, vypršela platnost Vašeho tokenu!');
        }
      });
  }

  render() {
    const { user, ...restProps } = this.props;

    return isEmpty(user) || this.state.loading ? (
      <Loader />
    ) : (
      <Screen header={<Header {...restProps} />}>
        {getRoutes(user).map((r, idx) =>
          r.prevented || r.uncompletedUser || r.enabledUser ? (
            <AuthenticatedRoute
              key={`route-${idx}`}
              component={r.component}
              enabledUser={r.enabledUser}
              isPrevented={r.prevented}
              path={r.route}
              uncompletedUser={r.uncompletedUser}
              user={user}
            />
          ) : (
            <Route key={`route-${idx}`} component={r.component} path={r.route} />
          )
        )}
      </Screen>
    );
  }
}

LayoutWrapper.defaultProps = {
  user: {}
};

LayoutWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  user: UserDuck.getUser(state)
});
export default connect(mapStateToProps)(LayoutWrapper);
