import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g, uniqBy } from "lodash";

import { bm, be } from "../../utils/bliss";
import "./projectPrices.css";

import EnumsDuck from "../../redux/ducks/enums";
import { getProjectProgress } from "../../utils/utils";
import Text from "../text/text";
import Button from "../button/button";
import ProgressBarLinear from "../progressBar/progressBarLinear/progressBarLinear";
import moment from "moment";

const MODULE_NAME = "ProjectPrices";

class ProjectPrices extends Component {
  getType = state => {
    const { projectStates } = this.props;

    switch (state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "making";

      case g(projectStates, "funding.name", ""):
        return "financing";

      case g(projectStates, "failed.name", ""):
        return "failed";

      case g(projectStates, "released.name", ""):
        return "released";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";
    }
  };

  getFinancingStatus() {
    const { project, projectStates } = this.props;

    switch (project.state) {
      case g(projectStates, "funding.name", ""):
        return "funding";

      case g(projectStates, "released.name", ""):
      case g(projectStates, "inProgress.name", ""):
        return "done";

      case g(projectStates, "failed.name", ""):
        return "failed";

      default:
        return "waiting";
    }
  }

  getTitle() {
    const { project, projectStates } = this.props;

    switch (project.state) {
      case g(projectStates, "proposal.name", ""):
      default:
        return "V ŠUPLÍKU";

      case g(projectStates, "funding.name", ""):
        return project.moonTarget === null ? "ČEKÁ SE NA SPUŠTĚNÍ PŘEDPRODEJE" : "PROBÍHÁ FINANCOVÁNÍ";

      case g(projectStates, "inProgress.name", ""):
        return "KNIHA SE VYRÁBÍ";

      case g(projectStates, "failed.name", ""):
        return "Časový limit vypršel";
    }
  }

  getMoonRemain() {
    const { project } = this.props;
    if (project.moonTarget && project.moonRemain < 1) {
      return (
        <Text component="div" inverted medium>
          Zbývá <b>méně než den</b> do konce
        </Text>
      );
    }
    if (project.moonTarget) {
      return (
        <Text component="div" inverted medium>
          Zbývá <b>{project.moonRemain || ""} dní</b>
        </Text>
      );
    }
  }

  renderReleaseDate = project => {
    const type = this.getType(project.state);
    return (
      <div>
        {project.releaseDate && (
          <div className="col-md mt-5 mb-4 text-center">
            <div className={be(MODULE_NAME, "spacing", "xs")} />
            <div className="row pt-4" style={{ borderTop: "1px solid lightGrey" }}>
              <Text component="div" float medium>
                Datum vydání:
              </Text>
              <div className="ml-auto pb-2">
                {type === "financing" && (
                  <Text component="div" bold green floatRight medium>
                    <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
                  </Text>
                )}
                {type === "inProgress" && (
                  <Text component="div" bold secondary floatRight medium>
                    <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
                  </Text>
                )}
                {type === "making" && (
                  <Text component="div" bold floatRight medium>
                    <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
                  </Text>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderChecklist = project => {
    const shouldDisplayChecklist = this.getType(project.state) !== "making";
    const { projectStates } = this.props;
    return (
      <Fragment>
        <div
          className={be(
            MODULE_NAME,
            "progressStatusRow",
            project.state === g(projectStates, "proposal.name", "") ? "proposal" : "done"
          )}
        >
          <div className={be(MODULE_NAME, "progressStatusKey")}>Shánění spolupracovníků</div>
          <div className={be(MODULE_NAME, "progressStatusValue")}>
            <div className={be(MODULE_NAME, "progressStatusIcon")} />
          </div>
        </div>
        <div className={be(MODULE_NAME, "progressStatusRow", this.getFinancingStatus())}>
          <div className={be(MODULE_NAME, "progressStatusKey")}>Financování projektu</div>
          <div className={be(MODULE_NAME, "progressStatusValue")}>
            <div className={be(MODULE_NAME, "progressStatusIcon")} />
          </div>
        </div>
        {shouldDisplayChecklist &&
          g(project, "projectChecklist", []).map(checklist => (
            <div
              key={g(checklist, "itemType.id", "")}
              className={be(MODULE_NAME, "progressStatusRow", checklist.deliveredAt ? "done" : "waiting")}
            >
              <div className={be(MODULE_NAME, "progressStatusKey")}>{g(checklist, "itemType.name")}</div>
              <div className={be(MODULE_NAME, "progressStatusValue")}>
                <div className={be(MODULE_NAME, "progressStatusIcon")} />
              </div>
            </div>
          ))}
      </Fragment>
    );
  };

  getRequiredSubRoles = () => {
    const { required } = g(this.props.project, "genres", []).reduce(
      (res, gnr) => {
        const genre = { ...gnr, ...g(this.props.genres, gnr.id, {}) };
        const required = uniqBy([...res.required, ...g(genre, "requiredSubroles", [])], "id");
        return { required };
      },
      { required: [] }
    );
    return required;
  };

  render() {
    const { project, projectStates, handshakesStates, showHandshakeRequestButton } = this.props;
    const type = this.getType(project.state);
    const required = this.getRequiredSubRoles();
    const progress = getProjectProgress(project, projectStates, required, handshakesStates);

    let price = null;
    if (type === "inProgress" && project && project.rewards) {
      const filteredRewards = project.rewards.filter(r => r.physical === 1 && r.state === "active");
      price = filteredRewards.length === 1 ? filteredRewards[0].price : null;
    }

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="row">
          <div className="col">
            {(type === "financing" || type === "failed") && (
              <Fragment>
                <div className={be(MODULE_NAME, "background", "financing")}>
                  <Text bold component="div" inverted light medium>
                    {this.getTitle()}
                  </Text>
                  {project.moonTarget && (
                    <Fragment>
                      <div className={be(MODULE_NAME, "spacing", "sm")} />
                      <Text component="div" float inverted medium>
                        Vybráno <b>{parseInt(project.moonCollected || 0).toLocaleString("cs-CZ")}</b>
                        {" z "}
                        {parseInt(project.moonTarget || 0).toLocaleString("cs-CZ")}
                        {" Kč"}
                      </Text>
                      <ProgressBarLinear progress={progress} />
                      <div className={be(MODULE_NAME, "spacing", "xs")} />
                      <Text component="div" inverted medium>
                        Přispělo <b>{project.moonContributors || 0} lidí</b>
                      </Text>
                      <div className={be(MODULE_NAME, "spacing", "xs")} />
                      {this.getMoonRemain()}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
            {type === "making" && (
              <Fragment>
                <div className={be(MODULE_NAME, "background", "making")}>
                  <Text bold component="div" inverted medium>
                    {this.getTitle()}{" "}
                    <img className="pull-right" src="/images/svg/colleagues.svg" style={{ height: "50px" }} />
                  </Text>
                  <div className={be(MODULE_NAME, "spacing", "xs")} />
                  <Text component="div" inverted light medium>
                    Kniha hledá kolegy
                  </Text>
                </div>
              </Fragment>
            )}
            {type === "released" && (
              <Fragment>
                <div className={be(MODULE_NAME, "background", "released")}>
                  <Text bold component="div" inverted medium>
                    {this.getTitle()}{" "}
                    <img className="pull-right" src="/images/svg/colleagues.svg" style={{ height: "50px" }} />
                  </Text>
                  <div className={be(MODULE_NAME, "spacing", "xs")} />
                  <Text component="div" inverted light medium>
                    Kniha hledá kolegy
                  </Text>
                </div>
              </Fragment>
            )}
            {type === "inProgress" && (
              <Fragment>
                <div className={be(MODULE_NAME, "background", "inProgress")}>
                  <Text bold component="div" inverted light medium>
                    {this.getTitle()}
                  </Text>
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                  <ProgressBarLinear progress={progress} />
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className={be(MODULE_NAME, "line")} />
        <div className={be(MODULE_NAME, "progressStatus")}>
          {type !== "failed" ? (
            this.renderChecklist(project)
          ) : (
            <Fragment>
              <div className="text-center">
                <Text bold center small>
                  Je nám líto, ale nestihli jste vybrat dostatečnou částku k financování svého projektu. Ale nevzdávejte
                  to, příště to třeba vyjde!
                </Text>
              </div>
            </Fragment>
          )}
        </div>

        {type !== "failed" && this.renderReleaseDate(project)}

        {type === "financing" && project.moonTarget && (
          <Fragment>
            <div className="col-md mt-4 text-center">
              <Button green small to={project.moonlineUrl}>
                PŘISPĚT NA VYDÁNÍ
              </Button>
            </div>
          </Fragment>
        )}

        {type === "inProgress" && project.moonlineUrl && (
          <Fragment>
            <div className="col-md mt-4 text-center">
              <Button invertedBlue to={project.moonlineUrl} target="_blank" wide small>
                KOUPIT {price && `ZA ${Math.round(price)} KČ`}
              </Button>
            </div>
          </Fragment>
        )}

        {type === "making" && showHandshakeRequestButton && (
          <Fragment>
            <div className="col-md mt-4 text-center">
              <Button
                invertedGrey
                onClick={e => {
                  e.preventDefault();
                  this.props.toggleHandshakeRequest(!this.props.showHandshakeRequest);
                }}
                wide
              >
                CHCI SPOLUPRACOVAT
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

ProjectPrices.defaultProps = {
  project: {},
  showMore: true
};

ProjectPrices.propTypes = {
  genres: PropTypes.object.isRequired,
  handshakesStates: PropTypes.object.isRequired,
  project: PropTypes.object,
  projectStates: PropTypes.object.isRequired,
  showHandshakeRequest: PropTypes.bool,
  showHandshakeRequestButton: PropTypes.bool,
  showMore: PropTypes.bool
};

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state),
  genres: EnumsDuck.getGenres(state),
  handshakesStates: EnumsDuck.getHandhakeStates(state)
});
export default connect(mapStateToProps)(ProjectPrices);
