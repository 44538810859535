import React, { Component } from "react";
import "./messageListPage.css";
import { be } from "../../utils/bliss";
import { isMobile } from "../../utils/utils";
import Header from "../../components/header/header";
import Headline from "../../components/headline/headline";
import Loader from "../../components/loader/loader";
import MessageItem from "./components/messageItem";
import Screen from "../../components/screen/screen";

const MODULE_NAME = "MessageListPage";

class MessageListPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loader />;
    }
    return (
      <Screen header={<Header />}>
        <div className="row">
          <div className="col-sm pb-5">
            <Headline page>Zprávy</Headline>
          </div>
        </div>
        <div className={`${!isMobile() ? "row" : ""}`}>
          <MessageItem />
          <MessageItem read />
          <MessageItem read />
        </div>
        <div className={be(MODULE_NAME, "spacingMd")} />
      </Screen>
    );
  }
}

export default MessageListPage;
