import React from "react";
import moment from "moment";

import "./notification.css";

import { uniqBy } from "lodash";
import { MenuItem } from "react-bootstrap";
import Text from "../text/text";
import { be, bm } from "../../utils/bliss";
import Avatar from "../avatar/avatar";

const MODULE_NAME = "Notification";

const Notification = ({ notif, onClick, onClickBtn, listing }) => (
  <MenuItem
    key={notif.id}
    className={bm(MODULE_NAME, `${listing && "listing"} ${notif.seen ? "seen" : "new"}`)}
    eventKey={notif.id}
    onClick={onClick}
  >
    <div className={be(MODULE_NAME, "body", { listing })}>
      <div className="d-flex align-items-center">
        <div className={be(MODULE_NAME, "icon")}>
          {notif.avatar ? (
            <Avatar image={notif.avatar} notif />
          ) : (
            <img alt="info" className="img-fluid" src="/images/svg/infoNotif.svg" />
          )}
        </div>
        <div className={be(MODULE_NAME, "text")}>
          <Text medium>{notif.message}</Text>
        </div>
      </div>
      {notif.rejectable && (
        <div className={be(MODULE_NAME, "reject")}>
          <span className="d-inline-flex align-items-center" onClick={onClickBtn}>
            <img className="mr-2" src="/images/svg/trashHandshake.svg" />
            <Text bold hover light medium>
              {" "}
              Odmítnout poptávku
            </Text>
          </span>
        </div>
      )}
      <div className={be(MODULE_NAME, "date")}>
        <Text light xxs>
          {moment(notif.createdAt).format("DD. MM. YYYY, HH: mm")}
        </Text>
      </div>
    </div>
  </MenuItem>
);

export default Notification;
