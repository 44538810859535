import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import EnumsDuck from "../../redux/ducks/enums";

const Public = ({ loggingIn, authenticated, component, registrationRoles, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (props.match.path === "/register/:role?" && !props.match.params.role) {
        return (
          <Redirect
            to={{
              pathname: `/register/${registrationRoles.author}`
            }}
          />
        );
      }

      if (loggingIn) {
        // TODO: implement loader
        return <div />;
      }

      return React.createElement(component, {
        ...props,
        loggingIn,
        authenticated
      });
    }}
  />
);

Public.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.func,
  loggingIn: PropTypes.bool,
  registrationRoles: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  registrationRoles: EnumsDuck.getRegistrationRoles(state)
});

export default connect(mapStateToProps)(Public);
