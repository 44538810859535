import React from "react";
import PropTypes from "prop-types";

export default class GA extends React.Component {
  get INPUT_TYPES() {
    return ["text", "number", "email", "phone", "tel"];
  }

  render() {
    const { children, pageName, ...otherProps } = this.props; // eslint-disable-line no-unused-vars
    return (
      <div
        {...otherProps}
        onBlur={this.onBlurHandler}
        onChange={this.onChangeHandler}
        onClick={this.onClickHandler}
        onSubmit={this.onSubmitHandler}
      >
        {children}
      </div>
    );
  }

  /**
   * Handler for submit event
   *
   * @param {Event} event
   */
  onSubmitHandler = event => {
    const target = event.target;
    const name = this._getTargetName(target);
    const eventLabel = `${this.props.pageName}#${name}`;

    if (typeof target.dataset.skipga === "undefined") {
      GA.sendEvent({
        eventCategory: target.type || target.tagName.toLowerCase(),
        eventAction: "submit",
        eventLabel
      });
    }
  };

  /**
   * Handler for click event
   *
   * @param {Event} event
   */
  onClickHandler = event => {
    const target = event.target;
    switch (target.tagName) {
      case "LI": {
        const attribute = target.attributes["data-ganame"];
        if (typeof attribute !== "undefined") {
          GA.sendEvent({
            eventCategory: "tab",
            eventAction: "click",
            eventLabel: `${this.props.pageName}#${attribute.value}`
          });
        }
        break;
      }
    }
  };

  onBlurHandler = event => {
    const target = event.target;
    const type = target.type;
    const name = (target.attributes["data-ganame"] || target.attributes.name || {}).value;
    const eventLabel = `${this.props.pageName}#${name}`;

    if ((target.tagName === "INPUT" && this.INPUT_TYPES.indexOf(type) !== -1) || target.tagName === "TEXTAREA") {
      GA.sendEvent({
        eventCategory: target.type || target.tagName.toLowerCase(),
        eventAction: "change",
        eventLabel
      });
    }
  };

  /**
   * Handler for change event
   *
   * @param {Event} event
   */
  onChangeHandler = event => {
    const target = event.target;
    const type = target.type;
    const name = (target.attributes["data-ganame"] || target.attributes.name || {}).value;
    let eventLabel = `${this.props.pageName}#${name}`;

    if (target.tagName === "SELECT" || (target.tagName === "INPUT" && type === "radio")) {
      eventLabel += `=${target.value}`;
    } else if (target.tagName === "INPUT" && type === "checkbox") {
      eventLabel += `=${target.checked}`;
    } else if ((target.tagName === "INPUT" && this.INPUT_TYPES.indexOf(type) !== -1) || target.tagName === "TEXTAREA") {
      return;
    }

    GA.sendEvent({
      eventCategory: target.type || target.tagName.toLowerCase(),
      eventAction: "change",
      eventLabel
    });
  };

  /**
   * Send event to GTM and GA
   *
   * @param {Object} eventData
   */
  static sendEvent(eventData) {
    const gaData = Object.assign({}, eventData, { hitType: "event" });
    typeof ga === "function" && ga("send", gaData); // eslint-disable-line no-undef

    const gtmData = Object.assign({}, eventData, { event: "ga.event" });
    typeof dataLayer !== "undefined" && dataLayer.push(gtmData); // eslint-disable-line no-undef
  }

  /**
   * Return name by given target element
   *
   * @param target
   * @return {string}
   * @private
   */
  _getTargetName(target) {
    return (target.attributes["data-ganame"] || target.attributes.name || target.attributes.id || {}).value || "";
  }
}

GA.defaultProps = {
  pageName: ""
};

GA.propTypes = {
  pageName: PropTypes.string
};
