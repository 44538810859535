import React from "react";
import { PropTypes as T } from "prop-types";
import { componentFromProp, defaultProps } from "recompose";
import { bm } from "../../utils/bliss";

import "./text.css";

const MODULE_NAME = "Text";

const enhance = defaultProps({ component: "span" });
const TextComponent = enhance(componentFromProp("component"));

const Text = ({
  component = "span",
  disabled,
  center,
  children,
  primary,
  secondary,
  longName,
  xs,
  midSemi,
  medium,
  mAuto,
  sd,
  small,
  bold,
  hover,
  semi,
  cartPrice,
  lg,
  cartItem,
  xl,
  xxl,
  xxs,
  normal,
  light,
  lighter,
  inverted,
  uppercase,
  label,
  float,
  floatRight,
  pointer,
  lineHeight,
  lineHeightSmall,
  capitalized,
  lowercase,
  statistics,
  left,
  green,
  underline,
  greenReward,
  dark,
  noWrap,
  textBreak,
  firstUp
}) => (
  <TextComponent
    className={bm(MODULE_NAME, {
      disabled,
      center,
      primary,
      secondary,
      pointer,
      xs,
      xxs,
      left,
      semi,
      cartPrice,
      statistics,
      underline,
      medium,
      mAuto,
      small,
      float,
      midSemi,
      longName,
      floatRight,
      lg,
      cartItem,
      xl,
      xxl,
      normal,
      noWrap,
      green,
      greenReward,
      capitalized,
      hover,
      bold,
      light,
      lighter,
      inverted,
      uppercase,
      label,
      dark,
      lineHeight,
      textBreak,
      lineHeightSmall,
      lowercase,
      firstUp
    })}
    component={component}
  >
    {children}
  </TextComponent>
);

Text.propTypes = {
  bold: T.bool,
  center: T.bool,
  inverted: T.bool,
  label: T.bool,
  light: T.bool,
  medium: T.bool,
  primary: T.bool,
  secondary: T.bool,
  small: T.bool,
  uppercase: T.bool,
  xs: T.bool
};

export default Text;
