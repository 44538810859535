import React, { Component } from "react";
import PropTypes from "prop-types";
import { get as g, isEmpty } from "lodash";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import "./followedProjects.css";

import { be, bm } from "../../utils/bliss";
import { isMobile } from "../../utils/utils";
import Headline from "../../components/headline/headline";
import SelectComponent from "../../components/input/select/select";
import ProjectInfo from "../../components/projectInfo/projectInfo";
import Text from "../../components/text/text";
import Loader from "../../components/loader/loader";
import WizardDuck from "../../redux/ducks/wizard";
import UserDuck from "../../redux/ducks/user";
import EnumsDuck from "../../redux/ducks/enums";
import Pagination from "../../components/pagination/pagination";
import { showProject } from "../../utils/goTo";

const MODULE_NAME = "FollowedProjects";

class FollowedProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        page: 1,
        order: "desc",
        limit: 9,
        ...props.opt
      }
    };
  }

  componentWillMount() {
    const { dispatch, user } = this.props;
    dispatch(WizardDuck.fetchFollowedProjects(user.id, this.state.filters));
  }

  getContent() {
    const data = Object.values(this.props.data);

    if (!isEmpty(data)) {
      return data.map(project => (
        <div key={project.id} className="col-sm-6 col-lg-4">
          <ProjectInfo followed={project.genres.length > 1} onClick={() => showProject(project.id)} project={project} />
        </div>
      ));
    }
    return (
      <div className="col-xs-12">
        <Text bold center lg>
          Pro specifikované parametry, nebyl nalezen projekt!
        </Text>
      </div>
    );
  }

  loadMore = () => {
    window.scrollTo(0, 0);
    const { dispatch, user } = this.props;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page: g(this.state, "filters.page", 1) + 1
        }
      },
      () => {
        dispatch(WizardDuck.fetchFollowedProjects(user.id, this.state.filters));
      }
    );
  };

  loadLess = () => {
    window.scrollTo(0, 0);
    const { dispatch, user } = this.props;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page: g(this.state, "filters.page", 2) - 1
        }
      },
      () => {
        dispatch(WizardDuck.fetchFollowedProjects(user.id, this.state.filters));
      }
    );
  };

  handleFilters = ({ target }) => {
    const {
      filters: { page }
    } = this.state;
    const { dispatch, user } = this.props;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [target.name]: target.value,
          page: target.name === "genres" && page > 1 ? 1 : page
        }
      },
      () => {
        dispatch(WizardDuck.fetchFollowedProjects(user.id, this.state.filters));
      }
    );
  };

  onPaginationChange = page => {
    window.scrollTo(0, 0);
    const { dispatch, user } = this.props;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page
        }
      },
      () => {
        dispatch(WizardDuck.fetchFollowedProjects(user.id, this.state.filters));
      }
    );
  };

  render() {
    const { loading, t, canLoadMore, canLoadLess, projectStates, genres, projectsTotalCount } = this.props;
    const { filters } = this.state;

    if (loading) {
      return <Loader />;
    }
    return (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "filters")}>
          <div className="row">
            {!isMobile() ? (
              <div className="col-sm">
                <Headline page>Sledované projekty</Headline>
              </div>
            ) : (
              <div className="col-sm" style={{ marginBottom: "20px" }}>
                <Headline page>Sledované projekty</Headline>
              </div>
            )}
            <div className="col-md-auto d-flex align-items-end">
              <div className={be(MODULE_NAME, "select", "first followed")}>
                <SelectComponent
                  classNamePrefix="select-box"
                  clearable
                  name="state"
                  onChange={this.handleFilters}
                  options={Object.values(projectStates).map(st => ({
                    value: st.name,
                    label: st.name
                  }))}
                  placeholder={t("dashboard.statesPlaceholder")}
                />
              </div>
            </div>
            <div className="col-md-auto d-flex align-items-end">
              <div className={be(MODULE_NAME, "select", "followed")}>
                <SelectComponent
                  classNamePrefix="select-box"
                  clearable
                  isMulti
                  name="genres"
                  onChange={this.handleFilters}
                  options={genres.map(genre => ({
                    value: genre.id,
                    label: genre.name
                  }))}
                  placeholder={t("dashboard.genresPlaceholder")}
                  value={filters.genres}
                />
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
        </div>

        <div className="row">{this.getContent()}</div>

        <div className="col-xs-12 d-flex mt-5 justify-content-center align-items-center">
          {(canLoadMore || canLoadLess) && (
            <Pagination activePage={filters.page} maxPage={projectsTotalCount} onChange={this.onPaginationChange} />
          )}
          {/* {canLoadLess && <Button spacing medium onClick={this.loadLess}>{t('dashboard.loadLess')}</Button>} */}
          {/* {canLoadMore && <Button spacing medium onClick={this.loadMore}>{t('dashboard.loadMore')}</Button>} */}
        </div>
      </div>
    );
  }
}

FollowedProjects.defaultProps = {
  opt: {}
};

FollowedProjects.propTypes = {
  canLoadLess: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  genres: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  opt: PropTypes.object,
  projectStates: PropTypes.object.isRequired,
  projectsTotalCount: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  data: WizardDuck.getFollowedProjects(state),
  canLoadMore: WizardDuck.canLoadMoreFollowedProjects(state),
  canLoadLess: WizardDuck.canLoadLessFollowedProjects(state),
  loading: WizardDuck.isLoadingFollowedProjects(state),
  user: UserDuck.getUser(state),
  projectStates: EnumsDuck.getProjectStates(state),
  genres: Object.values(EnumsDuck.getGenres(state)),
  projectsTotalCount: WizardDuck.getFollowedProjectTotalCount(state)
});

export default compose(withRouter, connect(mapStateToProps), translate("translations"))(FollowedProjects);
