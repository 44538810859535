import React, { Component } from "react";
import { be, bm } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import Button from "../../components/button/button";
// import PropTypes from 'prop-types';

import "./notAllowedPage.css";
import { dynamicTitle } from "../../utils/utils";

const MODULE_NAME = "NotAllowedPage";

class NotAllowedPage extends Component {
  render() {
    const { history } = this.props;

    return (
      <div className={bm(MODULE_NAME)}>
        {dynamicTitle("Litujeme, ale toto není možné", false, this.props.location.pathname)}
        <div className={be(MODULE_NAME, "icon")}>
          <img alt="404" src="/images/svg/cross.svg" />
        </div>
        <div className={be(MODULE_NAME, "title")}>
          <Headline bold danger>
            Litujeme, ale toto není možné
          </Headline>
        </div>
        <div className={be(MODULE_NAME, "button")}>
          {/* <Button white sm onClick={() => history.goBack()}>Zpět</Button> */}
          <Button onClick={() => history.push("/dashboard")} sm white>
            Zpět do aplikace
          </Button>
        </div>
      </div>
    );
  }
}

NotAllowedPage.propTypes = {};

export default NotAllowedPage;
