import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import PulseLoader from "react-spinners/PulseLoader";
import { SearchStorageService } from "../../../services/searchStorageService";

import "./searchInput.scss";

const WAITING_TIME = 500; // ms
const USE_DEBOUNCE = true;
const BIG_LOADER = false;

let typingTimeout = null;

class SearchInput extends Component {
  constructor() {
    super();
    this.state = {
      text: null,
      search: true
    };
  }

  componentDidMount() {
    const storedExpression = SearchStorageService.getSearchExpression();
    if (storedExpression) {
      this.setState({ text: storedExpression }, () => this.onSearch());
    }
  }

  onSearch() {
    if (this.state.text === this.props.currentSearch) {
      return;
    }
    SearchStorageService.storeSearchExpression(this.state.text);
    if (this.state.text && this.props.onSearch) {
      this.setState({search: false});
      this.props.onSearch(this.state.text);
    }
  }

  onCancel() {
    SearchStorageService.clearSearchExpression();
    this.setState({text: null, search: true});
    if (this.props.onSearch) {
      this.props.onSearch(null);
    }
  }

  debounce() {
    if (!USE_DEBOUNCE) {
      return;
    }
    if (this.state.text) {
      this.onSearch();
    } else {
      this.onCancel();
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        </Helmet>
        <div className="search-input form-control">
          <input
            autoFocus
            style={{display: 'inline-block'}}
            value={this.state.text || ""}
            onChange={(e) => {
              this.setState({text: e.target.value, search: true});
              if (typingTimeout) {
                clearTimeout(typingTimeout);
              }
              typingTimeout = setTimeout(() => this.debounce(), WAITING_TIME);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && e.shiftKey === false) {
                e.preventDefault();
                this.onSearch();
              }
            }}
            placeholder="Hledej kolegu podle jména"
          />
          <div
            className={this.state.search && (!USE_DEBOUNCE || USE_DEBOUNCE && !this.props.currentSearch) && (BIG_LOADER || !BIG_LOADER && !this.props.loading) ? "search-input__search" : "search-input__cancel"}
            style={{display: 'inline-block'}}

          >
            {(!BIG_LOADER && this.props.loading && (
              <PulseLoader />
            )) || (this.state.search && (!USE_DEBOUNCE || USE_DEBOUNCE && !this.props.currentSearch) && (
                <span
                  className="fa"
                  onClick={() => {
                    this.onSearch();
                  }}>&#xf002;</span>
            )) || (
              <Fragment>
                <div class="position-hellper">&nbsp;</div>
                <img
                  src="/images/svg/cross--black.svg"
                  onClick={() =>
                    this.onCancel()
                  }
                  width={20}
                />
              </Fragment>
            )}
          </div>
        </div>
        {BIG_LOADER && this.props.loading && <PulseLoader /> || ''}
      </Fragment>
    );
  }
}

export default SearchInput;
