/* eslint-disable react/jsx-sort-props */
import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import { isMobile } from "../../../../utils/utils";
import Button from "../../../button/button";
import { be, bm } from "../../../../utils/bliss";
import "./promo.css";
import Headline from "../../../headline/headline";
import Link from "../../../link/link";
import Text from "../../../text/text";
import Forms from "../../../forms/form";
import FormDuck from "../../../../redux/ducks/forms";
import letuska from "./img/letuska.png";
import UserService from "../../../../services/userService";
import CoreAPIService from "../../../../services/coreAPIService";
import UIDuck from "../../../../redux/ducks/ui";
import { API } from "../../../../services/variables";
import GA from "../../../ga/ga";

const MODULE_NAME = "Promo";

class Promo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribed: false
    };
  }

  subscribe = data => {
    CoreAPIService.post(`newsletterSubscribe/letuska`, {
      email: data.email
    }).then(postData => {
      if (postData.data.success) {
        this.setState({ subscribed: true });
      }
    });
    this.props.onClose();
    this.props.dispatch(UIDuck.openModal(UIDuck.modalTypes.promoThanks));
  };

  render() {
    return !isMobile() ? (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "header")}>
          <Headline bold component="h2" subheadline>
            Letuška z economy Petry Jirglové je <br /> prvním bestsellerem Pointy!
          </Headline>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Text small>
                Netrpělivě očekávaný dotisk dorazí koncem srpna. Nenech si knihu ujít a zanech nám svůj e-mail. My se ti
                ozveme, až bude opět k mání.
              </Text>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "bottom")}>
          <div className={be(MODULE_NAME, "left")}>
            <div className={be(MODULE_NAME, "form")}>
              <Forms
                onSubmit={this.subscribe}
                name="letuska-form"
                inputs={[
                  {
                    label: (
                      <div className="input-group-prepend" style={{ height: "38px" }}>
                        <button
                          className={be(MODULE_NAME, "newsletterButton", null, "btn btn-outline-secondary")}
                          name="_submit"
                          style={{ marginLeft: "-5px", borderLeft: "2px solid #DAD0CA" }}
                          type="submit"
                        >
                          ODESLAT
                        </button>
                      </div>
                    ),
                    placeholder: "Zadej svůj email...",
                    name: "email",
                    entityType: "email",
                    className: "col-12",
                    required: true,
                    type: "inlinePopup"
                  },
                  {
                    label: (
                      <Text small>
                        Souhlasím s{" "}
                        <Link target="_blank" to={`${API}/docs/gdpr`}>
                          <span style={{ textDecoration: "underline" }}>podmínkami ochrany osobních dat</span>
                        </Link>
                      </Text>
                    ),
                    name: "codexAgreed",
                    type: "checkbox",
                    required: true,
                    className: "Form-checkbox"
                  }
                ]}
              />
            </div>
          </div>
          <div className={be(MODULE_NAME, "right")}>
            <div className={be(MODULE_NAME, "imageBox")}>
              <img
                alt="masky"
                className={be(MODULE_NAME, "image", null, "img-fluid")}
                src={letuska}
                style={{ height: "330px" }}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default connect()(Promo);
