import { isEmpty, get as g } from "lodash";

import DashboardPage from "../pages/dashboard/dashboardPage";
import DocumentWizardPage from "../pages/documentWizard/documentWizardPage";
import StorageService from "../services/storageService";
import ProfilePage from "../pages/profile/profile";
import OnboardingPage from "../pages/onboarding/onboardingPage";
import BasicParametersPage from "../pages/details/basicParameters/basicParameters";
import ContributorsPage from "../pages/contributors/contributorsPage";
import TestPage from "../pages/test/testPage";
import NotificationsPage from "../pages/notifications/notificationsPage";
import AuthorsPage from "../pages/authors/authorsPage";
import TestDisqusPage from "../pages/test/testDisqusPage";
import FollowedProjects from "../pages/followedProjects/followedProjects";

export const DEFAULT_ROUTE = "/dashboard";
export const NOT_ALLOWED_ROUTE = "/not-allowed";

export const getRoutes = (user = {}, returnAsObject = false) => {
  const isToken = StorageService.hasToken();
  const prevented = !isToken || isEmpty(user);
  const uncompletedUser = g(user, "roles", []).includes("ROLE_USER");
  const enabledUser = user.isEnabled;

  const routes = [
    {
      route: "/basic-parameters/:projectId",
      component: BasicParametersPage,
      demo: false,
      prevented,
      uncompletedUser,
      enabledUser
    },
    {
      route: "/contributors/:projectId?",
      component: ContributorsPage,
      demo: false,
      prevented,
      uncompletedUser,
      enabledUser
    },
    {
      route: "/onboarding",
      component: OnboardingPage,
      demo: false,
      prevented: !g(user, "roles", []).includes("ROLE_USER")
    },
    {
      route: "/profile/:userId?/:projectId?/:subroleId?",
      component: ProfilePage,
      demo: false,
      prevented,
      uncompletedUser,
      enabledUser
    },
    {
      route: "/wizard",
      component: DocumentWizardPage,
      demo: false,
      prevented,
      uncompletedUser,
      enabledUser
    },
    {
      route: "/dashboard",
      component: DashboardPage,
      demo: true,
      prevented: uncompletedUser,
      enabledUser
    },
    {
      route: "/test",
      component: TestPage,
      demo: true,
      prevented: false,
      uncompletedUser,
      enabledUser
    },
    {
      route: "/test-disqus",
      component: TestDisqusPage,
      demo: true,
      prevented: false,
      uncompletedUser,
      enabledUser
    },
    { route: "/notifications", component: NotificationsPage, prevented },
    { route: "/authors", component: AuthorsPage, prevented: false },
    {
      route: "/projects-followed",
      component: FollowedProjects,
      prevented: uncompletedUser
    }
  ];

  return returnAsObject
    ? routes.reduce(
        (res, route) => ({
          ...res,
          [route.route]: route
        }),
        {}
      )
    : routes;
};

export const isPagePrevented = (user = {}, route = "") => {
  if (!isEmpty(user)) {
    return g(getRoutes(user, true), `${route}.prevented`, false);
  }
  return route.prevented;
};

export const isRouteValid = route => {
  route = (/^(\/(?:\w+\-?){1,})/i.exec(route) || [])[1] || "";

  return !!getRoutes().find(r => r.route.includes(route));
};
