import React, { Component } from "react";
import { connect } from "react-redux";

import "./mainNewsletter.css";

import { be } from "../../../../utils/bliss";
import Link from "../../../../components/link/link";
import Text from "../../../../components/text/text";
import { API } from "../../../../services/variables";
import Forms from "../../../../components/forms/form";
import FormDuck from "../../../../redux/ducks/forms";
import UserService from "../../../../services/userService";

const MODULE_NAME = "MainNewsletter";

class MainNewsletter extends Component {
  subscribe = (data = {}) => {
    UserService.subscribe(data.email, data.gdpr);
  };

  render() {
    return (
      <Forms
        center
        onSubmit={this.subscribe}
        name={FormDuck.formTypes.subscribe}
        inputs={[
          {
            label: (
              <div style={{ height: "38px" }} className="input-group-prepend">
                <button
                  name="_submit"
                  style={{ marginLeft: "-5px", borderLeft: "2px solid #DAD0CA" }}
                  className={be(MODULE_NAME, "newsletterButton", null, "btn btn-outline-secondary")}
                  type="submit"
                >
                  ODEBÍRAT
                </button>
              </div>
            ),
            placeholder: "Zadejte svůj email...",
            name: "email",
            entityType: "email",
            className: "col-12",
            required: true,
            type: "inline"
          },
          {
            label: (
              <Text greenReward small>
                Souhlasím s{" "}
                <Link target="_blank" to={`${API}/docs/gdpr`}>
                  <span style={{ color: "#38675B", textDecoration: "underline" }}>podmínkami ochrany osobních dat</span>
                </Link>
              </Text>
            ),
            name: "codexAgreed",
            type: "checkbox",
            required: true,
            className: "Form-checkbox"
          }
        ]}
      />
    );
  }
}

export default connect()(MainNewsletter);
