import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { isEmpty, get as g, noop, last, difference, differenceBy, head, debounce, uniqBy, uniq } from "lodash";
import moment from "moment";

import { bm, be } from "../../utils/bliss";
import "./profile.css";
import UserDuck from "../../redux/ducks/user";
import Avatar from "../../components/avatar/avatar";
import UserBadge from "../../components/userBadge/userBadge";
import Button from "../../components/button/button";
import Genre from "../../components/genres/genre/genre";
import GenreWrapper from "../../components/genres/genreWrapper/genreWrapper";
import Headline from "../../components/headline/headline";
import UploadFile from "../../components/input/uploadFile/uploadFile";
import { PREVIEWS_API, maxBirthDate, minBirthDate } from "../../services/variables";
import References from "../../components/references/references";
import Loader from "../../components/loader/loader";
import EnumsDuck from "../../redux/ducks/enums";
import WizardDuck from "../../redux/ducks/wizard";
import WizardService from "../../services/wizardService";
import FormDuck from "../../redux/ducks/forms";
import ConfirmBox from "../../components/confirmBox/confirmBox";
import ConfirmBottomBanner from "../../components/confirmBottomBanner/confirmBottomBanner";
import Forms from "../../components/forms/form";
import UserService from "../../services/userService";
import SelectComponent from "../../components/input/select/select";
import Text from "../../components/text/text";
import { dynamicTitle, formatPrice, getImagePreview, isMobile } from "../../utils/utils";
import { showProject } from "../../utils/goTo";
import UIDuck from "../../redux/ducks/ui";
import Link from "../../components/link/link";
import FollowedProjects from "../followedProjects/followedProjects";
import ProjectInfo from "../../components/projectInfo/projectInfo";
import GenresList from "../../components/genres/genresList";
import { push } from "react-router-redux";

const MODULE_NAME = "ProfileScreen";

const EditButton = ({ t, edit = false, show = true, style, ...restProps }) =>
  show ? (
    <Button inverted medium style={{ ...style }} {...restProps}>
      {!edit && <div className="button-label">{t("profile.edit")}</div>}
      {edit && <div className="button-label">{t("profile.confirm")}</div>}
    </Button>
  ) : null;

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { projectId, userId, subroleId }
      },
      handshakeStates,
      currentUser,
      user,
      roles,
      dispatch,
      data
    } = props;

    this.state = {
      edit: false,
      handshake: !!projectId,
      public: !!userId,
      loading: !!userId,
      user: props.user || {},
      cooperationAsked: false,
      units: {},
      projects: {},
      genres: g(props, "user.followedGenres", []).map(g => g.id)
    };

    if (userId) {
      UserService.getUserLightById(userId).then(user => {
        this.setState({
          loading: false,
          user,
          data,
          userPrices: g(user, "subroles", []),
          cooperationAsked: !!g(user, "contributorHandshakes", []).find(
            ({ subrole, project, state }) =>
              subrole.id === subroleId && projectId === project.id && state !== g(handshakeStates, "declined.name", "")
          ),
          requestedHandshakes: g(user, "handshakes", []).filter(
            ({ subrole, project, state, author }) =>
              g(author, "id", false) === currentUser.id &&
              state === g(handshakeStates, "requestedByContributor.name", "")
          ),
          units: {}
        });
        if (user.roles.includes(roles.author.name)) {
          WizardService.fetchUserProjects(userId).then(data => {
            console.log(data);

            this.setState({
              workedProjects: g(data, "data", [])
            });
            //res(data);
            dispatch(WizardDuck.setLoading("userProjects", false));
          });
        }
        if (user.roles.includes(roles.contributor.name)) {
          const acceptedHandshakes = g(user, "handshakes", []).filter(
            ({ state, project }) => g(handshakeStates, "accepted.name", "") === state && project.state !== "Neúspěšný"
          );

          console.log(acceptedHandshakes);

          var projectIds = [];

          uniq(acceptedHandshakes).forEach(h => {
            if (!projectIds.includes(h.project.id)) projectIds.push(h.project.id);
          });

          const promises = projectIds.map(projectId => WizardService.getProjectLightById(projectId, true));

          Promise.all(promises.map(p => (p.catch ? p.catch(e => null) : p)))
            .then(data => {
              this.setState({
                workedHandshakes: uniqBy(
                  data.filter(p => p != null),
                  "id"
                )
              });
            })
            .catch(err => {
              if (g(err, "response.data.code", "")) {
                // history.push('/login');
                // window.alertify.error('Prosím přihlaste se, vypršela platnost Vašeho tokenu!');
              }
            });
        }
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("op") === "edit") {
      this.handleEdit();
      window.history.pushState({}, "", window.location.pathname);
    }
  }

  componentWillReceiveProps(nextProps) {
    let toState = {};

    if (!isEmpty(nextProps.user)) {
      toState = {
        ...toState,
        loading: false,
        user: nextProps.user
      };
      if (g(this.props, "user.subroles", []).length !== g(nextProps, "user.subroles", []).length) {
        toState = {
          ...toState,
          userPrices: g(nextProps, "user.subroles", []),
          units: g(nextProps, "user.subroles", []).reduce(
            (res, subrole) => ({
              ...res,
              [g(subrole, "subrole.id", "")]: g(subrole, "unit.id", "")
            }),
            {}
          )
        };
      }
    }
    if (!isEmpty(toState)) {
      this.setState(toState);
    }
  }

  completeEdit = (data = {}) => {
    const picture = (g(data, "profilePic", []) || [])[0] || {};
    let editedData = {
      profilePic: picture.preview || picture.data || "",
      birthDate: data.birthDate,
      name: data.name,
      surname: data.surname,
      subroles: data.prices.map(price => ({
        id: price.name,
        rate: parseFloat(price.value),
        unit: this.state.units[price.name] || ""
      })),
      reference: g(data, "reference", [])
        .filter(ref => !!ref.value)
        .map(ref => ref.value),
      portfolio: g(data, "portfolio", []).map(file => ({
        data: file.data,
        filename: file.filename || file.name
      })),
      promoText: g(data, "promoText", ""),
      phone: g(data, "phone", "")
    };
    if (g(this.props, "user.roles", []).includes(g(this.props, "roles.reader.name", ""))) {
      editedData = {
        ...editedData,
        followedGenres: this.state.genres,
        web: data.web
      };
    }

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.dispatch(UserDuck.patchUser(editedData)).then(() => {
          this.setState({
            edit: false,
            loading: false
          });
        });
      }
    );
  };

  handleUnit = (subroleId, unitId) => {
    this.setState({
      units: {
        ...this.state.units,
        [subroleId]: unitId
      }
    });
  };

  onSetInput = (formName, entityName, value) => {
    const {
      dispatch,
      roles: {
        contributor: { subroles: contributorSubroles = [] }
      }
    } = this.props;
    const { user } = this.state;
    const userSubroles = g(user, "subroles", []);

    if (entityName === "profilePic") {
      value = last(value) ? [last(value)] : [];
      dispatch(FormDuck.setInput(formName, entityName, value));
    } else if (entityName === "subroles") {
      dispatch(
        FormDuck.setInput(
          formName,
          "prices",
          value.reduce((res, subroleId) => {
            const isUsed = userSubroles.find(({ subrole }) => subrole.id === subroleId);

            if (isUsed) {
              return [
                ...res,
                {
                  name: subroleId,
                  value: g(isUsed, "rate", 0),
                  label: g(isUsed, "subrole.name", "")
                }
              ];
            }
            const subrole = contributorSubroles.find(sr => sr.id === subroleId);
            return [
              ...res,
              {
                name: subroleId,
                value: 0,
                label: g(subrole, "name", "")
              }
            ];
          }, [])
        )
      );
    } else if (entityName === "reference") {
      if (value.length >= 65) {
        window.alertify.error("Maximální počet znaků textu je 65!");
        value = value.slice(0, 65);
        dispatch(FormDuck.setInput(formName, entityName, value));
      }
      const currentReference = g(user, "reference", []).map(r => ({
        ...r,
        name: r.id
      }));

      if (currentReference.length > value.length) {
        const diffRef = differenceBy(currentReference, value, "name");
        dispatch(UserDuck.deleteReference(head(diffRef).id || ""));
      }
    }
  };

  getEditProfile() {
    const {
      t,
      roles,
      dispatch,
      units,
      currentUser,
      editdata: { subroles: formSubrolesIds }
    } = this.props;
    const { user, units: selectedUnits } = this.state;
    const subroles = g(roles, "contributor.subroles", []);
    const isReader = g(user, "roles", []).includes(g(roles, "reader.name", ""));
    const isPrivateProfile = currentUser.id === user.id;
    const isContributor = g(user, "roles", []).includes(g(roles, "contributor.name", ""));
    const isAuthor = g(user, "roles", []).includes(g(roles, "author.name", ""));

    let inputs = [
      {
        placeholder: t("profile.profilePic"),
        label: (
          <div>
            <Headline bold component="h3" subheadline>
              {t("profile.profilePic")}
            </Headline>
            <div className={be(MODULE_NAME, "editSpacing", "xs")} />
          </div>
        ),
        name: "profilePic",
        className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
        type: "file",
        inputPlaceholder: <div className="UploadFile-placeholder" />,
        disableManual: true,
        multiple: false,
        dropzoneProps: {
          accept: ["image/jpeg", "image/png"]
        }
      },
      {
        placeholder: t("profile.name"),
        label: (
          <div>
            <Headline bold component="h3" subheadline>
              {t("profile.name")}
            </Headline>
            <div className={be(MODULE_NAME, "editSpacing", "xs")} />
          </div>
        ),
        name: "name",
        className: be(MODULE_NAME, "editSpacing", "small"),
        required: true
      },
      {
        placeholder: t("profile.surname"),
        label: (
          <div>
            <Headline bold component="h3" subheadline>
              {t("profile.surname")}
            </Headline>
            <div className={be(MODULE_NAME, "editSpacing", "xs")} />
          </div>
        ),
        name: "surname",
        className: be(MODULE_NAME, "editSpacing", null),
        required: true
      },
      {
        placeholder: t("registerPage.phone"),
        label: (
          <div>
            <Headline bold component="h3" subheadline>
              {t("registerPage.phone")}
            </Headline>
            <div className={be(MODULE_NAME, "editSpacing", "xs")} />
          </div>
        ),
        name: "phone",
        entityType: "phone",
        className: be(MODULE_NAME, "editSpacing", null),
        required: true
      },
      {
        label: (
          <div>
            <Headline bold component="h3" subheadline>
              Datum narození
            </Headline>
            <div className={be(MODULE_NAME, "editSpacing", "xs")} />
          </div>
        ),
        name: "birthDate",
        type: "date",
        entityType: "formatted-date",
        format: "YYYY-MM-DD",
        className: be(MODULE_NAME, "editSpacing", null),
        min: minBirthDate(),
        max: maxBirthDate(),
        required: true
      }
    ];
    if (isReader) {
      inputs = [
        ...inputs,
        {
          placeholder: "Představ se nám",
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                Představ se nám
              </Headline>
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          name: "promoText",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          type: "textarea",
          wide: true
        },
        {
          placeholder: "Web",
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                Web
              </Headline>
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          name: "web",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          wide: true
        }
      ];
    } else {
      inputs = [
        ...inputs,
        {
          placeholder: isAuthor ? t("profile.medailonek-placeholder") : t("profile.promoText"),
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                {isAuthor ? t("profile.medailonek") : t("profile.promoText")}
              </Headline>
              {isAuthor ? (
                <span>
                  <br />
                  Rady a tipy, jak vyplnit medailonek, najdeš{" "}
                  <a href="/knowledgebase/jak-napsat-vlastni-medailonek" target="_blank">
                    zde
                  </a>
                  .
                </span>
              ) : (
                ""
              )}
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          name: "promoText",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          type: "textarea",
          wide: true
        }
      ];
    }
    if (g(user, "roles", []).includes(g(roles, "contributor.name", ""))) {
      let userSubroles = g(user, "subroles", []);
      const enabledUserSubroles = g(user, "subroles", []);
      if (formSubrolesIds) {
        const userSubrolesIds = userSubroles.map(({ subrole }) => subrole.id);
        const missingSubrolesIds = difference(formSubrolesIds, userSubrolesIds);
        userSubroles = [
          ...userSubroles,
          ...missingSubrolesIds.map(subroleId => ({
            subrole: subroles.find(({ id }) => id === subroleId),
            unit: selectedUnits[subroleId],
            rate: 0
          }))
        ];
      }

      inputs = [
        ...inputs,
        {
          type: "customSelect",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          name: "subroles",
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                {t("profile.subroles")}
              </Headline>
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          data: subroles,
          enabledItemIds: g(user, "isVerifiedContributor", false)
            ? enabledUserSubroles.map(s => (s && s.subrole && s.subrole.id) || null).filter(s => "string" === typeof s)
            : false,
          footer: g(user, "isVerifiedContributor", false) ? (
            <span>
              V případě zájmu o schválení nové role zašli své zkušenosti s požadovanou rolí na{" "}
              <a href="mailto:dikyvam@pointa.cz">dikyvam@pointa.cz</a>.
            </span>
          ) : (
            ""
          )
        },
        {
          type: "list",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          name: "prices",
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                {t("profile.prices")}
              </Headline>
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          disableDelete: true,
          currency: true,
          onboarding: true,
          long: true,
          inputs: userSubroles.map(subrole => {
            const subroleId = g(subrole, "subrole.id", "");

            return {
              key: subroleId,
              name: subroleId,
              label: g(subrole, "subrole.name", ""),
              extra: (
                <SelectComponent
                  name="unit"
                  onChange={({ target }) => this.handleUnit(subroleId, target.value)}
                  options={units.map(unit => ({
                    value: unit.id,
                    label: unit.name
                  }))}
                  placeholder={null}
                  value={selectedUnits[subroleId]}
                />
              )
            };
          })
        }
      ];
    }
    if (!isReader) {
      inputs = [
        ...inputs,
        {
          placeholder: t("profile.myWork"),
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                {t("profile.myWork")}
              </Headline>
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          name: "portfolio",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          type: "file",
          buttonPlaceholder: null,
          disableManual: true,
          onFileDelete: debounce(({ id }) => {
            if (id) {
              dispatch(UserDuck.deletePortfolio(id));
            }
          }, 250)
        },
        {
          type: "list",
          className: be(MODULE_NAME, "editSpacing", null, "col-xs-12"),
          newBtn: t("onboardingPage.addNewReference"),
          name: "reference",
          label: (
            <div>
              <Headline bold component="h3" subheadline>
                {this.isAuthor() ? "Publikovaná díla" : t("profile.references")}
              </Headline>
              <div className={be(MODULE_NAME, "editSpacing", "xs")} />
            </div>
          ),
          inputs: g(user, "references", []).map((ref, idx) => ({
            ...ref,
            key: ref.id,
            name: ref.id,
            className: "col-xs-12"
          }))
        }
      ];
    }

    return (
      <div className="col-xs-12">
        <Headline bold spacingSm>
          Upravit můj profil
        </Headline>
        <Text light small>
          Přeješ-li si změnit e-mail nebo telefon zadaný při registraci, prosím kontaktuj nás na{" "}
          <Link to="mailto:dikyvam@pointa.cz">dikyvam@pointa.cz</Link>.
        </Text>
        <div className={be(MODULE_NAME, "editSpacing")} />
        <Forms
          inputs={inputs}
          name={FormDuck.formTypes.editProfile}
          onSetInput={this.onSetInput}
          onSubmit={this.completeEdit}
          scrollToFirstInvalid
          scrollOffset={-50}
          className={be(MODULE_NAME, "form")}
        >
          <Fragment>
            {isReader && (
              <div className={be(MODULE_NAME, "block")}>
                <div className={be(MODULE_NAME, "editSpacing", "xs")} />
                <Headline bold component="h3" subheadline>
                  Oblíbené žánry
                </Headline>
                <div className={be(MODULE_NAME, "editSpacing", "xs")} />
                <GenresList medium onSelectGenre={this.onSelectGenre} selectedGenres={this.state.genres} />
                <div className={be(MODULE_NAME, "editSpacing", "small")} />
              </div>
            )}
            <div className="col-xs-12 mt-2 mb-4 d-flex justify-content-center">
              <Button type="submit">{t("profile.confirm")}</Button>
            </div>
          </Fragment>
        </Forms>
      </div>
    );
  }

  onSelectGenre = genreId => {
    const { genres } = this.state;
    let newGenres = [];

    if (genres.includes(genreId)) {
      newGenres = genres.filter(gId => gId !== genreId);
    } else {
      newGenres = [...genres, genreId];
    }
    this.setState({
      genres: newGenres
    });
  };

  handleEdit = () => {
    const { user, units } = this.state;
    const { dispatch } = this.props;
    const subroles = g(user, "subroles", []);

    this.setState(
      {
        edit: !this.state.edit,
        units: subroles.reduce(
          (res, subrole) => ({
            ...res,
            [g(subrole, "subrole.id", "")]: g(subrole, "unit.id", "")
          }),
          units
        )
      },
      () => {
        dispatch(
          FormDuck.setData(FormDuck.formTypes.editProfile, {
            ...user,
            profilePic: user.profilePic && [
              {
                data: getImagePreview(user.profilePic),
                preview: getImagePreview(user.profilePic)
              }
            ],
            birthDate: moment(user.birthDate).format("YYYY-MM-DD"),
            promoText: user.promoText || "",
            portfolio: g(user, "portfolio", []).map(file => ({
              ...file,
              preview: `${PREVIEWS_API}${file.data}`
            })),
            prices: subroles.map(subrole => ({
              name: g(subrole, "subrole.id", ""),
              value: g(subrole, "rate", 0),
              label: g(subrole, "subrole.name", "")
            })),
            reference: g(user, "reference", []).map(ref => ({
              name: ref.id,
              value: ref.data
            })),
            subroles: subroles.map(s => s.subrole.id),
            phone: g(user, "phone", "")
          })
        );
      }
    );
  };

  askForCooperation = ({ subrole = {} } = {}) => {
    const {
      match: { params },
      t,
      dispatch
    } = this.props;
    const { user } = this.state;

    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("profile.askForCooperationText", {
          contributor: `${user.name} ${user.surname}`
        }),
        callback: () => {
          dispatch(
            UserDuck.setCooperation({
              subroleId: subrole.id,
              projectId: params.projectId,
              contributorId: params.userId
            })
          ).then(() => {
            this.setState({
              cooperationAsked: true
            });
            window.alertify.success(t("profile.askForCooperationConfirm"));
          });
        }
      })
    );
  };

  getHandshakeInfo(isContributor) {
    const { userProjects: currentUserProjects, t, handshakeStates } = this.props;
    const { user } = this.state;
    const handshakes = g(user, "handshakes", []).filter(hs => !!currentUserProjects[g(hs, "projectId", "")]);

    return !isContributor || isEmpty(handshakes)
      ? null
      : handshakes.map(hs => {
          switch (hs.state) {
            case g(handshakeStates, "accepted.name", ""):
              return (
                <div key={`hs${hs.id}`} className="w-100 handshake-info d-flex mb-2">
                  <div className="w-50 d-flex justify-content-center align-items-center">
                    <Avatar image={g(hs, "author.profilePic", "")} />
                    <img alt="" src="/images/svg/handshake.svg" />
                    <Avatar image={user.profilePic} />
                  </div>
                  <div className="w-50 d-flex flex-column justify-content-center align-items-center">
                    <p>
                      {t("profile.handshakeInfo.t3", {
                        name: user.name
                      })}{" "}
                      <strong>{g(hs, "project.name", "")}</strong>
                    </p>
                    <dl className="dl-horizontal col-xs-12 pull-left">
                      <dt>E-mail:</dt>
                      <dd>
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                      </dd>
                    </dl>
                  </div>
                </div>
              );

            case g(handshakeStates, "requested.name", ""):
              return (
                <div key={`hs${hs.id}`} className="w-100 handshake-info d-flex mb-2">
                  <img alt="" className="pull-right" src="/images/svg/pending.svg" />
                  <div className="container">
                    <p>
                      {t("profile.handshakeInfo.t1", {
                        name: `${user.name} ${user.surname}`,
                        projectName: g(hs, "project.name", ""),
                        role: g(hs, "subrole.name", "")
                      })}
                    </p>
                    <p>{t("profile.handshakeInfo.t2")}</p>
                  </div>
                </div>
              );

            default:
              return null;
          }
        });
  }

  isAuthor = () => {
    const { roles } = this.props;
    const { user } = this.state;
    return g(user, "roles", []).includes(roles.author.name);
  };

  toggleVisibility = () => {
    const { user } = this.state;

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.dispatch(UserDuck.toggleUserVisibility(user)).then(() => {
          this.setState({
            edit: false,
            loading: false
          });
        });
      }
    );
  };

  updateHandshakeState = (oldHandshake, accepted) => {
    const { currentUser, dispatch } = this.props;

    if (accepted) {
      dispatch(UserDuck.requestHandshake(g(oldHandshake, "project.id", ""), g(oldHandshake, "id"))).then(hs => {
        this.setState({
          requestedHandshakes: this.state.requestedHandshakes.map((h, i) => (h.id === hs.id ? { ...h, ...hs } : h))
        });
        dispatch(UserDuck.fetchUserNotifications(currentUser.id));
      });
    } else {
      dispatch(UserDuck.rejectHandshakeRequest(g(oldHandshake, "project.id", ""), g(oldHandshake, "id"), false)).then(
        () => {
          this.setState({
            requestedHandshakes: this.state.requestedHandshakes.filter((h, i) => h.id !== oldHandshake.id)
          });
          dispatch(UserDuck.fetchUserNotifications(currentUser.id));
        }
      );
    }
  };

  confirmHandshake = (handshake = {}) => {
    const { dispatch } = this.props;
    const handshakeId = g(handshake, "id", false);
    const projectId = g(handshake, "project.id", false);
    if (handshakeId && projectId) {
      Promise.all([WizardService.getProjectById(projectId), WizardService.getProjectHandshakes(projectId)]).then(
        ([project, projectHandshakes]) => {
          const projectHandshake = projectHandshakes.filter(h => h.id === handshakeId).pop();
          console.log({ project });
          console.log({ projectHandshake });
          console.log({ projectHandshakes });
          dispatch(
            UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
              handshake: projectHandshake,
              project
            })
          );
        }
      );
    }
  };

  renderContributionRequests = () => {
    const { t, handshakeStates } = this.props;
    const { requestedHandshakes, user } = this.state;
    return requestedHandshakes
      ? requestedHandshakes.map(h => {
          const name = `${g(user, "name", "")} ${g(user, "surname", "")}`;
          const projectName = g(h, "project.name", "");
          const subroleName = g(h, "subrole.name", "");
          const phone = g(user, "phone", "");
          const email = g(user, "email", "");
          if (h.state === g(handshakeStates, "requestedByContributor.name", "")) {
            return (
              <div key={h.id} className={be(MODULE_NAME, "topbar")}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md">
                      <div className="text-center text-md-left">
                        <Text component="div" inverted lg>
                          {name} nabízí spolupráci na projektu {projectName} jako {subroleName}
                        </Text>
                        <div className="mb-4" />
                        <Text component="div" inverted lineHeightSmall normal small>
                          Pokud tě nabídka zajímá a chceš vědět víc, klikni na Mám zájem a my ti dáme kontaktní
                          informace, abyste si mohli popovídat.
                        </Text>
                        <div className={be(MODULE_NAME, "infoSpacingMd")} />
                      </div>
                    </div>
                    <div className="col-auto d-flex align-items-center">
                      <div className={be(MODULE_NAME, "infoBtn")}>
                        <Button inverted onClick={() => this.updateHandshakeState(h, true)} succes>
                          Mám zájem
                        </Button>
                      </div>
                    </div>
                    <div className="col-auto pl-md-0 d-flex align-items-center">
                      <div className={be(MODULE_NAME, "infoBtn")}>
                        <Button green onClick={() => this.updateHandshakeState(h, false)} succes>
                          Nemám zájem
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div key={h.id} className={be(MODULE_NAME, "topbar")}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="text-center text-md-left">
                      <Text component="div" lg inverted>
                        Přijal/a jsi nabídku spolupráce na projektu {projectName}
                      </Text>
                      <div className="mb-4" />
                      <Text component="div" inverted lineHeightSmall normal small>
                        Spoj se s kolegou/kolegyní:{" "}
                        {email ? (
                          <Link to={`mailto:${email}`} white>
                            <img className="ml-5 mr-3" alt="" src="/images/svg/mail.svg" /> {email}
                          </Link>
                        ) : (
                          ""
                        )}{" "}
                        {phone ? (
                          <Link to={`tel:${phone}`} white>
                            <img alt="" className="ml-5 mr-3" src="/images/svg/phone.svg" />
                            <span style={{ position: "relative", top: "2px" }}>{phone}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                      </Text>
                      <div className="mb-3" />
                      <Text component="div" inverted lineHeightSmall small normal>
                        Až se domluvíte, shrnutí rozsahu práce a cenu zadej{" "}
                        <Link to={false} onClick={() => this.confirmHandshake(h)} underline white>
                          sem
                        </Link>
                        .
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      : "";
  };

  render() {
    const { t, key, project, handshakeStates, roles, currentUser, match, userProjects, dispatch } = this.props;
    const { edit, handshake, loading, user, cooperationAsked, workedHandshakes } = this.state;

    if (loading) {
      return <Loader />;
    }

    const isPrivateProfile = currentUser.id === user.id;
    const isContributor = g(user, "roles", []).includes(g(roles, "contributor.name", ""));
    const isReader = g(user, "roles", []).includes(g(roles, "reader.name", ""));
    const isAuthor = g(user, "roles", []).includes(g(roles, "author.name", ""));
    const portfolios = g(user, "portfolio", []);
    const wantedSubrole = g(match, "params.subroleId", "");
    const canToggleVisibility =
      isEmpty(g(user, "handshakes", [])) && (!isContributor || (isContributor && user.isVerifiedContributor));
    const pageHeadline = `${g(user, "name", "")} ${g(user, "surname", "")}`;
    const tags = g(user, "subroles", [])
      .map(g => g.subrole.name)
      .join(",");

    const workedProjects = isPrivateProfile ? userProjects : this.state.workedProjects;

    if (isPrivateProfile && edit) {
      return this.getEditProfile();
    }

    const visibilityText =
      user.isVisible && (!isContributor || (isContributor && user.isVerifiedContributor)) ? "veřejný" : "skrytý";

    return (
      <div className={bm(MODULE_NAME)}>
        {this.getHandshakeInfo(isContributor)}
        {isPrivateProfile && (
          <div>
            {dynamicTitle(
              pageHeadline,
              g(user, "promoText", ""),
              this.props.location.pathname,
              g(user, "profilePic", false),
              tags
            )}
            <div className={be(MODULE_NAME, "info")}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md">
                    <div className="text-center text-md-right">
                      <Text inverted small>
                        {isContributor &&
                          !user.isVerifiedContributor &&
                          !user.isContributorBeingVerified &&
                          "Zde si zkontroluj svůj profil. Poté můžeš dole na stránce odeslat profil Pointě na schválení."}
                        {(!isContributor ||
                          (isContributor && user.isVerifiedContributor) ||
                          (isContributor && user.isContributorBeingVerified)) &&
                          `Díváte se na váš ${visibilityText} profil.`}
                      </Text>
                      <div className={be(MODULE_NAME, "infoSpacingMd")} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className={be(MODULE_NAME, "infoBtn")}>
                      <EditButton mini onClick={this.handleEdit} show={!edit} succes t={t} />
                    </div>
                  </div>
                  {canToggleVisibility && (
                    <div className="col-auto pl-md-0">
                      <div className={be(MODULE_NAME, "infoBtn")}>
                        <Button green onClick={this.toggleVisibility} succes>
                          {user.isVisible ? "Skrýt profil" : "Zveřejnit profil"}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "infoStep")} />
          </div>
        )}
        {this.renderContributionRequests()}
        <div className={be(MODULE_NAME, "head")}>
          <div className={be(MODULE_NAME, "headLeft")}>
            <Avatar big image={user.profilePic} />
            {!isMobile() && isContributor && user.isVerifiedAuthor && (
              <div style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
                <UserBadge />
              </div>
            )}
          </div>
          {isMobile() && isContributor && user.isVerifiedAuthor && (
            <div style={{ display: "flex", justifyContent: "center", padding: "20px 0", margin: "0 auto" }}>
              <UserBadge />
            </div>
          )}
          <div className={be(MODULE_NAME, "headRight")}>
            <Headline page>{pageHeadline}</Headline>
            {isReader && !isEmpty(user.followedGenres) && (
              <div className={be(MODULE_NAME, "offer")}>
                <div className={be(MODULE_NAME, "offerTitle")}>{user.name} sleduje:</div>
                <GenreWrapper
                  genres={g(user, "followedGenres", []).map(s => (
                    <Genre key={s.id} className="col-xs-12" id={s.id} name={s.name} />
                  ))}
                  left
                />
              </div>
            )}
            {isContributor && !isEmpty(user.subroles) && (
              <div className={be(MODULE_NAME, "offer")}>
                <div className={be(MODULE_NAME, "offerTitle")}>{user.name} nabízí:</div>
                <GenreWrapper
                  genres={g(user, "subroles", g(user, "roles", [])).map((s, idx) => {
                    const name = `${g(s, "subrole.name", s)}`;

                    return (
                      <Genre
                        key={`subrole${g(s, "subrole.id", idx)}`}
                        className="col-xs-12"
                        id={g(s, "subrole.id", idx)}
                        name={name}
                      />
                    );
                  })}
                  left
                />
              </div>
            )}

            <div className={be(MODULE_NAME, "promoText")}>{user.promoText}</div>
          </div>
        </div>
        {!isEmpty(workedProjects) && (
          <div>
            <Headline bold component="h2" spacingMd pageSub>
              {t("profile.workedProjects")}
            </Headline>
            <div className="row">
              {Object.values(workedProjects).map(project => (
                <div key={project.id} className="col-sm-6 col-md-3">
                  <ProjectInfo
                    key={project.id}
                    onClick={() => dispatch(push(`/project/${project.id}`))}
                    project={project}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {isReader && <FollowedProjects opt={{ limit: 6 }} />}
        {isContributor && !isEmpty(user.subroles) && (
          <div className={be(MODULE_NAME, "prices")}>
            <div className={be(MODULE_NAME, "sectionTitle")}>
              <Headline component="h2" pageSub>
                {t("profile.prices")}
              </Headline>
            </div>
            <div className="row">
              <div className="col-md-9">
                <div className={be(MODULE_NAME, "box")}>
                  {g(user, "subroles", []).map(subrole => {
                    const isWantedSubrole = wantedSubrole && wantedSubrole === g(subrole, "subrole.id", null);

                    return (
                      <div key={`price${subrole.id}`} className={be(MODULE_NAME, "boxItem")}>
                        <div className="d-flex flex-column flex-sm-row">
                          <div className={be(MODULE_NAME, "boxItemName")}>{g(subrole, "subrole.name")}</div>
                          <div className={be(MODULE_NAME, "boxItemRate")}>
                            {formatPrice(g(subrole, "rate", 0))}
                            &nbsp;Kč&nbsp;
                            <span
                              style={{
                                fontWeight: 400
                              }}
                            >
                              /&nbsp;
                              {g(subrole, "unit.name", "?")}
                            </span>
                          </div>

                          {wantedSubrole && isWantedSubrole && !cooperationAsked && (
                            <div className={be(MODULE_NAME, "boxItemBtn")}>
                              <Button
                                isDisabled={cooperationAsked}
                                onClick={
                                  handshake && isWantedSubrole && !cooperationAsked
                                    ? () => this.askForCooperation(subrole)
                                    : noop
                                }
                                small
                              >
                                {cooperationAsked ? "Spolupráce poptána" : "Poptat spolupráci"}
                              </Button>
                            </div>
                          )}
                        </div>
                        {wantedSubrole && isWantedSubrole && (
                          <div className={be(MODULE_NAME, "boxItemInfo")}>
                            <Text light normal small>
                              Poptejte kolegu, získejte jeho e-mail a zjistěte, jestli spolu budete pracovat na vaší
                              knize.
                            </Text>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-3">
                <div className={be(MODULE_NAME, "boxHelp")}>
                  <Text small>
                    <b>Ceny jsou pouze orientační</b>, vždy záleží na velikosti a složitosti zakázky. Jestli se vám moje
                    práce líbí, poptejte mě a nějak to pořešíme.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isEmpty(portfolios) && (
          <div className={be(MODULE_NAME, "sampleWorks")}>
            <div className={be(MODULE_NAME, "sectionTitle")}>
              <Headline pageSub>{t("profile.sampleWorks")}</Headline>
            </div>

            <UploadFile
              disableManual
              editable={false}
              profile
              value={portfolios.map(file => ({
                ...file,
                data: `${PREVIEWS_API}${file.data}`
              }))}
            />
          </div>
        )}
        {!isEmpty(user.reference) && (
          <div className={be(MODULE_NAME, "references")}>
            <div className={be(MODULE_NAME, "sectionTitle")}>
              <Headline pageSub>{this.isAuthor() ? "Publikovaná díla" : t("profile.references")}</Headline>
            </div>
            <References edit={false} references={user.reference} />
            <div style={{ height: "25px" }} />
          </div>
        )}
        {!isEmpty(workedHandshakes) && (
          <div className="mt-5 pt-5">
            <Headline bold component="h2" spacingMd pageSub>
              {t("profile.coworkedProjects")}
            </Headline>
            <div className="row">
              {Object.values(workedHandshakes).map(project => (
                <div key={project.id} className="col-sm-6 col-md-3">
                  <ProjectInfo
                    key={project.id}
                    onClick={() => dispatch(push(`/project/${project.id}`))}
                    project={project}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {isReader && !isEmpty(g(user, "web", "")) && (
          <div className={be(MODULE_NAME, "references", "spacing")}>
            <div className={be(MODULE_NAME, "sectionTitle")}>
              <Headline component="h2" pageSub>
                Webové stránky
              </Headline>
            </div>
            <References edit={false} references={[g(user, "web", "")]} />
          </div>
        )}
        {isPrivateProfile && isContributor && !user.isVerifiedContributor && !user.isContributorBeingVerified && (
          <ConfirmBox />
        )}
        {isPrivateProfile && isContributor && !user.isVerifiedContributor && user.isContributorBeingVerified && (
          <ConfirmBottomBanner />
        )}
      </div>
    );
  }
}

ProfilePage.defaultProps = {
  editData: {}
};

ProfilePage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  editData: PropTypes.object,
  handshakeStates: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userProjects: PropTypes.object.isRequired
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { userId }
    }
  }
) => {
  const currentUser = UserDuck.getUser(state);

  return {
    user: userId ? {} : currentUser,
    currentUser,
    roles: EnumsDuck.getRoles(state),
    userProjects: WizardDuck.getUserProjects(state),
    handshakeStates: EnumsDuck.getHandhakeStates(state),
    units: EnumsDuck.getUnits(state),
    editdata: FormDuck.getData(state, FormDuck.formTypes.editProfile)
  };
};

export default compose(translate("translations"), connect(mapStateToProps))(ProfilePage);
