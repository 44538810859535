import React from "react";
import PropTypes from "prop-types";

import "./loader.css";

const Loader = ({ className = "" }) => (
  <div className={`loader ${className}`}>
    <span>.</span>
    <span>_</span>
  </div>
);

Loader.propTypes = {
  className: PropTypes.string
};

export default Loader;
