import React from "react";
import PropTypes from "prop-types";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import "./facebookButton.css";
import { bm, be } from "../../utils/bliss";
import { FB_APP_ID } from "../../services/config";
import Button from "../button/button";
import { isMobile } from "../../utils/utils";

const MODULE_NAME = "FacebookButton";

const FacebookButton = ({ onResponse, label, className, type, whiteIcon, login, buttonProps, ...restProps }) => (
  <FacebookLogin
    appId={FB_APP_ID}
    callback={onResponse}
    disableMobileRedirect
    fields="name,email,picture.type(large),first_name,last_name"
    isMobile={isMobile()}
    render={renderProps => (
      <Button
        className={bm(MODULE_NAME, { login }, className)}
        onClick={renderProps.onClick}
        type={type}
        {...buttonProps}
      >
        {whiteIcon ? (
          <img alt="facebook" className={be(MODULE_NAME, "icon")} src="/images/svg/facebook.svg" />
        ) : (
          <img alt="facebook" className={be(MODULE_NAME, "icon")} src="/images/svg/fb.svg" />
        )}{" "}
        {label}
      </Button>
    )}
    {...restProps}
  />
);

FacebookButton.defaultProps = {
  onResponse: () => {},
  label: "",
  className: "",
  type: ""
};

FacebookButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onResponse: PropTypes.func,
  type: PropTypes.string
};

export default FacebookButton;
