import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, get as g } from "lodash";
import { store } from "../../../redux/store";

import "./comment.css";

import Avatar from "../../../components/avatar/avatar";
import Button from "../../../components/button/button";
import Forms from "../../../components/forms/form";
import ThreadDuck, { actionTypes } from "../../../redux/ducks/thread";

class CommentForm extends Component {
  state = {
    body: "",
    sending: false
  };

  onSubmit = (params = {}) => {
    const { threadId, parentComment, toggleShowReplyForm } = this.props;
    params["thread"] = threadId;

    if (g(parentComment, "id", false)) {
      params["parent"] = g(parentComment, "id", false);
    }

    console.log({ params });
    this.setState({ sending: true });

    ThreadDuck.createComment(threadId, params).then(data => {
      this.setState({ sending: false });
      toggleShowReplyForm && toggleShowReplyForm();
      this.props.reloadThread(false);
    });
  };

  render() {
    const { parentComment, isSubComment, user } = this.props;
    const { sending } = this.state;
    const formName = g(parentComment, "id", new Date().getTime());

    return (
      <div className={`media Comment Comment-${isSubComment ? "sub" : "main"}`}>
        <div className="Comment-avatar">
          <Avatar image={user.profilePic} md />
        </div>
        <div className="media-body Comment-mediabody">
          <div className="Comment-form">
            <Forms
              inputs={[
                {
                  placeholder: "Tvůj komentář",
                  label: null,
                  type: "textarea",
                  wide: true,
                  name: "body",
                  required: true,
                  disabled: sending
                }
              ]}
              name={`CommentForm${formName}`}
              onSubmit={this.onSubmit}
            >
              <Button small isDisabled={sending ? true : undefined} type="submit">
                Odeslat
              </Button>
            </Forms>
          </div>
        </div>
      </div>
    );
  }
}

CommentForm.defaultProps = {
  isSubComment: true,
  parentComment: null
};

CommentForm.propTypes = {
  parentComment: PropTypes.object,
  isSubComment: PropTypes.bool.isRequired,
  threadId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  reloadThread: PropTypes.func.isRequired,
  toggleShowReplyForm: PropTypes.func
};

export default CommentForm;
