import jwt_decode from "jwt-decode";
import { TOKEN_STORAGE_NAME } from "./config";

class Storage {
  storage;

  constructor() {
    this.setupStorage();
  }

  setupStorage() {
    try {
      // try to use localStorage
      localStorage.setItem("test", "test");
      localStorage.removeItem("test");
      this.storage = true;
    } catch (e) {
      this.storage = false;
    }
  }

  clearStorage() {
    if (this.storage) {
      localStorage.clear();
      sessionStorage.clear();
    } else {
      window.storage = {};
    }
  }

  getStorage(session) {
    if (this.storage) {
      return session ? sessionStorage : localStorage;
    }
    // Privacy Mode. Using window.storage instead of localStorage.
    if (!window.hasOwnProperty("storage")) {
      window.storage = {};
    }

    return window.storage;
  }

  remove(name, session = false) {
    this.getStorage(session)[name] = null;
  }

  get(name, session = false) {
    const value = this.getStorage(session)[name];

    if (value === "null") {
      return null;
    }

    return value || null;
  }

  set(name, item, session = false) {
    const storage = this.getStorage(session);

    storage[name] = item;

    return storage;
  }

  has(name, session = false) {
    return this.getStorage(session).hasOwnProperty(name);
  }

  hasToken(session = false) {
    const storage = this.getStorage(session);
    if (!!storage[TOKEN_STORAGE_NAME] && storage[TOKEN_STORAGE_NAME] !== "null") {
      const decoded = jwt_decode(storage[TOKEN_STORAGE_NAME]);
      if (Date.now() >= decoded.exp * 1000) {
        return false;
      }
    }
    return !!storage[TOKEN_STORAGE_NAME] && storage[TOKEN_STORAGE_NAME] !== "null";
  }

  getToken(session = false) {
    const storage = this.getStorage(session);

    return {
      expires_in: parseInt(storage.expires_in, 10),
      access_token: storage[TOKEN_STORAGE_NAME]
    };
  }
}

export default new Storage();
