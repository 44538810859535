import React from "react";
import T from "prop-types";
import { bm, be } from "../../utils/bliss";

import "./avatar.css";

import { PREVIEWS_API } from "../../services/config";

const MODULE_NAME = "Avatar";

const Avatar = ({ image, big, medium, small, lg, md, xs, xxs, center, navbar, border, notif, shadow, onboarding }) => {
  let img = (image || "").includes("http") ? image : `${PREVIEWS_API}${image}`;
  if (!img || !image) {
    img = "/images/emptyAvatar.jpg";
  }

  return (
    <div
      className={bm(MODULE_NAME, {
        big,
        medium,
        small,
        lg,
        md,
        xs,
        xxs,
        center,
        border,
        navbar,
        notif,
        onboarding,
        shadow
      })}
    >
      <img alt="" className={be(MODULE_NAME, "img")} src={img} />
    </div>
  );
};

Avatar.defaultProps = {
  image: "",
  className: ""
};

Avatar.propTypes = {
  big: T.bool,
  image: T.string,
  medium: T.bool,
  small: T.bool
};

export default Avatar;
