import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

import "./welcomePanel.css";

import Avatar from "../avatar/avatar";
import Button from "../button/button";
import { bm, be } from "../../utils/bliss";
import Headline from "../headline/headline";
import Text from "../text/text";

const MODULE_NAME = "WelcomePanel";

const WelcomePanel = ({ user, t, onClick, isReader }) => (
  <div className={bm(MODULE_NAME)}>
    {isReader ? (
      <div className="container">
        <div className="row">
          <div className="col-lg">
            <Text inverted lg>
              Začněte se sledováním knihy, autor tak s vámi bude sdílet vždy nejaktuálnější novinky o díle.
            </Text>
          </div>
          <div className="col-lg-auto offset-lg-1 d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0">
            <Button invertedPrimary>Procházet knihy</Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="container">
        <div className="row">
          <div className="col-md-auto d-flex align-items-center justify-content-center">
            <div className={be(MODULE_NAME, "img")}>
              <Avatar image={user.profilePic} md />
            </div>
          </div>
          <div className="col-md d-flex align-items-center justify-content-center">
            <div className="text-center text-md-left">
              <div className={be(MODULE_NAME, "headline")}>
                <Headline bold inverted subheadline>
                  Super! Tvůj autorský profil je hotový.
                </Headline>
                <Text component="div" inverted style={{ marginTop: "10px" }}>
                  Těď můžeš založit knižní projekt. Jak na to?
                </Text>
                <ol className={be(MODULE_NAME, "list")}>
                  <li className={be(MODULE_NAME, "listItem")}>Vybereš žánr</li>
                  <li className={be(MODULE_NAME, "listItem")}>Navolíš si témata </li>
                  <li className={be(MODULE_NAME, "listItem")}>Vyplníš název knihy</li>
                  <li className={be(MODULE_NAME, "listItem")}>Představíš ji krátkou anotací</li>
                  <li className={be(MODULE_NAME, "listItem")}>Nahraješ ukázku</li>
                </ol>
                <Text component="div" inverted>
                  Potom můžeš projekt zveřejnit, začít sestavovat knižní tým a plnit si sen.
                </Text>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
            <div className="text-center text-md-right">
              <div className={be(MODULE_NAME, "button")}>
                <Button invertedPrimary onClick={onClick}>
                  {t("dashboard.welcomeBtn")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

WelcomePanel.defaultProps = {
  user: {},
  onClick: () => {}
};

WelcomePanel.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default translate("translations")(WelcomePanel);
