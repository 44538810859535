import * as moment from "moment";
import { PROJECT_STATES } from "../services/config";

const OMNIBUS_DELIVERY_DAYS = 30;

const isDeliveryAfterDays = (project, reward, days = OMNIBUS_DELIVERY_DAYS) => {
  if (project.state === PROJECT_STATES.FUNDING) {
    return !reward.is_contrib;
  }

  if (project.state === PROJECT_STATES.IN_PROGRESS) {
    const today = moment(new Date());
    today.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const releaseDate = moment(new Date(project.releaseDate));
    releaseDate.subtract(1, "day").set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
    const diff = releaseDate.diff(today, "days", true);
    return diff > days;
  }
};

export const isCartItemDeliveryAfterDays = (item, days = OMNIBUS_DELIVERY_DAYS) => {
  if (!item) {
    return null;
  }
  const { project = {}, reward = {} } = item;
  return isDeliveryAfterDays(project, reward, days);
};
