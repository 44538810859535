/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import Avatar from "../../../../components/avatar/avatar";
import { isMobile } from "../../../../utils/utils";
import Text from "../../../../components/text/text";

class SentMessage extends Component {
  render() {
    const { answered } = this.props;
    return answered ? (
      !isMobile() ? (
        <div className="media Comment Comment-main">
          <div>
            <div className="pb-3">
              It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
              McClintock, a Latin professor
            </div>
            <div>
              <Text disabled medium>
                25.06.2019
              </Text>
            </div>
          </div>
          <div className="Comment-avatar">
            <Avatar md />
          </div>
        </div>
      ) : (
        <div className="media Comment Comment-mainMessenger">
          <div>
            <div className="pb-3">
              It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
              McClintock, a Latin professor
            </div>
            <div>
              <Text disabled medium>
                25.06.2019
              </Text>
            </div>
          </div>
          <div className="Comment-avatarMessenger">
            <Avatar md />
          </div>
        </div>
      )
    ) : !isMobile() ? (
      <div className="media Comment Comment-main">
        <div className="Comment-avatar">
          <Avatar md />
        </div>
        <div>
          <div className="pb-3">
            It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
            McClintock, a Latin professor
          </div>
          <div>
            <Text disabled medium>
              25.06.2019
            </Text>
          </div>
        </div>
      </div>
    ) : (
      <div className="media Comment Comment-mainMessenger">
        <div className="Comment-avatarMessenger">
          <Avatar md />
        </div>
        <div>
          <div className="pb-3">
            It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
            McClintock, a Latin professor
          </div>
          <div>
            <Text disabled medium>
              25.06.2019
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

export default SentMessage;
