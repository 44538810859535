import { get as g } from "lodash";
import CoreAPIService from "./coreAPIService";

class SettingsService {
  async getDeliveryPrices() {
    return new Promise((res, rej) => {
      CoreAPIService.get("settings/delivery")
        .then(response => {
          res(g(response, "data", []));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getDepositSettings() {
    return new Promise((res, rej) => {
      CoreAPIService.get("settings/deposit")
        .then(response => {
          res(g(response, "data", []));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }

  async getBannerSettins() {
    return new Promise((res, rej) => {
      CoreAPIService.get("settings/banner-settings")
        .then(response => {
          res(g(response, "data", []));
        })
        .catch(err => {
          // window.alertify.notify(g(err, 'response.data.message', ''), 'error', 5);
          console.error(g(err, "response.data", ""));
          rej(err);
        });
    });
  }
}

export default new SettingsService();
