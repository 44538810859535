import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty } from "lodash";
import Link from "../../link/link";

import "./welcomeBackPanel.css";

import { bm, be } from "../../../utils/bliss";
import EnumsDuck from "../../../redux/ducks/enums";
import WizardDuck from "../../../redux/ducks/wizard";
import Avatar from "../../avatar/avatar";
import Headline from "../../headline/headline";
import Text from "../../text/text";
import Genre from "../../genres/genre/genre";
import GenreWrapper from "../../genres/genreWrapper/genreWrapper";
import UploadFile from "../../input/uploadFile/uploadFile";
import { PREVIEWS_API } from "../../../services/config";
import ProjectInfo from "../../projectInfo/projectInfo";
import { push } from "react-router-redux";
import { formatPrice } from "../../../utils/utils";
import VerificationCard from "../../card/card";

const MODULE_NAME = "WelcomeBack";

const WelcomeBackPanel = ({ user, t, userProjects, roles, dispatch, onClick, isContributor }) => {
  if (user.roles.includes(roles.author.name)) {
    return (
      <div className={bm(MODULE_NAME)}>
        <div className="row">
          {Object.values(userProjects).map(project => (
            <div key={project.id} className="col-sm-8 col-lg-6">
              <ProjectInfo
                key={project.id}
                onClick={() => dispatch(push(`/project/${project.id}`))}
                project={project}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const portfolios = g(user, "portfolio", []);

  return (
    <div className={bm(MODULE_NAME, "profile")}>
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-auto d-flex flex-column align-items-center">
              <Avatar image={user.profilePic} lg />
              <div className={be(MODULE_NAME, "spacing", "md", "d-md-none")} />
            </div>
            <div className="col-lg">
              <Headline>
                {user.name} {user.surname}
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "md")} />
              <GenreWrapper
                genres={g(user, "subroles", g(user, "roles", [])).map((s, idx) => {
                  const name = `${g(s, "subrole.name", s)}`;

                  return <Genre key={`subrole${g(s, "subrole.id", idx)}`} className="col-xs-12" name={name} />;
                })}
                left
              />
              <div className={be(MODULE_NAME, "spacing", "md")} />
              <div className="row d-none d-lg-flex">
                {user.subroles.map(subrole => (
                  <div
                    key={subrole.subrole.id + user.id}
                    className={be(MODULE_NAME, "subroleWrapper", null, "col-auto")}
                  >
                    <div key={subrole.subrole.id + user.id} className={be(MODULE_NAME, "subrole")}>
                      <Text small>od {formatPrice(subrole.rate)} Kč</Text>
                      {!isEmpty(subrole.unit) && <Text small>&nbsp;/ {subrole.unit.name}</Text>}
                      <Text small>&nbsp;za {subrole.subrole.name}</Text>
                    </div>
                  </div>
                ))}
              </div>
              {!isEmpty(portfolios) && (
                <div className={be(MODULE_NAME, "sampleWorks")}>
                  <div className={be(MODULE_NAME, "spacing", "lg")} />
                  <Headline bold component="h4" light uppercase xxs>
                    Ukázky mé práce
                  </Headline>
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                  <div className="row">
                    <div className="col-sm-auto">
                      <div>
                        <UploadFile
                          disableManual
                          editable={false}
                          preview
                          value={portfolios.slice(0, 4).map(file => ({
                            ...file,
                            data: `${PREVIEWS_API}${file.data}`
                          }))}
                        />
                      </div>
                    </div>

                    {portfolios.length > 4 && (
                      <div className="col-sm d-flex align-items-center">
                        <Text light small>
                          + {portfolios.length - 4}
                          {portfolios.length - 4 === 1 ? (
                            <span>&nbsp;další ukázka</span>
                          ) : portfolios.length - 4 < 5 ? (
                            <span>&nbsp;další ukázky</span>
                          ) : (
                            <span>&nbsp;dalších ukázek</span>
                          )}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isContributor && !user.isVerifiedContributor ? (
          <VerificationCard confirm={user.isContributorBeingVerified} />
        ) : (
          <div className="col-lg-4 d-flex justify-content-center  justify-content-lg-end align-items-center">
            <div className={be(MODULE_NAME, "card")}>
              <Headline bold component="h3" inverted xs>
                {t("dashboard.welcomeBack")}
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "sm")} />
              <div className="w-100">
                <Text inverted>
                  {t("dashboard.authorVisits", {
                    visits: user.profile_visits || 0
                  })}
                </Text>
              </div>
              <div className={be(MODULE_NAME, "spacing", "lg")} />
              <Link to="/profile/?op=edit" white>
                {t("dashboard.editMyProfile")}
              </Link>
              <Link to="/profile" white>
                {t("dashboard.showMyProfile")}
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

WelcomeBackPanel.defaultProps = {
  user: {},
  isAuthor: false
};

WelcomeBackPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthor: PropTypes.bool,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userProjects: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  roles: EnumsDuck.getRoles(state),
  userProjects: WizardDuck.getUserProjects(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(WelcomeBackPanel);
