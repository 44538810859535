import React from "react";
import PropTypes from "prop-types";

import Genre from "../genres/genre/genre";
import GenreWrapper from "../genres/genreWrapper/genreWrapper";

const List = ({ data, selectedItems, onSelect, form, enabledItems }) => (
  <div className="custom-list">
    <GenreWrapper
      genres={data.map(item => (
        <Genre
          key={item.id}
          onClick={form ? () => onSelect(item) : onSelect}
          selected={selectedItems.includes(item.id)}
          disabled={Array.isArray(enabledItems) && !enabledItems.includes(item.id)}
          {...item}
        />
      ))}
    />
  </div>
);

List.defaultProps = {
  data: [],
  selectedItems: [],
  onSelect: () => {},
  form: false,
  enabledItems: undefined,
};

List.propTypes = {
  data: PropTypes.array,
  form: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedItems: PropTypes.array
};

export default List;
