/* global dataLayer */
import { get as g } from "lodash";

import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _isEqual from "lodash/isEqual";
import _isNumber from "lodash/isNumber";
import _findIndex from "lodash/findIndex";

import { createActionTypes } from "../createActionTypes";

import StorageService from "../../services/storageService";

const STORAGE_NAME = "POINTA_CART";

const CartDuck = {
  name: "Cart"
};

const initialState = {
  items: StorageService.get(STORAGE_NAME) ? JSON.parse(StorageService.get(STORAGE_NAME)).items : [],
  numberOfItems: StorageService.get(STORAGE_NAME) ? JSON.parse(StorageService.get(STORAGE_NAME)).numberOfItems : 0
};

const actionTypes = createActionTypes(
  {
    addItem: "add_item".toUpperCase(),
    removeItem: "remove_item".toUpperCase(),
    resetCart: "reset_cart".toUpperCase()
  },
  CartDuck.name
);

const getIndexOfElement = (action, items) => {
  const objToSearch = {
    project: action.payload.project.id,
    reward: action.payload.reward.id
  };
  const indexOfElement = _findIndex(items, v => {
    const obj = {
      project: v.project.id,
      reward: v.reward.id
    };
    return _isEqual(obj, objToSearch);
  });
  return indexOfElement === -1 ? null : indexOfElement;
};

CartDuck.reducer = (state = initialState, action) => {
  const { numberOfItems } = state;
  const items = _cloneDeep(state.items);
  switch (action.type) {
    case actionTypes.resetCart:
      StorageService.set(
        STORAGE_NAME,
        JSON.stringify({
          items: [],
          numberOfItems: 0
        })
      );
      return {
        ...state,
        items: [],
        numberOfItems: 0
      };
    case actionTypes.addItem:
      const indexOfElement = getIndexOfElement(action, items);
      dataLayer.push({
        event: "addToCart",
        content_ids: [action.payload.project.id],
        value: action.payload.number * Math.round(action.payload.reward.price),
        ecommerce: {
          currencyCode: "CZK",
          add: {
            products: [
              {
                name: action.payload.project.name,
                id: action.payload.project.id,
                price: action.payload.reward.price,
                brand: action.payload.project.authorName,
                category: action.payload.project.genres[0].name,
                quantity: action.payload.number
              }
            ]
          }
        }
      });
      if (_isNumber(indexOfElement)) {
        items[indexOfElement].number += action.payload.number;
      } else {
        items.push(action.payload);
      }
      StorageService.set(
        STORAGE_NAME,
        JSON.stringify({
          items,
          numberOfItems: numberOfItems + action.payload.number
        })
      );
      return {
        ...state,
        items,
        numberOfItems: numberOfItems + action.payload.number
      };

    case actionTypes.removeItem:
      const indexOfElement2 = getIndexOfElement(action, items);
      let removedItems = items; // eslint-disable-line
      dataLayer.push({
        event: "removeFromCart",
        ecommerce: {
          remove: {
            products: [
              {
                name: action.payload.project.name,
                id: action.payload.project.id,
                price: action.payload.reward.price,
                brand: action.payload.project.authorName,
                category: action.payload.project.genres[0].name,
                quantity: action.payload.number
              }
            ]
          }
        }
      });
      if (_isNumber(indexOfElement2) && removedItems[indexOfElement2].number > action.payload.number) {
        removedItems[indexOfElement2].number -= action.payload.number;
      } else {
        removedItems = _filter(
          items,
          v =>
            !_isEqual(
              {
                project: action.payload.project.id,
                reward: action.payload.reward.id
              },
              {
                project: v.project.id,
                reward: v.reward.id
              }
            )
        );
      }
      StorageService.set(
        STORAGE_NAME,
        JSON.stringify({
          items: removedItems,
          numberOfItems: numberOfItems - action.payload.number
        })
      );
      return {
        ...state,
        items: removedItems,
        numberOfItems: numberOfItems - action.payload.number
      };

    default:
      return state;
  }
};

CartDuck.addItem = (project, reward, number = 1) => dispatch =>
  dispatch({
    type: actionTypes.addItem,
    payload: {
      project,
      reward,
      number
    }
  });

CartDuck.removeItem = (project, reward, number = 1) => dispatch =>
  dispatch({
    type: actionTypes.removeItem,
    payload: {
      project,
      reward,
      number
    }
  });

CartDuck.resetCart = () => dispatch =>
  dispatch({
    type: actionTypes.resetCart
  });

const getOwnState = state => g(state, CartDuck.name, initialState);
CartDuck.getItems = state => getOwnState(state).items;
CartDuck.getNumberOfItems = state => getOwnState(state).numberOfItems;

export default CartDuck;
