import React, { Component } from "react";
import PropTypes from "prop-types";
import { get as g } from "lodash";

import "./confirmEmail.css";
import Header from "../../components/header/header";
import Screen from "../../components/screen/screen";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import { bm, be } from "../../utils/bliss";
import UserService from "../../services/userService";

const MODULE_NAME = "ConfirmEmail";

class ConfirmEmail extends Component {
  resendEmail = () => {
    const {
      match: {
        params: { email = "" }
      }
    } = this.props;
    UserService.resendConfirmEmailByEmail(email)
      .then(() => {
        window.alertify.notify(`Email byl poslán na ${email}`, "success", 5);
      })
      .catch(err => {
        window.alertify.notify(g(err, "response.data.message"), "success", 5);
      });
  };

  render() {
    const {
      match: {
        params: { email = "" }
      }
    } = this.props;

    return (
      <Screen header={<Header {...this.props} />}>
        <div className={be(MODULE_NAME, "spacing", "xl")} />
        <div className={bm(MODULE_NAME)}>
          <Headline page primary>
            Tvůj účet byl vytvořen
          </Headline>

          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <Text>
                Výborně! Teď už zbývá jen potvrdit e-mail. Na adresu <strong>{email}</strong> jsme ti poslali zprávu.
                Otevři ji, klikni na odkaz a je hotovo.
              </Text>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
          <img alt="confirm email" src="/images/svg/confirm-email.svg" />
          <div className={be(MODULE_NAME, "spacing", "xl")} />
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <Text bold>
                E-maily jsou jako beatnici. Pořád na cestě a někdy se zatoulají.
                <br />
                Obvykle stačí chvíli počkat podívat se do nevyžádané pošty nebo do koše, jestli neleží mezi odpadem.
              </Text>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "md")} />
          <Text>
            Nezabralo to? Nevadí.{" "}
            <span onClick={this.resendEmail}>
              <Text pointer primary>
                Pošli e-mail znovu na cestu.
              </Text>
            </span>
          </Text>
        </div>
      </Screen>
    );
  }
}

ConfirmEmail.propTypes = {
  location: PropTypes.object.isRequired
};

export default ConfirmEmail;
