/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { Fragment } from "react";
import { get as g, isEmpty } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { be } from "../../../../utils/bliss";
import { isMobile } from "../../../../utils/utils";

import Avatar from "../../../../components/avatar/avatar";
import Text from "../../../../components/text/text";
import Headline from "../../../../components/headline/headline";

const MODULE_NAME = "ProjectDetailScreen";

const AuthorInfoReleased = ({ project, onClick, extended }) => {
  return extended ? (
    <Fragment>
      <div className={be(MODULE_NAME, "authorsExtended")} style={{ padding: "30px 60px 15px 30px" }}>
        <div className={be(MODULE_NAME, "authorInfo")}>
          <div className="d-flex">
            <div className={be(MODULE_NAME, "authorAvatarExtended")}>
              <Avatar big image={g(project, "authorData.foto")} />
            </div>
            <div className={be(MODULE_NAME, "authorFinancingExtended")}>
              <div className={be(MODULE_NAME, "authorFinancingName")} style={{ paddingBottom: "15px" }}>
                <Headline smallSemi bold greenReward>
                  {g(project, "authorData.firstname")} {g(project, "authorData.surname")}
                </Headline>
              </div>
              <div>
                <Text lineHeight greenReward>
                  {g(project, "authorData.text")}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={be(MODULE_NAME, "spacing", "lg")} />
    </Fragment>
  ) : g(project, "authorData.firstname") ? (
    <Fragment>
      <div className={be(MODULE_NAME, "authors")}>
        <div className={be(MODULE_NAME, "authorReleasedWrapper")}>
          <div className={be(MODULE_NAME, "authorAvatar")}>
            <Avatar image={g(project, "authorData.foto")} xs />
          </div>
          <div className={be(MODULE_NAME, "authorFinancing")}>
            <div className={be(MODULE_NAME, "authorReleasedName")} onClick={onClick}>
              <Text bold greenReward hover pointer>
                {g(project, "authorData.firstname")} {g(project, "authorData.surname")}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
    </Fragment>
  ) : (
    <Fragment>
      <div className={be(MODULE_NAME, "authors")}>
        {g(project, "author", []).map(author => (
          <div
            key={author.id}
            className={be(MODULE_NAME, "authorInfo")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={be(MODULE_NAME, "authorAvatar")}>
              <Avatar image={author.profilePic} xs />
            </div>
            <div key={author.id} className={be(MODULE_NAME, "authorFinancing")}>
              <div className={be(MODULE_NAME, "authorReleasedName")} style={{ padding: "0 7px" }}>
                <Text bold greenReward>
                  {author.name} {author.surname}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
    </Fragment>
  );
};

export default compose(connect(), withRouter)(AuthorInfoReleased);
