/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { translate } from "react-i18next";

import _map from "lodash/map";
import _filter from "lodash/filter";
import _includes from "lodash/includes";

import "../messagePage.css";

import { be } from "../../../utils/bliss";
import { isMobile } from "../../../utils/utils";
import Avatar from "../../../components/avatar/avatar";
import Button from "../../../components/button/button";
import Text from "../../../components/text/text";
import UserDuck from "../../../redux/ducks/user";

const MODULE_NAME = "MessagePage";

const dontShowInStates = ["DELETED", "DECLINED", "CANCELED", "DELIVERED", "ACCEPTED"];

class UserBox extends Component {
  render() {
    const { thread, otherUser, user, dispatch, t } = this.props;

    const handshakes = _map(_filter(thread.project.handshakes, { contributorId: user.id }), h => {
      const handshake = h;
      handshake.showButton = !_includes(dontShowInStates, h.state);
      return handshake;
    });

    return !isMobile() ? (
      <div className={be(MODULE_NAME, "boxContainer")}>
        <div className="d-flex justify-content-center">
          <Avatar image={otherUser.profilePic} medium />
        </div>
        <div className="d-flex justify-content-center">
          <Text bold cartItem dark>
            {otherUser.name} {otherUser.surname}
          </Text>
        </div>
        <div className="d-flex justify-content-center" style={{ padding: "5px 0" }}>
          <Text dark small>
            má zájem o tvé služby v roli
          </Text>
        </div>
        <div className="d-flex flex-column pt-3">
          {handshakes.map(handshake => (
            <div key={handshake.id} className={be(MODULE_NAME, "roleContributorWrapper")}>
              <div>
                <Text bold dark small>
                  {handshake.subrole.name}
                </Text>
              </div>
              {handshake.showButton && (
                <div>
                  <Button
                    onClick={() => {
                      dispatch(UserDuck.removeHandshake(thread.project.id, handshake.id, false)).then(() => {
                        window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
                      });
                    }}
                    lightGreySm
                  >
                    <img alt="userPic" src="/images/trashGrey.svg" style={{ width: "20px" }} />
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
        <hr />
        <div className="d-flex flex-column">
          <div className="pl-3 pb-3">
            <div>
              <Text bold dark small>
                Název knihy
              </Text>
            </div>
            <div>
              <a href={thread.project.projectDashboard}>{thread.project.name}</a>
            </div>
          </div>
          <div className="pl-3 pb-3">
            <div>
              <Text bold dark small>
                Žánr
              </Text>
            </div>
            <div>{_map(thread.project.genres, "name").join(", ")}</div>
          </div>
          <div className="pl-3 pb-3">
            <div>
              <Text bold dark small>
                Počet stran
              </Text>
            </div>
            <div>{thread.project.printer.pages || "Neuvedeno"}</div>
          </div>
          <div className="pl-3 pb-3">
            <div>
              <Text bold dark small>
                Vazba
              </Text>
            </div>
            <div>{thread.project.printer.binding.binding || "Neuvedeno"}</div>
          </div>
          <div className="pl-3 pb-3">
            <div>
              <Text bold dark small>
                Barevnost
              </Text>
            </div>
            <div>{thread.project.printer.binding.color || "Neuvedeno"}</div>
          </div>
          <div className="pl-3 pb-3">
            <div>
              <Text bold dark small>
                Rozměry
              </Text>
            </div>
            <div>{thread.project.printer.binding.size || "Neuvedeno"}</div>
          </div>
        </div>
      </div>
    ) : (
      <div className="comment Comment-mainMessenger">
        <div className="d-flex">
          <div className="Comment-avatarMessenger d-flex">
            <Avatar image={otherUser.profilePic} md />
          </div>
          <div>
            <div className="d-flex flex-column">
              <div className="pr-3">
                <Text bold small dark>
                  {otherUser.name} {otherUser.surname}
                </Text>
              </div>
              <div style={{ paddingBottom: "5px" }}>
                <Text dark medium>
                  má zájem o tvé služby v roli
                </Text>
              </div>
              <div className="d-flex flex-column">
                {handshakes.map(handshake => (
                  <div key={handshake.id} className={be(MODULE_NAME, "roleContributorAligned")}>
                    <div>
                      <Text bold dark small>
                        {handshake.subrole.name}
                      </Text>
                    </div>
                    {handshake.showButton && (
                      <div>
                        <Button
                          onClick={() => {
                            dispatch(UserDuck.removeHandshake(thread.project.id, handshake.id, false)).then(() => {
                              window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
                            });
                          }}
                          lightGreySm
                        >
                          <img alt="userPic" src="/images/trashGrey.svg" style={{ width: "20px" }} />
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(connect(), translate("translations"))(UserBox);
