import React from "react";
import { get as g, isEmpty } from "lodash";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";

import { be } from "../../../../utils/bliss";

import EnumsDuck from "../../../../redux/ducks/enums";
import UIDuck from "../../../../redux/ducks/ui";
import UserDuck from "../../../../redux/ducks/user";
import WizardDuck from "../../../../redux/ducks/wizard";

import DetailBubble from "../../../../components/bubbles/detailBubble/detailBubble";
import Headline from "../../../../components/headline/headline";

const MODULE_NAME = "ProjectDetailScreen";

const GetRecommendedContent = ({
  project = {},
  data = [],
  history,
  handshakeStates,
  dispatch,
  t,
  user,
  match: { params }
}) => {
  const { handshakesBySubroleId } = project;

  const confirmHandshake = (handshake = {}) => {
    // if (g(handshakeStates, 'requested.name', '') === handshake.state) {
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
        handshake,
        project
      })
    );
    // }
  };

  const removeHandshake = (handshake = {}) => {
    dispatch(
      UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
        text: t("projectDetail.removeHandshakeText"),
        callback: () => {
          dispatch(UserDuck.removeHandshake(params.id, handshake.id)).then(() => {
            window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
            dispatch(WizardDuck.setProjectById(params.id));
            dispatch(WizardDuck.setDetail(params.id, user.id));
          });
        }
      })
    );
  };

  return (
    <div className={be(MODULE_NAME, "parameters")}>
      <Headline bold component="h3" light xs>
        Doporučeno
      </Headline>
      <div className={be(MODULE_NAME, "spacing", "sm")} />
      {data.map(d => {
        const handshakes = (handshakesBySubroleId || {})[d.id] || [];

        return (
          <DetailBubble
            key={d.id}
            cancelHandshake={removeHandshake}
            complete={!!handshakes.find(hs => hs.state === g(handshakeStates, "accepted.name", ""))}
            confirmHandshake={confirmHandshake}
            description={d.description}
            handshakes={handshakes}
            icon="/images/svg/optional.svg"
            iconLabel="Doporučený kolega"
            label="Najít kolegu"
            locked={!project.printer}
            onClick={() => {
              if (isEmpty(g(project, "handshakes", []))) {
                dispatch(
                  UIDuck.openModal(UIDuck.modalTypes.alertPrompt, {
                    text:
                      "Jste si jistí, že máte vhodně zvolené žánry a tagy? Po poptání kolegů již nebude možnost to změnit...",
                    callback: () => {
                      history.push(`/contributors/${project.id}?subroles=[${d.id}]`);
                    }
                  })
                );
              } else {
                history.push(`/contributors/${project.id}?subroles=[${d.id}]`);
              }
            }}
            title={d.name}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  user: UserDuck.getUser(state)
});

export default compose(translate("translations"), connect(mapStateToProps), withRouter)(GetRecommendedContent);
