/* global dataLayer */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty, isEqual } from "lodash";

import "./projectsPage.css";

import _map from "lodash/map";
import _take from "lodash/take";
import _isEmpty from "lodash/isEmpty";

import { isMobile } from "../../utils/utils";
import { bm, be } from "../../utils/bliss";
import WizardDuck from "../../redux/ducks/wizard";
import ProjectCard from "../../pages/landing/components/projectCard/projectCard";
import SelectComponent from "../../components/input/select/select";
import Button from "../../components/button/button";
import Header from "../../components/header/header";
import Headline from "../../components/headline/headline";
import Screen from "../../components/screen/screen";
import Loader from "../../components/loader/loader";
import UserDuck from "../../redux/ducks/user";
import EnumsDuck from "../../redux/ducks/enums";
import StorageService from "../../services/storageService";
import Pagination from "../../components/pagination/pagination";
import { STATES_WHITE_LIST } from "./projectsPage";
import { showProject } from "../../utils/goTo";
import Text from "../../components/text/text";
import DataLayerPush from "../../components/gtm/dataLayerPush";

const MODULE_NAME = "ProjectsPage";

class ProjectsStatePage extends Component {
  state = {
    loading: true,
    filters: {
      page: 1,
      order: "desc",
      limit: 16,
      state: STATES_WHITE_LIST[this.props.match.params.state].name
    }
  };

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { state }
      }
    } = this.props;

    let promises = [
      dispatch(EnumsDuck.setRoles()),
      dispatch(EnumsDuck.loadCatalog()),
      dispatch(WizardDuck.fetchAllProjects(this.state.filters))
    ];
    if (StorageService.hasToken()) {
      promises = [...promises, dispatch(UserDuck.setUserByToken("", true, false)), dispatch(EnumsDuck.setBindings())];
    }
    Promise.all(promises).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (!isEqual(this.state.filters, nextState.filters)) {
      this.props.dispatch(WizardDuck.fetchAllProjects(nextState.filters)).then(() => {
        this.setState({
          loading: false
        });
      });
    }
    if (this.props.projects !== nextProps.projects && !_isEmpty(nextProps.projects)) {
      const {
        t,
        match: {
          params: { state }
        },
        projects
      } = nextProps;

      const projectsArray = Object.values(projects);

      dataLayer.push({
        event: "viewCategory",
        content_name: t(`projectsPageStates.${state}.title`),
        content_category: `Knihy -> ${t(`projectsPageStates.${state}.title`)}`,
        content_ids: _map(_take(projectsArray, 6), "id")
      });
    }
  }

  handleStateChange(newState) {
    const {
      match: {
        params: { state }
      }
    } = this.props;

    if (state !== newState) {
      const newStateName = STATES_WHITE_LIST[newState].name;
      this.setState(
        {
          loading: true,
          filters: {
            ...this.state.filters,
            page: 1,
            state: newStateName
          }
        },
        () => this.props.history.push(`/projects/${newState}`)
      );
    }
  }

  handleFilters = ({ target }) => {
    const {
      filters: { page }
    } = this.state;

    this.setState({
      filters: {
        ...this.state.filters,
        [target.name]: target.value,
        page: target.name === "genres" && page > 1 ? 1 : page
      }
    });
  };

  onPaginationChange = page => {
    window.scrollTo(0, 0);
    this.setState({
      filters: {
        ...this.state.filters,
        page
      }
    });
  };

  renderPagination() {
    const { filters } = this.state;
    const { projectsPagesCount } = this.props;

    if (projectsPagesCount > 1) {
      const {
        match: {
          params: { state }
        }
      } = this.props;

      const divStyle = {
        background: state !== "proposal" ? "#ffffff" : "#FAEDE6",
        margin: "0 -100%",
        padding: "0 100%",
        display: "inherit",
        overflow: "auto"
      };

      return (
        <div className="row" style={divStyle}>
          <Pagination activePage={filters.page} maxPage={projectsPagesCount} onChange={this.onPaginationChange} />
        </div>
      );
    }
  }

  renderList = () => {
    const {
      t,
      match: {
        params: { state }
      },
      projects
    } = this.props;

    const divStyle = {
      background: state !== "proposal" ? "#ffffff" : "#FAEDE6",
      margin: "0 -100%",
      padding: "0 100%"
    };

    const projectsArray = Object.values(projects);

    // TODO: THIS TRICK ENSURES THE CORRECTNESS OF THE DISPLAING OF THE GRID WITH THE PROJECTS.
    //       NOTE, THAT CORRECT FIX COULD REQUIRES BIGGER REFACTORING OF THE CSS.
    //       THE ISSUE IS TRACKED HERE: https://creativedock.atlassian.net/browse/PNT-385
    for (var i = 0; projectsArray.length < 4; i++) {
      projectsArray.push({ hiddenElement: true });
    }

    return (
      <div className={be(MODULE_NAME, "row", false, "row")} style={divStyle}>
        {!isEmpty(projectsArray) ? (
          <React.Fragment>
            <div className="d-flex justify-content-between w-100">
              <div className="col-md-10 text-center" style={{ padding: "30px 0", margin: "auto" }}>
                <Headline
                  greenReward
                  className={(be(MODULE_NAME, "rowHeading"), false, be(MODULE_NAME, "rowHeading", state))}
                >
                  {t(`projectsPageStates.${state}.title`)}
                </Headline>
                <div style={{ height: "15px" }} />
                <div style={{ padding: "0 10px" }}>
                  <Text greenReward className={be(MODULE_NAME, "rowSubheading")}>
                    {t(`projectsPageStates.${state}.desc`)}
                  </Text>
                </div>
              </div>
            </div>
            {!isMobile() && (
              <div style={{ margin: "0 -105%", padding: "0 100%" }}>
                <div className="container-fluid">
                  <div className="row">
                    {projectsArray.map(project => (
                      <div
                        key={project.id}
                        className="col-sm-6 col-md-4 col-lg-3"
                        visibility={project.hiddenElement ? "hidden" : "visible"}
                        pointerEvents={project.hiddenElement ? "none" : "auto"}
                      >
                        <ProjectCard onClick={() => showProject(project.id)} project={project} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {isMobile() && (
              <div style={{ margin: "0 -105%", padding: "0 100%" }}>
                <div className="container-fluid">
                  <div className="col" style={{ marginLeft: "-15px" }}>
                    {projectsArray.map(project => (
                      <div key={project.id} className="col">
                        <ProjectCard onClick={() => showProject(project.id)} project={project} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className="col-xs-12">
            <Text bold center lg>
              Pro specifikované parametry, nebyl nalezen projekt!
            </Text>
          </div>
        )}
      </div>
    );
  };

  renderFilters() {
    const { t, projectStates, genres } = this.props;
    const { filters } = this.state;

    return (
      <div className={be(MODULE_NAME, "filters")}>
        <div className="row">
          <div className="col-sm pb-5">
            <Headline page>{t("header.books")}</Headline>
          </div>
        </div>
        <div className="row d-flex justify-content-between w-100 ml-0">
          <div className="pull-left">
            <div className={be(MODULE_NAME, "state", "first")}>
              <Button black lowerCase to="/projects">
                Všechny
              </Button>
              {Object.keys(projectStates)
                .filter(st => STATES_WHITE_LIST.hasOwnProperty(st))
                .map(st => {
                  const state = projectStates[st];
                  return (
                    <Button
                      key={state.name}
                      black
                      lowerCase
                      onClick={() => this.handleStateChange(st)}
                      primary={state.name === filters.state ? true : undefined}
                    >
                      {STATES_WHITE_LIST[st].label}
                    </Button>
                  );
                })}
            </div>
          </div>
          <div className="pull-right mb-5">
            <div className={be(MODULE_NAME, "select")}>
              <SelectComponent
                classNamePrefix="select-box"
                clearable
                isMulti
                name="genres"
                onChange={this.handleFilters}
                options={genres.map(genre => ({
                  value: genre.id,
                  label: genre.name
                }))}
                placeholder={t("dashboard.genresPlaceholder")}
                value={filters.genres}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    return loading ? (
      <Loader />
    ) : (
      <Screen header={<Header {...this.props} />}>
        <div className={bm(MODULE_NAME)}>
          {this.renderFilters()}
          {this.renderList()}
          {this.renderPagination()}
        </div>
      </Screen>
    );
  }
}

ProjectsStatePage.propTypes = {
  genres: PropTypes.array.isRequired,
  projects: PropTypes.object.isRequired,
  projectsPagesCount: PropTypes.number,
  projectStates: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  projects: WizardDuck.getProjects(state),
  projectStates: EnumsDuck.getProjectStates(state),
  genres: Object.values(EnumsDuck.getGenres(state)),
  projectsPagesCount: WizardDuck.getProjectsPagesCount(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ProjectsStatePage);
