import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { isEmpty, get as g, uniq, isEqual, head, filter, difference, debounce, uniqBy } from "lodash";
import moment from "moment";

import "./dashboardPage.css";
import { bm, be } from "../../utils/bliss";
import { getImagePreview } from "../../utils/utils";

import WizardDuck from "../../redux/ducks/wizard";
import FormDuck from "../../redux/ducks/forms";
import UserDuck from "../../redux/ducks/user";
import WelcomePanel from "../../components/welcomePanel/welcomePanel";
import ProjectInfo from "../../components/projectInfo/projectInfo";
import WelcomeBackPanel from "../../components/welcomePanel/welcomeBackPanel/welcomeBackPanel";
import SelectComponent from "../../components/input/select/select";
import UserPanel from "../../components/userPanel/userPanel";
import EnumsDuck from "../../redux/ducks/enums";
import Headline from "../../components/headline/headline";
import Button from "../../components/button/button";
import WizardService from "../../services/wizardService";
import UserService from "../../services/userService";
import Loader from "../../components/loader/loader";
import FollowedProjects from "../../components/followedProjects/followedProjects";
import { showProject } from "../../utils/goTo";
import { PREVIEWS_API } from "../../services/config";
import Link from "../../components/link/link";
import Text from "../../components/text/text";
import Forms from "../../components/forms/form";
import GenresList from "../../components/genres/genresList";

const MODULE_NAME = "Dashboard";

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { projectId, userId, subroleId }
      },
      handshakeStates,
      currentUser,
      user,
      roles,
      dispatch,
      data
    } = props;

    this.state = {
      handshake: !!projectId,
      public: !!userId,
      loading: !!userId,
      user: props.user || {},
      cooperationAsked: false,
      units: {},
      projects: {},
      genres: g(props, "user.followedGenres", []).map(g => g.id),
      contributors: {
        order: "desc",
        subroles: []
      },
      projects: {
        order: "desc"
      },
      authors: {
        order: "desc"
      },
      followedProjects: {
        order: "desc",
        limit: 6
      },
      loadAuthors: false
    };

    if (userId) {
      UserService.getUserById(userId).then(user => {
        this.setState({
          loading: false,
          user,
          data,
          userPrices: g(user, "subroles", []),
          cooperationAsked: !!g(user, "contributorHandshakes", []).find(
            ({ subrole, project, state }) =>
              subrole.id === subroleId && projectId === project.id && state !== g(handshakeStates, "declined.name", "")
          ),
          requestedHandshakes: g(user, "handshakes", []).filter(
            ({ subrole, project, state, author }) =>
              g(author, "id", false) === currentUser.id &&
              state === g(handshakeStates, "requestedByContributor.name", "")
          ),
          units: {}
        });
        if (user.roles.includes(roles.author.name)) {
          WizardService.fetchUserProjects(userId).then(data => {
            console.log(data);

            this.setState({
              workedProjects: g(data, "data", [])
            });
            //res(data);
            dispatch(WizardDuck.setLoading("userProjects", false));
          });
        }
        if (user.roles.includes(roles.contributor.name)) {
          const acceptedHandshakes = g(user, "handshakes", []).filter(
            ({ state }) => g(handshakeStates, "accepted.name", "") === state
          );

          const promises = uniq(acceptedHandshakes).map(h => WizardService.getProjectById(h.project.id));

          Promise.all(promises)
            .then(data => {
              this.setState({
                workedHandshakes: uniqBy(data, "id")
              });
            })
            .catch(err => {
              if (g(err, "response.data.code", "")) {
                // history.push('/login');
                // window.alertify.error('Prosím přihlaste se, vypršela platnost Vašeho tokenu!');
              }
            });
        }
      });
    }
  }

  componentWillMount() {
    const { dispatch, user } = this.props;
    dispatch(WizardDuck.fetchAllProjects());
    this.loadInitStateByUser(user);
  }

  componentWillReceiveProps(nextProps) {
    let toState = {};

    const hsDiff = !isEqual(
      g(this.props, "user.handshakes", []).map(hs => hs.state),
      g(nextProps, "user.handshakes", []).map(hs => hs.state)
    );
    if (hsDiff) {
      this.loadInitStateByUser(nextProps.user);
    }

    if (!isEmpty(nextProps.user)) {
      toState = {
        ...toState,
        loading: false,
        user: nextProps.user
      };
      if (g(this.props, "user.subroles", []).length !== g(nextProps, "user.subroles", []).length) {
        toState = {
          ...toState,
          userPrices: g(nextProps, "user.subroles", []),
          units: g(nextProps, "user.subroles", []).reduce(
            (res, subrole) => ({
              ...res,
              [g(subrole, "subrole.id", "")]: g(subrole, "unit.id", "")
            }),
            {}
          )
        };
      }
    }
    if (!isEmpty(toState)) {
      this.setState(toState);
    }
  }

  loadInitStateByUser(user = this.props.user) {
    const { handshakesStates, projectStates, dispatch, roles } = this.props;
    let toState = this.state;
    const isContributor = user.roles.includes(g(roles, "contributor.name", ""));
    const isAuthor = user.roles.includes(g(roles, "author.name", ""));
    const execludedStates = [g(projectStates, "failed.name", "")];

    if (!isEmpty(user) && isContributor) {
      // user is contributor
      dispatch(UserDuck.setLoading("authors", false));
      if (g(user, "handshakes", []).find(hs => hs.state === g(handshakesStates, "accepted.name", ""))) {
        // contributor is  working on some project
        const workDelivedProjectsIds = uniq(
          g(user, "handshakes", [])
            .filter(hs => hs.state === g(handshakesStates, "accepted.name", ""))
            .map(hs => hs.projectId)
        );
        if (!isEmpty(workDelivedProjectsIds)) {
          Promise.all(
            workDelivedProjectsIds.map(
              projectId =>
                new Promise(res => {
                  WizardService.getProjectById(projectId, true)
                    .then(project => res(project))
                    .catch(() => res(null)); // needs to be here, 'cos failed projects fails with no-found msg
                })
            )
          ).then(data => {
            this.setState({
              workedProjects: data.filter(d => !!d)
            });
          });
        }

        toState = {
          ...toState,
          contributors: {
            ...toState.contributors,
            subroles: g(user, "subroles", []).map(({ subrole }) => subrole.id), // contributors that have the same role as the user
            order: "desc"
          },
          projects: {
            ...toState.projects,
            state: g(projectStates, "proposal.name", ""), // 3 newest projects in proposal
            order: "desc"
          }
        };
      } else {
        // contributor is not working on any project
        toState = {
          ...toState,
          contributors: {
            ...toState.contributors,
            subroles: g(user, "subroles", []).map(({ subrole }) => subrole.id) // contributors that have the same role as the user
          },
          projects: {
            ...toState.projects,
            state: g(projectStates, "proposal.name", ""), // 3 newest projects in proposal
            order: "desc"
          }
        };
      }
      this.setState(toState, () => {
        dispatch(UserDuck.loadContributors(toState.contributors));
        dispatch(WizardDuck.fetchAllProjects(toState.projects));
      });
    } else if (!isEmpty(user) && isAuthor) {
      // user is author
      dispatch(WizardDuck.fetchUserProjects(user.id)).then(({ data: projects }) => {
        if (projects && !isEmpty(projects)) {
          // User has a project
          toState = {
            ...toState,
            projects: {
              ...toState.projects,
              state: (projects[0] || {}).state || ""
            }
          };
        } else {
          // User does not have the project
          toState = {
            ...toState,
            projects: {
              ...toState.projects,
              state: g(projectStates, "proposal.name", "")
            },
            loadAuthors: true
          };
        }
        this.setState(toState, () => {
          if (toState.loadAuthors) {
            dispatch(UserDuck.loadAuthors(toState.authors));
            dispatch(UserDuck.setLoading("contributors", false));
            dispatch(UserDuck.setLoading("allUsers", false));
          } else {
            dispatch(UserDuck.setLoading("authors", false));
            dispatch(UserDuck.loadContributors(toState.contributors));
          }
          dispatch(WizardDuck.fetchAllProjects({ ...toState.projects, execludedStates }));
        });
      });
    } else if (!isEmpty(user)) {
      dispatch(UserDuck.setLoading("contributors", false));
      dispatch(UserDuck.setLoading("authors", false));
      dispatch(UserDuck.setLoading("allUsers", false));
      dispatch(WizardDuck.fetchFollowedProjects(user.id, { ...this.state.followedProjects, execludedStates }));
      dispatch(WizardDuck.setInterestedProjects(g(user, "followedGenres", []).map(g => g.id)));
    }
  }

  createProject = () => {
    this.props.history.push("/wizard");
  };

  showUser = userId => {
    this.props.history.push(`/profile/${userId}`);
  };

  handleFilters = ({ target }) => {
    this.setState(
      {
        ...this.state,
        contributors: {
          ...this.state.contributors,
          [target.name]: target.value
        }
      },
      () => {
        this.props.dispatch(UserDuck.fetchContributors(this.state.contributors));
      }
    );
  };

  filteroutFailedProjects = projects => {
    const { projectStates } = this.props;
    return filter(projects, p => p.state.toLowerCase() !== g(projectStates, "failed.name", "").toLowerCase());
  };

  getContent(type = 1, isAuthor = true) {
    const { projects, t, roles, authors, contributors, userProjects } = this.props;
    const subroles = g(roles, "contributor.subroles", []);
    const isAuthors = !isEmpty(authors) && isEmpty(userProjects);

    if (type === 1) {
      if (isAuthor) {
        return (
          <div className={be(MODULE_NAME, "newProjects")}>
            <Headline bold component="h2" xs>
              {t("dashboard.newProjects")}
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            <div className="row">
              {this.filteroutFailedProjects(projects)
                .slice(0, 3)
                .map(project => (
                  <div key={project.id} className="col-sm-6 col-lg-4">
                    <ProjectInfo onClick={() => showProject(project.id)} project={project} />
                  </div>
                ))}
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className="text-center">
              <Button medium to="/projects">
                {t("dashboard.allProjects")}
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className={be(MODULE_NAME, "newContributors")}>
          <div className="row">
            <div className="col-md">
              <Headline bold component="h2" xs>
                {t(`dashboard.${isAuthors ? "authors" : "contributors"}`)}
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "lg", "d-md-none")} />
            </div>
            {!isAuthors && (
              <div className="col-md-auto">
                <div className={be(MODULE_NAME, "select", "big")}>
                  <SelectComponent
                    classNamePrefix="select-box"
                    isMulti
                    name="subroles"
                    onChange={this.handleFilters}
                    options={subroles.map(sr => ({
                      label: sr.name,
                      value: sr.id
                    }))}
                    placeholder={t("dashboard.rolesPlaceholder")}
                    value={g(this.state, "contributors.subroles", [])}
                  />
                  <div className={be(MODULE_NAME, "spacing", "xs", "d-md-none")} />
                </div>
              </div>
            )}
            {!isAuthors && (
              <div className="col-md-auto">
                <div className={be(MODULE_NAME, "select")}>
                  <SelectComponent
                    classNamePrefix="select-box"
                    defaultValue="desc"
                    name="order"
                    onChange={this.handleFilters}
                    options={[
                      {
                        label: t("dashboard.sortDesc"),
                        value: "desc"
                      },
                      {
                        label: t("dashboard.sortAsc"),
                        value: "asc"
                      }
                    ]}
                    placeholder={t("dashboard.sortPlaceholder")}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />

          <div className="row">
            {Object.values(!isAuthors ? contributors : authors)
              .slice(0, 3)
              .map(user => (
                <div key={user.id} className="col-sm-6 col-lg-4">
                  <UserPanel onClick={() => this.showUser(user.id)} user={user} />
                </div>
              ))}
          </div>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="text-center">
            <Button medium to="/contributors">
              {t("dashboard.allContributors")}
            </Button>
          </div>
        </div>
      );
    }
    if (isAuthor) {
      return (
        <div className={be(MODULE_NAME, "newContributors")}>
          <div className="row">
            <div className="col-md">
              <Headline bold component="h2" xs>
                {t(`dashboard.${isAuthors ? "authors" : "contributors"}`)}
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "lg", "d-md-none")} />
            </div>
            {!isAuthors && (
              <div className="col-md-auto">
                <div className={be(MODULE_NAME, "select", "big")}>
                  <SelectComponent
                    classNamePrefix="select-box"
                    isMulti
                    name="subroles"
                    onChange={this.handleFilters}
                    options={subroles.map(sr => ({
                      label: sr.name,
                      value: sr.id
                    }))}
                    placeholder={t("dashboard.rolesPlaceholder")}
                    value={g(this.state, "contributors.subroles", [])}
                  />
                  <div className={be(MODULE_NAME, "spacing", "xs", "d-md-none")} />
                </div>
              </div>
            )}
            {!isAuthors && (
              <div className="col-md-auto">
                <div className={be(MODULE_NAME, "select")}>
                  <SelectComponent
                    classNamePrefix="select-box"
                    defaultValue="desc"
                    name="order"
                    onChange={this.handleFilters}
                    options={[
                      {
                        label: t("dashboard.sortDesc"),
                        value: "desc"
                      },
                      {
                        label: t("dashboard.sortAsc"),
                        value: "asc"
                      }
                    ]}
                    placeholder={t("dashboard.sortPlaceholder")}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={be(MODULE_NAME, "spacing", "lg")} />
          <div className="row">
            {Object.values(!isAuthors ? contributors : authors)
              .slice(0, 3)
              .map(user => (
                <div key={user.id} className="col-sm-6 col-lg-4">
                  <UserPanel onClick={() => this.showUser(user.id)} user={user} />
                </div>
              ))}
          </div>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="text-center">
            <Button medium to="/contributors">
              {t("dashboard.allContributors")}
            </Button>
          </div>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className={be(MODULE_NAME, "spacing", "sm")} />
        </div>
      );
    }
    return (
      <div className={be(MODULE_NAME, "newProjects")}>
        <div className="row">
          <div className="col-md">
            <Headline bold component="h2" xs>
              {t("dashboard.newProjects")}
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
          </div>
        </div>
        <div className="row">
          {Object.values(projects)
            .slice(0, 3)
            .map(project => (
              <div key={project.id} className="col-sm-6 col-lg-4">
                <ProjectInfo onClick={() => showProject(project.id)} project={project} />
              </div>
            ))}
        </div>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className="text-center mb-4">
          <Button medium to="/projects">
            {t("dashboard.allProjects")}
          </Button>
        </div>
      </div>
    );
  }

  isAuthor = () => {
    const { roles } = this.props;
    const { user } = this.state;
    return g(user, "roles", []).includes(roles.author.name);
  };

  render() {
    const { t, userProjects, user, roles, isDashboardLoading, interestedProjects } = this.props;
    const { workedProjects } = this.state;
    const isAuthor = g(user, "roles", []).includes(g(roles, "author.name", ""));
    const isContributor = g(user, "roles", []).includes(g(roles, "contributor.name", ""));
    const isReader = g(user, "roles", []).includes(g(roles, "reader.name", ""));

    return (
      <div className={bm(MODULE_NAME)}>
        {isDashboardLoading && <Loader />}
        {isEmpty(userProjects) && isAuthor && <WelcomePanel onClick={this.createProject} t={t} user={user} />}
        {isEmpty(user.followedProjects) && isReader && <WelcomePanel isReader={isReader} />}
        {(isContributor || (!isEmpty(userProjects) && !isReader)) && (
          <div className={be(MODULE_NAME, "hero", isAuthor && "author")}>
            <div className={be(MODULE_NAME, "heroContent")}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div>
                      {isAuthor && (
                        <div>
                          <Headline bold component="h2" xs>
                            {t("dashboard.myProjects")}
                          </Headline>
                          <div className={be(MODULE_NAME, "spacing", "lg")} />
                        </div>
                      )}
                      <WelcomeBackPanel
                        isAuthor={isAuthor}
                        isContributor={isContributor}
                        user={user}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isContributor && workedProjects && !isEmpty(workedProjects) && (
          <div className={be(MODULE_NAME, "newProjects")} style={{ marginTop: "100px" }}>
            <Headline bold component="h2" xs>
              {t("dashboard.coworkedProjects")}
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            <div className="row">
              {workedProjects.slice(0, 3).map(project => (
                <div key={project.id} className="col-sm-6 col-lg-4">
                  <ProjectInfo onClick={() => showProject(project.id)} project={project} />
                </div>
              ))}
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className="text-center">
              <Button medium to="/projects">
                {t("dashboard.allProjects")}
              </Button>
            </div>
            <div className={be(MODULE_NAME, "spacing", "xl")} />
          </div>
        )}

        {(isAuthor || isContributor) && this.getContent(1, isAuthor)}
        {isReader && user.followedProjects.length > 0 && <FollowedProjects opt={{ limit: 6 }} />}
        <div className={be(MODULE_NAME, "spacing", "xl")} />
        {(isAuthor || isContributor) && this.getContent(2, isAuthor)}
        {isReader && (
          <div className={be(MODULE_NAME, "interestedProjects")}>
            <Headline bold component="h2" xs>
              Mohlo by se ti líbit
            </Headline>
            <div className={be(MODULE_NAME, "spacing", "lg")} />
            <div className="row">
              {this.filteroutFailedProjects(interestedProjects).map(project => (
                <div key={project.id} className="col-sm-6 col-lg-4">
                  <ProjectInfo onClick={() => showProject(project.id)} project={project} />
                </div>
              ))}
            </div>
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className="text-center">
              <Button medium to="/projects">
                {t("dashboard.allProjects")}
              </Button>
            </div>
            <div className={be(MODULE_NAME, "spacing", "md")} />
          </div>
        )}
      </div>
    );
  }
}

DashboardPage.propTypes = {
  authors: PropTypes.object.isRequired,
  contributors: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handshakesStates: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  interestedProjects: PropTypes.object.isRequired,
  isDashboardLoading: PropTypes.bool.isRequired,
  projects: PropTypes.object.isRequired,
  projectStates: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userProjects: PropTypes.object.isRequired
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { userId }
    }
  }
) => {
  const currentUser = UserDuck.getUser(state);

  return {
    user: userId ? {} : currentUser,
    currentUser,
    contributors: UserDuck.getContributors(state),
    roles: EnumsDuck.getRoles(state),
    projects: WizardDuck.getProjects(state),
    userProjects: WizardDuck.getUserProjects(state),
    handshakeStates: EnumsDuck.getHandhakeStates(state),
    projectStates: EnumsDuck.getProjectStates(state),
    authors: UserDuck.getAuthors(state),
    units: EnumsDuck.getUnits(state),
    isDashboardLoading: UserDuck.isDashboardLoading(state) || WizardDuck.isDashboardLoading(state),
    interestedProjects: WizardDuck.getInterestedProjects(state)
  };
};

export default compose(translate("translations"), connect(mapStateToProps))(DashboardPage);
