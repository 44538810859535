import React, { Component } from "react";
import PropTypes from "prop-types";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";

import "./loginPage.css";

import { bm, be } from "../../utils/bliss";
import FormDuck from "../../redux/ducks/forms";
import Forms from "../../components/forms/form";
import UserDuck from "../../redux/ducks/user";
import Button from "../../components/button/button";
import Headline from "../../components/headline/headline";
import Link from "../../components/link/link";
import Screen from "../../components/screen/screen";
import Text from "../../components/text/text";
import Header from "../../components/header/header";
import { FB_APP_ID } from "../../services/variables";
import { dynamicTitle, isMobile } from "../../utils/utils";

const MODULE_NAME = "LoginPage";

class LoginPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleLogin = userToLogin => {
    const {
      location: { search }
    } = this.props;
    this.props.dispatch(UserDuck.loginUser(userToLogin, search));
  };

  responseFacebook = response => {
    this.props.dispatch(
      UserDuck.loginUser({
        fbAccessToken: response.accessToken
      })
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Screen header={<Header />}>
        {dynamicTitle(t("loginPage.title"), false, this.props.location.pathname)}
        <div className={bm(MODULE_NAME)}>
          <div className="row">
            <div className="col">
              <div className="text-center">
                <Headline page primary>
                  {t("loginPage.title")}
                </Headline>
              </div>
            </div>
          </div>
          <div className={be(MODULE_NAME, "headlineSpacing")} />
          <div className={be(MODULE_NAME, "body")}>
            <div className="row">
              <div className="col-sm-6 offset-sm-3">
                <Forms
                  inputs={[
                    {
                      placeholder: t("loginPage.email"),
                      label: <Text small>{t("loginPage.email")}</Text>,
                      name: "email",
                      entityType: "email",
                      className: "col-xs-12"
                    },
                    {
                      placeholder: t("loginPage.password"),
                      label: (
                        <div className="center space">
                          <Text small>{t("loginPage.password")}</Text>
                        </div>
                      ),
                      name: "password",
                      entityType: "password",
                      type: "password",
                      className: "col-xs-12"
                    }
                  ]}
                  name={FormDuck.formTypes.login}
                  onSubmit={this.handleLogin}
                >
                  <div className="col">
                    <div className="text-center">
                      <div className={be(MODULE_NAME, "formSpacing")} />
                      <Button medium type="submit">
                        {t("loginPage.submit")}
                      </Button>
                    </div>
                  </div>
                </Forms>
              </div>
            </div>
            <div className={be(MODULE_NAME, "buttonSpacing")} />
            <div className="row">
              <div className="col">
                <div className="d-flex w-100 justify-content-center">
                  <FacebookLogin
                    appId={FB_APP_ID}
                    callback={this.responseFacebook}
                    className="col-xs-12"
                    disableMobileRedirect
                    fields="name,email,picture,first_name,last_name"
                    isMobile={isMobile()}
                    render={renderProps => (
                      <Button fb medium onClick={renderProps.onClick}>
                        {t("loginPage.fbButton")}
                      </Button>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "buttonSpacing")} />
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <Link big to="/forgotten-password">
                    {t("loginPage.forgottenPassword")}
                  </Link>
                </div>
              </div>
            </div>
            <div className={be(MODULE_NAME, "linkSpacing")} />
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <Text>Nemám účet.</Text>
                  <Link big to="/register">
                    {" "}
                    Registrovat se
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(translate("translations"), connect())(LoginPage);
