import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { isEmpty, get as g } from "lodash";
import moment from "moment";

import _filter from "lodash/filter";

import "./form.css";

import InputField from "./inputField";
import FormDuck from "../../redux/ducks/forms";
import { bm, be } from "../../utils/bliss";

const MODULE_NAME = "Form";

export const composeAnchor = (formName, inputName) => `${formName}__${inputName}`;

class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getStructure(props.inputs)
    };

    props.dispatch(FormDuck.signForm(props.name, this.state.data));

    const propsWithDefaultValues = _filter(props.inputs, "defaultValue");
    propsWithDefaultValues.forEach(({ name, defaultValue }) => {
      props.dispatch(FormDuck.setInput(props.name, name, defaultValue));
    });

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.setSubmit(this.submit);
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.inputs.length !== this.props.inputs.length || nextProps.name !== this.props.name) {
  //         this.setState(this.getStructure(nextProps.inputs), () => {
  //             nextProps.dispatch(FormDuck.signForm(nextProps.name, nextProps.data, true));
  //         });
  //     }
  // }

  componentWillReceiveProps(newProps) {
    if (newProps && Array.isArray(newProps.inputs)) {
      newProps.inputs.forEach(input => {
        // reset only email external error
        if ("email" === input.entityType) {
          if (!this.isExternalError(input)) {
            const oldProps =
              Array.isArray(this.props.inputs) && this.props.inputs.find(el => el.entityType === input.entityType);
            if (oldProps && input.errorType !== oldProps.errorMessage && input.errorMessage !== oldProps.errorMessage) {
              // reset only when there is a change
              this.setState(state => ({ validations: { ...state.validations, [input.entityType]: null } }));
            }
          }
        }
      });
    }
  }

  getStructure(inputs = this.props.inputs) {
    return inputs.reduce(
      (res, input) => {
        if (!input.name) {
          return res;
        }
        return {
          ...res,
          data: {
            ...res.data,
            [input.name]: typeof input.value === "string" ? input.value.trim() : input.value || ""
          },
          validations: {
            ...res.validations,
            [input.name]: {
              type: null,
              msg: ""
            }
          }
        };
      },
      {
        data: {},
        validations: {}
      }
    );
  }

  onChange = ({ target }) => {
    const { dispatch, name, onSetInput } = this.props;
    let value = target.value;
    if (target.type === "number") {
      value = `${value}`.includes(".") ? parseFloat(value, 10) : parseInt(value, 10);
    }
    if (target.type === "checkbox") {
      value = `${target.value}` === "false" || !target.value;
    }
    dispatch(FormDuck.setInput(name, target.name, value));
    onSetInput(name, target.name, value);
  };

  getValidations({ value = "", entityType = "none", required = false, type, format, min, max }) {
    let erType = null;
    let msg = "";

    if ((!value && required) || (required && typeof value === "object" && isEmpty(value))) {
      if (value !== 0 && type !== "number") {
        return { type: "warning", msg: "Pole je povinné." };
      }
    }

    if (value) {
      switch (entityType) {
        case "password":
          if (value.length < 6) {
            erType = "error";
            msg = "Heslo musí mít minimálně 6 znaků.";
          }
          break;

        case "email":
          if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value)) {
            erType = "error";
            msg = 'E-mail musí mít formát "josef.novak@seznam.cz"';
          }
          break;

        case "phone":
          if (!/\d{3}\s?\d{3}\s?\d{3}/.test(value)) {
            erType = "error";
            msg = "Telefon musí byt mít formát 724123456, nebo 123 456 789.";
          }
          break;

        case "formatted-date":
          const date = moment(value, format);

          if (!date.isValid()) {
            erType = "error";
            msg = `Datum musí být ve formátu ${format}!`;
          }

          if (min && date.isBefore(moment(min))) {
            erType = "error";
            msg = `Datum nesmí být menší než ${moment(min).format("M. D. YYYY")}!`;
          }

          if (max && date.isAfter(moment(max))) {
            erType = "error";
            msg = `Datum nesmí být větší než ${moment(max).format("M. D. YYYY")}!`;
          }

          break;

        case "link":
          if (!/https?\:\/\//.test(value)) {
            erType = "error";
            msg = "Adresa musí být ve formátu http[s]?://www.google.com!";
          }

        default:
          break;
      }
    }
    return { type: erType, msg };
  }

  isExternalError = input => typeof input.errorType === "string" && typeof input.errorMessage === "string";

  submit = e => {
    e.preventDefault();
    let { inputs, onSubmit, onSubmitInvalid, data, name, scrollToFirstInvalid, scrollOffset } = this.props;
    data = Object.keys(data).reduce((res, entity) => {
      let val = data[entity];
      if (typeof val === "string") {
        val = val.trim();
      }
      return {
        ...res,
        [entity]: val
      };
    }, {});

    const { validations, isAllValid } = inputs
      .filter(i => !i.el)
      .reduce(
        (res, input) => {
          let validations = this.getValidations({
            ...input,
            value: data[input.name]
          });

          if (this.isExternalError(input)) {
            validations = { type: input.errorType, msg: input.errorMessage };
          }

          if (input.name === "confirm_password" && data[input.name] !== data.password) {
            validations = {
              type: "error",
              msg: "Vámi zvolená hesla se neshodují."
            };
          }

          return {
            ...res,
            validations: {
              ...res.validations,
              [input.name]: validations
            },
            isAllValid: res.isAllValid && !validations.type
          };
        },
        {
          isAllValid: true,
          validations: {}
        }
      );

    this.setState(
      {
        validations
      },
      () => {
        if (isAllValid) {
          onSubmit(data, name);
        } else {
          if (scrollToFirstInvalid) this.scrollToFirstInvalid();

          onSubmitInvalid && onSubmitInvalid(data, name);
        }
      }
    );

    if ("function" === typeof this.props.onSubmitError) {
      const validationErrors = Object.keys(validations).filter(
        v => validations[v] && validations[v].type && validations[v].msg
      );
      if (validationErrors.length > 0) {
        this.props.onSubmitError(
          validationErrors.length > 0 ? composeAnchor(this.props.name, validationErrors[0]) : null
        );
      }
    }

    return isAllValid ? { data, name, failed: false } : { name, failed: true };
  };

  scrollToFirstInvalid = () => {
    const inputWarning = document.querySelector(".has-warning");
    const inputError = document.querySelector(".has-error");
    const scrollOffset = this.props.scrollOffset ? this.props.scrollOffset : 0;

    if (!isEmpty(inputWarning)) scrollTo(inputWarning);
    if (!isEmpty(inputError)) scrollTo(inputError);

    function scrollTo(element) {
      const y = element.getBoundingClientRect().top + window.scrollY + scrollOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  onKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.submit(e);
    }
  };

  resetError = field => this.setState(state => ({ validations: { ...state.validations, [field]: null } }));

  render() {
    const { inputs, children, data, horizontal, inline, center, className, wrapperClassName, name } = this.props;

    return (
      <Form
        className={bm(MODULE_NAME, null, `m-t ${className}`)}
        horizontal={horizontal}
        inline={inline}
        noValidate
        onSubmit={this.submit}
      >
        <div className="form-group">
          <div className={be(MODULE_NAME, "body", { center }, center ? "" : "27-4-18")}>
            {inputs
              .filter(i => !i.hidden)
              .map((input, idx) => {
                let value = data[input.name];
                if (!value) {
                  if (input.type === "number") {
                    value = 0;
                  } else {
                    value = "";
                  }
                }

                return (
                  input.el || (
                    <div key={idx} className="row">
                      <div key={`${input.name}-form-input-container${idx}`} className="col-12">
                        <InputField
                          key={`${input.name}-form-input${idx}`}
                          checked={`${value}` === "true"}
                          onChange={this.onChange}
                          onKeyDown={this.onKeyDown}
                          validationMessage={
                            (this.isExternalError(input) && input.errorMessage) ||
                            (this.state.validations[input.name] || {}).msg
                          }
                          validationType={
                            (this.isExternalError(input) && input.errorType) ||
                            (this.state.validations[input.name] || {}).type
                          }
                          value={value}
                          wrapperClassName={wrapperClassName}
                          anchor={composeAnchor(name, input.name)}
                          {...input}
                        />
                      </div>
                    </div>
                  )
                );
              })}
            {children}
          </div>
        </div>
      </Form>
    );
  }
}

Forms.defaultProps = {
  inputs: [],
  onSubmit: () => {},
  horizontal: false,
  inline: false,
  data: {},
  name: `form_${Math.random()}`,
  className: "",
  onSetInput: () => {},
  setSubmit: () => {}
};

Forms.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  inputs: PropTypes.array,
  name: PropTypes.string,
  onSetInput: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitInvalid: PropTypes.func,
  setSubmit: PropTypes.func,
  scrollToFirstInvalid: PropTypes.bool,
  scrollOffset: PropTypes.number
};

const mapStateToProps = (state, { name }) => ({
  data: FormDuck.getData(state, name)
});
export default connect(mapStateToProps)(Forms);
