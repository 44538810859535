import React from "react";
import PropTypes from "prop-types";
import { Line } from "rc-progress";
import { bm, be } from "../../../utils/bliss";

import "./progressBarLinear.css";

const MODULE_NAME = "ProgressBarLinear";

const ProgressBarLinear = ({ progress }) => (
  <div className={bm(MODULE_NAME)}>
    <div className={be(MODULE_NAME, "percentage", null)}>{progress}%</div>
    <Line
      percent={progress > 100 ? 100 : progress}
      strokeColor="#ffffff"
      strokeWidth="3"
      text={`${progress}%`}
      trailColor="#4F4F4F"
      trailWidth="3"
    />
  </div>
);

ProgressBarLinear.defaultProps = {
  progress: 0
};

ProgressBarLinear.propTypes = {
  progress: PropTypes.number
};

export default ProgressBarLinear;
