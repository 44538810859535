import React, { Component } from "react";
import * as moment from 'moment';

import { isMobile } from "../../../utils/utils";
import SettingsService from "../../../services/settingsService";

export default class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      settings: null,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    SettingsService.getBannerSettins().then(settings => this.setState({ settings }));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.scrollY > 10;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };

  render() {
    if (!this.state.settings) {
      return "";
    }

    const currentDate = new Date().getTime();
    const toDatePlus1Day = this.state.settings.toDate ? moment(this.state.settings.toDate).add(1, "day").valueOf() : null;

    // at least one of the dates has to be filled
    if (!this.state.settings.fromDate && !this.state.settings.toDate) {
      return "";
    }
    if (this.state.settings.fromDate && (currentDate < this.state.settings.fromDate)) {
      return "";
    }
    if (toDatePlus1Day && (currentDate > toDatePlus1Day)) {
      return "";
    }

    return !isMobile() ? (
      <div
        className={!this.state.visible ? "hidden" : "visible"}
        style={{ padding: "10px 40px", background: "#4B8795", color: "#ffffff" }}
      >
        <div style={{ fontWeight: "bold", display: "flex", justifyContent: "center" }}>{this.state.settings.title}</div>{" "}
        <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>{this.state.settings.text}</div>
      </div>
    ) : (
      <div style={{ padding: "10px 20px", background: "#4B8795", color: "#ffffff" }}>
        <div style={{ fontWeight: "bold", textAlign: "left" }}>{this.state.settings.title}</div>{" "}
        <div style={{ textAlign: "left" }}>{this.state.settings.text}</div>
      </div>
    );
  }
}
