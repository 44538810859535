import React, { Component, Fragment } from "react";
import PropTypes, { number } from "prop-types";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { compose } from "redux";

import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";

import { isMobile } from "../../utils/utils";
import { bm, be } from "../../utils/bliss";
import "./projectProgress.css";
import Text from "../text/text";
import Button from "../button/button";
import { PREVIEWS_API } from "../../services/variables";

import CartDucks from "../../redux/ducks/cart";

const MODULE_NAME = "ProjectProgress";

class ProjectProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: _isEmpty(props.project.rewards) ? null : props.project.rewards[0].id
    };
  }

  orderBook(selectedReward) {
    const { project, dispatch } = this.props;

    if (project.state === "Výroba") {
      this.context.router.history.push({
        pathname: "/delivery",
        state: {
          items: [
            {
              project,
              reward: selectedReward,
              number: 1
            }
          ]
        }
      });
    } else {
      dispatch(CartDucks.addItem(project, selectedReward));
      this.context.router.history.push("/cart");
    }
  }

  render() {
    const { project } = this.props;
    const { value } = this.state;

    const selectedReward = _find(project.rewards, { id: value });
    let price = null;
    if (project && project.rewards) {
      const filteredRewards = project.rewards.filter(r => r.physical === 1 && r.state === "active");
      price = filteredRewards.length === 1 ? filteredRewards[0].price : null;
    }

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="row">
          <div className="col-12">
            <div className={be(MODULE_NAME, "spacing", "sm")} />
            <div className={be(MODULE_NAME, "bibliography")}>
              {project.coverPhoto && (
                <img className={be(MODULE_NAME, "coverPhoto")} src={`${PREVIEWS_API}${project.coverPhoto}`} />
              )}
              {!_isEmpty(project.rewards) && !isMobile() && (
                <div>
                  <hr style={{ margin: "0" }} />
                  <div>
                    <div className="row">
                      <div className="col-8">
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="bookType"
                            onChange={(e, val) => {
                              this.setState({ value: Number(val) });
                            }}
                            value={Number(value)}
                          >
                            {project.rewards.map(reward => (
                              <FormControlLabel
                                key={reward.id}
                                control={<Radio style={{ color: "#E8452C" }} />}
                                label={<Typography style={{ fontSize: "14px" }}>{reward.title}</Typography>}
                                labelPlacement="end"
                                value={Number(reward.id)}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="col-4 text-right">
                        <div style={{ padding: "13px 0 30px 0" }}>
                          <Text bold primary small>
                            {`${Math.round(price)} Kč`}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <hr style={{ margin: '-10px 0' }} /> */}
                </div>
              )}
              {!_isEmpty(project.rewards) && isMobile() && (
                <div>
                  <hr style={{ margin: "0" }} />
                  <div>
                    <div className="row">
                      <div className="col-8">
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="bookType"
                            onChange={(e, val) => {
                              this.setState({ value: Number(val) });
                            }}
                            value={Number(value)}
                          >
                            {project.rewards.map(reward => (
                              <FormControlLabel
                                key={reward.id}
                                control={<Radio style={{ color: "#E8452C" }} />}
                                label={<Typography style={{ fontSize: "14px" }}>{reward.title}</Typography>}
                                labelPlacement="end"
                                value={Number(reward.id)}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="col-4 text-right">
                        <div style={{ padding: "13px 0 30px 0" }}>
                          <Text bold primary small>
                            {`${Math.round(price)} Kč`}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <hr style={{ margin: '-10px 0' }} /> */}
                </div>
              )}
              {value ? (
                <div className="d-flex justify-content-center text-center mt-5">
                  <Button large onClick={() => this.orderBook(selectedReward)} primary wide>
                    KOUPIT
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectProgress.defaultProps = {
  project: {}
};

ProjectProgress.propTypes = {
  project: PropTypes.object
};

ProjectProgress.contextTypes = {
  router: PropTypes.object.isRequired
};
export default compose(connect(null))(ProjectProgress);
