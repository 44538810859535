import React from "react";

import { be } from "../../../../utils/bliss";

import Text from "../../../text/text";
import Button from "../../../button/button";

const MODULE_NAME = "ProjectDetailScreen";

const AcceptMessage = () => (
  <div className={be(MODULE_NAME, "acceptContainer")}>
    <button
      className="pull-right pr-2 pl-2"
      style={{
        borderRadius: "25px",
        padding: "2px 20px 4px 20px",
        fontSize: "14px",
        color: "#A8A8A8",
        fontWeight: "700",
        background: "none",
        border: "none"
      }}
    />
    <div style={{ height: "15px" }} />
    <div style={{ textAlign: "center", marginBottom: "15px" }}>
      <img src="/images/blackLogo.png" style={{ width: "100px" }} alt="logo" />
    </div>
    <div>
      <div>
        <Text bold primary>
          Díky, že si objednáváte u nás!
        </Text>
      </div>
      <div>
        <Text xs bold dark lighter>
          Do e-mailu vám posíláme potvrzení.
        </Text>
      </div>
      <div style={{ textAlign: "center", marginTop: "15px" }}>
        <Button
          smallModal
          target="_blank"
          to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
        >
          <img src="/images/svg/shareNew.svg" /> Sdílet
        </Button>
      </div>
    </div>
  </div>
);

export default AcceptMessage;
