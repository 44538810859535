import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { isEmpty } from "lodash";

import "./modal.css";

import UIDuck from "../../redux/ducks/ui";
import PageCountBody from "./bodies/pageCount/pageCount";
import Handshake from "./bodies/handshake/handshake";
import AcceptedHandshake from "./bodies/acceptedHandshake/acceptedHandshake";
import Alert from "./bodies/alert/alert";
import DetailBox from "./bodies/detailBox/detailBox";
import Newsletter from "./bodies/newsletter/newsletter";
import NewsletterThanks from "./bodies/newsletterThanks/newsletterThanks";
import Promo from "./bodies/promo/promo";
import PromoThanks from "./bodies/promoThanks/promoThanks";
import SuccessfulPayment from "./bodies/successfulPayment/successfulPayment";
import UnsuccessfulPayment from "./bodies/unsuccessfulPayment/unsuccessfulPayment";
import UnsuccessfulPaymentFinancing from "./bodies/unsuccessfulPaymentFinancing/unsuccessfulPaymentFinancing";
import { isMobile } from "../../utils/utils";

class ModalComponent extends Component {
  handleClose = () => {
    this.props.dispatch(UIDuck.closeModal());
  };

  handleSubmits = (data = {}, formName = "") => {
    const { data: additionalData } = this.props;
    switch (formName) {
      default:
        console.log("submit modal", data, formName, additionalData);
        if (typeof additionalData.callback === "function") {
          this.handleClose();
          additionalData.callback(formName, data);
        }
        break;
    }
  };

  getContentByModalType = type => {
    const { t, data, dispatch } = this.props;

    switch (type) {
      case UIDuck.modalTypes.test:
        return <h5>Test modal content</h5>;

      case UIDuck.modalTypes.bookPages:
        return (
          <PageCountBody
            {...data}
            closeModal={this.handleClose}
            onSubmit={pages => dispatch(UIDuck.setModalData({ pages: parseInt(pages) }))}
            t={t}
          />
        );

      case UIDuck.modalTypes.confirmHandshake:
        return <Handshake data={data} onClose={this.handleClose} onSubmit={this.handleSubmits} t={t} />;

      case UIDuck.modalTypes.acceptedHandshake:
        return <AcceptedHandshake data={data} onClose={this.handleClose} onSubmit={this.handleSubmits} t={t} />;

      case UIDuck.modalTypes.alert:
        return <Alert data={data} onClose={this.handleClose} onSubmit={this.handleSubmits} />;

      case UIDuck.modalTypes.alertSuccess:
        return <Alert data={data} onClose={this.handleClose} onSubmit={this.handleSubmits} success />;

      case UIDuck.modalTypes.alertDanger:
        return <Alert danger data={data} onClose={this.handleClose} onSubmit={this.handleSubmits} />;

      case UIDuck.modalTypes.alertPrompt:
        return <Alert data={data} onClose={this.handleClose} onSubmit={this.handleSubmits} prompt />;

      case UIDuck.modalTypes.newsletterModal:
        return !isMobile() ? <Newsletter onClose={this.handleClose} onSubmit={this.handleSubmits} /> : "";

      case UIDuck.modalTypes.promoModal:
        return !isMobile() ? <Promo onClose={this.handleClose} onSubmit={this.handleSubmits} /> : "";

      case UIDuck.modalTypes.promoThanks:
        return !isMobile() ? <PromoThanks onClose={this.handleClose} /> : "";

      case UIDuck.modalTypes.newsletterThanks:
        return <NewsletterThanks onClose={this.handleClose} />;

      case UIDuck.modalTypes.successfulPayment:
        return <SuccessfulPayment onClose={this.handleClose} />;

      case UIDuck.modalTypes.unsuccessfulPayment:
        return <UnsuccessfulPayment onClose={this.handleClose} />;

      case UIDuck.modalTypes.unsuccessfulPaymentFinancing:
        return <UnsuccessfulPaymentFinancing onClose={this.handleClose} />;

      case UIDuck.modalTypes.detailBox:
        return <DetailBox onClose={this.handleClose} />;

      default:
        return null;
    }
  };

  render() {
    const { open, type, children } = this.props;

    return !isEmpty(this.getContentByModalType(type)) ? (
      <Modal animation={false} onHide={this.handleClose} show={open}>
        <Modal.Header>
          <img className="close-btn" onClick={this.handleClose} src="/images/svg/cross--black.svg" />
        </Modal.Header>
        <Modal.Body>{children || this.getContentByModalType(type)}</Modal.Body>
        {/* <Modal.Footer>
                    <button onClick={this.handleClose}>Close</button>
                </Modal.Footer> */}
      </Modal>
    ) : (
      ""
    );
  }
}

ModalComponent.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string
};

const mapStateToProps = state => ({
  type: UIDuck.getModalType(state),
  open: UIDuck.isModalOpen(state),
  data: UIDuck.getModalData(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ModalComponent);
