import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, get as g } from "lodash";

import "./comment.css";

import Avatar from "../../../components/avatar/avatar";
import Text from "../../../components/text/text";
import moment from "moment";
import "moment/locale/cs";
import CommentForm from "./commentForm";
import nl2br from "react-nl2br";
import ThreadDuck from "../../../redux/ducks/thread";

moment.locale("cs");

class Comment extends Component {
  state = {
    showReplyForm: false
  };

  MAX_DEPTH = 3;

  toggleShowReplyForm = e => {
    e && e.preventDefault();
    this.setState({
      showReplyForm: !this.state.showReplyForm
    });
  };

  deleteComment = (e, commentId) => {
    e && e.preventDefault();
    console.log({ commentId });
    ThreadDuck.deleteComment(commentId).then(data => {
      this.props.reloadThread(false);
    });
  };

  renderReplyForm = () => {
    const { comment, threadId, user, reloadThread } = this.props;
    return (
      <CommentForm
        reloadThread={reloadThread}
        parentComment={comment}
        isSubComment={true}
        toggleShowReplyForm={this.toggleShowReplyForm}
        threadId={threadId}
        user={user}
      />
    );
  };

  get showCommentsForm() {
    const max = g(this.props.comment, "depth", 0) <= this.MAX_DEPTH;
    return this.props.showCommentsForm && max;
  }

  render() {
    const { comment, isSubComment, user } = this.props;
    const ownerName = `${comment.owner.name} ${comment.owner.surname}`;

    return (
      <div className={`media Comment Comment-${isSubComment ? "sub" : "main"}`}>
        <div className="Comment-avatar">
          <Avatar image={comment.owner.profilePic} md />
        </div>
        <div className="media-body Comment-mediabody">
          <div className="Comment-content">
            <div className="Comment-info">
              <Text bold dark>
                {ownerName}
              </Text>
              <Text xs light normal>
                &nbsp;&nbsp;-&nbsp;&nbsp;{moment(comment.createdAt).fromNow()}{" "}
              </Text>
            </div>
            <div className="Comment-body">{nl2br(comment.body)}</div>
            <div className="Comment-actions">
              {this.showCommentsForm && (
                <a className="Link Link--small" href="#" onClick={this.toggleShowReplyForm}>
                  odpovědět{" "}
                </a>
              )}
              {this.props.user.id === comment.owner.id && (
                <a className="Link Link--small" href="#" onClick={e => this.deleteComment(e, comment.id)}>
                  {" "}
                  smazat
                </a>
              )}
            </div>
          </div>
          {this.state.showReplyForm && this.renderReplyForm()}
          {g(comment, "childsTree", []).map(c => (
            <Comment
              key={c.id}
              showCommentsForm={this.props.showCommentsForm}
              threadId={this.props.threadId}
              user={this.props.user}
              comment={c}
              reloadThread={this.props.reloadThread}
              isSubComment={true}
            />
          ))}
        </div>
      </div>
    );
  }
}

Comment.defaultProps = {
  isSubComment: true
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  isSubComment: PropTypes.bool.isRequired,
  showCommentsForm: PropTypes.bool.isRequired,
  threadId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  reloadThread: PropTypes.func.isRequired
};

export default Comment;
