/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { translate } from "react-i18next";

import _filter from "lodash/filter";
import _find from "lodash/find";
import _map from "lodash/map";
import _includes from "lodash/includes";

import "../messagePage.css";
import { be } from "../../../utils/bliss";
import { isMobile } from "../../../utils/utils";
import Avatar from "../../../components/avatar/avatar";
import Button from "../../../components/button/button";
import Text from "../../../components/text/text";
import UserBadge from "../../../components/userBadge/userBadge";
import UIDuck from "../../../redux/ducks/ui";
import UserDuck from "../../../redux/ducks/user";

const MODULE_NAME = "MessagePage";

const dontShowInStates = ["DELETED", "DECLINED", "CANCELED", "DELIVERED", "ACCEPTED"];

class UserBox extends Component {
  render() {
    const { thread, otherUser, dispatch, t } = this.props;

    const handshakes = _map(_filter(thread.project.handshakes, { contributorId: otherUser.id }), h => {
      const handshake = h;
      handshake.showButton = !_includes(dontShowInStates, h.state);
      handshake.author = thread.project.author;
      return handshake;
    });
    const multiple = handshakes.length > 1;

    let userRoleDetail = {};
    if (!multiple) {
      userRoleDetail = _find(otherUser.subroles, { subrole: { id: handshakes[0].subrole.id } });
    }

    return !isMobile() ? (
      multiple ? (
        <div className={be(MODULE_NAME, "boxContainer")}>
          <div className="d-flex justify-content-center">
            <Avatar image={otherUser.profilePic} medium />
          </div>
          <div className="d-flex justify-content-center">
            <Text bold cartItem dark>
              {otherUser.name} {otherUser.surname}
            </Text>
          </div>
          {otherUser.isVerifiedAuthor && (
            <div className="d-flex justify-content-center" style={{ padding: "20px 0" }}>
              <UserBadge />
            </div>
          )}
          <div className="d-flex flex-column">
            {handshakes.map(handshake => (
              <div
                key={handshake.id}
                className="d-flex justify-content-center flex-column pb-4"
                style={{ alignItems: "center" }}
              >
                <div className={be(MODULE_NAME, "roleWrapper")}>
                  <div>
                    <Text bold dark>
                      {handshake.subrole.name}
                    </Text>
                  </div>
                </div>
                {handshake.showButton && (
                  <div>
                    <div className={be(MODULE_NAME, "buttonWrapper")}>
                      <div className="pl-3 pr-3">
                        <Button lightGreyMob pBox>
                          Plácnout si
                        </Button>
                      </div>
                      <div>
                        <Button lightGreySm>
                          <img alt="userPic" src="/images/trashGrey.svg" style={{ width: "20px" }} />
                        </Button>
                      </div>
                    </div>
                    <hr style={{ margin: "15px -30px -10px -30px" }} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={be(MODULE_NAME, "boxContainer")}>
          <div className="d-flex justify-content-center">
            <Avatar image={otherUser.profilePic} medium />
          </div>
          <div className="d-flex justify-content-center">
            <Text bold cartItem dark>
              {otherUser.name} {otherUser.surname}
            </Text>
          </div>
          {otherUser.isVerifiedAuthor && (
            <div className="d-flex justify-content-center" style={{ padding: "20px 0" }}>
              <UserBadge />
            </div>
          )}
          <hr />
          <div className={be(MODULE_NAME, "roleWrapper")}>
            <div>
              <Text bold dark small>
                {handshakes[0].subrole.name}
              </Text>
            </div>
            {userRoleDetail && userRoleDetail.unit && (
              <div>
                <b>od {userRoleDetail.rate} Kč</b> / <span>{userRoleDetail.unit.name}</span>
              </div>
            )}
          </div>
          <hr />
          {handshakes[0].showButton && (
            <div className={be(MODULE_NAME, "buttonWrapper")}>
              <div>
                <Button
                  onClick={() =>
                    dispatch(
                      UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
                        handshake: handshakes[0],
                        project: thread.project
                      })
                    )
                  }
                  lightGrey
                  pBox
                >
                  Plácnout si
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    dispatch(UserDuck.removeHandshake(thread.project.id, handshakes[0].id, false)).then(() => {
                      window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
                    });
                  }}
                  lightGreySm
                >
                  <img alt="userPic" src="/images/trashGrey.svg" style={{ width: "20px" }} />
                </Button>
              </div>
            </div>
          )}
        </div>
      )
    ) : multiple ? (
      <div className="comment Comment-mainMessenger">
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div className="Comment-avatarMessenger d-flex">
            <Avatar image={otherUser.profilePic} md />
          </div>
          <div className="d-flex pb-4">
            <div className="pr-3">
              <Text bold small dark>
                {otherUser.name} {otherUser.surname}
              </Text>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column">
          {handshakes.map(handshake => (
            <div key={handshake.id} className={be(MODULE_NAME, "buttonWrapperFlexed")}>
              <div className="pr-4 pl-2">
                <Text dark disabled small>
                  {handshake.subrole.name}
                </Text>
              </div>
              {handshake.showButton && (
                <React.Fragment>
                  <div className="pr-3">
                    <Button
                      onClick={() =>
                        dispatch(
                          UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
                            author: thread.project.author,
                            handshake,
                            project: thread.project
                          })
                        )
                      }
                      lightGrey
                      pBox
                    >
                      Plácnout si
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        dispatch(UserDuck.removeHandshake(thread.project.id, handshake.id, false)).then(() => {
                          window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
                        });
                      }}
                      lightGreySm
                    >
                      <img alt="userPic" src="/images/trashGrey.svg" style={{ width: "20px" }} />
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="comment Comment-mainMessenger">
        <div className="d-flex">
          <div className="Comment-avatarMessengerMob d-flex">
            <Avatar image={otherUser.profilePic} md />
          </div>
          <div>
            <div className="d-flex pb-4">
              <div className="pr-3">
                <Text bold small dark>
                  {otherUser.name} {otherUser.surname}
                </Text>
              </div>
              <Text dark disabled small>
                {handshakes[0].subrole.name}
              </Text>
            </div>
            {handshakes[0].showButton && (
              <div className={be(MODULE_NAME, "buttonWrapperMob")}>
                <div className="pr-3">
                  <Button
                    onClick={() =>
                      dispatch(
                        UIDuck.openModal(UIDuck.modalTypes.confirmHandshake, {
                          handshake: handshakes[0],
                          project: thread.project
                        })
                      )
                    }
                    lightGrey
                    pBox
                  >
                    Plácnout si
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      dispatch(UserDuck.removeHandshake(thread.project.id, handshakes[0].id, false)).then(() => {
                        window.alertify.success(t("projectDetail.removeHandshakeConfirm"));
                      });
                    }}
                    lightGreySm
                  >
                    <img alt="userPic" src="/images/trashGrey.svg" style={{ width: "20px" }} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(connect(), translate("translations"))(UserBox);
