import React, { Component } from "react";
import PropTypes from "prop-types";
import { uniqBy, isEmpty } from "lodash";
import { connect } from "react-redux";

import { be, bm } from "../../utils/bliss";
import Headline from "../../components/headline/headline";
import Text from "../../components/text/text";
import Loader from "../../components/loader/loader";

import "./contactPage.css";
import Link from "../../components/link/link";

import team from "./img/team.png";
import Header from "../../components/header/header";
import Screen from "../../components/screen/screen";
import UserDuck from "../../redux/ducks/user";
import {
  ADDRESS_CITY,
  ADDRESS_DIC,
  ADDRESS_ICO,
  ADDRESS_ORG,
  ADDRESS_ORG_STAFF,
  ADDRESS_STREET
  // CONTACT_PHONE
} from "../../services/variables";

const MODULE_NAME = "ContactPage";

class ContactPage extends Component {
  componentWillMount() {
    this.props.dispatch(UserDuck.setUserByToken("", false));
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loader />;
    }
    return (
      <Screen header={<Header />}>
        <div className={bm(MODULE_NAME)}>
          <div className="row">
            <div className="col-md-5 offset-md-7">
              <Headline bold page>
                Kontakty
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "sm", "d-md-none")} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className={be(MODULE_NAME, "imageBox")}>
                <img alt="tým" className={be(MODULE_NAME, "image")} src={team} />
              </div>
            </div>
            <div className="col-md-5">
              <div className={be(MODULE_NAME, "spacing", "lg")} />
              <Headline bold subheadline>
                Online nakladatelská služba Pointa
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "sm")} />
              <Text component="div" lineHeight>
                {ADDRESS_ORG}
                <br />
                {ADDRESS_STREET}
                <br />
                {ADDRESS_CITY}
                <br />
              </Text>
              <div className={be(MODULE_NAME, "line")} />
              <Text component="div" lineHeight>
                <b>Kontaktní e-mail:</b> <Link to="mailto:dikyvam@pointa.cz">dikyvam@pointa.cz</Link>
              </Text>
              {/* <Text component="div" lineHeight>
                <b>Kontaktní telefon:</b> {CONTACT_PHONE}
              </Text> */}
              <Text component="div" lineHeight>
                <b>Kontaktní adresa:</b>
                <br />
                {ADDRESS_ORG_STAFF}
                <br />
                {ADDRESS_STREET}
                <br />
                {ADDRESS_CITY}
                <br />
              </Text>
              <div className={be(MODULE_NAME, "line")} />
              <Text component="div" lineHeight>
                IČ: {ADDRESS_ICO}
              </Text>
              <Text component="div" lineHeight>
                DIČ: {ADDRESS_DIC}
              </Text>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

ContactPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default connect()(ContactPage);
