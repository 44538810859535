import axios from "axios";

import { API, TOKEN_STORAGE_NAME } from "./config";
import StorageService from "./storageService";

class CoreAPIService {
  getToken() {
    return StorageService.get(TOKEN_STORAGE_NAME);
  }

  buildRequest(url, data) {
    let body = {
      url: `${API}/${url}`,
      data
    };
    if (StorageService.hasToken()) {
      body = {
        ...body,
        headers: { Authorization: `Bearer ${this.getToken()}` }
      };
    }
    return body;
  }

  async get(url, params) {
    return await axios.request({
      method: "get",
      ...this.buildRequest(url, params)
    });
  }

  async post(url, data) {
    return await axios.request({
      method: "post",
      ...this.buildRequest(url, data)
    });
  }

  async put(url, data) {
    return await axios.request({
      method: "put",
      ...this.buildRequest(url, data)
    });
  }

  async patch(url, data) {
    return await axios.request({
      method: "patch",
      ...this.buildRequest(url, data)
    });
  }

  async delete(url, data) {
    return await axios.request({
      method: "delete",
      ...this.buildRequest(url, data)
    });
  }
}

export default new CoreAPIService();
