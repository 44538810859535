import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { get as g, noop, isEmpty } from "lodash";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import moment from "moment";

import "./detailBubble.css";

import Button from "../../button/button";
import Avatar from "../../avatar/avatar";
import EnumsDuck from "../../../redux/ducks/enums";
import { be, bm } from "../../../utils/bliss";
import Headline from "../../headline/headline";
import Text from "../../text/text";
import Link from "../../link/link";
import { formatPhone, formatPrice } from "../../../utils/utils";

class DetailBubble extends Component {
  getButton(possibleToMakeHandshake, isAccepted) {
    const { label, locked } = this.props;

    return label && possibleToMakeHandshake && !isAccepted ? (
      <Button border disabled={locked} invertedPrimary onClick={this.onClick} project>
        {label}
      </Button>
    ) : null;
  }

  getHandshakes(isAccepted) {
    const { handshakes, cancelHandshake, confirmHandshake, handshakeStates, t } = this.props;
    const hs = isAccepted
      ? handshakes.filter(hs => g(hs, "state", "") === g(handshakeStates, "accepted.name", ""))
      : handshakes;

    return hs.map(handshake => {
      const isRequested = g(handshake, "state", "") === g(handshakeStates, "requested.name", "");
      const isDeclined = g(handshake, "state", "") === g(handshakeStates, "declined.name", "");
      const isSuggested = g(handshake, "state", "") === g(handshakeStates, "suggested.name", "");
      const isAccepted = g(handshake, "state", "") === g(handshakeStates, "accepted.name", "");
      const showContainer = isRequested || isSuggested;
      const MODULE_NAME = "Handshake";
      let buttonLabel = t("projectDetail.handshake");

      if (isSuggested) {
        buttonLabel = t("projectDetail.pendingHandshake");
      }

      if (isDeclined) {
        return null;
      }

      return (
        <div key={handshake.id} className={bm(MODULE_NAME)}>
          {showContainer && (
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-auto d-flex align-items-center pr-0">
                <Avatar image={g(handshake, "contributor.profilePic", "")} xxs />
              </div>
              <div className="col-auto col-md d-flex align-items-center">
                <Text bold small>{`${g(handshake, "contributor.name", "")} ${g(
                  handshake,
                  "contributor.surname",
                  ""
                )}`}</Text>
                <Text xs>{`${g(handshake, "contributor.subrole.name", "")}`}</Text>
              </div>
              <div className="w-100 mt-3 d-md-none" />
              <div className="col-auto d-flex align-items-center pr-0">
                <div
                  className={be(MODULE_NAME, "btn", {
                    isSuggested
                  })}
                  onClick={e => {
                    e.stopPropagation();
                    confirmHandshake(handshake);
                  }}
                >
                  {isSuggested ? (
                    <Fragment>
                      <img className="mr-2" src="/images/svg/minihandshake.svg" />
                      <span className="d-none d-lg-inline">{buttonLabel}</span>
                      <span className="d-lg-none">{t("projectDetail.pendingHandshakeMob")}</span>
                    </Fragment>
                  ) : (
                    buttonLabel
                  )}
                </div>
              </div>
              <div className="col-auto d-flex align-items-center my-3 my-sm-0">
                <span
                  className={be(MODULE_NAME, "trash")}
                  onClick={e => {
                    e.stopPropagation();
                    cancelHandshake(handshake);
                  }}
                >
                  <img src="/images/svg/trashHandshake.svg" />
                </span>
              </div>
            </div>
          )}
          {isAccepted && (
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-auto pr-0 d-flex align-items-center">
                <Avatar image={g(handshake, "contributor.profilePic", "")} xxs />
              </div>
              <div className="col-4 d-flex align-items-center">
                <Text bold small>{`${g(handshake, "contributor.name", "")} ${g(
                  handshake,
                  "contributor.surname",
                  ""
                )}`}</Text>
              </div>
              <div className="col-sm text-center text-md-left">
                <div>
                  <Text bold light xs>
                    Email:{" "}
                  </Text>
                  <Link bold light small to={`mailto:${g(handshake, "contributor.email", "")}`}>
                    {g(handshake, "contributor.email", "")}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  onCancel = e => {
    e.stopPropagation();
    this.props.onCancel(e);
  };

  onClick = e => {
    e.stopPropagation();
    this.props.onClick(e);
  };

  render() {
    const {
      title,
      description,
      className,
      icon,
      inProgress,
      small,
      locked,
      complete,
      done,
      handshakes,
      t,
      handshakeStates,
      price,
      parameters,
      data,
      onWrapperClick,
      iconLabel,
      requirements
    } = this.props;

    const isRequested = !!handshakes.find(hs => g(hs, "state", "") === handshakeStates.requested.name);
    const isSuggested = !!handshakes.find(hs => g(hs, "state", "") === handshakeStates.suggested.name);
    const acceptedHandshake = handshakes.find(hs => g(hs, "state", "") === handshakeStates.accepted.name);
    const isAccepted = !!acceptedHandshake;
    const possibleToMakeHandshake = handshakes.filter(hs => hs.state === handshakeStates.requested.name).length < 3;
    const MODULE_NAME = "Bubble";
    const priceToDisplay = g(acceptedHandshake, "price", price || 0);

    return (
      <div
        className={bm(
          MODULE_NAME,
          `${locked ? "locked" : ""} ${done ? "done" : "  "} ${small ? "small" : "  "}`,
          className
        )}
        onClick={onWrapperClick}
      >
        <div className={be(MODULE_NAME, "detail", locked ? "locked" : "")}>
          {icon && !complete && !isRequested && !isSuggested && !done && (
            <div className={be(MODULE_NAME, "icon")}>
              <img alt="" className="img-fluid" src={icon} />
              {iconLabel && (
                <Fragment>
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                  <div className={be(MODULE_NAME, "iconDone")}>
                    <Text light small>
                      {iconLabel}
                    </Text>
                  </div>
                </Fragment>
              )}
            </div>
          )}
          {(complete || done) && (
            <div className={be(MODULE_NAME, "icon")}>
              <img alt="check" className="img-fluid" src="/images/svg/check.svg" />
            </div>
          )}
          {(isRequested || isSuggested) && !complete && (
            <div className={be(MODULE_NAME, "icon")}>
              <img alt="" src="/images/svg/pending.svg" />
            </div>
          )}
          <div className={be(MODULE_NAME, "body", complete ? "is-completed" : "")}>
            <div className={be(MODULE_NAME, "description")}>
              <Headline bold component="h4" subheadline>
                {title}
              </Headline>
              <Text>{description}</Text>
            </div>
            {inProgress ? (
              <Fragment>
                <div className={be(MODULE_NAME, "contributor")}>
                  <div className="row">
                    <div className="col">
                      <Text light uppercase xs>
                        Spolupracovník:
                      </Text>
                    </div>
                  </div>
                  <div className={be(MODULE_NAME, "spacing", "sm")} />
                  <div className="row justify-content-center">
                    <div className="col-auto pr-0 d-flex align-items-start align-items-md-center">
                      <Avatar image={g(data, "handshake.contributor.profilePic", "")} xxs />
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-md-3 d-flex justify-content-center flex-column">
                          <Text bold small>
                            {g(data, "handshake.contributor.name", "")} {g(data, "handshake.contributor.surname", "")}
                          </Text>
                          <div className={be(MODULE_NAME, "spacing", "xs", "d-none d-md-block")} />
                          <Text light small>
                            {g(data, "handshake.subrole.name", "")}
                          </Text>
                          <div className={be(MODULE_NAME, "spacing", "xs", "d-md-none")} />
                        </div>
                        <div className="col-md d-flex align-items-center">
                          <div className="row w-100">
                            <div className="col-md">
                              <Text bold light xs>
                                Telefon:{" "}
                              </Text>
                              <Text light xs>
                                <a href={`tel:${g(data, "handshake.contributor.phone", "")}`}>
                                  {g(data, "handshake.contributor.phone", "")}
                                </a>
                              </Text>
                            </div>

                            <div className="col-md">
                              <Text bold light xs>
                                Email:{" "}
                              </Text>
                              <Link bold light small to={`mailto:${g(data, "handshake.contributor.mail", "")}`}>
                                {g(data, "handshake.contributor.email", "")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={be(MODULE_NAME, "spacing", "lg")} />
                </div>
                <div className={be(MODULE_NAME, "contributor")}>
                  <div className="row">
                    {done ? (
                      <Fragment>
                        <div className="col-lg-auto justify-content-center justify-content-lg-start mb-3 mb-lg-0 d-flex align-items-center">
                          <Text bold uppercase xs>
                            Dodáno - {g(data, "itemType.name")}
                          </Text>
                        </div>
                        <div className="col-lg d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center d-none">
                          <div className="d-flex mb-4 mb-lg-0 align-items-center">
                            <Text light xs>
                              Označeno za hotové:
                            </Text>
                            <div className={be(MODULE_NAME, "spacing", "wSm")} />
                            <Text primary small>
                              {moment(g(data, "itemType.deliveredAt", null)).format("DD.MM.YYYY")}
                            </Text>
                          </div>
                          <div className="w-100 d-lg-none" />
                          <div className={be(MODULE_NAME, "spacing", "wMd", "d-none d-lg-block")} />
                          <Button border invertedPrimary onClick={this.onCancel} project>
                            Zrušit
                          </Button>
                        </div>
                      </Fragment>
                    ) : (
                      <div className="col-sm-auto d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap flex-lg-nowrap">
                        <Button border invertedPrimary isDisabled={locked} onClick={this.onClick} project>
                          {complete ? "Zrušit" : `dodáno - ${g(data, "itemType.name", "")}`}
                        </Button>
                        <div className={be(MODULE_NAME, "spacing", "wMd", "d-none d-lg-block")} />
                        <div className="w-100 mt-3 d-lg-none" />
                        {!complete && !isEmpty(requirements) && (
                          <Text light xs>{`(Nejprve musí být: ${requirements.map(r => r.name).join(", ")})`}</Text>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            ) : !parameters ? (
              <div className={be(MODULE_NAME, "contributor")}>
                {isRequested ||
                  isSuggested ||
                  (isAccepted && (
                    <Headline light uppercase xxs>
                      {t("projectDetail.coworker")}
                    </Headline>
                  ))}
                {!complete && (isRequested || isSuggested) && (
                  <Headline light uppercase xxs>
                    Spolupracovníci, které poptáváte
                  </Headline>
                )}
                <div className={be(MODULE_NAME, "spacing", "md")} />
                {this.getHandshakes(isAccepted)}
                {this.getButton(possibleToMakeHandshake, isAccepted)}
              </div>
            ) : (
              <div>
                {this.getHandshakes(isAccepted)}
                {this.getButton(possibleToMakeHandshake, isAccepted)}
              </div>
            )}
          </div>
          {priceToDisplay !== 0 && (
            <div className={be(MODULE_NAME, "price")}>
              <div className="text-center">
                <Text component="div">Domluveno za</Text>
                <Text bold lg>
                  {formatPrice(priceToDisplay)}&nbsp;Kč
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DetailBubble.defaultProps = {
  locked: false,
  complete: false,
  title: "title",
  label: "",
  icon: "",
  description: "",
  className: "",
  onClick: noop,
  handshakes: [],
  cancelHandshake: noop,
  confirmHandshake: noop,
  price: null,
  data: {},
  onCancel: noop,
  onWrapperClick: noop,
  requirements: []
};

DetailBubble.propTypes = {
  cancelHandshake: PropTypes.func,
  className: PropTypes.string,
  complete: PropTypes.bool,
  confirmHandshake: PropTypes.func,
  data: PropTypes.object,
  description: PropTypes.string,
  handshakes: PropTypes.array,
  handshakeStates: PropTypes.object.isRequired,
  icon: PropTypes.string,
  iconLabel: PropTypes.string,
  label: PropTypes.string,
  locked: PropTypes.bool,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  onWrapperClick: PropTypes.func,
  parameters: PropTypes.bool,
  price: PropTypes.any,
  requirements: PropTypes.array,
  t: PropTypes.func.isRequired,
  title: PropTypes.string
};

const mapStateToProps = state => ({
  handshakeStates: EnumsDuck.getHandhakeStates(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(DetailBubble);
