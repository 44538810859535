import React, { useState } from "react";

import { be } from "../../../../utils/bliss";

import Text from "../../../../components/text/text";
import { ADDRESS_COMPLETE } from "../../../../services/variables";
import { UNKNOWN_DELIVERY_PRICE } from "../../../shopDelivery/shopDelivery";
import SettingsService from "../../../../services/settingsService";

const MODULE_NAME = "ProjectDetailScreen";

const GetFaqContent = () => {
  const [deliveryPrice, setDeliveryPrice] = useState(UNKNOWN_DELIVERY_PRICE);

  SettingsService.getDeliveryPrices().then(prices => {
    if (prices.CP_CZ) {
      setDeliveryPrice(prices.CP_CZ);
    }
  });

  return (
    <div className="row">
      <div className="col-md-9">
        <div className={be(MODULE_NAME, "question")}>
          <Text bold greenReward lg>
            Co bude s mými penězi, když se autorovi nepodaří vybrat 100 % částky?
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "semi")} />
        <div className={be(MODULE_NAME, "answer")}>
          <Text greenReward small>
            Peníze ti vrátíme. Do 20 dnů od konce předprodeje ti je v plné výši pošleme zpátky na kartu nebo účet, odkud
            jsi nám peníze poslal(a).
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className={be(MODULE_NAME, "question")}>
          <Text bold greenReward lg>
            Kdy mi přijde kniha, kterou jsem podpořil/a v předprodeji?
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "semi")} />
        <div className={be(MODULE_NAME, "answer")}>
          <Text greenReward small>
            Finální práce na knize, včetně následného tisku, začnou hned po konci úspěšného předprodeje. Průměrnou dobu
            doručení odhadujeme na půl roku, ale může se lišit v závislosti na typu knihy a náročnosti jejího zpracování.
            O tom, v jaké fázi výroby se kniha nachází, tě budeme průběžně informovat.
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className={be(MODULE_NAME, "question")}>
          <Text bold greenReward lg>
            Jak můžu za knihu zaplatit?
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "semi")} />
        <div className={be(MODULE_NAME, "answer")}>
          <Text greenReward small>
            Platit u nás můžeš jednoduše a bezpečně kartou, internetovým bankovnictvím nebo bankovním převodem.
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className={be(MODULE_NAME, "question")}>
          <Text bold greenReward lg>
            Kolik stojí poštovné?
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "semi")} />
        <div className={be(MODULE_NAME, "answer")}>
          <Text greenReward small>
            Poštovné stojí {deliveryPrice} Kč a přičte se k celkové ceně knihy až před platbou. Nebo si můžeš knihu osobně převzít u
            nás na adrese {ADDRESS_COMPLETE}.
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className={be(MODULE_NAME, "question")}>
          <Text bold greenReward lg>
            Jak naložíte s mým jménem a e-mailem?
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "semi")} />
        <div className={be(MODULE_NAME, "answer")}>
          <Text greenReward small>
            Jméno a e-mail potřebujeme k získání údajů pro doručení tebou podpořené knihy. Díky e-mailu tě pak taky můžeme
            průběžně informovat o stavu objednávky.
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <div className={be(MODULE_NAME, "question")}>
          <Text bold greenReward lg>
            Na co se v předprodeji vlastně vybírají peníze?
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "semi")} />
        <div className={be(MODULE_NAME, "answer")}>
          <Text greenReward small>
            Na vše, co se výroby knihy týká. Cílová částka slouží primárně k pokrytí nákladů na tisk knihy a práce všech
            kolegů (redakce, korektura, sazba, grafika).
          </Text>
        </div>
      </div>
    </div>
  );
};

export default GetFaqContent;
