import { createActionTypes } from "../createActionTypes";

const UIDuck = {
  name: "UI"
};

const initialState = {
  modal: {
    open: false,
    type: "",
    data: {}
  }
};

UIDuck.modalTypes = {
  test: "TEST_MODAL",
  bookPages: "BOOK_PAGES",
  confirmHandshake: "CONFIRM_HANDSHAKE",
  acceptedHandshake: "ACCEPTED_HANDSHAKE",
  alert: "ALERT_INFO",
  alertSuccess: "ALERT_SUCCESS",
  alertDanger: "ALERT_DANGER",
  alertPrompt: "ALERT_PROMPT",
  detailBox: "DETAIL_BOX",
  newsletterModal: "NEWSLETTER_MODAL ",
  promoModal: "PROMO_MODAL",
  promoThanks: "PROMO_THANKS",
  newsletterThanks: "NEWSLETTER_THANKS ",
  successfulPayment: "SUCCESSFUL_PAYMENT",
  unsuccessfulPayment: "UNSUCCESSFUL_PAYMENT",
  unsuccessfulPaymentFinancing: "UNSUCCESSFUL_PAYMENT_FINANCING"
};

const actionTypes = createActionTypes(
  {
    openModal: "open_modal".toUpperCase(),
    closeModal: "close_modal".toUpperCase(),
    resetModalData: "reset_modal_data".toUpperCase(),
    setModalData: "set_modal_data".toUpperCase()
  },
  UIDuck.name
);

UIDuck.reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.openModal:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
          data: action.data || {}
          // data: {
          //     ...state.modal.data,
          //     ...action.data
          // }
        }
      };

    case actionTypes.setModalData:
      return {
        ...state,
        modal: {
          ...state.modal,
          data: {
            ...state.modal.data,
            ...action.payload
          }
        }
      };

    case actionTypes.resetModalData:
      return {
        ...state,
        modal: {
          ...state.modal,
          data: {}
        }
      };

    case actionTypes.closeModal:
      return {
        ...state,
        modal: {
          ...state.modal,
          type: "",
          open: false
        }
      };

    default:
      return state;
  }
};

UIDuck.openModal = (modalType = "", data = {}) => ({
  type: actionTypes.openModal,
  payload: {
    type: modalType,
    open: true
  },
  data
});

UIDuck.resetModalData = () => ({
  type: actionTypes.resetModalData
});

UIDuck.closeModal = () => ({
  type: actionTypes.closeModal
});

UIDuck.setModalData = (data = {}) => ({
  type: actionTypes.setModalData,
  payload: data
});

const getOwnState = state => state[UIDuck.name] || initialState;

UIDuck.getModalState = state => getOwnState(state).modal;
UIDuck.isModalOpen = state => UIDuck.getModalState(state).open;
UIDuck.getModalType = state => UIDuck.getModalState(state).type;
UIDuck.getModalData = state => UIDuck.getModalState(state).data;

export default UIDuck;
