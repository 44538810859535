import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import { bm, be } from "../../utils/bliss";

import "./soldRewardInfo.css";
import "./rewardInfo.css";
import WizardDuck from "../../redux/ducks/wizard";
import Headline from "../headline/headline";
import Text from "../text/text";
import Button from "../button/button";
import { formatPrice } from "../../utils/utils";
import { get as g, isEmpty } from "lodash";
import moment from "moment";
import "moment/locale/cs";

const MODULE_NAME = "RewardInfo";
const MODULE_NAME_SOLD = "SoldRewardInfo";

class RewardInfo extends Component {
  showStocks = () => {
    const { reward } = this.props;
    if (reward.physical === 1)
      return (
        <div className="row flex-nowrap">
          <div className="col-sm">
            <Text greenReward component="div" medium>
              Zbývá kusů
            </Text>
          </div>
          <div className=" col-sm d-flex justify-content-end flex-grow-1">
            <Text bold greenReward component="div" medium>
              {reward.stock} ks
            </Text>
          </div>
        </div>
      );
  };

  showPostTax = () => {
    const { reward } = this.props;
    if (reward.physical === 1)
      return (
        <div>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row flex-nowrap">
            <div className="col-sm">
              <Text greenReward component="div" medium>
                Poštovné
              </Text>
            </div>
            <div className=" col-sm d-flex justify-content-end flex-grow-1">
              <Text bold greenReward component="div" medium>
                + 89 Kč
              </Text>
            </div>
          </div>
        </div>
      );
  };

  getPřispětButton = (text, onClick) => {
    return (
      <Button onClick={onClick} smallRadius small wide>
        {text}
      </Button>
    );
  };

  showFundingButton = () => {
    const { reward, project, history } = this.props;
    const isExtendable = reward.is_extendable;
    if (reward.stock > 0) {
      if (isExtendable) {
        if (this.state && this.state.showPriceInput) {
          const baseRewardPrice = parseFloat(reward.price);
          const maxRewardPrice = parseFloat(reward["*maxPrice"]);
          const rewardPrice =
            this.state && (this.state.rewardPrice == "" || this.state.rewardPrice > 0)
              ? this.state.rewardPrice
              : baseRewardPrice;
          return (
            <>
              <Text component="div" medium left>
                Chceš podpořit knihu vyšší částkou? Uprav příspěvek podle svého uvážení:
              </Text>
              <div
                style={{
                  marginTop: 6,
                  position: "relative" /* just to allow correct aboslute positioning of currency */
                }}
              >
                <input
                  className="form-control"
                  max={reward["*maxPrice"]}
                  min={reward.price}
                  onChange={e => {
                    const price = parseFloat(e.target.value);
                    if (!Number.isNaN(price)) {
                      this.setState({ rewardPrice: price, maxPriceMessage: null, minPriceMessage: null });
                    } else {
                      this.setState({ rewardPrice: "", maxPriceMessage: null, minPriceMessage: null });
                    }
                  }}
                  style={{
                    fontSize: "14px",
                    marginBottom: this.state && (this.state.minPriceMessage || this.state.maxPriceMessage) ? "0" : "1em"
                  }}
                  type="number"
                  value={rewardPrice != null ? rewardPrice : ""}
                />
                <div style={{ position: "absolute", right: "2em", top: "0.5em", fontSize: "14px" }}>Kč</div>
                {this.state && this.state.minPriceMessage && (
                  <div className="settableError">
                    Minimální příspěvek pro zakoupení této odměny je {reward.price.toString().split(".")[0]} Kč
                  </div>
                )}
                {this.state && this.state.maxPriceMessage && (
                  <div className="settableError">
                    Limit pro příspěvek v rámci této odměny je {reward["*maxPrice"]} Kč. Pokud chceš přispět vyšší
                    částkou, napiš prosím na mail <a href="mailto:dikyvam@pointa.cz">dikyvam@pointa.cz</a>.
                  </div>
                )}
              </div>
              {this.getPřispětButton(<span>Pokračovat</span>, () => {
                let manualRewardPrice = null;

                if (this.state && this.state.rewardPrice !== null) {
                  manualRewardPrice = Number.parseInt(this.state.rewardPrice);
                  if (Number.isNaN(manualRewardPrice)) manualRewardPrice = 0;

                  if (manualRewardPrice < reward.price) {
                    this.setState({ rewardPrice: baseRewardPrice, minPriceMessage: true });
                    return;
                  }

                  if (manualRewardPrice > maxRewardPrice) {
                    this.setState({ rewardPrice: maxRewardPrice, maxPriceMessage: true });
                    return;
                  }
                }

                const currentRewardPrice = manualRewardPrice || reward.price;

                history.push({
                  pathname: "/delivery",
                  state: {
                    items: [
                      {
                        project,
                        reward,
                        number: 1,
                        rewardPrice: currentRewardPrice
                      }
                    ]
                  }
                });
              })}
            </>
          );
        }
        return this.getPřispětButton(<span>Přispět {formatPrice(reward.price)} Kč</span>, () => {
          this.setState({ showPriceInput: true, rewardPrice: Number.parseInt(reward.price) });
        });
      }

      return this.getPřispětButton(<span>Přispět {formatPrice(reward.price)} Kč</span>, () =>
        history.push({
          pathname: "/delivery",
          state: {
            items: [
              {
                project,
                reward,
                number: 1
              }
            ]
          }
        })
      );
    } else {
      return (
        <Button disableFunding smallRadius small wide>
          Vyprodáno
        </Button>
      );
    }
  };

  render() {
    const { reward, project, className, onClick } = this.props;

    return reward.stock > 0 ? (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "headline")}>
          <Headline greenReward bold xs>
            {reward.title}
          </Headline>
        </div>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "headline")}>
          <Text greenReward component="div" medium>
            {reward.description}
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "md")} />
        <div className={be(MODULE_NAME, "spacing", "sm")} />

        <div className={be(MODULE_NAME, "footerSection")}>
          {this.showStocks()}

          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row flex-nowrap">
            <div className="col-sm">
              <Text greenReward component="div" medium>
                Doručení odměny
              </Text>
            </div>
            <div className="col-sm d-flex justify-content-end flex-grow-1">
              <Text bold capitalized greenReward component="div" medium>
                {moment(reward.expect_delivery)
                  .locale("cs")
                  .format("MMMM YYYY")}
              </Text>
            </div>
          </div>
          {/* {this.showPostTax()} */}
        </div>

        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "spacing", "sm")} />

        <div className="text-center">{this.showFundingButton()}</div>
      </div>
    ) : (
      <div className={bm(MODULE_NAME_SOLD)}>
        <div className={be(MODULE_NAME, "headline")}>
          <Headline greenReward bold xs>
            {reward.title}
          </Headline>
        </div>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "headline")}>
          <Text greenReward component="div" medium>
            {reward.description}
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "md")} />
        <div className={be(MODULE_NAME, "spacing", "sm")} />

        <div className={be(MODULE_NAME, "footerSection")}>
          {this.showStocks()}

          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row flex-nowrap">
            <div className="col-sm">
              <Text greenReward component="div" medium>
                Doručení odměny
              </Text>
            </div>
            <div className="col-sm d-flex justify-content-end flex-grow-1">
              <Text bold capitalized greenReward component="div" medium>
                {moment(reward.expect_delivery)
                  .locale("cs")
                  .format("MMMM YYYY")}
              </Text>
            </div>
          </div>
          {/* {this.showPostTax()} */}
        </div>

        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "spacing", "sm")} />

        <div className="text-center">{this.showFundingButton()}</div>
      </div>
    );
  }
}

RewardInfo.defaultProps = {
  project: {},
  className: "",
  onClick: () => {},
  reward: {}
};

RewardInfo.propTypes = {
  project: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  reward: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  project: WizardDuck.getDetail(state)
});

export default compose(connect(mapStateToProps), withRouter)(RewardInfo);
