import React, { Component } from "react";
import PropTypes from "prop-types";

import "./pageCount.css";

import Button from "../../../button/button";
import RangeSlider from "../../../rangeSlider/rangeSlider";
import { bm, be } from "../../../../utils/bliss";
import Headline from "../../../headline/headline";
import Text from "../../../text/text";
import Line from "../../../line/line";

const MODULE_NAME = "PageCount";

class PageCountBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: props.pages
    };
  }

  selectPages = pages => {
    const { onSubmit, closeModal } = this.props;

    this.setState(
      {
        pages
      },
      () => {
        onSubmit(pages);
        closeModal();
      }
    );
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.selectPages(e.target.value);
    }
  };

  render() {
    const { closeModal, t } = this.props;
    const { pages } = this.state;

    return (
      <div className={bm(MODULE_NAME)}>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <Headline bold component="h2" subheadline>
                Přesný počet stránek
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "xs")} />
              <Text small>{t("modals.pageCount.label")}</Text>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "md")} />
          <Line />
          <div className={be(MODULE_NAME, "spacing", "lg")} />
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <Headline bold component="h2" xs>
                {t("modals.pageCount.normPagesLabel")}
              </Headline>
              <div className={be(MODULE_NAME, "spacing", "xs")} />
              <RangeSlider
                className="w-100"
                label={`${pages} stran`}
                max={528}
                min={48}
                onChange={value => this.setState({ pages: value })}
                step={16}
                value={pages}
              />
              <div className={be(MODULE_NAME, "spacing", "sm")} />
              <Text component="div" primary small>
                {t("modals.pageCount.description")}
              </Text>
              <div className={be(MODULE_NAME, "spacing", "sm")} />
              <div className={be(MODULE_NAME, "spacing", "xl")} />
              <Button onClick={() => this.selectPages(pages)}>Zadat {pages} stránek do kalkulace</Button>
            </div>
          </div>
          <div className={be(MODULE_NAME, "spacing", "button")} />
          <span onClick={closeModal}>
            <Text light pointer small>
              {t("modals.pageCount.cancel")}
            </Text>
          </span>
        </div>
      </div>
    );
  }
}

PageCountBody.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

export default PageCountBody;
