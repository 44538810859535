import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g, uniqBy, isEmpty } from "lodash";
import moment from "moment";

import { bm, be } from "../../utils/bliss";

import "./projectInfo.css";
import Avatar from "../avatar/avatar";
import Headline from "../headline/headline";
import Text from "../text/text";
import Button from "../button/button";
import ProgressBarLinear from "../progressBar/progressBarLinear/progressBarLinear";
import EnumsDuck from "../../redux/ducks/enums";
import { formatPrice, getActiveNewsCount, getProjectProgress } from "../../utils/utils";
import FollowToggler from "../follow/followToggler";
import Bell from "../bell/bell";
import GaSend from "../ga/gaSend";
import { PREVIEWS_API } from "../../services/variables";

const MODULE_NAME = "ProjectInfo";

class ProjectInfo extends Component {
  getType = state => {
    const { projectStates } = this.props;

    switch (state) {
      case g(projectStates, "proposal.name", ""):
        return "making";

      case g(projectStates, "funding.name", ""):
        return "financing";

      case g(projectStates, "inProgress.name", ""):
        return "inProgress";

      case g(projectStates, "released.name", ""):
        return "released";

      case g(projectStates, "failed.name", ""):
        return "failed";

      default:
        return "making";
    }
  };

  getNews() {
    const { project } = this.props;
    // console.log(project, project.followed && !isEmpty(g(project, 'news', [])))
    if (project.followed && !isEmpty(g(project, "news", []))) {
      return (
        <div className={be(MODULE_NAME, "news")}>
          <div className={be(MODULE_NAME, "newsContent")}>
            <div className={be(MODULE_NAME, "newsTitle")}>
              <Text bold medium>
                {g(project, "news.0.title", "")}
              </Text>
            </div>
            <div className={be(MODULE_NAME, "newsDate")}>
              <Text light medium>
                {moment(g(project, "news.0.updatedAt", "")).format("DD. M. YYYY")}
              </Text>
            </div>
          </div>
          <Bell className="ml-3" number={getActiveNewsCount(g(project, "news", []))} />
        </div>
      );
    }
    return null;
  }

  getMoonRemain() {
    const { project } = this.props;
    if (project.moonTarget && project.moonRemain < 1) {
      return (
        <span className="d-inline-flex align-items-center">
          <img className="mr-2" src="/images/svg/hours.svg" />
          <Text component="div" inverted medium>
            <b>Méně než den</b> do konce
          </Text>
        </span>
      );
    }
    if (project.moonTarget) {
      return (
        <span className="d-inline-flex align-items-center">
          <img className="mr-2" src="/images/svg/hours.svg" />
          <Text component="div" inverted medium>
            <b>{project.moonRemain || ""} dní</b> do konce
          </Text>
        </span>
      );
    }
  }

  renderReleaseDate = project => {
    const type = this.getType(project.state);
    return (
      <div>
        {project.releaseDate && (
          <div className="mt-5 mb-4 text-center">
            <div className={be(MODULE_NAME, "spacing", "xs")} />
            <div>
              <Text component="div" inverted float medium>
                Datum vydání:
              </Text>
              {type === "financing" && (
                <Text component="div" bold inverted floatRight medium>
                  <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
                </Text>
              )}
              {type === "inProgress" && (
                <Text component="div" bold inverted floatRight medium>
                  <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
                </Text>
              )}
              {type === "making" && (
                <Text component="div" inverted bold floatRight medium>
                  <b>{moment(project.releaseDate).format("DD. MM. YYYY")}</b>
                </Text>
              )}
            </div>
            <div className={be(MODULE_NAME, "spacing", "md")} />
            <div className={be(MODULE_NAME, "spacing", "xs")} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      project,
      onClick,
      wrapper,
      projectStates,
      genres: propsGenres,
      handshakesStates,
      book,
      eshop,
      className
    } = this.props;
    const genres = g(project, "genres", []).filter(g => !isEmpty(g));
    const authors = g(project, "author", []);
    const type = this.getType(project.state);
    const followed = g(project, "followed", false);
    const emptyNews = isEmpty(g(project, "news", []));

    const { required } = g(project, "genres", []).reduce(
      (res, gnr) => {
        const genre = {
          ...gnr,
          ...g(propsGenres, gnr.id, {})
        };
        const required = uniqBy([...res.required, ...g(genre, "requiredSubroles", [])], "id");

        return {
          required
        };
      },
      {
        required: []
      }
    );
    const progress = getProjectProgress(project, projectStates, required, handshakesStates);

    if (eshop) {
      return (
        <div className={`${bm(MODULE_NAME, { eshop })} ${className}`} onClick={onClick}>
          <div className={be(MODULE_NAME, "headline")}>
            <Headline card component="h2">
              E-shop s čerstvými knihami
            </Headline>
          </div>
        </div>
      );
    }
    if (book) {
      return (
        <div className={bm(MODULE_NAME, { book })} onClick={onClick}>
          <div className={be(MODULE_NAME, "headline")}>
            <Headline card component="h2" inverted>
              A co tvoje kniha?
            </Headline>
          </div>
          <div className={be(MODULE_NAME, "book")}>
            <img alt="book" className="img-fluid" src="/images/svg/book2.svg" />
          </div>
          <div className={be(MODULE_NAME, "button")}>
            <GaSend action="click" category="button" label="Chci konečně vydat svoji knihu">
              <Button invertedPrimary radiusBig to="/register" wide>
                Chci konečně vydat svoji knihu
              </Button>
            </GaSend>
          </div>
        </div>
      );
    }

    let price = null;
    if ((type === "inProgress" || type === "released") && project.rewards) {
      const filteredRewards = project.rewards.filter(r => r.physical === 1 && r.state === "active");
      price = filteredRewards.length === 1 ? filteredRewards[0].price : null;
    }

    return (
      <div className={bm(MODULE_NAME, { wrapper, followed })} onClick={onClick}>
        <div className={be(MODULE_NAME, "headline")}>
          {type === "financing" && (
            <Fragment>
              <Headline card component="h2" green>
                {project.name}
              </Headline>
            </Fragment>
          )}
          {type === "making" && (
            <Fragment>
              <Headline card component="h2">
                {project.name}
              </Headline>
            </Fragment>
          )}
          {type === "inProgress" && (
            <Fragment>
              <Headline blue card component="h2">
                {project.name}
              </Headline>
            </Fragment>
          )}
          {type === "released" && (
            <Fragment>
              <Headline card component="h2" primary>
                {project.name}
              </Headline>
            </Fragment>
          )}
          <FollowToggler project={project} />
        </div>
        <div className={be(MODULE_NAME, "spacing", "md")} />
        <div className={be(MODULE_NAME, "genres")}>
          {genres.map((genre, idx) => (
            <span key={project.id + genre.id}>
              <Text firstUp light medium>{`${genre.name}${idx !== genres.length - 1 ? ", " : ""}`}</Text>
            </span>
          ))}
        </div>
        {/* <div className={be(MODULE_NAME, 'tags')}>
                    {g(project, 'tags', []).filter(t => !isEmpty(t)).map((tag, idx) => {
                        return (
                            <span key={project.id + tag.id}><Text medium light firstUp>{`${tag.name}${idx !== project.tags.length - 1 ? ', ' : ''}`}</Text></span>
                        );
                    })}
                </div> */}

        <div className={be(MODULE_NAME, "spacing", "sm")} />

        {this.getNews()}

        <div
          className={be(MODULE_NAME, "progress", {
            followed,
            emptyNews
          })}
        >
          {type === "released" && (project.coverPhoto || project.bibliographyDescription) && (
            <div className={be(MODULE_NAME, "bibliography")}>
              {project.coverPhoto && (
                <img className={be(MODULE_NAME, "coverPhoto")} src={`${PREVIEWS_API}${project.coverPhoto}`} />
              )}
              {project.bibliographyDescription && (
                <Text bold small>
                  {project.bibliographyDescription}
                </Text>
              )}
            </div>
          )}
          {type !== "released" && project.bibliographyDescription && (
            <div className={be(MODULE_NAME, "bibliography")}>
              {project.bibliographyDescription && (
                <Text bold small>
                  {project.bibliographyDescription}
                </Text>
              )}
            </div>
          )}
          <div className={be(MODULE_NAME, "authors")}>
            {authors.map(author => (
              <div key={project.id + author.id} className={be(MODULE_NAME, "author")}>
                <div className={be(MODULE_NAME, "authorAvatar")}>
                  <Avatar image={author.profilePic} shadow />
                </div>
                <div className={be(MODULE_NAME, "authorName")}>
                  <Text bold small>
                    {author.name} {author.surname}
                  </Text>
                </div>
              </div>
            ))}
          </div>

          <div className={be(MODULE_NAME, "spacing", "md")} />
          <div className={be(MODULE_NAME, "spacing", "md")} />
          <div className={be(MODULE_NAME, "spacing", "sm")} />

          {type === "financing" && (
            <Fragment>
              <div className={be(MODULE_NAME, "background", "financing")}>
                <Headline bold component="h4" inverted xxs>
                  {project.moonTarget ? "FINANCOVÁNÍ" : "ČEKÁ SE NA PŘEDPRODEJ"}
                </Headline>

                {project.moonTarget && (
                  <Fragment>
                    <div>
                      <span>
                        <Text component="div" float inverted medium>
                          <b>
                            {parseInt(project.moonCollected || 0)
                              .toLocaleString("en-US")
                              .replace(",", " ")}{" "}
                            Kč
                          </b>{" "}
                          vybráno
                        </Text>
                        <ProgressBarLinear progress={progress} />
                      </span>
                    </div>
                    <Text component="div" inverted medium>
                      <b>{project.moonContributors || 0} lidí </b>
                      přispělo
                    </Text>
                  </Fragment>
                )}
                <div className={be(MODULE_NAME, "spacing", "sm")} />
                {this.getMoonRemain()}
                <div className={be(MODULE_NAME, "spacing", "sm")} />
                {project.moonTarget && (
                  <div className="text-center">
                    <Button invertedGreen small wide>
                      Přispět na knihu
                    </Button>
                  </div>
                )}
              </div>
            </Fragment>
          )}
          {type === "making" && (
            <Fragment>
              {/* <Headline component='h4' bold xs light>Kniha se připravuje</Headline> */}
              <div className={be(MODULE_NAME, "background", "making")}>
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <div className="text-center">
                  <span className="d-inline-flex align-items-center">
                    <img className="mr-2" src="/images/svg/colleagues.svg" />
                    <Text component="div" inverted medium>
                      Kniha hledá kolegy
                    </Text>
                  </span>
                </div>
                <div className={be(MODULE_NAME, "spacing", "md")} />
                <div className="text-center">
                  <Button grey small wide>
                    Omrknout ukázku
                  </Button>
                </div>
              </div>
            </Fragment>
          )}
          {type === "inProgress" && (
            <Fragment>
              <div className={be(MODULE_NAME, "background", "inProgress")}>
                <div>
                  <span>
                    <Text component="div" float inverted medium>
                      <b>KNIHA SE VYRÁBÍ</b>
                    </Text>

                    <ProgressBarLinear progress={progress} />
                  </span>
                </div>
                <div className={be(MODULE_NAME, "spacing", "sm")} />
                {/* <div className={be(MODULE_NAME, 'spacing','xs')} /> */}
                {/* <Text medium light component='div'>Prodáno <b>113 ks</b></Text> */}
                {type !== "failed" && this.renderReleaseDate(project)}
                {project.moonlineUrl && (
                  <div className="text-center">
                    <Button inverted href={project.moonlineUrl} small target="_blank" wide>
                      KOUPIT {price && `ZA ${Math.round(price)} KČ`}
                    </Button>
                  </div>
                )}
              </div>
            </Fragment>
          )}
          {type === "released" && (
            <Fragment>
              {/* <Headline component='h4' bold xs light>Kniha se připravuje</Headline> */}
              <div className={be(MODULE_NAME, "background", "released")}>
                <div className={be(MODULE_NAME, "spacing", "sm")} />
                <div className="text-center">
                  <Button greyPrimary href={project.moonlineUrl} small target="_blank" wide>
                    KOUPIT {price && `ZA ${Math.round(price)} KČ`}
                  </Button>
                </div>
                <div className={be(MODULE_NAME, "spacing", "sm")} />
              </div>
            </Fragment>
          )}

          {type === "failed" && (
            <Fragment>
              <Headline blue bold component="h4" light xs>
                Kniha se nezdařila
              </Headline>
            </Fragment>
          )}
        </div>
        {/* {type !== 'failed' && <div className='col-4 mt-0 pl-0 d-flex justify-content-end'>
                    <ProgressBarCircle progress={progress} type={type}/>
                </div>} */}
      </div>
    );
  }
}

ProjectInfo.defaultProps = {
  project: {},
  className: "",
  onClick: () => {}
};

ProjectInfo.propTypes = {
  className: PropTypes.string,
  followed: PropTypes.bool,
  genres: PropTypes.object.isRequired,
  handshakesStates: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  project: PropTypes.object,
  projectStates: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  genres: EnumsDuck.getGenres(state),
  handshakesStates: EnumsDuck.getHandhakeStates(state),
  projectStates: EnumsDuck.getProjectStates(state)
});
export default connect(mapStateToProps)(ProjectInfo);
