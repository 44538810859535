/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import "../messageListPage.css";
import { be } from "../../../utils/bliss";
import Avatar from "../../../components/avatar/avatar";
import { isMobile } from "../../../utils/utils";
import Text from "../../../components/text/text";

const MODULE_NAME = "MessageListPage";

class MessageItem extends Component {
  render() {
    const { read } = this.props;
    return read ? (
      !isMobile() ? (
        <div className="row d-flex media Comment Comment-main" style={{ alignItems: "center" }}>
          <div className="col-1 Comment-avatar">
            <Avatar small />
          </div>
          <div className="col-1">
            <div>
              <Text bold medium>
                Jan Novak
              </Text>
            </div>
            <div>
              <Text medium>Sazeč</Text>
            </div>
          </div>
          <div className="col-2">
            <Text medium>Prague Coffee Guide</Text>
          </div>
          <div className="col-7 pb-3">
            It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
            McClintock, a Latin professor
          </div>
          <div className="col-1">
            <Text disabled medium>
              25.06.2019
            </Text>
          </div>
        </div>
      ) : (
        <div className="d-flex Comment Comment-main" style={{ alignItems: "center" }}>
          <div className="d-flex flex-column w-100">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div className="Comment-avatarMessenger">
                <Avatar small />
              </div>
              <div>
                <div style={{ maxWidth: "185px" }}>
                  <span className="pr-2">
                    <Text bold medium>
                      Jan Novak
                    </Text>
                  </span>
                  <Text medium>Sazeč</Text>
                  <div>It has roots in a piece of...</div>
                  <div>
                    <Text disabled medium>
                      Spolupráce odmítnutá
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div>
                <Text disabled medium>
                  25.06.2019
                </Text>
              </div>
            </div>
          </div>
        </div>
      )
    ) : !isMobile() ? (
      <div className="row d-flex media Comment Comment-main" style={{ alignItems: "center" }}>
        <div className="col-1 Comment-avatar">
          <Avatar small />
        </div>
        <div className="col-1">
          <div>
            <Text bold medium>
              Jan Novak
            </Text>
          </div>
          <div>
            <Text medium>Sazeč</Text>
          </div>
        </div>
        <div className="col-2">
          <Text medium>Prague Coffee Guide</Text>
        </div>
        <div className="col-7 pb-3">
          It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
          McClintock, a Latin professor
        </div>
        <div className="col-1">
          <div className={be(MODULE_NAME, "dot")} />
        </div>
        <div className="col d-flex justify-content-end p-0">
          <Text disabled medium>
            25.06.2019
          </Text>
        </div>
      </div>
    ) : (
      <div className="d-flex Comment Comment-main" style={{ alignItems: "center" }}>
        <div className="d-flex flex-column w-100">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <div className="Comment-avatarMessenger">
              <Avatar small />
            </div>
            <div>
              <div>
                <span className="pr-2">
                  <Text bold medium>
                    Jan Novak
                  </Text>
                </span>
                <Text medium>Sazeč</Text>
                <div>It has roots in a piece of...</div>
                <div>
                  <Text disabled medium>
                    Spolupráce odmítnutá
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={be(MODULE_NAME, "dotMobile")} />
          </div>
          <div className="d-flex justify-content-end">
            <div>
              <Text disabled medium>
                25.06.2019
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageItem;
