/* global dataLayer */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";

import _sumBy from "lodash/sumBy";
import _reject from "lodash/reject";
import _map from "lodash/map";

import CoreAPIService from "../../../../services/coreAPIService";
import EnumsDuck from "../../../../redux/ducks/enums";
import UIDuck from "../../../../redux/ducks/ui";

class ShowModal extends React.Component {
  componentWillMount() {
    const { project, location, history, dispatch } = this.props;
    const params = new URLSearchParams(location.search);
    const { type } = project;

    const showSuccessPayment = params.get("success") === "true";
    const showErrorPayment = params.get("success") === "false" && type !== "financing";
    const showErrorPaymentFinancing = params.get("success") === "false" && type === "financing";

    if (params.get("orderId")) {
      CoreAPIService.get(`orders/${params.get("orderId")}`).then(({ data }) => {
        const items = _reject(data.items, i => i.id_book === -1 && i.price < 1);
        const books = _reject(data.items, i => i.id_book === -1);

        const price = _sumBy(data.items, i => i.num * i.price);
        dataLayer.push({
          event: "contentPurchase",
          typ_nakupu: books[books.length - 1].project.moonlineType === "pre-sale" ? "predprodej knihy" : "vydana kniha",
          value: price,
          items: _map(items, item => {
            if (item.id_book === -1 && item.id_reward === 1) {
              return {
                id: -1,
                price: Math.round(item.price),
                quantity: item.num
              };
            }
            return {
              id: item.project.id,
              price: Math.round(item.price),
              quantity: item.num
            };
          })
        });

        dataLayer.push({
          event: "transaction",
          bookState:
            books[books.length - 1].project.moonlineType === "pre-sale"
              ? "předprodej"
              : books[books.length - 1].project.moonlineType === "active"
              ? "vydané"
              : "výroba",
          ecommerce: {
            purchase: {
              actionField: {
                id: data.externId,
                affiliation: "pointa",
                revenue: price,
                tax: price - price / 1.21,
                shipping: "",
                coupon: ""
              },
              products: _map(items, item => {
                if (item.id_book === -1 && item.id_reward === 1) {
                  return {
                    name: "Doprava - Česká pošta",
                    id: -1,
                    price: Math.round(item.price),
                    brand: "Česká pošta",
                    category: "Doprava",
                    variant: "Balík",
                    quantity: item.num,
                    coupon: ""
                  };
                }
                return {
                  name: item.project.name,
                  id: item.project.id,
                  price: Math.round(item.price),
                  brand: item.project.authorName,
                  category: item.project.genres[0].name,
                  variant: `${item.id_reward} - ${item.reward.title}`,
                  quantity: item.num,
                  coupon: ""
                };
              })
            }
          }
        });
      });
    }

    if (showSuccessPayment) {
      dispatch(UIDuck.openModal(UIDuck.modalTypes.successfulPayment));
      setTimeout(() => {
        history.push(window.location.href.length - 13);
      }, 500);
    } else if (showErrorPayment) {
      dispatch(UIDuck.openModal(UIDuck.modalTypes.unsuccessfulPayment));
      setTimeout(() => {
        history.push(window.location.href.length - 13);
      }, 500);
    } else if (showErrorPaymentFinancing) {
      dispatch(UIDuck.openModal(UIDuck.modalTypes.unsuccessfulPaymentFinancing));
      setTimeout(() => {
        history.push(window.location.href.length - 13);
      }, 500);
    }
  }

  render() {
    return <React.Fragment />;
  }
}

const mapStateToProps = state => ({
  projectStates: EnumsDuck.getProjectStates(state)
});

ShowModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  projectStates: PropTypes.object.isRequired
};

export default compose(translate("translations"), connect(mapStateToProps))(ShowModal);
