import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as g } from "lodash";

import { bm, be } from "../../utils/bliss";
import "./contributorHandshake.css";

import WizardDuck from "../../redux/ducks/wizard";
import Button from "../button/button";
import Text from "../text/text";
import { compose } from "recompose";
import { translate } from "react-i18next";
import Forms from "../forms/form";
import FormDuck from "../../redux/ducks/forms";
import UserDuck from "../../redux/ducks/user";

const MODULE_NAME = "ContributorHandshake";

class ContributorHandshake extends Component {
  state = {
    submitted: false
  };

  componentWillUnmount() {
    this.props.dispatch(FormDuck.resetForm(FormDuck.formTypes.requestHandshake));
  }

  get subroles() {
    return g(this.props.user, "subroles", []);
  }

  submit = formData => {
    const { t, dispatch, project, user } = this.props;
    const message = g(formData, "message", null);
    const promises = g(formData, "subroles", []).map(s =>
      dispatch(
        UserDuck.setCooperation({
          subroleId: s,
          projectId: project.id,
          contributorId: user.id,
          contributorDescription: message
        })
      )
    );

    Promise.all(promises)
      .then(() => {
        this.props.toggleHandshakeRequest(false);
        this.props.toggleShowSuccessTopBar(true);
        window.scrollTo(0, 0);
      })
      .catch(err => {
        if (g(err, "response.data.code", "")) {
          // history.push('/login');
          // window.alertify.error('Prosím přihlaste se, vypršela platnost Vašeho tokenu!');
        }
      });
  };

  shouldDisableSubrole = (projectHandshakes, currentSubrole) => {
    // Když jsou tři handshaky pro danou roli, disable
    const subroleHandshakes = projectHandshakes.filter(
      h => h.subrole.id === currentSubrole.subrole.id && h.state === "REQUESTED"
    );
    if (subroleHandshakes.length > 2) {
      return true;
    }

    // 23. 2. 2024: Pokud se někde neprovádí řazení handshaků, tak následující kód vypadá podezřele a na první pohled vypadá,
    // že může selhávat
    // Handshaky pro danou roli mohou být až tři, ale následující kód vybere pouze první z nich
    // Aby to fungovalo, muselo by být zaručeno, že jako první mezi handshaky pro danou roli je handshake
    // s ACCEPTED nebo takový, kde je contributorem právě přihlášený uživatel
    // Vypadá to jako pozůstatek z minulosti, kdy třeba mohl být povolený pouze jeden handshake
    const handshake = projectHandshakes.find(h => h.subrole.id === currentSubrole.subrole.id);
    return handshake && (handshake.state === "ACCEPTED" || handshake.contributorId === this.props.user.id);
  };

  getForm = () => {
    const { t, project } = this.props;
    const projectHandshakes = g(project, "handshakes", []);

    const formInputs = [
      {
        label: (
          <Text bold small>
            Nabízím spolupráci jako
          </Text>
        ),
        type: "checkbox-list",
        name: "subroles",
        required: true,
        className: "Form-checkbox",
        items: this.subroles.map(subrole => ({
          label: subrole.subrole.name,
          value: subrole.subrole.id,
          disabled: this.shouldDisableSubrole(projectHandshakes, subrole)
        }))
      },
      {
        placeholder: "Napiš autorovi/autorce něco o sobě a řekni mu/jí, čím můžeš být pro jeho/její knihu přínosný",
        label: (
          <Text bold small>
            Proč se chceš podílet?
          </Text>
        ),
        type: "textarea",
        wide: true,
        name: "message",
        className: "col-xs-12 mb-4",
        required: true
      }
    ];

    return (
      <Forms center inputs={formInputs} name={FormDuck.formTypes.requestHandshake} onSubmit={this.submit}>
        <div className={be(MODULE_NAME, "formSpacing")} />
        <div>
          <Button medium type="submit" wide>
            Odeslat nabídku
          </Button>
        </div>
      </Forms>
    );
  };

  render() {
    if (this.subroles.length) {
      return (
        <div className={bm(MODULE_NAME)}>
          <div className={be(MODULE_NAME, "background", "financing")}>
            <Text bold component="div" inverted light>
              CHCI SPOLUPRACOVAT
            </Text>
          </div>
          {this.getForm()}
        </div>
      );
    }
  }
}

ContributorHandshake.propTypes = {
  project: PropTypes.object,
  t: PropTypes.func.isRequired,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  project: WizardDuck.getDetail(state),
  user: UserDuck.getUser(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(ContributorHandshake);
