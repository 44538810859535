import React from "react";
import PropTypes from "prop-types";
import { Line } from "rc-progress";
import { bm, be } from "../../../utils/bliss";

import "./progressBarFunding.css";

const MODULE_NAME = "ProgressBarFunding";

const ProgressBarFunding = ({ progress }) => (
  <div className={bm(MODULE_NAME)}>
    <Line
      percent={progress > 100 ? 100 : progress}
      strokeColor="#E73F2D"
      strokeWidth="2"
      text={`${progress}%`}
      trailColor="#D9DAD7"
      trailWidth="2"
    />
  </div>
);

ProgressBarFunding.defaultProps = {
  progress: 0
};

ProgressBarFunding.propTypes = {
  progress: PropTypes.number
};

export default ProgressBarFunding;
