import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import Button from "../../../button/button";
import { be, bm } from "../../../../utils/bliss";
import "./newsletter.css";
import Headline from "../../../headline/headline";
import Link from "../../../link/link";
import Text from "../../../text/text";
import Forms from "../../../forms/form";
import FormDuck from "../../../../redux/ducks/forms";
import masks from "./img/masks.png";
import UserService from "../../../../services/userService";
import UIDuck from "../../../../redux/ducks/ui";
import { API } from "../../../../services/variables";
import GA from "../../../ga/ga";

const MODULE_NAME = "NewsletterPopup";

class Newsletter extends Component {
  constructor(props) {
    super(props);
  }

  subscribe = (data = {}) => {
    const { onClose, dispatch } = this.props;

    UserService.subscribe(data.email, data.gdpr).then(() => {
      onClose();
      dispatch(UIDuck.openModal(UIDuck.modalTypes.newsletterThanks));
      GA.sendEvent({
        eventCategory: "form",
        eventAction: "submit",
        eventLabel: "newsletter"
      });
    });
  };

  render() {
    return (
      <div className={bm(MODULE_NAME)}>
        <div className={be(MODULE_NAME, "header")}>
          <Headline bold component="h2" subheadline>
            Měníme svět knih. Buď u toho!
          </Headline>
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Text small>
                Nakladatelství Pointa chce českou nakladatelskou tradici rozšířit o nový směr. Nejen debutujícím autorům
                chce dodat odvahu vydat to, co schovávají v šuplíku nebo počítači, ale i nabídnout potřebné nástroje.
                Zároveň chce tvořit literární komunitu lákající neokoukané talenty i angažované čtenáře.
              </Text>
            </div>
          </div>
        </div>
        <div className={be(MODULE_NAME, "bottom")}>
          <div className={be(MODULE_NAME, "left")}>
            <div className={be(MODULE_NAME, "form")}>
              <Forms
                center
                inputs={[
                  {
                    placeholder: "Email",
                    label: (
                      <div className="pl-3">
                        {" "}
                        <Text small>
                          <b>
                            Odebírejte novinky dvakrát měsíčně a můžete se těšit na zprávy a fotky z naších akcí a
                            besed, to nejnovější z našeho blogu a tipy na knižní novinky.
                          </b>
                        </Text>
                      </div>
                    ),
                    name: "email",
                    entityType: "email",
                    className: "col-xs-12",
                    required: true
                  },
                  {
                    label: (
                      <Text small>
                        Souhlasím se{" "}
                        <Link target="_blank" to={`${API}/docs/gdpr`}>
                          zpracováním osobních údajů
                        </Link>
                      </Text>
                    ),
                    name: "codexAgreed",
                    type: "checkbox",
                    required: true,
                    className: "Form-checkbox"
                  }
                ]}
                name={FormDuck.formTypes.subscribe}
                onSubmit={this.subscribe}
              >
                <div className={be(MODULE_NAME, "formSpacing")} />
                <div>
                  <Button mini type="submit">
                    odeslat
                  </Button>
                </div>
              </Forms>
            </div>
          </div>
          <div className={be(MODULE_NAME, "right")}>
            <div className={be(MODULE_NAME, "imageBox")}>
              <img alt="masky" className={be(MODULE_NAME, "image", null, "img-fluid")} src={masks} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Newsletter);
